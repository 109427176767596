//
import { useState } from "react";

// Material UI
import {
  Card,
  Container,
  Typography,
  Button,
  Stack,
  Divider,
  TextField,
  Autocomplete,
  Grid,
  IconButton,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
  TablePagination,
} from "@mui/material";

// Componenst
import {
  AlertDialog,
  LoadingOrder,
  LoadingPage,
  Page,
  ListHead,
  SearchNotFound,
  ActionTable,
} from "../../../components";

// Router
import { useParams, useHistory } from "react-router";

// Icon
import { Icon } from "@iconify/react";
import trashFill from "@iconify/icons-eva/trash-2-fill";
import arrowBackFill from "@iconify/icons-eva/arrow-back-fill";

// API
import customer from "../../../constant/customer";
import salesCustomer from "../../../constant/salesCustomer";

// Toast
import { toast } from "react-toastify";

//
import { useData } from "../../../context/DataProvider";
import {
  GetDetailCustomer,
  GetCustomerType,
  GetBusinessType,
  GetSales,
  GetAllContact,
} from "./API";
import TableToolbar from "../../../components/Table/TableToolBar";
import { applySortFilter, getComparator } from "../../../utils";
import AddContactPerson from "./AddContactPerson";
import contact from "../../../constant/contact";

function DetailCustomer() {
  const { user } = useData();

  const history = useHistory();

  const { id } = useParams();

  const [detail, loading, setDetail, refresh] = GetDetailCustomer(id);

  const [listContact, loadingContact, refreshContact] = GetAllContact(id);

  const [listType, loadingType] = GetCustomerType();

  const [listBusiness, loadingBusiness] = GetBusinessType();

  const [listSales, loadingSales] = GetSales(user.role);

  const [isEdit, setEdit] = useState(false);

  const [dialog, setDialog] = useState({
    edit: false,
    delete: false,
  });

  const [error, setError] = useState({
    name: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const validate = () => {
    const newError = { ...error };

    const { name } = detail;

    if (!name) {
      newError.no_hp = "Name cannot be empty";
    }

    return newError;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setEdit(true);

    setDetail((f) => ({
      ...f,
      [name]: value,
    }));

    setError((f) => ({
      ...f,
      [name]: "",
    }));
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      // Delete Customer
      await customer.destroy(id);

      toast.success("Customer deleted");

      history.push("/customer");
    } catch (e) {
      console.log(e);
    }
    setIsSubmitting(false);
  };

  const handleSubmit = async () => {
    const findError = validate();

    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setIsSubmitting(true);
      try {
        if (isEdit) {
          // Sales Customer
          await salesCustomer.update({
            customer_id: detail?.id,
            sales_id: detail?.sales?.id ?? 0,
          });

          // Customer Section
          await customer.update(id, { ...detail });
        }

        setEdit(false);

        setDialog((d) => ({
          ...d,
          edit: false,
        }));

        refresh(true);

        // Notify
        toast.success("Customer Edited");
      } catch (e) {
        console.log(e);
      }
      setIsSubmitting(false);
    }
  };

  if (loading || loadingContact) {
    return <LoadingPage />;
  }

  return (
    <Page title="Detail Customer | Bayu Sinergi">
      {isSubmitting ? <LoadingOrder /> : null}

      {/* Navigation */}
      <Stack direction="row" alignItems="center" sx={{ px: 3 }} mb={3}>
        <IconButton
          size="small"
          onClick={() => {
            history.goBack();
          }}
        >
          <Icon icon={arrowBackFill} fontSize={24} />
        </IconButton>
        <Typography sx={{ ml: 1 }} variant="h5">
          Detail Customer Management
        </Typography>
      </Stack>

      <Container maxWidth="xl">
        <Card sx={{ py: 4, px: 3 }}>
          {/* Detail */}
          <Stack
            mb={3}
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <Typography variant="subtitle1">Detail Customer</Typography>
            {user.role !== "bod" && (
              <Button
                variant="contained"
                color="error"
                startIcon={<Icon icon={trashFill} />}
                disabled={isSubmitting}
                onClick={() => {
                  setDialog((d) => ({
                    ...d,
                    delete: true,
                  }));
                }}
              >
                Delete
              </Button>
            )}
          </Stack>

          {/* Form Top */}
          <Grid container spacing={3}>
            {/* Section 1 */}
            <Grid item lg={6} md={6}>
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
              >
                <Typography style={{ width: 140 }}>Name</Typography>
                {user?.role !== "bod" ? (
                  <TextField
                    name="name"
                    size="small"
                    value={detail?.name}
                    onChange={handleChange}
                    error={error.name ? true : false}
                    helperText={error.name}
                    disabled={isSubmitting}
                    fullWidth
                  />
                ) : (
                  <Typography>{detail?.name}</Typography>
                )}
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={3}
              >
                <Typography style={{ width: 140 }}>
                  NIB (Nomor Induk Berusaha)
                </Typography>
                {user?.role !== "bod" ? (
                  <TextField
                    name="nib"
                    size="small"
                    autoComplete="off"
                    value={detail?.nib}
                    onChange={handleChange}
                    error={error.nib ? true : false}
                    helperText={error.nb}
                    disabled={isSubmitting}
                    fullWidth
                  />
                ) : (
                  <Typography>{detail?.nib}</Typography>
                )}
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={3}
              >
                <Typography style={{ width: 140 }}>Customer Type</Typography>
                {user?.role !== "bod" ? (
                  <Autocomplete
                    fullWidth
                    getOptionLabel={(option) => option.name}
                    options={listType}
                    loading={loadingType}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={error.custtype ? true : false}
                        helperText={error.custtype}
                      />
                    )}
                    onChange={(e, v) => {
                      setDetail((f) => ({
                        ...f,
                        custtype: v,
                      }));
                      setError((e) => ({
                        ...e,
                        custtype: "",
                      }));
                    }}
                    value={detail.custtype}
                    disabled={isSubmitting}
                    sx={{ mb: 2 }}
                  />
                ) : (
                  <Typography>{detail?.custtype?.name ?? "-"}</Typography>
                )}
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={3}
              >
                <Typography style={{ width: 140 }}>Bidang Usaha</Typography>
                {user?.role !== "bod" ? (
                  <Autocomplete
                    fullWidth
                    getOptionLabel={(option) => option.name}
                    options={listBusiness}
                    loading={loadingBusiness}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        size="small"
                        error={error.businesstype ? true : false}
                        helperText={error.businesstype}
                      />
                    )}
                    onChange={(e, v) => {
                      setDetail((f) => ({
                        ...f,
                        businesstype: v,
                      }));
                      setError((e) => ({
                        ...e,
                        businesstype: "",
                      }));
                    }}
                    value={detail.businesstype}
                    disabled={isSubmitting}
                    sx={{ mb: 2 }}
                  />
                ) : (
                  <Typography>{detail?.businesstype?.name ?? "-"}</Typography>
                )}
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={3}
              >
                <Typography style={{ width: 140 }}>Sales</Typography>
                {user.role !== "bod" ? (
                  <Autocomplete
                    size="small"
                    fullWidth
                    getOptionLabel={(option) => option.name}
                    options={listSales}
                    loading={loadingSales}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                    onChange={(e, v) => {
                      setDetail((f) => ({
                        ...f,
                        sales: v,
                      }));

                      setEdit(true);
                    }}
                    value={detail.sales}
                    disabled={isSubmitting}
                  />
                ) : (
                  <Typography>{detail?.sales?.name ?? "-"}</Typography>
                )}
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={3}
              >
                <Typography style={{ width: 140 }}>Company Address</Typography>
                {user?.role !== "bod" ? (
                  <TextField
                    name="address"
                    size="small"
                    multiline
                    minRows={1}
                    maxRows={2}
                    value={detail?.address}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    fullWidth
                  />
                ) : (
                  <Typography>{detail?.address ?? "-"}</Typography>
                )}
              </Stack>
            </Grid>

            {/* Section 2 */}
            <Grid item lg={6} md={6}>
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
              >
                <Typography style={{ width: 140 }}>Billing Address</Typography>
                {user?.role !== "bod" ? (
                  <TextField
                    name="address_billing"
                    size="small"
                    multiline
                    minRows={1}
                    maxRows={2}
                    value={detail?.address_billing}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    fullWidth
                  />
                ) : (
                  <Typography>{detail?.address_billing ?? "-"}</Typography>
                )}
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={3}
              >
                <Typography style={{ width: 140 }}>Email</Typography>
                {user?.role !== "bod" ? (
                  <TextField
                    name="email"
                    size="small"
                    onChange={handleChange}
                    value={detail?.email}
                    fullWidth
                    disabled
                  />
                ) : (
                  <Typography>{detail?.email ?? "-"}</Typography>
                )}
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={3}
              >
                <Typography style={{ width: 140 }}>Contact</Typography>
                {user?.role !== "bod" ? (
                  <TextField
                    autoComplete="off"
                    name="contact"
                    size="small"
                    value={detail?.contact}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    fullWidth
                  />
                ) : (
                  <Typography>{detail?.contact ?? "-"}</Typography>
                )}
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={3}
              >
                <Typography style={{ width: 140 }}>Description</Typography>
                {user?.role !== "bod" ? (
                  <TextField
                    name="description"
                    size="small"
                    multiline
                    minRows={1}
                    maxRows={2}
                    value={detail?.description}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    fullWidth
                  />
                ) : (
                  <Typography>{detail?.description ?? "-"}</Typography>
                )}
              </Stack>

              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={3}
              >
                <Typography style={{ width: 140 }}>Note</Typography>
                {user?.role !== "bod" ? (
                  <TextField
                    name="note"
                    size="small"
                    multiline
                    minRows={1}
                    maxRows={2}
                    value={detail?.note}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    fullWidth
                  />
                ) : (
                  <Typography>{detail?.note ?? "-"}</Typography>
                )}
              </Stack>
            </Grid>
          </Grid>

          {/* Action Button */}
          {user.role !== "bod" && (
            <Stack mt={3} alignItems="flex-end">
              <Button
                onClick={() => {
                  setDialog((d) => ({
                    ...d,
                    edit: true,
                  }));
                }}
                disabled={!isEdit || isSubmitting}
                variant="contained"
              >
                Edit Customer
              </Button>
            </Stack>
          )}
        </Card>

        {/* Gap */}
        <div style={{ height: 40 }} />

        {/* Contact Person */}
        <ContactPerson listContact={listContact} refresh={refreshContact} />
      </Container>

      {/* Edit Dialog */}
      <AlertDialog
        open={dialog.edit}
        title={"Edit Customer"}
        content={"Are you sure you want edit this customer?"}
        handleOk={handleSubmit}
        handleClose={() => {
          setDialog((d) => ({
            ...d,
            edit: false,
          }));
        }}
      />

      {/* Delete Dialog */}
      <AlertDialog
        open={dialog.delete}
        title={"Delete Customer"}
        content={"Are you sure you want delete this customer?"}
        handleOk={handleDelete}
        handleClose={() => {
          setDialog((d) => ({
            ...d,
            delete: false,
          }));
        }}
      />
    </Page>
  );
}

const ContactPerson = ({ listContact, refresh }) => {
  const { user } = useData();

  const [q, setQ] = useState("");

  const [dialog, setDialog] = useState({
    open: false,
    data: {},
    mode: "Add",
  });

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("name");

  const [no, setNo] = useState(0);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setNo(no + 5);
    } else {
      setNo(no - 5);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const contactList = applySortFilter(
    listContact,
    getComparator(order, orderBy),
    q,
    "name"
  );

  const isNotFound = contactList?.length === 0;

  const TABLE_HEAD = [
    { id: "no", label: "No", alignRight: false, isSort: false },
    { id: "name", label: "Name", alignRight: false, isSort: true },
    {
      id: "email",
      label: "Email",
      alignRight: false,
      isSort: false,
    },
    {
      id: "contactnumber",
      label: "Phone Number",
      alignRight: false,
      isSort: false,
    },
    {
      id: "note",
      label: "Note",
      alignRight: false,
      isSort: false,
    },
    {
      id: "",
    },
  ];

  const TABLE_HEAD_BOD = [
    { id: "no", label: "No", alignRight: false, isSort: false },
    { id: "name", label: "Name", alignRight: false, isSort: true },
    {
      id: "email",
      label: "Email",
      alignRight: false,
      isSort: false,
    },
    {
      id: "contactnumber",
      label: "Phone Number",
      alignRight: false,
      isSort: false,
    },
    {
      id: "note",
      label: "Note",
      alignRight: false,
      isSort: false,
    },
  ];

  return (
    <>
      <Card>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
          sx={{ pt: 4, px: 3 }}
        >
          <Typography variant="subtitle1">List Contact Person</Typography>
          {user?.role !== "bod" && (
            <Button
              onClick={(e) => {
                setDialog({ mode: "Add", open: true, data: {} });
              }}
              variant="contained"
            >
              New Contact Person
            </Button>
          )}
        </Stack>

        {/* Search */}
        <TableToolbar
          value={q}
          onChange={(e) => {
            setQ(e.target.value);
            setPage(0);
          }}
          placeholder="Search Contact"
          onClear={() => setQ("")}
        />

        <TableContainer sx={{ minWidth: 200 }}>
          <Table>
            <ListHead
              order={order}
              orderBy={orderBy}
              headLabel={user?.role === "bod" ? TABLE_HEAD_BOD : TABLE_HEAD}
              onRequestSort={handleRequestSort}
              colored
            />
            <TableBody>
              {contactList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{index + 1 + no}</TableCell>
                      <TableCell>{item?.name ?? "-"}</TableCell>
                      <TableCell>{item?.email ?? "-"}</TableCell>
                      <TableCell>{item?.contactnumber ?? "-"}</TableCell>
                      <TableCell>{item?.note ?? "-"}</TableCell>
                      {user?.role !== "bod" && (
                        <ActionTable
                          onEdit={() => {
                            setDialog({
                              open: true,
                              data: item,
                              mode: "Edit",
                            });
                          }}
                          onDelete={async () => {
                            try {
                              await contact.delete(item?.id);

                              refresh(true);

                              toast.success("Contact Person Deleted!");
                            } catch (e) {
                              console.log(e);
                            }
                          }}
                        />
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={q} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={contactList?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      {/* Dialog */}
      <AddContactPerson
        dialog={dialog}
        load={() => {
          refresh(true);
        }}
        onClose={() => {
          setDialog((v) => ({
            ...v,
            mode: "Add",
            open: false,
          }));
        }}
      />
    </>
  );
};

export default DetailCustomer;
