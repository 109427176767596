//
import { useState, useEffect } from "react";
// material
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
  Autocomplete,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
//
import pajak from "../../../constant/pajak";
//
import { toast } from "react-toastify";
//
import { GetAll } from "../Province/API";

function AddPajak({ onClose, load, dialog: { open, mode, data } }) {
  const [listProvince, loading] = GetAll();

  const [form, setForm] = useState({
    province: null,
    pajak: "",
  });

  const [error, setError] = useState({
    province: "",
    pajak: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((f) => ({
      ...f,
      [name]: value,
    }));

    setError((f) => ({
      ...f,
      [name]: "",
    }));
  };

  const validate = () => {
    const newError = { ...error };
    const { province, pajak } = form;

    if (!province) {
      newError.province = "Province can't be empty";
    }

    if (!pajak) {
      newError.oajak = "Pajak can't be empty";
    }

    return newError;
  };

  const handleSubmit = async () => {
    const findError = validate();

    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setIsSubmitting(true);
      try {
        if (mode === "Edit") {
          const newData = {
            prov_id: form?.province?.id,
            value: form?.pajak / 100,
          };

          await pajak.update(data.id, newData);

          toast.success("Pajak Edited");
        } else {
          const newData = {
            prov_id: form?.province?.id,
            value: form?.pajak / 100,
          };

          await pajak.create(newData);

          toast.success("Pajak Added");
        }

        onClose();

        setForm({
          province: null,
          pajak: "",
        });

        setError({
          province: "",
          pajak: "",
        });

        load();
      } catch (e) {
        console.log(e);
      }
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (mode === "Edit" && data) {
      setForm({
        province: data?.province,
        pajak: data?.value * 100,
      });
    }
  }, [mode, data]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isSubmitting) {
          onClose();
          setForm({
            province: null,
            pajak: "",
          });
          setError({
            province: "",
            pajak: "",
          });
        }
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{mode === "Edit" ? "Edit" : "Add New"} Pajak</DialogTitle>
      <DialogContent dividers>
        <Autocomplete
          fullWidth
          getOptionLabel={(option) => option.name}
          options={listProvince?.map((item) => {
            return {
              id: item?.id,
              name: item?.name,
            };
          })}
          renderInput={(params) => <TextField {...params} label="Province" />}
          onChange={(e, v) => {
            setForm((f) => ({
              ...f,
              province: v,
            }));
          }}
          loading={loading}
          value={form.province}
          disabled={isSubmitting}
          sx={{ mb: 2 }}
        />
        <TextField
          name="pajak"
          label="Pajak (%)"
          autoComplete="off"
          fullWidth
          value={form.pajak}
          onChange={handleChange}
          error={error.pajak ? true : false}
          helperText={error.pajak}
          disabled={isSubmitting}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button
          variant="contained"
          color="inherit"
          disabled={isSubmitting}
          onClick={() => {
            onClose();
            setForm({
              province: null,
              pajak: "",
            });
            setError({
              province: "",
              pajak: "",
            });
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          onClick={handleSubmit}
          loading={isSubmitting}
          variant="contained"
        >
          {mode === "Edit" ? "Edit" : "Add"} Pajak
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddPajak;
