//
import { useState } from "react";
//
import {
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
//
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
//
import {
  Page,
  ListHead,
  TableToolBar,
  LoadingPage,
  SearchNotFound,
  ActionTable,
  AlertDialog,
} from "../../../components";
//
import user from "../../../constant/users";
//
import { applySortFilter, getComparator } from "../../../utils";
import AddUser from "./AddUser";
import { GetAll, GetRoles } from "./API";

// Table Head
const TABLE_HEAD = [
  { id: "no", label: "No", alignRight: false, isSort: true },
  { id: "name", label: "Name", alignRight: false, isSort: true },
  { id: "email", label: "Email", alignRight: false, isSort: false },
  { id: "" },
];

const TABLE_HEAD_MITRA = [
  { id: "no", label: "No", alignRight: false, isSort: true },
  { id: "name", label: "Name", alignRight: false, isSort: true },
  { id: "email", label: "Email", alignRight: false, isSort: false },
];

function User() {
  const [tabs, setTabs] = useState(9);

  const [listUser, loading, refresh] = GetAll(tabs);

  const [listRole, loadingRole] = GetRoles();

  const [dialog, setDialog] = useState({
    open: false,
    data: {},
    id: "",
    mode: "Add",
  });

  const [openAlert, setOpenAlert] = useState({
    open: false,
    id: "",
  });

  const [q, setQ] = useState("");

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("name");

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [no, setNo] = useState(0);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setNo(no + 5);
    } else {
      setNo(no - 5);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async () => {
    try {
      await user.destroy(openAlert.id);
      setOpenAlert({
        open: false,
        id: "",
      });
      refresh(true);
    } catch (e) {
      console.log(e.message);
    }
  };

  if (loading || loadingRole) {
    return <LoadingPage />;
  }

  const userList = applySortFilter(
    listUser,
    getComparator(order, orderBy),
    q,
    "name"
  );

  const isNotFound = userList?.length === 0;

  return (
    <Page title="User Management | Bayu Sinergi">
      <Container maxWidth="xl">
        {/* Header */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            User Management
          </Typography>
          <Stack direction="row" alignItems="center" gap={2}>
            <Button
              onClick={(e) => {
                setDialog({ mode: "Add", open: true, data: {}, id: "" });
              }}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
            >
              New User
            </Button>
            <Button
              onClick={(e) => {
                setDialog({ mode: "AddCust", open: true, data: {}, id: "" });
              }}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
            >
              Create User From Customer
            </Button>
          </Stack>
        </Stack>

        {/* Table */}
        <Card>
          {/* Search */}
          <TableToolBar
            value={q}
            onChange={(e) => {
              setQ(e.target.value);
              setPage(0);
            }}
            placeholder="Search User"
            onClear={() => setQ("")}
          />

          {/* Tabs */}
          <Tabs
            value={tabs}
            onChange={(i, v) => setTabs(v)}
            variant="fullWidth"
            sx={{ border: "1px solid #EAEAEA", borderRadius: "6px", mb: 1 }}
          >
            {listRole?.map((item, index) => {
              return <Tab key={index} label={item?.name} value={item?.id} />;
            })}
          </Tabs>

          {/* Table */}
          <TableContainer sx={{ minWidth: 200 }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={tabs === 6 ? TABLE_HEAD_MITRA : TABLE_HEAD}
                onRequestSort={handleRequestSort}
                colored
              />
              <TableBody>
                {userList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell align="left">{index + 1 + no}</TableCell>
                        <TableCell align="left">{item.name}</TableCell>
                        <TableCell align="left">{item.email}</TableCell>
                        {tabs !== 6 && (
                          <ActionTable
                            onEdit={() => {
                              setDialog({
                                mode: "Edit",
                                open: true,
                                data: item,
                                id: item?.user_id,
                              });
                            }}
                            onDelete={() => {
                              setOpenAlert({
                                open: true,
                                id: item?.user_id,
                              });
                            }}
                          />
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={q} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={listUser?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* Alert Dialog */}
      <AlertDialog
        open={openAlert.open}
        title="Delete User"
        content="Are you sure you want delete this user?"
        handleOk={handleDelete}
        handleClose={() =>
          setOpenAlert({
            open: false,
            id: "",
          })
        }
      />

      {/* Dialog */}
      <AddUser
        dialog={dialog}
        load={() => {
          refresh(true);
        }}
        onClose={() => {
          setDialog({
            open: false,
            data: {},
            id: "",
            mode: "Add",
          });
        }}
      />
    </Page>
  );
}

export default User;
