import axios from "../config";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getNewOrder: () => axios.get("/summary/new"),
  getOnApproval: () => axios.get("/summary/on-approval"),
  getRevoke: () => axios.get("/summary/revoke"),
  getTotalSO: () => axios.get("/summary/sales-order"),
  getOnProgress: () => axios.get("/summary/on-progress"),
  getOnPlan: () => axios.get("/summary/on-plan"),
  getCompleted: () => axios.get("/summary/completed"),
  getRejected: () => axios.get("/summary/rejected"),
  getWillExpire: () => axios.get("/summary/will-expire"),
  getExpired: () => axios.get("/summary/expired"),
};
