// React
import { useState } from "react";

// Material UI
import {
  Container,
  Stack,
  Button,
  Typography,
  Card,
  TableCell,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TablePagination,
} from "@mui/material";

// Components
import {
  ActionTable,
  ListHead,
  LoadingPage,
  Page,
  SearchNotFound,
  TableToolBar,
} from "../../../components";

// Iconify
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";

// API
import { GetAll } from "./API";
import region from "../../../constant/region";

//
import AddRegion from "./AddRegion";

//
import { applySortFilter, getComparator } from "../../../utils";

//
import { toast } from "react-toastify";

//
import { useData } from "../../../context/DataProvider";

// Table Head
const TABLE_HEAD = [
  { id: "no", label: "No", alignRight: false, isSort: true },
  { id: "name", label: "Name", alignRight: false, isSort: true },
  { id: "id", label: "Code", alignRight: false, isSort: true },
  { id: "description", label: "Description", alignRight: false, isSort: false },
  { id: "" },
];

const TABLE_HEAD_BOD = [
  { id: "no", label: "No", alignRight: false, isSort: true },
  { id: "name", label: "Name", alignRight: false, isSort: true },
  { id: "id", label: "Code", alignRight: false, isSort: true },
  { id: "description", label: "Description", alignRight: false, isSort: false },
];

function Region() {
  const { user } = useData();

  const [listRegion, loading, refresh] = GetAll();

  const [q, setQ] = useState("");

  const [dialog, setDialog] = useState({
    open: false,
    mode: "Add",
    data: {},
  });

  const [no, setNo] = useState(0);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("name");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setNo(no + 5);
    } else {
      setNo(no - 5);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <LoadingPage />;
  }

  const regionList = applySortFilter(
    listRegion,
    getComparator(order, orderBy),
    q,
    "name"
  );

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - regionList.length) : 0;

  const isNotFound = regionList?.length === 0;

  return (
    <Page title="Region | Bayu Sinergi">
      <Container maxWidth="xl">
        {/* Header */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            List Region
          </Typography>

          {user?.role !== "bod" && (
            <Button
              onClick={(e) => {
                setDialog((d) => ({
                  ...d,
                  open: true,
                  mode: "Add",
                }));
              }}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
              sx={{ ml: 1.5 }}
            >
              New Region
            </Button>
          )}
        </Stack>

        <Card>
          {/* Search */}
          <TableToolBar
            value={q}
            onChange={(e) => {
              setQ(e.target.value);
              setPage(0);
            }}
            placeholder="Search Region"
            onClear={() => setQ("")}
          />

          {/* Table */}
          <TableContainer sx={{ minWidth: 200 }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={user?.role === "bod" ? TABLE_HEAD_BOD : TABLE_HEAD}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {regionList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{index + 1 + no}</TableCell>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell>{item?.id}</TableCell>
                        <TableCell>{item?.description ?? "-"}</TableCell>
                        {user?.role !== "bod" && (
                          <ActionTable
                            onEdit={() => {
                              setDialog({
                                open: true,
                                data: item,
                                mode: "Edit",
                              });
                            }}
                            onDelete={async () => {
                              try {
                                await region.delete(item?.id);

                                refresh(true);

                                toast.success("Region Deleted!");
                              } catch (e) {
                                console.log(e);
                              }
                            }}
                          />
                        )}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 67 * emptyRows }}>
                    <TableCell colSpan={4} />
                  </TableRow>
                )}
              </TableBody>
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={q} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={regionList?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* Dialog */}
      <AddRegion
        dialog={dialog}
        load={() => {
          refresh(true);
        }}
        onClose={() => {
          setDialog({
            open: false,
            data: {},
            mode: "Add",
          });
        }}
      />
    </Page>
  );
}

export default Region;
