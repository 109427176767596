//
import { useEffect, useMemo, useState } from "react";
//
import report from "../../../../constant/report";

function GetRevenueAlir(type, year, month) {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAll = async () => {
      setLoading(true);
      try {
        const res = await report.getRevenueAlir(type, year, month);

        setData(
          res.data
        );
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    getAll();
  }, [type, year, month]);

  return useMemo(() => [data, loading], [data, loading]);
}

export default GetRevenueAlir;
