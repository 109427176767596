//
import { useEffect, useState } from "react";
//
import {
  Button,
  Card,
  Container,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
//
import { LoadingOrder, Page, AlertDialog } from "../../../components";
//
import users from "../../../constant/users";
//
import { useData } from "../../../context/DataProvider";
//
import { GetOnce } from "./API";
//
import { toast } from "react-toastify";

function Settings() {
  const { user } = useData();

  const [detail, loading, refresh] = GetOnce(user.id);

  const [isSubmitting, setIsSubmitting] = useState(false);

  if (loading) {
    return <LoadingOrder />;
  }

  return (
    <Page title="Settings | Bayu Sinergi">
      {isSubmitting && <LoadingOrder />}
      <Container maxWidth="xl">
        {/* Header */}
        <Typography variant="h4" mb={4}>
          Account Settings
        </Typography>

        {/* Content */}
        <Grid container spacing={3}>
          {/* General */}
          <Grid item lg={6} md={6}>
            <General
              detail={detail}
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
              refresh={() => refresh(true)}
            />
          </Grid>

          {/* Change Password */}
          <Grid item lg={6} md={6}>
            <ChangePassword
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
            />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}

const General = ({ detail, isSubmitting, setIsSubmitting, refresh }) => {
  const { user } = useData();

  const [dialog, setDialog] = useState(false);

  const [form, setForm] = useState({
    name: "",
    email: "",
  });

  const [error, setError] = useState({
    name: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((f) => ({
      ...f,
      [name]: value,
    }));

    setError((e) => ({
      ...e,
      [name]: "",
    }));
  };

  const validate = () => {
    const newError = { ...error };

    if (!form.name) {
      newError.name = "Name required!";
    }

    if (!form.email) {
      newError.email = "Email required!";
    }

    return newError;
  };

  const handleSubmit = async () => {
    const findErrors = validate();

    if (Object.values(findErrors).some((e) => e !== "")) {
      setError(findErrors);

      setDialog(false);
    } else {
      try {
        setIsSubmitting(true);

        setDialog(false);

        await users.update(user.id, form);

        refresh();

        toast.success("User Updated");

        setIsSubmitting(false);
      } catch (error) {
        console.log(error);

        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (detail) {
      setForm((f) => ({
        ...f,
        ...detail,
      }));
    }
  }, [detail]);

  return (
    <>
      <Card sx={{ py: 4, px: 3 }}>
        {/* Header */}
        <Typography variant="subtitle1" mb={3}>
          General
        </Typography>

        {/* Name */}
        <TextField
          autoComplete="off"
          name="name"
          label="Name"
          onChange={handleChange}
          value={form.name}
          error={error.name ? true : false}
          helperText={error.name}
          disabled={isSubmitting}
          fullWidth
          sx={{ mb: 2 }}
        />

        {/* Email */}
        <TextField
          autoComplete="off"
          name="email"
          label="Email"
          onChange={handleChange}
          value={form.email}
          error={error.email ? true : false}
          helperText={error.email}
          disabled={isSubmitting}
          fullWidth
          sx={{ mb: 2 }}
        />

        <Stack mt={3} alignItems="end">
          <Button
            variant="contained"
            onClick={() => setDialog(true)}
            disabled={isSubmitting}
          >
            Save Changes
          </Button>
        </Stack>
      </Card>

      {/* Alert */}
      <AlertDialog
        open={dialog}
        title="Change Personal Information"
        content="Are you sure want to change your personal information?"
        handleOk={handleSubmit}
        handleClose={() => setDialog(false)}
      />
    </>
  );
};

const ChangePassword = ({ isSubmitting, setIsSubmitting }) => {
  const { user } = useData();

  const [dialog, setDialog] = useState(false);

  const [form, setForm] = useState({
    old: "",
    new: "",
    confirm: "",
  });

  const [error, setError] = useState({
    old: "",
    new: "",
    confirm: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((f) => ({
      ...f,
      [name]: value,
    }));

    setError((e) => ({
      ...e,
      [name]: "",
    }));
  };

  const validate = () => {
    const newError = { ...error };

    if (!form.old) {
      newError.old = "Old Password required!";
    }

    if (!form.new) {
      newError.new = "New Password required!";
    } else if (form.new.length < 6) {
      newError.new = "New Password minimal 6 character ";
    } else if (form.new === form.old) {
      newError.new = "New Password can't be same with old password!";
    }

    if (!form.confirm) {
      newError.confirm = "Confirm Password required!";
    } else if (form.confirm !== form.new) {
      newError.confirm = "Confirm Password not match!";
    }

    return newError;
  };

  const handleSubmit = async () => {
    const findErrors = validate();

    if (Object.values(findErrors).some((e) => e !== "")) {
      setError(findErrors);

      setDialog(false);
    } else {
      try {
        setIsSubmitting(true);

        setDialog(false);

        const newData = {
          id: user.id,
          old: form.old,
          new: form.new,
        };

        await users.changePassword(newData);

        toast.success("Password Changed");

        setForm({
          old: "",
          new: "",
          confirm: "",
        });

        setError({
          old: "",
          new: "",
          confirm: "",
        });

        setIsSubmitting(false);
      } catch (error) {
        toast.error(error.message);

        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      <Card sx={{ py: 4, px: 3 }}>
        {/* Header */}
        <Typography variant="subtitle1" mb={3}>
          Change Password
        </Typography>

        {/* Old Password */}
        <TextField
          name="old"
          label="Old Password"
          type="password"
          onChange={handleChange}
          value={form.old}
          error={error.old ? true : false}
          helperText={error.old}
          disabled={isSubmitting}
          fullWidth
          sx={{ mb: 2 }}
        />

        {/* New Password */}
        <TextField
          name="new"
          label="New Password"
          type="password"
          onChange={handleChange}
          value={form.new}
          error={error.new ? true : false}
          helperText={error.new}
          disabled={isSubmitting}
          fullWidth
          sx={{ mb: 2 }}
        />

        {/* Confirm Password */}
        <TextField
          name="confirm"
          label="Confirm Password"
          type="password"
          onChange={handleChange}
          value={form.confirm}
          error={error.confirm ? true : false}
          helperText={error.confirm}
          disabled={isSubmitting}
          fullWidth
        />

        <Stack mt={3} alignItems="end">
          <Button
            variant="contained"
            onClick={() => setDialog(true)}
            disabled={isSubmitting}
          >
            Change Password
          </Button>
        </Stack>
      </Card>

      {/* Alert */}
      <AlertDialog
        open={dialog}
        title="Change Password"
        content="Are you sure want to change your password?"
        handleOk={handleSubmit}
        handleClose={() => setDialog(false)}
      />
    </>
  );
};

export default Settings;
