//
import { useEffect, useState, useMemo } from "react";

//
import province from "../../../../constant/province";

function GetPajak() {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    const getAll = async () => {
      setLoading(true);
      try {
        const res = await province.getPajak();

        setData(
          res?.data
            ?.map((item) => {
              const { prov_id, ...rest } = item;

              return {
                id: prov_id,
                ...rest,
              };
            })
            ?.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
        );
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };

    getAll();
  }, []);

  return useMemo(() => [data, loading, error], [data, loading, error]);
}

export default GetPajak;
