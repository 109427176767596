// React
import React, { useState } from "react";

// Material UI
import {
  Autocomplete,
  Card,
  styled,
  TextField,
  Typography,
} from "@mui/material";

// Chart JS
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

// API
import { GetListMonthAdmin, GetReportAdmin } from "./API";

// Components
import { LoadingPage, Page } from "../../../components";

// Register
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const defaultDate = `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
  .toString()
  .padStart(2, "0")}`;

const defaultDate1 = `${new Date().getFullYear()}${(new Date().getMonth() + 1)
  .toString()
  .padStart(2, "0")}`;

function ReportAdmin() {
  const [month, setMonth] = useState({
    month: defaultDate,
    order_month: defaultDate1,
  });

  const [report, loading] = GetReportAdmin(month?.month);

  const [months, loadingMonths] = GetListMonthAdmin();

  if (loading || loadingMonths) {
    return <LoadingPage />;
  }

  const listMonth = months?.sort((a, b) => {
    const aA = parseInt(a?.order_month);

    const bB = parseInt(b?.order_month);

    return bB - aA;
  });

  return (
    <Page title="Report Admin | Bayu Sinergi">
      {/* Header */}
      <Header>
        <div style={{ flexGrow: 1 }} />

        {/* Filter */}
        <Autocomplete
          options={listMonth}
          getOptionLabel={(option) => option?.month}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Filter Month" />
          )}
          onChange={(e, v) => {
            setMonth(v);
          }}
          value={month}
        />
      </Header>

      {/* Gap */}
      <div style={{ height: 16 }} />

      {/* Volume */}
      <Volume report={report} />

      {/* Gap */}
      <div style={{ height: 24 }} />

      {/* Grand Total */}

      <GrandTotal report={report} />
    </Page>
  );
}

const Volume = ({ report }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const data = {
    labels: report?.map(
      (item) =>
        `${item?.order_month?.substring(0, 4)}-${item?.order_month?.substring(
          4,
          6
        )}`
    ),
    datasets: [
      {
        label: "Volume",
        data: report?.map((item) => item?.sum_qty),
        backgroundColor: "#e0a338",
      },
    ],
  };

  return (
    <Card sx={{ py: 4, px: 3 }}>
      {/* Header */}
      <Header>
        {/* Title */}
        <Typography variant="h4">Report Total Volume Monthly</Typography>

        <div style={{ flexGrow: 1 }} />

        {/* Filter */}
      </Header>

      {/* Bar */}
      <Bar options={options} data={data} width={800} height={200} />
    </Card>
  );
};

const GrandTotal = ({ report }) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
  };

  const data = {
    labels: report?.map(
      (item) =>
        `${item?.order_month?.substring(0, 4)}-${item?.order_month?.substring(
          4,
          6
        )}`
    ),
    datasets: [
      {
        label: "Grand Total",
        data: report?.map((item) => item?.sum_grandtotal),
        backgroundColor: "#e0a338",
      },
    ],
  };

  return (
    <Card sx={{ py: 4, px: 3 }}>
      {/* Header */}
      <Header>
        {/* Title */}
        <Typography variant="h4">Report Grand Total Monthly</Typography>

        <div style={{ flexGrow: 1 }} />

        {/* Filter */}
      </Header>

      {/* Bar */}
      <Bar options={options} data={data} width={800} height={200} />
    </Card>
  );
};

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  alignItems: "center",
  marginBottom: theme.spacing(3),
}));

export default ReportAdmin;
