import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import searchFill from "@iconify/icons-eva/search-fill";
import closeFill from "@iconify/icons-eva/close-fill";
import plusFill from "@iconify/icons-eva/plus-fill";
// material
import { styled } from "@mui/system";
import {
  Box,
  Toolbar,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Typography,
  Button,
} from "@mui/material";

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: "flex",
  justifyContent: "space-between",
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 260,
  transition: theme.transitions.create(["box-shadow", "width"], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  "& fieldset": {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

TableToolbar.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
};

export default function TableToolbar({
  value,
  onChange,
  placeholder,
  onClear,
  title,
  onClick,
}) {
  return (
    <RootStyle>
      {title ? (
        <Typography variant="h5" gutterBottom>
          {title}
        </Typography>
      ) : null}
      <div>
        <SearchStyle
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          startAdornment={
            <InputAdornment position="start">
              <Box
                component={Icon}
                icon={searchFill}
                sx={{ color: "text.disabled" }}
              />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="start">
              <IconButton
                size="small"
                onClick={onClear}
                disabled={value.length === 0}
              >
                <Box
                  component={Icon}
                  icon={closeFill}
                  sx={{ color: "text.disabled" }}
                />
              </IconButton>
            </InputAdornment>
          }
        />
        {onClick ? (
          <Button
            onClick={onClick}
            variant="contained"
            size="large"
            sx={{ ml: 2, height: "56px" }}
          >
            <Icon icon={plusFill} fontSize={24} />
          </Button>
        ) : null}
      </div>
    </RootStyle>
  );
}
