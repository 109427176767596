//
import { useMemo, useState, useEffect } from "react";

//
import users from "../../../../constant/users";

//
import { useData } from "../../../../context/DataProvider";

function GetSales() {
  const { user } = useData();

  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    const getAll = async () => {
      setLoading(true);
      try {
        const res = await users.getSales();

        setData(
          res.data
            ?.map((item) => {
              return {
                id: item.id,
                name: item.name,
              };
            })
            ?.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
        );
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };

    if (user.role === "sales admin" || user.role === "mitra") {
      getAll();
    }
  }, [user]);

  return useMemo(() => [data, loading, error], [data, loading, error]);
}

export default GetSales;
