//
import { useEffect, useState } from "react";
//
import {
  Card,
  Container,
  TablePagination,
  Tab,
  Tabs,
  Stack,
  Typography,
  Toolbar,
  styled,
  CircularProgress,
  Grid,
  Divider,
  Button,
} from "@mui/material";
//
import { TableToolBar, SearchNotFound, MHidden } from "../../../components";
//
import { useLocation, useHistory } from "react-router-dom";
//
import salesOrder from "../../../constant/salesOrder";
import socket from "../../../constant/socket";
//
import { useData } from "../../../context/DataProvider";
//
import {
  applySortFilterOrder,
  exportToExcel,
  formatDateBAST,
  formatDateList,
  formatThousand,
  getComparator,
} from "../../../utils";
//
import { Icon } from "@iconify/react";
import arrowCircleUpFill from "@iconify/icons-eva/arrow-circle-up-fill";
//
import XLSX from "xlsx";

function ListOrderComponent() {
  const { user } = useData();

  const location = useLocation();

  const [tabs, setTabs] = useState("pending");

  const [orders, setOrders] = useState([]);

  const [loading, setLoading] = useState(false);

  const [loadingReport, setLoadingReport] = useState(false);

  const [q, setQ] = useState("");

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  useEffect(() => {
    if (user.role === "sales manager" || user.role === "finance manager") {
      setTabs(location?.state?.tabs ?? "on-approval");
    } else {
      setTabs(location?.state?.tabs ?? "pending");
    }
  }, [user, location]);

  useEffect(() => {
    let mounted = true;

    const getAll = async () => {
      try {
        if (mounted) {
          setLoading(true);
        }

        const res = await salesOrder.getAll(tabs);

        if (mounted) {
          setOrders(res.data);
          setLoading(false);
        }
      } catch (e) {
        console.log(e);

        if (mounted) {
          setLoading(false);
        }
      }
    };

    getAll();

    return () => {
      mounted = false;
    };
  }, [tabs]);

  useEffect(() => {
    const getAll = async () => {
      try {
        const res = await salesOrder.getAll(tabs);

        setOrders(res.data);
      } catch (e) {
        console.log(e);
      }
    };

    socket.on("load", () => {
      getAll();
    });
  }, [tabs]);

  const handleExport = async () => {
    setLoadingReport(true);
    try {
      const res = await salesOrder.getReport();

      const order = res?.data
        ?.filter((item) => item.status !== "expired")
        ?.map((item) => {
          const status = {
            pending: "New Order",
            "on-verification": "New Order",
            "on-approval": "On Approval",
            revoke: "Revoke",
            rejected: "Rejected",
            "open-so": "Open SO",
            "on-plan": "On Plan",
            "on-progress": "On Progress",
            completed: "Completed",
          };

          return {
            "Nomor PO": item?.po_number,
            "Nomor SO": item?.order_number,
            "PO Date": formatDateBAST(item?.order_date),
            "PO Expired Date": formatDateBAST(item?.po_expiredate),
            "SO Expired Date": formatDateBAST(item?.so_expiredate),
            Sales: item?.sales?.name,
            Customer: item?.customer?.name,
            "Customer Type": item?.customer?.custtype?.name,
            "Business Type": item?.customer?.businesstype?.name,
            "Alamat Serah 1": item?.address,
            "Alamat Serah 2": item?.prov?.name,
            PBBKB: item?.pajak?.value,
            "Payment Method": item?.method?.name,
            "Payment Term": item?.payment?.name,
            "SKP ID": item?.skp_id,
            "Type Of Sales":
              item?.type_of_sales === "CIF" ? "On Site" : item?.type_of_sales,
            OAT: item?.oat,
            "Fee Agent": item?.fee_agent,
            "Expected Delivery Date": formatDateBAST(item?.expected_date),
            "Supply Point": item?.supply?.name,
            "Ops Type": item?.ops_type,
            Product: item?.so_detail?.products?.name,
            "Volume Order (L)": item?.summary?.volume_start,
            "Volume Booked (L)": item?.summary?.volume_booked,
            "Volume Loaded (L)": item?.summary?.volume_loaded,
            "Volume Remaining (L)": item?.summary?.volume_end,
            "Grand Total": item?.so_detail?.total,
            Status: status[item?.status],
          };
        });
        

      const expired = res?.data
        ?.filter((item) => item.status === "expired")
        ?.map((item) => {
          return {
            "Nomor PO": item?.po_number,
            "Nomor SO": item?.order_number,
            "PO Date": formatDateBAST(item?.order_date),
            "PO Expired Date": formatDateBAST(item?.po_expiredate),
            "SO Expired Date": formatDateBAST(item?.so_expiredate),
            Sales: item?.sales?.name,
            Customer: item?.customer?.name,
            "Customer Type": item?.customer?.custtype?.name,
            "Business Type": item?.customer?.businesstype?.name,
            "Alamat Serah 1": item?.address,
            "Alamat Serah 2": item?.prov?.name,
            PBBKB: item?.pajak?.value,
            "Payment Method": item?.method?.name,
            "Payment Term": item?.payment?.name,
            "SKP ID": item?.skp_id,
            "Type Of Sales": item?.type_of_sales,
            OAT: item?.oat,
            "Fee Agent": item?.fee_agent,
            "Expected Delivery Date": formatDateBAST(item?.expected_date),
            "Supply Point": item?.supply?.name,
            "Ops Type": item?.ops_type,
            Product: item?.so_detail?.products?.name,
            "Volume Order (L)": item?.summary?.volume_start,
            "Volume Booked (L)": item?.summary?.volume_booked,
            "Volume Loaded (L)": item?.summary?.volume_loaded,
            "Volume Remaining (L)": item?.summary?.volume_end,
            "Grand Total": item?.so_detail?.total,
            Status: "Expired",
          };
        });

      const wscols = [
        { width: 20 }, // Nomor PO
        { width: 20 }, // Nomor SO
        { width: 20 }, // PO Date
        { width: 20 }, // PO Expired Date
        { width: 20 }, // SO Expired Date
        { width: 40 }, // Sales
        { width: 40 }, // Customer
        { width: 20 }, // Customer Type
        { width: 20 }, // Business Type
        { width: 40 }, // Alamat Serah 1
        { width: 20 }, // Alamat Serah 2
        { width: 20 }, // PBBKB
        { width: 20 }, // Payment Method
        { width: 20 }, // Payment Term
        { width: 20 }, // SKP ID
        { width: 20 }, // Type Of Sales
        { width: 20 }, // OAT
        { width: 20 }, // Fee Agent
        { width: 20 }, // Expected Delivery Date
        { width: 20 }, // Supply Point
        { width: 20 }, // Ops Type
        { width: 20 }, // Product
        { width: 20 }, // Volume Order (L)
        { width: 20 }, // Volume Booked (L)
        { width: 20 }, // Volume Loaded (L)
        { width: 20 }, // Volume Remaining (L)
        { width: 40 }, // Grand Total
        { width: 20 }, // Status
      ];

      const ws1 = XLSX.utils.json_to_sheet(order);
      const ws2 = XLSX.utils.json_to_sheet(expired);
      ws1["!cols"] = wscols;
      ws2["!cols"] = wscols;

      const wb = {
        Sheets: {
          "Data Order": ws1,
          "Data Expired": ws2,
        },
        SheetNames: ["Data Order", "Data Expired"],
      };

      exportToExcel(wb, `Sales_Order_Report-${formatDateBAST(new Date())}`);
    } catch (error) {
      console.log(error);
    }
    setLoadingReport(false);
  };

  const orderList = applySortFilterOrder(
    orders,
    getComparator("desc", "createdAt"),
    q
  );

  return (
    <Container maxWidth="xl">
      {/* Export */}
      {location?.pathname !== "/dashboard" && user?.role !== "sales rep" && (
        <Stack mb={2} alignItems="end">
          <Button
            variant="contained"
            startIcon={<Icon icon={arrowCircleUpFill} />}
            onClick={handleExport}
            disabled={loadingReport}
          >
            Export Sales Order
          </Button>
        </Stack>
      )}

      {/* Table */}
      <Card>
        {/* Search */}
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Toolbar>
            <Typography variant="h5">List Order</Typography>
          </Toolbar>
          <TableToolBar
            value={q}
            onChange={(e) => {
              setQ(e.target.value);
              setPage(0);
            }}
            placeholder="Search Data"
            onClear={() => setQ("")}
          />
        </Stack>

        {/* Tabs */}
        <MHidden width="mdDown">
          <Tabs
            value={tabs}
            onChange={(i, v) => setTabs(v)}
            variant="fullWidth"
            sx={{ border: "1px solid #EAEAEA", borderRadius: "6px", mb: 1 }}
          >
            <Tab label="New Order" value="pending" />
            <Tab label="On Approval" value="on-approval" />
            <Tab label="Revoke" value="revoke" />
            <Tab label="Rejected" value="rejected" />
            <Tab label="Open SO" value="open-so" />
            <Tab label="On Plan" value="on-plan" />
            <Tab label="On Progress" value="on-progress" />
            <Tab label="Completed" value="completed" />
            <Tab label="Will Expire" value="willExpire" />
            <Tab label="Expired" value="expired" />
          </Tabs>
        </MHidden>

        <MHidden width="mdUp">
          <Tabs
            value={tabs}
            onChange={(i, v) => setTabs(v)}
            variant="scrollable"
            scrollButtons="auto"
            sx={{ border: "1px solid #EAEAEA", borderRadius: "6px", mb: 1 }}
          >
            <Tab label="New Order" value="pending" />
            <Tab label="On Approval" value="on-approval" />
            <Tab label="Revoke" value="revoke" />
            <Tab label="Rejected" value="rejected" />
            <Tab label="Open SO" value="open-so" />
            <Tab label="On Plan" value="on-plan" />
            <Tab label="On Progress" value="on-progress" />
            <Tab label="Completed" value="completed" />
            <Tab label="Will Expire" value="willExpire" />
            <Tab label="Expired" value="expired" />
          </Tabs>
        </MHidden>

        {/* Table */}
        {loading ? (
          <LoadingWrap>
            <CircularProgress />
          </LoadingWrap>
        ) : (
          <>
            {orderList
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((item, index) => {
                return <ListItem key={index} item={item} tabs={tabs} />;
              })}
            {orderList?.length === 0 && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  height: 150,
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <SearchNotFound searchQuery={q} />
              </div>
            )}
            <TablePagination
              rowsPerPageOptions={[5, 10]}
              component="div"
              count={orderList?.length ?? 0}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </>
        )}
      </Card>
    </Container>
  );
}

const ListItem = ({ item, tabs }) => {
  const history = useHistory();

  return (
    <>
      <Grid
        container
        style={{
          backgroundColor: "#F4F4F4",
          marginTop: 16,
          cursor: "pointer",
        }}
        onClick={() => history.push(`/order/${item.so_id}`)}
      >
        <Grid
          item
          container
          spacing={3}
          alignItems="center"
          style={{ padding: 16 }}
        >
          {/* Order Information*/}
          <Grid item lg={6} xs={12}>
            {/* Customer & Sales*/}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
            >
              <div style={{ width: "100%" }}>
                <Typography style={{ fontSize: 14, color: "#ADADAD" }}>
                  Customer
                </Typography>
                <Typography
                  style={{
                    fontSize: 14,
                    color: "#6B6B6B",
                    fontWeight: "500",
                  }}
                >
                  {item?.customer ?? "-"}
                </Typography>
              </div>

              {/* Gap */}

              <div style={{ width: "100%" }}>
                <Typography style={{ fontSize: 14, color: "#ADADAD" }}>
                  Sales
                </Typography>
                <Typography
                  style={{
                    fontSize: 14,
                    color: "#6B6B6B",
                    fontWeight: "500",
                  }}
                >
                  {item?.sales ?? "-"}
                </Typography>
              </div>
            </Stack>

            {/* SO Number & PO Number */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt="16px"
            >
              <div style={{ width: "100%" }}>
                <Typography style={{ fontSize: 14, color: "#ADADAD" }}>
                  SO Number
                </Typography>
                <Typography
                  style={{
                    fontSize: 14,
                    color: "#6B6B6B",
                    fontWeight: "500",
                  }}
                >
                  {item?.order_number ?? "-"}
                </Typography>
              </div>

              <div style={{ width: "100%" }}>
                <Typography style={{ fontSize: 14, color: "#ADADAD" }}>
                  PO Number
                </Typography>
                <Typography
                  style={{
                    fontSize: 14,
                    color: "#6B6B6B",
                    fontWeight: "500",
                  }}
                >
                  {item?.po_number ?? "-"}
                </Typography>
              </div>
            </Stack>

            {/* PO Date & Valid Untill */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt="16px"
            >
              <div style={{ width: "100%" }}>
                <Typography style={{ fontSize: 14, color: "#ADADAD" }}>
                  PO Date
                </Typography>
                <Typography
                  style={{
                    fontSize: 14,
                    color: "#6B6B6B",
                    fontWeight: "500",
                  }}
                >
                  {formatDateList(item?.order_date) ?? "-"}
                </Typography>
              </div>

              {/* Gap */}

              <div style={{ width: "100%" }}>
                <Typography style={{ fontSize: 14, color: "#ADADAD" }}>
                  Valid Untill
                </Typography>
                <Typography
                  style={{
                    fontSize: 14,
                    color: "#6B6B6B",
                    fontWeight: "500",
                  }}
                >
                  {formatDateList(item?.so_expiredate) ?? "-"}
                </Typography>
              </div>
            </Stack>
          </Grid>

          {/* Status & Note*/}
          <Grid item lg={6} xs={12}>
            {/* Approval */}
            <Grid
              item
              lg={12}
              xs={12}
              style={{
                backgroundColor: "#E0A338",
                borderRadius: 6,
              }}
              sx={{ py: "8px" }}
              mb={1.5}
            >
              <Typography
                align="center"
                style={{
                  color: "#FFF",
                  fontSize: 14,
                  fontWeight: 600,
                }}
              >
                <Status
                  admin={item?.app_admin}
                  manager={item?.app_sales}
                  finance={item?.app_finance}
                  status={item?.status}
                  verificator={item?.verificator}
                  sales={item?.sales}
                  sales_id={item?.sales_id}
                  created_by={item?.created_by}
                  sales_verify_date={item?.sales_verify_date}
                />
              </Typography>
            </Grid>

            {item?.responsible && (
              <Grid item lg={12} xs={12}>
                <Stack direction="row" alignItems="center" spacing={2} mb={1.5}>
                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#FF4842",
                      borderRadius: 6,
                      padding: "8px 0px",
                    }}
                  >
                    <Typography
                      align="center"
                      style={{
                        color: "#FFF",
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      Closed By {item?.responsible?.name ?? "-"}
                    </Typography>
                  </div>

                  <div
                    style={{
                      width: "100%",
                      backgroundColor: "#637381",
                      borderRadius: 6,
                      padding: "8px 0px",
                    }}
                  >
                    <Typography
                      align="center"
                      style={{
                        color: "#FFF",
                        fontSize: 14,
                        fontWeight: 600,
                      }}
                    >
                      Closed at {formatDateList(item?.closed_at) ?? "-"}
                    </Typography>
                  </div>
                </Stack>
              </Grid>
            )}

            {/* Note */}
            <div
              style={{
                backgroundColor: "#FFF",
                padding: 12,
                borderRadius: 6,
                height: "100%",
              }}
            >
              <Typography style={{ fontSize: 14, color: "#ADADAD" }}>
                Note
              </Typography>

              {/* Value */}
              <span
                style={{
                  display: "-webkit-box",
                  overflow: "hidden",
                  WebkitBoxOrient: "vertical",
                  WebkitLineClamp: 2,
                  color: "#6B6B6B",
                }}
              >
                {item.note !== "" ? item.note : "-"}
              </span>
            </div>
          </Grid>
        </Grid>

        {/* Saldo */}
        {(tabs === "open-so" ||
          tabs === "on-plan" ||
          tabs === "on-progress") && (
          <Grid
            item
            container
            style={{
              backgroundColor: "#E5E5E5",
            }}
          >
            {/* Volume Order */}
            <Grid item lg={3} md={3} xs={12} sx={{ py: "8px" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Typography style={{ fontSize: 14, color: "#ADADAD" }}>
                  Volume Order
                </Typography>
                <Typography
                  style={{
                    fontSize: 16,
                    color: "#6B6B6B",
                    fontWeight: "800",
                  }}
                >
                  {formatThousand(item?.summary?.volume_start ?? 0)} L
                </Typography>
              </Stack>
            </Grid>

            {/* Booked */}
            <Grid item lg={3} md={3} xs={12} sx={{ py: "8px" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Typography style={{ fontSize: 14, color: "#ADADAD" }}>
                  Booked Order
                </Typography>
                <Typography
                  style={{
                    fontSize: 16,
                    color: "#6B6B6B",
                    fontWeight: "800",
                  }}
                >
                  {formatThousand(item?.summary?.volume_booked ?? 0)} L
                </Typography>
              </Stack>
            </Grid>

            {/* Loaded */}
            <Grid item lg={3} md={3} xs={12} sx={{ py: "8px" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Typography style={{ fontSize: 14, color: "#ADADAD" }}>
                  Loaded Order
                </Typography>
                <Typography
                  style={{
                    fontSize: 16,
                    color: "#6B6B6B",
                    fontWeight: "800",
                  }}
                >
                  {formatThousand(item?.summary?.volume_loaded ?? 0)} L
                </Typography>
              </Stack>
            </Grid>

            {/* Remaining */}
            <Grid item lg={3} md={3} xs={12} sx={{ py: "8px" }}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="center"
                spacing={1}
              >
                <Typography style={{ fontSize: 14, color: "#ADADAD" }}>
                  Remaining Order
                </Typography>
                <Typography
                  style={{
                    fontSize: 16,
                    color: "#6B6B6B",
                    fontWeight: "800",
                  }}
                >
                  {formatThousand(item?.summary?.volume_end ?? 0)} L
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};

const Status = ({
  admin,
  manager,
  finance,
  status,
  verificator,
  sales,
  sales_id,
  created_by,
  sales_verify_date,
}) => {
  if (
    status === "pending" &&
    admin === null &&
    manager === null &&
    finance == null &&
    sales_id !== created_by
  ) {
    return "Waiting Approval From Admin";
  } else if (
    status === "pending" &&
    admin === null &&
    manager === null &&
    finance == null &&
    sales_id === created_by
  ) {
    return `Waiting Approval From Sales Reff`;
  } else if (
    status === "on-approval" &&
    admin !== null &&
    manager === null &&
    finance == null &&
    sales_verify_date === null
  ) {
    return `Waiting Approval From Sales Reff`;
  } else if (
    status === "on-approval" &&
    admin !== null &&
    manager === null &&
    finance === null
  ) {
    return "Waiting Approval From Sales Manager";
  } else if (
    status === "on-approval" &&
    admin !== null &&
    manager !== null &&
    finance === null
  ) {
    return "Waiting Approval From Finance Manager";
  } else if (
    status === "open-so" &&
    admin !== null &&
    manager !== null &&
    finance !== null
  ) {
    return "Open SO";
  } else if (
    status === "on-plan" &&
    admin !== null &&
    manager !== null &&
    finance !== null
  ) {
    return "On Plan";
  } else if (
    status === "on-progress" &&
    admin !== null &&
    manager !== null &&
    finance !== null
  ) {
    return "On Progress";
  } else if (
    status === "on-verification" &&
    admin === null &&
    manager === null &&
    finance === null &&
    sales_verify_date !== null
  ) {
    return `Waiting Approval From Admin`;
  } else if (
    status === "on-verification" &&
    admin === null &&
    manager === null &&
    finance === null
  ) {
    return `On Verification By ${verificator}`;
  } else if (
    status === "on-verification" &&
    admin !== null &&
    manager === null &&
    finance === null
  ) {
    return `Waiting Verification From ${sales}`;
  } else if (status === "revoke") {
    return "Order Revoke";
  } else if (status === "rejected") {
    return "Rejected";
  } else {
    return "Completed";
  }
};

const LoadingWrap = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: 250,
  alignItems: "center",
  justifyContent: "center",
}));

export default ListOrderComponent;
