//
import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import peopleFill from "@iconify/icons-eva/people-fill";
import shoppingBagFill from "@iconify/icons-eva/shopping-bag-fill";
import fileTextFill from "@iconify/icons-eva/file-text-fill";
import listFill from "@iconify/icons-eva/list-fill";
import pricetagsFill from "@iconify/icons-eva/pricetags-fill";
import layersFill from "@iconify/icons-eva/layers-fill";
import homeFill from "@iconify/icons-eva/home-fill";
import globe3Fill from "@iconify/icons-eva/globe-3-fill";
import gridFill from "@iconify/icons-eva/grid-fill";
import pieChartFill from "@iconify/icons-eva/pie-chart-fill";
import barChart2Fill from "@iconify/icons-eva/bar-chart-2-fill";
//

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    key: "/dashboard",
    icon: getIcon(pieChart2Fill),
    sort: 1,
  },
  {
    key: "/user",
    icon: getIcon(peopleFill),
    sort: 2,
  },
  {
    key: "/customer",
    icon: getIcon(peopleFill),
    sort: 3,
  },
  {
    key: "/customer-type",
    icon: getIcon(peopleFill),
    sort: 4,
  },
  {
    key: "/mitra",
    icon: getIcon(peopleFill),
    sort: 5,
  },
  {
    key: "/partner-type",
    icon: getIcon(listFill),
    sort: 6,
  },
  {
    key: "/business-type",
    icon: getIcon(listFill),
    sort: 7,
  },
  {
    key: "/product",
    icon: getIcon(shoppingBagFill),
    sort: 8,
  },
  {
    key: "/product-category",
    icon: getIcon(gridFill),
    sort: 9,
  },
  {
    key: "/pajak",
    icon: getIcon(pricetagsFill),
    sort: 10,
  },
  {
    key: "/province",
    icon: getIcon(globe3Fill),
    sort: 11,
  },
  {
    key: "/depo",
    icon: getIcon(homeFill),
    sort: 12,
  },
  {
    key: "/payment-method",
    icon: getIcon(layersFill),
    sort: 13,
  },
  {
    key: "/payment-term",
    icon: getIcon(layersFill),
    sort: 14,
  },
  {
    key: "/new-order",
    icon: getIcon(fileTextFill),
    sort: 15,
  },
  {
    key: "/order",
    icon: getIcon(listFill),
    sort: 16,
  },
  {
    key: "/loading-order",
    icon: getIcon(listFill),
    sort: 17,
  },
  {
    key: "/delivery-order",
    icon: getIcon(listFill),
    sort: 18,
  },
  {
    key: "/page",
    icon: getIcon(listFill),
    sort: 19,
  },
  {
    key: "/access-role",
    icon: getIcon(listFill),
    sort: 20,
  },
  {
    key: "/region",
    icon: getIcon(listFill),
    sort: 21,
  },
  {
    key: "/dashboard-report",
    icon: getIcon(pieChartFill),
    sort: 22,
  },
  {
    key: "/report",
    icon: getIcon(barChart2Fill),
    sort: 23,
  },
  {
    key: "/report-admin",
    icon: getIcon(barChart2Fill),
    sort: 24,
  },
  {
    key: "/report-salesrep",
    icon: getIcon(barChart2Fill),
    sort: 25,
  },
  {
    key: "/report-operation",
    icon: getIcon(barChart2Fill),
    sort: 26,
  },
  {
    key: "/target",
    icon: getIcon(pieChart2Fill),
    sort: 27,
  },
];

export default sidebarConfig;
