// React
import { useState } from "react";

// Material UI
import {
  Container,
  Stack,
  Typography,
  Button,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";

// Components
import {
  ActionTable,
  ListHead,
  LoadingPage,
  Page,
  SearchNotFound,
  TableToolBar,
} from "../../../components";

// Iconify
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";

//
import { GetAll } from "./API";

//
import { applySortFilter, getComparator } from "../../../utils";

//
import AddPage from "./AddPage";

//
import pages from "../../../constant/page";

//
import { toast } from "react-toastify";

// Table Head
const TABLE_HEAD = [
  { id: "no", label: "No", alignRight: false, isSort: true },
  { id: "name", label: "Name", alignRight: false, isSort: true },
  { id: "path", label: "Path", alignRight: false, isSort: false },
  { id: "" },
];

function ListPage() {
  const [listPage, loading, refresh] = GetAll();

  const [q, setQ] = useState("");

  const [dialog, setDialog] = useState({
    open: false,
    mode: "Add",
    data: {},
  });

  const [no, setNo] = useState(0);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("name");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setNo(no + 5);
    } else {
      setNo(no - 5);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <LoadingPage />;
  }

  const pageList = applySortFilter(
    listPage,
    getComparator(order, orderBy),
    q,
    "name"
  );

  const isNotFound = pageList?.length === 0;

  return (
    <Page title="List Page | Bayu Sinergi">
      <Container maxWidth="xl">
        {/* Header */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            List Page
          </Typography>
          <Button
            onClick={(e) => {
              setDialog((d) => ({
                ...d,
                open: true,
                mode: "Add",
              }));
            }}
            variant="contained"
            startIcon={<Icon icon={plusFill} />}
          >
            New Page
          </Button>
        </Stack>

        <Card>
          {/* Search */}
          <TableToolBar
            value={q}
            onChange={(e) => {
              setQ(e.target.value);
              setPage(0);
            }}
            placeholder="Search Page"
            onClear={() => setQ("")}
          />

          {/* Table */}
          <TableContainer sx={{ minWidth: 200 }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {pageList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{index + 1 + no}</TableCell>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell>{item?.path}</TableCell>
                        <ActionTable
                          onEdit={() => {
                            setDialog({
                              open: true,
                              data: item,
                              mode: "Edit",
                            });
                          }}
                          onDelete={async () => {
                            try {
                              await pages.delete(item?.id);

                              refresh(true);

                              toast.success("Page Deleted!");
                            } catch (e) {
                              console.log(e);
                            }
                          }}
                        />
                      </TableRow>
                    );
                  })}
              </TableBody>
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={5} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={q} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={pageList?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* Add Page */}
      <AddPage
        dialog={dialog}
        load={() => {
          refresh(true);
        }}
        onClose={() => {
          setDialog({
            open: false,
            mode: "Add",
            data: {},
          });
        }}
      />
    </Page>
  );
}

export default ListPage;
