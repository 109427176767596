//
import { useMemo, useState, useEffect } from "react";

//
import priceProduct from "../../../../constant/priceProduct";

function GetPriceProduct(region, type) {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    const getAll = async () => {
      setLoading(true);
      try {
        const res = await priceProduct.getPriceProduct(region, type);

        setData(
          res.data.map((item) => {
            const { price, ...rest } = item;

            return {
              ...rest,
              price: price[0].price,
              price_id: price[0].price_id,
            };
          })
        );
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };

    if (region && type) {
      getAll();
    }
  }, [region, type]);

  return useMemo(() => [data, loading, error], [data, loading, error]);
}

export default GetPriceProduct;
