// React
import { useEffect, useMemo, useState } from "react";

//
import salesOrder from "../../../../constant/salesOrder";
import socket from "../../../../constant/socket";

function GetDetail(id) {
  const [data, setData] = useState({});

  const [loading, setLoading] = useState(false);

  const [load, setLoad] = useState(false);

  useEffect(() => {
    const getDetail = async () => {
      setLoading(true);
      try {
        const res = await salesOrder.getDetail(id);

        const { attach, province, prov, pbbkb, pajak, depo, supply, ...rest } =
          res.data;

        setData({
          ...rest,
          province: prov,
          pbbkb: {
            ...pajak,
            name: `${pajak?.value * 100} %`,
          },
          depo: supply,
          attach: attach.map((x) => {
            return {
              isNew: false,
              isRemove: false,
              ...x,
            };
          }),
        });
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    };

    if (id) {
      getDetail();
    }
  }, [id]);

  useEffect(() => {
    const getDetail = async () => {
      try {
        const res = await salesOrder.getDetail(id);

        const { attach, province, prov, pbbkb, pajak, depo, supply, ...rest } =
          res.data;

        setData({
          ...rest,
          province: prov,
          pbbkb: {
            ...pajak,
            name: `${pajak?.value * 100} %`,
          },
          depo: supply,
          attach: attach.map((x) => {
            return {
              isNew: false,
              isRemove: false,
              ...x,
            };
          }),
        });
      } catch (e) {
        console.log(e);
      }
      setLoad(false);
    };

    if (id && load) {
      getDetail();
    }
  }, [id, load]);

  useEffect(() => {
    socket.on("get-detail", (data) => {
      setData((d) => ({
        ...d,
        ...data,
      }));
    });
  }, []);

  return useMemo(
    () => [data, loading, setData, setLoad],
    [data, loading, setData, setLoad]
  );
}

export default GetDetail;
