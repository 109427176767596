//
import { Switch, Route, Redirect } from "react-router-dom";
//
import TableListOrder from "./TableListOrder";
import DetailOrder from "./DetailOrder";

function Customer() {
  return (
    <Switch>
      <Route path="/order/detail/:id" component={DetailOrder} />
      <Route path="/order" component={TableListOrder} />
      <Redirect to="/order" />
    </Switch>
  );
}

export default Customer;
