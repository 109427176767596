//
import { useEffect, useMemo, useState } from "react";
//
import report from "../../../../constant/report";

function GetListMonthAdmin() {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAll = async () => {
      setLoading(true);
      try {
        const res = await report.getListMonthAdmin();

        setData(
          res?.data?.map((item) => {
            return {
              month: `${item?.order_month?.substring(
                0,
                4
              )}-${item?.order_month?.substring(4, 6)}`,
              order_month: item?.order_month,
            };
          })
        );
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    getAll();
  }, []);

  return useMemo(() => [data, loading], [data, loading]);
}

export default GetListMonthAdmin;
