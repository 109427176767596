// React
import { useState } from "react";

// Material UI
import {
  Container,
  Stack,
  IconButton,
  Typography,
  Card,
  Grid,
  TextField,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Button,
  Autocomplete,
} from "@mui/material";

// Components
import {
  ListHead,
  LoadingPage,
  Page,
  SearchNotFound,
  ActionTable,
  AlertDialog,
} from "../../../components";

// Iconify
import { Icon } from "@iconify/react";
import arrowBackFill from "@iconify/icons-eva/arrow-back-fill";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
import editFill from "@iconify/icons-eva/edit-fill";

//
import { useHistory, useLocation, useParams } from "react-router-dom";
import priceProduct from "../../../constant/priceProduct";

// API
import { GetOnce, GetCategory, GetPriceProduct } from "./API";

//
import { formatDate, formatThousand } from "../../../utils";

//
import AddPriceProduct from "./AddPriceProduct";

//
import { toast } from "react-toastify";

//
import product from "../../../constant/product";

//
import { useData } from "../../../context/DataProvider";

function DetailProduct() {
  const { id } = useParams();

  const [detail, setDetail, loading, refresh] = GetOnce(id);

  const [listPrice, loadingPrice, refreshPrice] = GetPriceProduct(id);

  const history = useHistory();

  const location = useLocation();

  if (loading || loadingPrice) {
    return <LoadingPage />;
  }

  return (
    <Page title="Detail Product | Bayu Sinergi">
      {/* Navigation */}
      <Stack direction="row" alignItems="center" sx={{ px: 3, mb: 2 }}>
        <IconButton
          size="small"
          onClick={() => {
            history.goBack();
          }}
        >
          <Icon icon={arrowBackFill} fontSize={24} />
        </IconButton>
        <Typography sx={{ ml: 1 }} variant="h5">
          Detail Product {location?.state?.name}
        </Typography>
      </Stack>

      <Container maxWidth="xl">
        {/* Detail */}
        <DetailForm
          id={id}
          detail={detail}
          setDetail={setDetail}
          refresh={() => {
            refresh(true);
          }}
        />

        {/* Gap */}
        <div style={{ height: 40 }} />

        {/* Line Pricing */}
        <TablePrice listPrice={listPrice} refresh={refreshPrice} />
      </Container>
    </Page>
  );
}

const DetailForm = ({ id, detail, setDetail, refresh }) => {
  const { user } = useData();

  const history = useHistory();

  const [listCategory, loading] = GetCategory();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [dialog, setDialog] = useState({
    open: false,
    mode: "Delete",
  });

  const [error, setError] = useState({
    name: "",
    category: "",
  });

  const validate = () => {
    const newError = { ...error };
    const { name, category } = detail;

    if (!name) {
      newError.name = "Name can't be empty";
    }

    if (category === null) {
      newError.category = "Category can't be empty";
    }

    return newError;
  };

  const update = async () => {
    const findError = validate();

    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setIsSubmitting(true);
      try {
        const newData = {
          name: detail?.name,
          description: detail?.description,
          category_id: detail?.category?.id,
        };

        await product.update(id, newData);

        setDialog((d) => ({
          ...d,
          open: false,
        }));

        toast.success("Product Edited");

        refresh();
      } catch (e) {
        console.log(e);
      }
      setIsSubmitting(false);
    }
  };

  const deleteProduct = async () => {
    try {
      await product.delete(id);

      setDialog((d) => ({
        ...d,
        open: false,
      }));

      toast.success("Product deleted!");

      history.push("/product");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Card sx={{ py: 4, px: 3 }}>
        {/* Detail */}
        <Stack mb={3}>
          <Typography variant="subtitle1">Detail Product</Typography>
        </Stack>

        {/* Form */}
        <Grid container spacing={2} mt={1}>
          <Grid item lg={6} md={6} xs={6}>
            <TextField
              label="Name"
              value={detail?.name}
              fullWidth
              disabled={user?.role === "bod"}
            />
          </Grid>
          <Grid item lg={6} md={6} xs={6}>
            <Autocomplete
              fullWidth
              getOptionLabel={(option) => option.name}
              options={listCategory}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Category"
                  error={error.category ? true : false}
                  helperText={error.category}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              onChange={(e, v) => {
                setDetail((f) => ({
                  ...f,
                  category: v,
                }));
              }}
              value={detail?.category}
              disabled={isSubmitting || user?.role === "bod"}
            />
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <TextField
              label="Description"
              multiline
              minRows={1}
              maxRows={4}
              onChange={(e) => {
                setDetail((f) => ({
                  ...f,
                  description: e.target.value,
                }));
              }}
              value={detail?.description}
              fullWidth
              disabled={user?.role === "bod"}
            />
          </Grid>
        </Grid>

        {user?.role !== "bod" && (
          <Stack
            mt={3}
            direction="row"
            alignItems="center"
            justifyContent="end"
            spacing={1}
          >
            <Button
              variant="contained"
              color="error"
              startIcon={<Icon icon={trash2Fill} />}
              onClick={() =>
                setDialog((d) => ({
                  ...d,
                  open: true,
                  mode: "Delete",
                }))
              }
            >
              Delete
            </Button>
            <Button
              variant="contained"
              startIcon={<Icon icon={editFill} />}
              onClick={() =>
                setDialog((d) => ({
                  ...d,
                  open: true,
                  mode: "Edit",
                }))
              }
            >
              Edit
            </Button>
          </Stack>
        )}
      </Card>

      {/* Alert Dialog */}
      <AlertDialog
        open={dialog.open}
        title={`${dialog.mode === "Delete" ? "Delete" : "Edit"} Product`}
        content={`Are you sure want to ${
          dialog.mode === "Delete" ? "delete" : "edit"
        } this product?`}
        handleOk={dialog.mode === "Delete" ? deleteProduct : update}
        handleClose={() =>
          setDialog((d) => ({
            ...d,
            open: false,
          }))
        }
      />
    </>
  );
};

const TablePrice = ({ listPrice, refresh }) => {
  const { user } = useData();

  const [dialog, setDialog] = useState({
    open: false,
    data: {},
    mode: "Add",
  });

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isNotFound = listPrice?.length === 0;

  const TABLE_HEAD = [
    { id: "no", label: "No", alignRight: false, isSort: false },
    { id: "region_name", label: "Region", alignRight: false, isSort: false },
    {
      id: "customer_type",
      label: "Customer Type",
      alignRight: false,
      isSort: false,
    },
    { id: "price", label: "Price", alignRight: false, isSort: false },
    {
      id: "start_at",
      label: "Start Active Date",
      alignRight: false,
      isSort: false,
    },
    {
      id: "",
    },
  ];

  const TABLE_HEAD_BOD = [
    { id: "no", label: "No", alignRight: false, isSort: false },
    { id: "region_name", label: "Region", alignRight: false, isSort: false },
    {
      id: "customer_type",
      label: "Customer Type",
      alignRight: false,
      isSort: false,
    },
    { id: "price", label: "Price", alignRight: false, isSort: false },
    {
      id: "start_at",
      label: "Start Active Date",
      alignRight: false,
      isSort: false,
    },
  ];

  return (
    <>
      <Card sx={{ py: 4, px: 3 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="subtitle1">List Pricing</Typography>
          {user?.role !== "bod" && (
            <Button
              onClick={(e) => {
                setDialog({ mode: "Add", open: true, data: {} });
              }}
              variant="contained"
            >
              New Pricing
            </Button>
          )}
        </Stack>

        <TableContainer sx={{ minWidth: 200 }}>
          <Table>
            <ListHead
              headLabel={user?.role === "bod" ? TABLE_HEAD_BOD : TABLE_HEAD}
              colored
            />
            <TableBody>
              {listPrice
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{item?.region_name}</TableCell>
                      <TableCell>{item?.customer_type}</TableCell>
                      <TableCell>Rp. {formatThousand(item?.price)}</TableCell>
                      <TableCell>{formatDate(item?.start_at)}</TableCell>
                      {user?.role !== "bod" && (
                        <ActionTable
                          onEdit={() => {
                            setDialog({
                              open: true,
                              data: item,
                              mode: "Edit",
                            });
                          }}
                          onDelete={async () => {
                            try {
                              await priceProduct.delete(item?.id);

                              refresh(true);

                              toast.success("Price Product Deleted!");
                            } catch (e) {
                              console.log(e);
                            }
                          }}
                        />
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery="" />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={listPrice?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      {/* Dialog Add Price Product */}
      <AddPriceProduct
        dialog={dialog}
        load={() => {
          refresh(true);
        }}
        onClose={() => {
          setDialog({
            open: false,
            data: {},
            mode: "Add",
          });
        }}
      />
    </>
  );
};

export default DetailProduct;
