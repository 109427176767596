import { filter } from "lodash";
import { formatDateList } from ".";

// Filter
export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function applySortFilter(array, comparator, query, key) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (item) =>
      item?.[key]?.toLowerCase().includes(query.toLowerCase())
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export function applySortFilterOrder(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);

  const date = (v) => {
    return formatDateList(v);
  };

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (item) =>
        item?.["customer"]?.toLowerCase().includes(query.toLowerCase()) ||
        item?.["order_number"]?.toLowerCase().includes(query.toLowerCase()) ||
        item?.["po_number"]?.toLowerCase().includes(query.toLowerCase()) ||
        item?.["sales"]?.toLowerCase().includes(query.toLowerCase()) ||
        date(item?.["order_date"])
          ?.toLowerCase()
          .includes(query.toLowerCase()) ||
        date(item?.["so_expiredate"])
          ?.toLowerCase()
          .includes(query.toLowerCase())
    );
  }
  return stabilizedThis.map((el) => el[0]);
}
