import axios from "../config";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  create: (data) => axios.post("/access-role", data),
  getAll: () => axios.get("/access-role"),
  getOnce: (id) => axios.get(`/access-role/${id}`),
  update: (id, data) => axios.put(`/access-role/${id}`, data),
  delete: (id) => axios.delete(`/access-role/${id}`),
};
