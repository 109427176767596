//
import { useState, useEffect } from "react";
// material
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
//
import contact from "../../../constant/contact";
//
import { toast } from "react-toastify";
//
import { useParams } from "react-router-dom";

function AddContactPerson({ onClose, load, dialog: { open, mode, data } }) {
  const { id } = useParams();

  const [form, setForm] = useState({
    name: "",
    email: "",
    contactnumber: "",
    note: "",
  });

  const [error, setError] = useState({
    name: "",
    email: "",
    contactnumber: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((f) => ({
      ...f,
      [name]: value,
    }));

    setError((f) => ({
      ...f,
      [name]: "",
    }));
  };

  const validate = () => {
    const newError = { ...error };
    const { name, email, contactnumber } = form;

    if (!name) {
      newError.name = "Name can't be empty";
    }

    if (!email) {
      newError.email = "Email can't be empty";
    }

    if (!contactnumber) {
      newError.contactnumber = "Phone Number can't be empty";
    }

    return newError;
  };

  const handleSubmit = async () => {
    const findError = validate();

    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setIsSubmitting(true);
      try {
        if (mode === "Edit") {
          await contact.update(data.id, { ...form, customer_id: parseInt(id) });

          toast.success("Contact Person Edited");
        } else {
          await contact.create({ ...form, customer_id: parseInt(id) });

          toast.success("Contact Person Added");
        }

        onClose();

        setForm({
          name: "",
          email: "",
          contactnumber: "",
          note: "",
        });

        setError({
          name: "",
          email: "",
          contactnumber: "",
        });

        load();
      } catch (e) {
        console.log(e);
      }
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (mode === "Edit" && data) {
      setForm(data);
    }
  }, [mode, data]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isSubmitting) {
          onClose();
          setForm({
            name: "",
            email: "",
            contactnumber: "",
            note: "",
          });
          setError({
            name: "",
            email: "",
            contactnumber: "",
          });
        }
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {mode === "Edit" ? "Edit" : "Add New"} Contact Person
      </DialogTitle>
      <DialogContent dividers>
        <TextField
          name="name"
          label="Name"
          autoComplete="off"
          fullWidth
          sx={{ mb: 2 }}
          value={form.name}
          onChange={handleChange}
          error={error.name ? true : false}
          helperText={error.name}
          disabled={isSubmitting}
        />
        <TextField
          name="email"
          label="Email"
          autoComplete="off"
          type="email"
          fullWidth
          sx={{ mb: 2 }}
          value={form.email}
          onChange={handleChange}
          error={error.email ? true : false}
          helperText={error.email}
          disabled={isSubmitting}
        />
        <TextField
          name="contactnumber"
          label="Phone Number"
          autoComplete="off"
          fullWidth
          sx={{ mb: 2 }}
          value={form.contactnumber}
          onChange={handleChange}
          error={error.contactnumber ? true : false}
          helperText={error.contactnumber}
          disabled={isSubmitting}
        />
        <TextField
          name="note"
          label="Note"
          autoComplete="off"
          multiline
          minRows={1}
          maxRows={4}
          fullWidth
          value={form.note}
          onChange={handleChange}
          disabled={isSubmitting}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <Button
          variant="contained"
          color="inherit"
          disabled={isSubmitting}
          onClick={() => {
            onClose();
            setForm({
              name: "",
              email: "",
              contactnumber: "",
              note: "",
            });
            setError({
              name: "",
              email: "",
              contactnumber: "",
            });
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          onClick={handleSubmit}
          loading={isSubmitting}
          variant="contained"
        >
          {mode === "Edit" ? "Edit" : "Add"} Contact Person
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddContactPerson;
