import { useHistory } from "react-router-dom";
// material
import { Box, Button, Typography, Container, styled } from "@mui/material";
// components
import { Page } from "../../components";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  minHeight: "100%",
  alignItems: "center",
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10),
}));

// ----------------------------------------------------------------------

export default function Unauthorized() {
  const history = useHistory();

  return (
    <RootStyle title="Unauthorized | Bayu Sinergi">
      <Container>
        <Box sx={{ maxWidth: 480, margin: "auto", textAlign: "center" }}>
          <Typography variant="h3" paragraph>
            Sorry, you don't have access to this page!
          </Typography>

          <Button
            onClick={() => {
              history.goBack();
            }}
            size="large"
            variant="contained"
          >
            Go Back
          </Button>
        </Box>
      </Container>
    </RootStyle>
  );
}
