//
import { useState, useEffect } from "react";
// material
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
//
import accessRole from "../../../constant/accessRole";
//
import { toast } from "react-toastify";
//
import { GetPage } from "./API";
//
import { useParams } from "react-router-dom";

function AddAccessRole({ onClose, load, dialog: { open, mode, data } }) {
  const { id } = useParams();

  const [listPage, loadingPage] = GetPage();

  const [form, setForm] = useState({
    page: null,
  });

  const [error, setError] = useState({
    page: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const validate = () => {
    const newError = { ...error };
    const { page } = form;

    if (page === null) {
      newError.page = "Page can't be empty";
    }

    return newError;
  };

  const handleSubmit = async () => {
    const findError = validate();

    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setIsSubmitting(true);
      try {
        const newData = {
          role_id: parseInt(id),
          page_id: form?.page?.id,
        };

        if (mode === "Edit") {
          await accessRole.update(data.id, newData);

          toast.success("Access Role Edited");
        } else {
          await accessRole.create(newData);

          toast.success("Access Role Added");
        }

        setForm({
          role: null,
          page: null,
        });

        setError({
          role: "",
          page: "",
        });

        onClose();

        load();
      } catch (e) {
        console.log(e);
      }
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (mode === "Edit" && data) {
      setForm(data);
    }
  }, [mode, data]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isSubmitting) {
          onClose();
          setForm({
            page: null,
          });
          setError({
            page: "",
          });
        }
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {mode === "Edit" ? "Edit" : "Add New"} Access Role
      </DialogTitle>
      <DialogContent dividers>
        <Autocomplete
          fullWidth
          getOptionLabel={(option) => option.name}
          options={listPage}
          loading={loadingPage}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Page"
              error={error.page ? true : false}
              helperText={error.page}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
          onChange={(e, v) => {
            setForm((f) => ({
              ...f,
              page: v,
            }));

            setError((err) => ({
              ...err,
              page: "",
            }));
          }}
          value={form.page}
          disabled={isSubmitting}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <LoadingButton
          onClick={handleSubmit}
          loading={isSubmitting}
          variant="contained"
        >
          {mode === "Edit" ? "Edit" : "Add"} Access Role
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddAccessRole;
