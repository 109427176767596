import format from "date-fns/format";
import { id } from "date-fns/locale";
import parseISO from "date-fns/parseISO";
import isToday from "date-fns/isToday";
import isYesterday from "date-fns/isYesterday";

export const formatDate = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  return format(new Date(timestamp), "EEEE, dd LLL yyyy HH:mm 'WIB'", {
    locale: id,
  });
};

export const formatDateList = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  return format(new Date(timestamp), "dd/LL/yyyy HH:mm 'WIB'", {
    locale: id,
  });
};

export const formatDateBAST = (timestamp) => {
  if (!timestamp) {
    return null;
  }
  return format(new Date(timestamp), "dd/LL/yyyy", {
    locale: id,
  });
};

export const isoDate = (timestamp) => {
  if (!timestamp) {
    return null;
  }

  return format(new Date(timestamp), "y-LL-dd");
};

export const isoToRelative = (timestamp) => {
  try {
    const date = parseISO(timestamp);

    if (isToday(date)) {
      return "Today";
    } else if (isYesterday(date)) {
      return "Yesterday";
    } else {
      return format(date, "EEEE, dd MMM Y", { locale: id });
    }
  } catch (e) {
    return "";
  }
};

export const isoToTime = (timestamp) => {
  try {
    return format(new Date(timestamp), "HH:mm");
  } catch (e) {
    return "";
  }
};
