//
import React, { useContext, useState, useEffect } from "react";
//
import jwtDecode from "jwt-decode";
//
import users from "../constant/users";
import salesCustomer from "../constant/salesCustomer";
import socket from "../constant/socket";
//
import { LoadingPage } from "../components";
//
import { toast } from "react-toastify";
//
import { Typography } from "@mui/material";
//
import UIfx from "uifx";
import tone from "../assets/tone.mp3";

// Context
const DataContext = React.createContext();

export function useData() {
  return useContext(DataContext);
}

export function DataProvider({ children }) {
  const [user, setUser] = useState({});
  const [role, setRole] = useState([]);
  const [sales, setSales] = useState({});
  const [customer, setCustomer] = useState({});

  const [loading, setLoading] = useState(false);
  const [loadingSales, setLoadingSales] = useState(false);
  const [loadingDetail, setLoadingDetail] = useState(false);

  const [reloadDetail, setReloadDetail] = useState(true);

  useEffect(() => {
    const getToken = () => {
      setLoading(true);
      const token = localStorage.getItem("bayusinergi-token");
      if (token) {
        const decoded = jwtDecode(token);

        setUser(decoded?.data);

        setRole(
          decoded?.data?.akses?.map((item) => {
            const { page } = item;

            return page;
          })
        );
      }
      setLoading(false);
    };

    getToken();
  }, []);

  useEffect(() => {
    const getSales = async () => {
      setLoadingSales(true);
      try {
        const res = await salesCustomer.getSales(customer?.customer_id);

        setSales(res?.data);
      } catch (e) {
        console.log(e);
      }
      setLoadingSales(false);
    };

    if (user?.role === "customer" && customer?.customer_id) {
      getSales();
    }
  }, [user, customer]);

  useEffect(() => {
    const getDetail = async () => {
      setLoadingDetail(true);
      try {
        const res = await users.getDetail(user?.id);

        setCustomer(res?.data);
      } catch (e) {
        console.log(e);
      }
      setReloadDetail(false);
      setLoadingDetail(false);
    };

    if (user?.role === "customer") {
      if (reloadDetail) {
        getDetail();
      }
    }
  }, [user, reloadDetail]);

  useEffect(() => {
    const token = localStorage.getItem("bayusinergi-token");

    const decoded = jwtDecode(token);

    const userT = decoded?.data;

    if (userT?.role) {
      socket.emit("subscribe-notif", userT?.role?.replace(" ", "-"));
    }

    return () => {
      socket.emit("leave-notif", userT?.role?.replace(" ", "-"));
    };
  }, []);

  useEffect(() => {
    socket.on("get-notif", (data) => {
      const bel = new UIfx(tone, {
        volume: 1.0,
      });

      bel.play();

      toast.info(
        <>
          <Typography variant="subtitle1">{data.tile}</Typography>
          <Typography variant="subtitle1">{data.message}</Typography>
        </>
      );
    });
  }, []);

  if (loading || loadingSales || loadingDetail) {
    return <LoadingPage height="100%" />;
  }

  return (
    <DataContext.Provider
      value={{
        user,
        sales,
        customer,
        role,
        setReloadDetail,
      }}
    >
      {children}
    </DataContext.Provider>
  );
}
