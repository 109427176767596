import axios from "../config";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  create: (data) => axios.post(`/delivery-order/`, data),
  proccess: (data) => axios.post(`/delivery-order/proccess`, data),
  getOnce: (id) => axios.get(`/delivery-order/${id}`),
  getALl: (status) => axios.get(`/delivery-order/?status=${status}`),
  getReport: () => axios.get(`/delivery-order/report`),
  update: (id, data) => axios.put(`/delivery-order/${id}`, data),
};
