// React
import React, { useState } from "react";

// Material UI
import {
  Autocomplete,
  Card,
  styled,
  TextField,
  Typography,
} from "@mui/material";

// Chart
import Chart from "react-apexcharts";

// API
import { GetListMonthSalesRep, GetReportSalesRep } from "./API";

// Components
import { LoadingPage, Page } from "../../../components";
import { formatThousand } from "../../../utils";

const defaultDate = `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
  .toString()
  .padStart(2, "0")}`;

const defaultDate1 = `${new Date().getFullYear()}${(new Date().getMonth() + 1)
  .toString()
  .padStart(2, "0")}`;

function ReportSalesRep() {
  const [month, setMonth] = useState({
    month: defaultDate,
    order_month: defaultDate1,
  });

  const [report, loading] = GetReportSalesRep(month?.month);

  const [months, loadingMonths] = GetListMonthSalesRep();

  if (loading || loadingMonths) {
    return <LoadingPage />;
  }

  const listMonth = months?.sort((a, b) => {
    const aA = parseInt(a?.order_month);

    const bB = parseInt(b?.order_month);

    return bB - aA;
  });

  return (
    <Page title="Report Sales Rep | Bayu Sinergi">
      {/* Header */}
      <Header>
        <div style={{ flexGrow: 1 }} />

        {/* Filter */}
        <Autocomplete
          options={listMonth}
          getOptionLabel={(option) => option?.month}
          sx={{ width: 300 }}
          renderInput={(params) => (
            <TextField {...params} label="Filter Month" />
          )}
          onChange={(e, v) => {
            setMonth(v);
          }}
          value={month}
        />
      </Header>

      {/* Gap */}
      <div style={{ height: 16 }} />

      {/* Volume */}
      <Volume report={report} />

      {/* Gap */}
      <div style={{ height: 24 }} />

      {/* Grand Total */}

      <GrandTotal report={report} />
    </Page>
  );
}

const Volume = ({ report }) => {
  const options = {
    chart: {
      type: "bar",
      height: 380,
    },
    colors: "#e0a338",
    dataLabels: {
      formatter: (value) => {
        return formatThousand(value);
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return formatThousand(value);
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return formatThousand(value);
        },
      },
    },
  };

  const series = [
    {
      name: "Volume",
      data: report?.map((item) => {
        return {
          x: `${item?.order_month?.substring(
            0,
            4
          )}-${item?.order_month?.substring(4, 6)}`,
          y: item?.sum_qty,
        };
      }),
    },
  ];

  return (
    <Card sx={{ py: 4, px: 3 }}>
      {/* Header */}
      <Header>
        {/* Title */}
        <Typography variant="h4">Report Total Volume Monthly</Typography>

        <div style={{ flexGrow: 1 }} />

        {/* Filter */}
      </Header>

      {/* Bar */}
      <Chart options={options} series={series} type="bar" height={300} />
    </Card>
  );
};

const GrandTotal = ({ report }) => {
  const options = {
    chart: {
      type: "bar",
      height: 380,
    },
    colors: "#e0a338",
    dataLabels: {
      formatter: (value) => {
        return formatThousand(value);
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return formatThousand(value);
        },
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return formatThousand(value);
        },
      },
    },
  };

  const series = [
    {
      name: "Grand Total",
      data: report?.map((item) => {
        return {
          x: `${item?.order_month?.substring(
            0,
            4
          )}-${item?.order_month?.substring(4, 6)}`,
          y: item?.sum_grandtotal,
        };
      }),
    },
  ];

  return (
    <Card sx={{ py: 4, px: 3 }}>
      {/* Header */}
      <Header>
        {/* Title */}
        <Typography variant="h4">Report Grand Total Monthly</Typography>

        <div style={{ flexGrow: 1 }} />

        {/* Filter */}
      </Header>

      {/* Bar */}
      <Chart options={options} series={series} type="bar" height={300} />
    </Card>
  );
};

const Header = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  alignItems: "center",
  marginBottom: theme.spacing(3),
}));

export default ReportSalesRep;
