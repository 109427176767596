//
import { useState } from "react";
//
import {
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
//
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
//
import {
  ListHead,
  LoadingPage,
  Page,
  SearchNotFound,
  TableToolBar,
} from "../../../components";
//
import { applySortFilter, getComparator } from "../../../utils";
//
import AddProduct from "./AddProduct";
//
import { GetAll } from "./API";
//
import { useHistory } from "react-router-dom";
//
import { useData } from "../../../context/DataProvider";

// Table Head
const TABLE_HEAD = [
  { id: "no", label: "No", alignRight: false, isSort: true },
  { id: "name", label: "Name", alignRight: false, isSort: true },
  { id: "category", label: "Category", alignRight: false, isSort: true },
  { id: "description", label: "Description", alignRight: false, isSort: true },
];

function Product() {
  const { user } = useData();

  const history = useHistory();

  const [listProduct, loading, refresh] = GetAll();

  const [dialog, setDialog] = useState({
    open: false,
    data: {},
    id: "",
    mode: "Add",
  });

  const [q, setQ] = useState("");

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("name");

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <LoadingPage />;
  }

  const productList = applySortFilter(
    listProduct,
    getComparator(order, orderBy),
    q,
    "name"
  );

  const isNotFound = productList?.length === 0;

  return (
    <Page title="List Product | Bayu Sinergi">
      <Container maxWidth="xl">
        {/* Header */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            List Product
          </Typography>
          {user?.role !== "bod" && (
            <Button
              onClick={(e) => {
                setDialog({ mode: "Add", open: true, data: {}, id: "" });
              }}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
            >
              New Product
            </Button>
          )}
        </Stack>

        {/* Table */}
        <Card>
          {/* Search */}
          <TableToolBar
            value={q}
            onChange={(e) => {
              setQ(e.target.value);
              setPage(0);
            }}
            placeholder="Search Product"
            onClear={() => setQ("")}
          />

          {/* Table */}
          <TableContainer sx={{ minWidth: 200 }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {productList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return (
                      <TableRow
                        key={index}
                        hover
                        onClick={() => {
                          history.push(`/product/${item?.product_id}`, {
                            name: item?.name,
                          });
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell align="left">{index + 1}</TableCell>
                        <TableCell align="left">{item?.name}</TableCell>
                        <TableCell align="left">
                          {item?.category?.name ?? "-"}
                        </TableCell>
                        <TableCell align="left">
                          {item?.description ?? "-"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={5} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={q} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={productList?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* Dialog Add Product */}
      <AddProduct
        dialog={dialog}
        load={() => {
          refresh(true);
        }}
        onClose={() => {
          setDialog({
            open: false,
            data: {},
            id: "",
            mode: "Add",
          });
        }}
      />
    </Page>
  );
}

export default Product;
