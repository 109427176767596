//
import { useMemo, useState, useEffect } from "react";

//
import page from "../../../../constant/page";

function GetPage() {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    const getAll = async () => {
      setLoading(true);
      try {
        const res = await page.getAll();

        setData(
          res.data?.map((item) => {
            const { page_id, name } = item;

            return {
              id: page_id,
              name,
            };
          })
        );
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };

    getAll();
  }, []);

  return useMemo(() => [data, loading, error], [data, loading, error]);
}

export default GetPage;
