//
import { useState } from "react";
//
import {
  Card,
  Container,
  Grid,
  Typography,
  Stack,
  Divider,
  TextField,
  styled,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
  MenuItem,
  Autocomplete,
  IconButton,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
//
import { LoadingOrder, Page, NumberFormatRp } from "../../../components";
//
import OrderLine from "./OrderLine";
//
import { GetSales, GetCustomer, GetPajak } from "./API";
import { GetAll as GetPaymentMethod } from "../PaymnetMethod/API";
import { GetAll as GetPaymentTerm } from "../PaymentTerm/API";
import { GetAll as GetDepo } from "../Depo/API";
//
import { Icon } from "@iconify/react";
import fileAddFill from "@iconify/icons-eva/file-add-fill";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
//
import { toast } from "react-toastify";
//
import { getBase64 } from "../../../utils";
//
import { useData } from "../../../context/DataProvider";
//
import salesOrder from "../../../constant/salesOrder";
import orderAttachment from "../../../constant/orderAttachment";
import socket from "../../../constant/socket";
//
import { useHistory } from "react-router-dom";

function PurchaseOrder() {
  const { user, customer, sales } = useData();

  const history = useHistory();

  const [listSales, loadingSales] = GetSales();

  const [listProvince, loadingProvince] = GetPajak();

  const [listPayment, loadingPayment] = GetPaymentTerm();

  const [listPaymentMethod, loadingPaymentMethod] = GetPaymentMethod();

  const [listDepo, loadingDepo] = GetDepo();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form, setForm] = useState({
    order_date: "",
    expected_date: "",
    po_expiredate: "",
    so_expiredate: "",
    type_of_sales: "",
    no_fo_skp: "",
    price_skp: "",
    contact: "",
    telp_email: "",
    npwp: "",
    oat: "",
    fee_agent: "",
    po_number: "",
    skp_id: "",
    sales: null,
    customer: null,
    address: "",
    province: null,
    pbbkb: null,
    method: null,
    payment: null,
    ops_type: "",
    depo: null,
    note: "",
  });

  const [completeness, setCompleteness] = useState({
    sign_sts: false,
    npwp_sts: false,
    attach_sts: false,
  });

  const [orderLine, setOrderLine] = useState({
    product: null,
  });

  const [listFile, setListFile] = useState([]);

  const [error, setError] = useState({
    order_date: "",
    expected_date: "",
    po_expiredate: "",
    so_expiredate: "",
    type_of_sales: "",
    no_fo_skp: "",
    price_skp: "",
    oat: "",
    fee_agent: "",
    po_number: "",
    skp_id: "",
    sales: "",
    customer: "",
    address: "",
    province: "",
    pbbkb: "",
    method: "",
    payment: "",
    ops_type: "",
    depo: "",
    note: "",
  });

  const handleChecked = (event) => {
    setCompleteness((c) => ({
      ...c,
      [event.target.name]: event.target.checked,
    }));
  };

  const validate = () => {
    const newError = { ...error };

    const {
      order_date,
      expected_date,
      po_expiredate,
      so_expiredate,
      type_of_sales,
      oat,
      fee_agent,
      po_number,
      skp_id,
      no_fo_skp,
      // price_skp,
      sales,
      customer,
      address,
      province,
      pbbkb,
      method,
      payment,
      ops_type,
      depo,
      note,
    } = form;

    if (!order_date) {
      newError.order_date = "PO Date required!";
    }

    if (!po_number) {
      newError.po_number = "PO Number required!";
    }

    if (!expected_date) {
      newError.expected_date = "Expected Date required!";
    }

    if (!po_expiredate) {
      newError.po_expiredate = "PO Expire Date required!";
    }

    if (!so_expiredate) {
      newError.so_expiredate = "SO Expire Date required!";
    }

    if (
      user.role !== "customer" &&
      user.role === "sales admin" &&
      sales === null
    ) {
      newError.sales = "Sales required!";
    }

    if (
      ((user.role !== "customer" && sales !== null) ||
        (user.role !== "customer" && sales === null)) &&
      customer === null
    ) {
      newError.customer = "Customer can't be empty!";
    }

    if (!address) {
      newError.address = "Address required!";
    }

    if (province === null) {
      newError.province = "Province required!";
    }

    if (pbbkb === null) {
      newError.pbbkb = "PBBKB required!";
    }

    if (method === null) {
      newError.method = "Method required!";
    }

    if (payment === null) {
      newError.payment = "Paymnet required!";
    }

    if (!ops_type) {
      newError.ops_type = "Ops Type required!";
    }

    if (depo === null) {
      newError.depo = "Depo required!";
    }

    if (type_of_sales === "CIF" && !oat) {
      newError.oat = "OAT can't be empty!";
    }

    if (type_of_sales === "CIF" && !fee_agent) {
      newError.fee_agent = "Fee Agent can't be empty!";
    }

    if (!type_of_sales) {
      newError.type_of_sales = "Type Of Sales required!";
    }

    if (!skp_id) {
      newError.skp_id = "SKP ID required!";
    } else if (skp_id !== "BS" && !no_fo_skp) {
      newError.no_fo_skp = "No FO SKP required!";
    }

    // if (user.role === "mitra" && user.type === "skp" && !price_skp) {
    //   newError.price_skp = "Harga Tebus SKP required!";
    // }

    if (
      user.role === "sales admin" &&
      Object.values(completeness).includes(false) &&
      !note
    ) {
      newError.note = "Note required!";
    }

    return newError;
  };

  const handleSubmit = async () => {
    const findErrors = validate();

    if (Object.values(findErrors).some((e) => e !== "")) {
      if (
        user.role === "sales admin" &&
        Object.values(completeness).includes(false) &&
        !form?.note
      ) {
        toast.error("Completeness required!");
      }

      setError(findErrors);
    } else {
      try {
        setIsSubmitting(true);

        const {
          sales: s,
          customer: c,
          payment,
          oat,
          fee_agent,
          province,
          depo,
          pbbkb,
          method,
          price_skp,
          so_expiredate,
          ...rest
        } = form;

        let expired = new Date(so_expiredate).setHours(23, 59, 59, 59);

        let data = {
          sales_id:
            user.role === "sales rep"
              ? user?.id
              : user.role === "customer"
              ? sales?.sales_id
              : s?.id,
          customer_id:
            user.role === "customer" ? customer?.customer_id : c?.customer_id,
          payment_method: method?.id,
          payment_term: payment?.id,
          oat: oat === "" ? 0 : oat,
          fee_agent: fee_agent === "" ? 0 : fee_agent,
          province: province?.id,
          pbbkb: pbbkb?.id,
          depo: depo?.id,
          product: orderLine?.product?.product_id,
          price_id: orderLine?.product?.price_id,
          qty: parseInt(orderLine?.qty !== "" ? orderLine?.qty : 0),
          total: parseInt(orderLine?.total !== "" ? orderLine?.total : 0),
          region: form?.depo?.region?.id,
          so_expiredate: expired,
          ...completeness,
          ...rest,
        };

        // if (user?.role === "mitra" && user?.type === "skp") {
        //   data.price_skp = parseInt(price_skp);
        // }

        const res = await salesOrder.create(data);

        const attach = listFile.map((item) => {
          return {
            order_number: res.data.order_number,
            filename: item.filename,
            url: item.file,
            upload_by: user.id,
          };
        });

        if (attach.length > 0) {
          await orderAttachment.create({ list_attach: attach });
        }

        socket.emit("send-load");

        toast.success("Sales Order Submitted");

        history.push(`/order/${res.data.id}`);

        setIsSubmitting(false);
      } catch (e) {
        console.log(e);
        setIsSubmitting(false);
      }
    }
  };

  const [listCustomer, loadingCustomer] = GetCustomer(form?.sales);

  const listPajak =
    listProvince?.find((item) => item?.id === form?.province?.id)?.pajak ?? [];

  return (
    <Page title="Sales Order | Bayu Sinergi">
      {isSubmitting ? <LoadingOrder /> : null}
      <Container maxWidth="xl">
        <Card sx={{ py: 4, px: 3 }}>
          {/* Header */}
          <Typography variant="h4" mb={4}>
            Create Sales Order
          </Typography>

          {/* Form */}
          <Grid container direction="row" spacing={3}>
            {/* Section 1 */}
            <Grid item lg={6}>
              {/* PO Date */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
              >
                <Typography style={{ width: 150 }}>PO Date</Typography>
                <DatePicker
                  onChange={(v) => {
                    setForm((f) => ({
                      ...f,
                      order_date: v,
                    }));
                    setError((e) => ({
                      ...e,
                      order_date: "",
                    }));
                  }}
                  value={form.order_date}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      size="small"
                      error={error.order_date ? true : false}
                      helperText={error.order_date}
                      fullWidth
                    />
                  )}
                />
              </Stack>

              {/* PO Expire Date */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>PO Expire Date</Typography>
                <DatePicker
                  onChange={(v) => {
                    setForm((f) => ({
                      ...f,
                      po_expiredate: v,
                    }));
                    setError((e) => ({
                      ...e,
                      po_expiredate: "",
                    }));
                  }}
                  value={form.po_expiredate}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      size="small"
                      error={error.po_expiredate ? true : false}
                      helperText={error.po_expiredate}
                      fullWidth
                    />
                  )}
                />
              </Stack>

              {/* Customer */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Customer</Typography>
                {user.role === "customer" ? (
                  <CustomTypography>{customer?.name ?? "-"}</CustomTypography>
                ) : (
                  <CustomAutoComplete
                    getOptionLabel={(option) => option.name}
                    options={listCustomer}
                    loading={loadingCustomer}
                    onChange={(e, v) => {
                      setForm((f) => ({
                        ...f,
                        customer: v,
                        sales: v?.sales ?? null,
                        address: v?.address ?? "",
                      }));

                      setError((err) => ({
                        ...err,
                        customer: "",
                        sales: "",
                      }));
                    }}
                    value={form.customer}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose Customer"
                        size="small"
                        error={error.customer ? true : false}
                        helperText={error.customer}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    fullWidth
                  />
                )}
              </Stack>

              {/* Cust Type */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Cust Type</Typography>
                <CustomTypography>
                  {form?.customer?.customer_type ?? "-"}
                </CustomTypography>
              </Stack>

              {/* Business Fields */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Business Fields</Typography>
                <CustomTypography>
                  {form?.customer?.business_type ?? "-"}
                </CustomTypography>
              </Stack>

              {/* Ship To Address */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Ship To Address</Typography>
                <CustomTextField
                  value={form.address}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      address: e.target.value,
                    }));
                  }}
                  error={error.address ? true : false}
                  helperText={error.address}
                  multiline
                  minRows={6}
                  maxRows={6}
                  size="small"
                />
              </Stack>

              {/* Province */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Province</Typography>
                <CustomAutoComplete
                  getOptionLabel={(option) => option.name}
                  options={listProvince?.map((item) => {
                    return {
                      id: item?.id,
                      name: item?.name,
                    };
                  })}
                  onChange={(e, v) => {
                    setForm((f) => ({
                      ...f,
                      province: v,
                    }));

                    setError((err) => ({
                      ...err,
                      province: "",
                    }));
                  }}
                  value={form.province}
                  loading={loadingProvince}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose Province"
                      size="small"
                      error={error.province ? true : false}
                      helperText={error.province}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                  fullWidth
                />
              </Stack>

              {/* PBBKB */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>PBBKB</Typography>
                <CustomAutoComplete
                  getOptionLabel={(option) => option.name}
                  options={listPajak?.map((item) => {
                    return {
                      id: item?.pajak_id,
                      name: `${item?.value * 100} %`,
                      value: item?.value,
                    };
                  })}
                  onChange={(e, v) => {
                    setForm((f) => ({
                      ...f,
                      pbbkb: v,
                    }));

                    setError((err) => ({
                      ...err,
                      pbbkb: "",
                    }));
                  }}
                  value={form.pbbkb}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose PBBKB"
                      size="small"
                      error={error.pbbkb ? true : false}
                      helperText={error.pbbkb}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                  fullWidth
                  disabled={form.province === null}
                />
              </Stack>

              {/* Payment Method */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Payment Method</Typography>
                <CustomAutoComplete
                  getOptionLabel={(option) => option.name}
                  options={listPaymentMethod}
                  loading={loadingPaymentMethod}
                  onChange={(e, v) => {
                    setForm((f) => ({
                      ...f,
                      method: v,
                    }));

                    setError((err) => ({
                      ...err,
                      method: "",
                    }));
                  }}
                  value={form.method}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose Payment Method"
                      size="small"
                      error={error.method ? true : false}
                      helperText={error.method}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                  fullWidth
                />
              </Stack>

              {/* Payment Term */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Payment Term</Typography>
                <CustomAutoComplete
                  getOptionLabel={(option) => option.name}
                  options={listPayment}
                  loading={loadingPayment}
                  onChange={(e, v) => {
                    setForm((f) => ({
                      ...f,
                      payment: v,
                    }));

                    setError((err) => ({
                      ...err,
                      payment: "",
                    }));
                  }}
                  value={form.payment}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose Payment Term"
                      size="small"
                      error={error.payment ? true : false}
                      helperText={error.payment}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                  fullWidth
                />
              </Stack>

              {/* Ops Type */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Ops Type</Typography>
                <CustomTextField
                  size="small"
                  select
                  value={form.ops_type}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      ops_type: e.target.value,
                    }));
                    setError((e) => ({
                      ...e,
                      ops_type: "",
                    }));
                  }}
                  error={error.ops_type ? true : false}
                  helperText={error.ops_type}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="Truck">Truck</MenuItem>
                  <MenuItem value="Vessel">Vessel</MenuItem>
                </CustomTextField>
              </Stack>
            </Grid>

            {/* Section 2 */}
            <Grid item lg={6}>
              {/* PO Number */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
              >
                <Typography style={{ width: 150 }}>PO Number</Typography>
                <CustomTextField
                  size="small"
                  value={form.po_number}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      po_number: e.target.value,
                    }));
                    setError((e) => ({
                      ...e,
                      po_number: "",
                    }));
                  }}
                  error={error.po_number ? true : false}
                  helperText={error.po_number}
                />
              </Stack>

              {/* SO Expire Date */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>SO Expire Date</Typography>
                <DatePicker
                  onChange={(v) => {
                    setForm((f) => ({
                      ...f,
                      so_expiredate: v,
                    }));
                    setError((e) => ({
                      ...e,
                      so_expiredate: "",
                    }));
                  }}
                  value={form.so_expiredate}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      size="small"
                      error={error.so_expiredate ? true : false}
                      helperText={error.so_expiredate}
                      fullWidth
                    />
                  )}
                />
              </Stack>

              {/* Sales */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Sales</Typography>
                {user.role === "sales rep" || user.role === "customer" ? (
                  <CustomTypography>
                    {user.role === "sales rep"
                      ? user?.name
                      : sales?.sales?.name ?? "-"}
                  </CustomTypography>
                ) : (
                  <CustomAutoComplete
                    getOptionLabel={(option) => option.name}
                    options={listSales}
                    loading={loadingSales}
                    onChange={(e, v) => {
                      setForm((f) => ({
                        ...f,
                        sales: v,
                      }));

                      setError((err) => ({
                        ...err,
                        sales: "",
                      }));
                    }}
                    value={form.sales}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose Sales"
                        size="small"
                        error={error.sales ? true : false}
                        helperText={error.sales}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    fullWidth
                  />
                )}
              </Stack>

              {/* SKP ID */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>SKP ID</Typography>
                <CustomTextField
                  size="small"
                  value={form.skp_id}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      skp_id: e.target.value,
                    }));
                    setError((e) => ({
                      ...e,
                      skp_id: "",
                    }));
                  }}
                  error={error.skp_id ? true : false}
                  helperText={error.skp_id}
                />
              </Stack>

              {/* Type Of Sales */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Type Of Sales</Typography>
                <CustomTextField
                  size="small"
                  select
                  value={form.type_of_sales}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      type_of_sales: e.target.value,
                    }));
                    setError((e) => ({
                      ...e,
                      type_of_sales: "",
                    }));
                  }}
                  error={error.skp_id ? true : false}
                  helperText={error.skp_id}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="FOB">FOB</MenuItem>
                  <MenuItem value="CIF">On Site</MenuItem>
                </CustomTextField>
              </Stack>

              {/* Contact */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Contact</Typography>
                <CustomTextField
                  size="small"
                  value={form.contact}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      contact: e.target.value,
                    }));
                  }}
                />
              </Stack>

              {/* Telp/Email */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Telp/Email</Typography>
                <CustomTextField
                  size="small"
                  value={form.telp_email}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      telp_email: e.target.value,
                    }));
                  }}
                />
              </Stack>

              {/* NPWP */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>NPWP</Typography>
                <CustomTextField
                  size="small"
                  value={form.npwp}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      npwp: e.target.value,
                    }));
                  }}
                />
              </Stack>

              {/* Expected Date */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Expected Date</Typography>
                <DatePicker
                  onChange={(v) => {
                    setForm((f) => ({
                      ...f,
                      expected_date: v,
                    }));
                    setError((e) => ({
                      ...e,
                      expected_date: "",
                    }));
                  }}
                  value={form.expected_date}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      size="small"
                      error={error.expected_date ? true : false}
                      helperText={error.expected_date}
                      fullWidth
                    />
                  )}
                />
              </Stack>

              {/* No FO SKP */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>NO FO SKP</Typography>
                <CustomTextField
                  size="small"
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      no_fo_skp: e.target.value,
                    }));

                    setError((e) => ({
                      ...e,
                      no_fo_skp: "",
                    }));
                  }}
                  value={form?.no_fo_skp}
                  error={error.no_fo_skp ? true : false}
                  helperText={error.no_fo_skp}
                />
              </Stack>

              {/* Harga Tebus SKP */}
              {/* {user.role === "mitra" && user.type === "skp" && (
                <Stack
                  direction="row"
                  alignItems="center"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={3}
                  mt={2}
                >
                  <Typography style={{ width: 150 }}>
                    Harga Tebus SKP
                  </Typography>
                  <CustomTextField
                    name="price_skp"
                    size="small"
                    onChange={(e) => {
                      setForm((f) => ({
                        ...f,
                        price_skp: e.target.value,
                      }));

                      setError((e) => ({
                        ...e,
                        price_skp: "",
                      }));
                    }}
                    value={form?.price_skp}
                    error={error.price_skp ? true : false}
                    helperText={error.price_skp}
                    InputProps={{
                      inputComponent: NumberFormatRp,
                    }}
                  />
                </Stack>
              )} */}

              {/* OAT */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>OAT</Typography>
                <CustomTextField
                  name="oat"
                  autoComplete="off"
                  multiline
                  size="small"
                  value={form?.oat}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      oat: e.target.value,
                    }));
                    setError((e) => ({
                      ...e,
                      oat: "",
                    }));
                  }}
                  error={error.oat ? true : false}
                  helperText={error.oat}
                  InputProps={{
                    inputComponent: NumberFormatRp,
                  }}
                />
              </Stack>

              {/* Fee Agent */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Fee Agent</Typography>
                <CustomTextField
                  name="fee_agent"
                  autoComplete="off"
                  multiline
                  size="small"
                  value={form?.fee_agent}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      fee_agent: e.target.value,
                    }));
                    setError((e) => ({
                      ...e,
                      fee_agent: "",
                    }));
                  }}
                  error={error.fee_agent ? true : false}
                  helperText={error.fee_agent}
                  InputProps={{
                    inputComponent: NumberFormatRp,
                  }}
                />
              </Stack>

              {/* Supply Point */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Supply Point</Typography>
                <CustomAutoComplete
                  getOptionLabel={(option) => option.name}
                  options={listDepo}
                  onChange={(e, v) => {
                    setForm((f) => ({
                      ...f,
                      depo: v,
                    }));

                    setError((err) => ({
                      ...err,
                      depo: "",
                    }));
                  }}
                  value={form.depo}
                  loading={loadingDepo}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose Supply Point"
                      size="small"
                      error={error.depo ? true : false}
                      helperText={error.depo}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                  fullWidth
                />
              </Stack>
            </Grid>
          </Grid>

          {/* Completeness */}
          {user?.role === "sales admin" && (
            <Wrapper>
              {/* Title */}
              <Typography mb={2}>Completeness</Typography>

              {/* Controller */}
              <FormControl fullWidth>
                {/* Grid */}
                <Grid container alignItems="center">
                  {/* Section 1 */}
                  <Grid item lg={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={completeness.sign_sts}
                          onChange={handleChecked}
                          name="sign_sts"
                        />
                      }
                      label="Sign/Stamp"
                    />
                  </Grid>

                  {/* Section 2 */}
                  <Grid item lg={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={completeness.npwp_sts}
                          onChange={handleChecked}
                          name="npwp_sts"
                        />
                      }
                      label="NPWP/SPPKP"
                    />
                  </Grid>

                  {/* Section 3 */}
                  <Grid item lg={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={completeness.attach_sts}
                          onChange={handleChecked}
                          name="attach_sts"
                        />
                      }
                      label="Attachment PO (PDF)"
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Wrapper>
          )}

          {/* Order Line */}
          <OrderLine
            region={form?.depo?.region}
            type={2}
            pbbkb={(form?.pbbkb?.value ?? 0) * 100}
            orderLine={orderLine}
            setOrderLine={setOrderLine}
            disabled={form?.customer === null || form?.depo === null}
            hide={user?.role !== "finance manager" ? true : false}
          />

          {/* Note */}
          <TextField
            label="Note"
            onChange={(e) => {
              setForm((f) => ({
                ...f,
                note: e.target.value,
              }));

              setError((e) => ({
                ...e,
                note: "",
              }));
            }}
            value={form.note}
            error={error.note ? true : false}
            helperText={error.note}
            multiline
            minRows={4}
            maxRows={4}
            fullWidth
            sx={{ mt: 3 }}
          />

          {/* Attach File */}
          {listFile?.length > 0 ? (
            <Stack mt={6}>
              <Typography mb={3}>Attach List</Typography>
              {listFile?.map((item, index) => {
                return (
                  <Stack
                    key={index}
                    spacing={2}
                    direction="row"
                    alignItems="center"
                  >
                    <Typography>{item?.filename}</Typography>
                    <IconButton
                      onClick={() => {
                        const data = listFile.splice(0, index);

                        setListFile(data);
                      }}
                    >
                      <Icon
                        icon={trash2Outline}
                        width={24}
                        height={24}
                        color="red"
                      />
                    </IconButton>
                  </Stack>
                );
              })}
            </Stack>
          ) : null}

          {/* Button */}
          <CustomStack
            mt={3}
            alignItems="center"
            justifyContent="space-between"
          >
            <Label htmlFor="raised-button-file">
              <Button
                fullWidth
                variant="contained"
                color="inherit"
                component="span"
                startIcon={<Icon icon={fileAddFill} width={24} height={24} />}
              >
                Attach Files
              </Button>
            </Label>

            <CustomButton onClick={handleSubmit} variant="contained">
              Create Sales Order
            </CustomButton>
          </CustomStack>
        </Card>
      </Container>

      {/* Input File */}
      <input
        accept=".pdf, .xlsx, .doc, .docx"
        style={{ display: "none" }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={async (e) => {
          let selectedFile = e.target.files;

          const data = await Promise.all(
            Object.values(selectedFile)?.map(async (item) => {
              if (item.size >= 2097152) {
                toast.error(`${item.name} size more than 2 mb!`);
              } else {
                const base64 = await getBase64(item);
                return {
                  filename: item.name,
                  file: base64,
                };
              }
            })
          );

          const newVal = [
            ...listFile,
            ...data.filter((item) => item !== undefined),
          ];

          setListFile(newVal);
        }}
      />
    </Page>
  );
}

const CustomStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const Label = styled("label")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  marginTop: 0,
  [theme.breakpoints.down("md")]: {
    marginTop: 16,
    width: "100%",
  },
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const CustomAutoComplete = styled(Autocomplete)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  lineHeight: "40px",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const Wrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(4),
  border: `2px solid ${theme.palette.grey[300]}`,
  borderRadius: 6,
  padding: 16,
}));

export default PurchaseOrder;
