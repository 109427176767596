//
import { useState, useEffect } from "react";
// material
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
//
import product from "../../../constant/product";
//
import { toast } from "react-toastify";
//
import { GetCategory } from "./API";

function AddProduct({ onClose, load, dialog: { open, mode, data, id } }) {
  const [listCategory, loading] = GetCategory();

  const [form, setForm] = useState({
    name: "",
    description: "",
    category: null,
  });

  const [error, setError] = useState({
    name: "",
    category: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((f) => ({
      ...f,
      [name]: value,
    }));

    setError((f) => ({
      ...f,
      [name]: "",
    }));
  };

  const validate = () => {
    const newError = { ...error };
    const { name, category } = form;

    if (!name) {
      newError.name = "Name can't be empty";
    }

    if (category === null) {
      newError.category = "Category can't be empty";
    }

    return newError;
  };

  const handleSubmit = async () => {
    const findError = validate();

    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setIsSubmitting(true);
      try {
        const newData = {
          name: form?.name,
          description: form?.description,
          category_id: form?.category?.id,
        };

        if (mode === "Edit") {
          await product.update(id, newData);

          toast.success("Product Edited");
        } else {
          await product.create(newData);

          toast.success("Product Added");
        }

        onClose();

        load();
      } catch (e) {
        console.log(e);
      }
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (mode === "Edit" && data) {
      setForm(data);
    }
  }, [mode, data]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isSubmitting) {
          onClose();
          setForm({
            name: "",
            description: "",
            category: null,
          });
          setError({
            name: "",
            category: "",
          });
        }
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{mode === "Edit" ? "Edit" : "Add New"} Product</DialogTitle>
      <DialogContent dividers>
        <TextField
          name="name"
          label="Product Name"
          autoComplete="off"
          fullWidth
          sx={{ mb: 2 }}
          value={form.name}
          onChange={handleChange}
          error={error.name ? true : false}
          helperText={error.name}
          disabled={isSubmitting}
        />
        <Autocomplete
          fullWidth
          getOptionLabel={(option) => option.name}
          options={listCategory}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Category"
              error={error.category ? true : false}
              helperText={error.category}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
          onChange={(e, v) => {
            setForm((f) => ({
              ...f,
              category: v,
            }));

            setError((err) => ({
              ...err,
              category: "",
            }));
          }}
          value={form.category}
          disabled={isSubmitting}
          sx={{ mb: 2 }}
        />
        <TextField
          name="description"
          label="Description"
          autoComplete="off"
          multiline
          minRows={1}
          maxRows={4}
          fullWidth
          value={form.description}
          onChange={handleChange}
          disabled={isSubmitting}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <LoadingButton
          onClick={handleSubmit}
          loading={isSubmitting}
          variant="contained"
        >
          {mode === "Edit" ? "Edit" : "Add"} Product
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddProduct;
