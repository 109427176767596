//
import { useState } from "react";
// material
import { Stack, Container, Card, styled, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { Page, Logo } from "../../components";
//
import SendIcon from "@mui/icons-material/Send";
//
import { toast } from "react-toastify";
//
import users from "../../constant/users";

export default function ForgotPassword() {
  const [isSubmitting, setSubmitting] = useState(false);

  const [form, setForm] = useState({
    email: "",
  });

  const [error, setError] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((f) => ({
      ...f,
      [name]: value,
    }));

    setError((f) => ({
      ...f,
      [name]: "",
    }));
  };

  const validate = () => {
    const newError = { ...error };
    const { email } = form;

    if (!email) {
      newError.email = "Please insert your email";
    }

    return newError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const findError = validate();

    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setSubmitting(true);
      try {
        await users.forgotPassword({ email: form.email });

        setSubmitting(false);

        toast.info("Verify link has been send to your email!");
      } catch (e) {
        const newError = {};

        if (e.message) {
          newError.email = "Can't Find Your Email!";
        }

        setSubmitting(false);

        setError(newError);
      }
    }
  };

  return (
    <RootStyle title="Forgot Password | Bayu Sinergi">
      <Container maxWidth="lg">
        <Card>
          <ContentStyle>
            {/* Header */}
            <Stack sx={{ mb: 5, textAlign: "center" }}>
              <Logo sx={{ width: 60, height: 60, margin: "auto", mb: 2 }} />
            </Stack>

            {/* Form */}
            <form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="email"
                  label="Email address"
                  name="email"
                  value={form.email}
                  error={error.email ? true : false}
                  helperText={error.email}
                  onChange={handleChange}
                  disabled={isSubmitting}
                />

                {/* Button */}
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                  endIcon={<SendIcon />}
                >
                  Send
                </LoadingButton>
              </Stack>
            </form>
          </ContentStyle>
        </Card>
      </Container>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(() => ({
  display: "flex",
  height: "100vh",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  // background: `url(${bg}) rgba(0, 0, 0, 0.4)`,
  // backgroundSize: "cover",
  // backgroundBlendMode: "multiply",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(8, 0),
}));

// ----------------------------------------------------------------------
