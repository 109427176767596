//
import { useMemo, useState, useEffect } from "react";

//
import salesCustomer from "../../../../constant/salesCustomer";

function GetCustomer() {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    const getAll = async () => {
      setLoading(true);
      try {
        const res = await salesCustomer.getCustomer();

        setData(res.data);
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };

    getAll();
  }, []);

  return useMemo(() => [data, loading, error], [data, loading, error]);
}

export default GetCustomer;
