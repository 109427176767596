// React
import { useState } from "react";

// Material UI
import {
  Card,
  Stack,
  Button,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";

// Components
import { ListHead, SearchNotFound } from "../../../components";

//
import { formatDate, formatThousand } from "../../../utils";

//
import CreateDeliveryOrder from "./CreateDeliveryOrder";

//
import { useData } from "../../../context/DataProvider";
import CreateReceiptOrder from "./CreateReceiptOrder";

//
const TABLE_HEAD = [
  { id: "no", label: "No", alignRight: false, isSort: false },
  { id: "do_number", label: "DO Number", alignRight: false, isSort: false },
  { id: "volume", label: "Volume (L)", alignRight: false, isSort: false },
  { id: "pickup_date", label: "Pickup Date", alignRight: false, isSort: false },
  { id: "status", label: "Status", alignRight: false, isSort: false },
  {
    id: "",
  },
];

function ListDeliveryOrder({ listItem = [], refreshDo }) {
  const [dialog, setDialog] = useState({
    open: false,
    data: null,
    line: null,
  });

  const [dialogRo, setDialogRo] = useState({
    open: false,
    data: null,
  });

  const [no, setNo] = useState(0);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setNo(no + 5);
    } else {
      setNo(no - 5);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Card sx={{ py: 4, px: 3 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="subtitle1">List Delivery Order</Typography>
        </Stack>

        <TableContainer sx={{ minWidth: 200 }}>
          <Table>
            <ListHead headLabel={TABLE_HEAD} colored />
            <TableBody>
              {listItem
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((item, index) => {
                  return (
                    <DeliveryItem
                      key={index}
                      item={item}
                      index={index}
                      no={no}
                      setDialog={(v) => setDialog(v)}
                      setDialogRo={(v) => setDialogRo(v)}
                    />
                  );
                })}
            </TableBody>
            {listItem?.length === 0 && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery="" />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={listItem?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      {/* Receipt Order */}
      <CreateReceiptOrder
        open={dialogRo.open}
        data={dialogRo.data}
        refresh={refreshDo}
        onClose={() => {
          setDialogRo((d) => ({
            ...d,
            open: false,
            data: null,
          }));
        }}
      />

      {/* Delivery Order */}
      <CreateDeliveryOrder
        open={dialog.open}
        mode="View"
        data={dialog.data}
        orderLine={dialog.line}
        onClose={() => {
          setDialog((d) => ({
            ...d,
            open: false,
            data: null,
            line: null,
          }));
        }}
      />
    </>
  );
}

const DeliveryItem = ({ item, index, no, setDialog, setDialogRo }) => {
  const { user } = useData();

  return (
    <TableRow>
      <TableCell>{index + 1 + no}</TableCell>
      <TableCell>{item?.do_number}</TableCell>
      <TableCell>{formatThousand(item?.detail?.qty ?? 0)} L</TableCell>
      <TableCell>{formatDate(item?.pickup_date) ?? "-"}</TableCell>
      <TableCell style={{ textTransform: "capitalize" }}>
        {item?.status ?? "-"}
      </TableCell>
      <TableCell style={{ width: user.role === "customer" ? 400 : 200 }}>
        <Stack direction="row" spacing={2}>
          <Button
            fullWidth
            size="small"
            variant="outlined"
            onClick={() => {
              setDialog((d) => ({
                ...d,
                open: true,
                data: item,
                line: item?.detail,
              }));
            }}
          >
            View DO
          </Button>
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default ListDeliveryOrder;
