//
import ThemeConfig from "./theme";
//
import Routes from "./routes";
//
import FirebaseProvider from "./context/FirebaseProvider";

function App() {
  return (
    <ThemeConfig>
      <FirebaseProvider>
        <Routes />
      </FirebaseProvider>
    </ThemeConfig>
  );
}

export default App;
