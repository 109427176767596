//
import { useMemo, useState, useEffect } from "react";

//
import productCategory from "../../../../constant/productCategory";

function GetCategory() {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    const getAll = async () => {
      setLoading(true);
      try {
        const res = await productCategory.getAll();

        setData(
          res.data
            ?.map((item) => {
              const { pc_id, name } = item;

              return {
                id: pc_id,
                name,
              };
            })
            ?.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
        );
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };

    getAll();
  }, []);

  return useMemo(() => [data, loading, error], [data, loading, error]);
}

export default GetCategory;
