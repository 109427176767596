// React
import React, { useState } from "react";

// Material UI
import {
  Autocomplete,
  Card,
  Container,
  styled,
  Typography,
  TextField,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TableRow,
} from "@mui/material";

// API
import {
  GetListMonth,
  GetListMonthDelivery,
  GetListMonthlyDelivery,
  GetListMonthlyHauler,
} from "./API";

// Components
import { LoadingPage, Page } from "../../../components";

// Utils
import { formatThousand, formatDateList } from "../../../utils";

// Default
const defaultDate = `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
  .toString()
  .padStart(2, "0")}`;

const defaultDate1 = `${new Date().getFullYear()}${(new Date().getMonth() + 1)
  .toString()
  .padStart(2, "0")}`;

const defaultDateD = `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
  .toString()
  .padStart(2, "0")}-${new Date().getDate().toString().padStart(2, "0")}`;

const defaultDateD1 = `${new Date().getFullYear()}${(new Date().getMonth() + 1)
  .toString()
  .padStart(2, "0")}${new Date().getDate()}`;

function ReportOpeartion() {
  return (
    <Page title="Report Admin | Bayu Sinergi">
      <Container maxWidth="xl">
        {/* Hauler Monthly */}
        <HaulerMonthly />

        {/* Gap */}
        <div style={{ height: 24 }} />

        {/* Delivery Time Monthly */}
        <DeliveryTimeMonthly />
      </Container>
    </Page>
  );
}

// Hauler Monthly
const HaulerMonthly = () => {
  const [month, setMonth] = useState({
    month: defaultDate,
    order_month: defaultDate1,
  });

  const [months, loadingMonths] = GetListMonth();

  const [listMonthly, loading] = GetListMonthlyHauler(month?.month);

  const listMonth = months?.sort((a, b) => {
    const aA = parseInt(a?.order_month);

    const bB = parseInt(b?.order_month);

    return bB - aA;
  });

  return (
    <Card sx={{ py: 4, px: 3 }}>
      {loading || loadingMonths ? (
        <LoadingPage />
      ) : (
        <>
          {/* Header */}
          <Header>
            {/* Title */}
            <Typography variant="h4">Report Hauler Monthly</Typography>

            <div style={{ flexGrow: 1 }} />

            {/* Filter */}
            <Autocomplete
              options={listMonth}
              getOptionLabel={(option) => option?.month}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Filter Month" />
              )}
              onChange={(e, v) => {
                setMonth(v);
              }}
              value={month}
            />
          </Header>

          {/* Table */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nama Hauler</TableCell>
                  <TableCell>Dipesan</TableCell>
                  <TableCell>Dikirim</TableCell>
                  <TableCell>Diterima</TableCell>
                  <TableCell>Sisa belum dikirim</TableCell>
                  <TableCell>Selisih</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listMonthly?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item?.hauler_name}</TableCell>
                      <TableCell>{formatThousand(item?.qty_booked)}</TableCell>
                      <TableCell>{formatThousand(item?.qty_loaded)}</TableCell>
                      <TableCell>
                        {formatThousand(item?.qty_received)}
                      </TableCell>
                      <TableCell>
                        {formatThousand(item?.qty_remaining)}
                      </TableCell>
                      <TableCell>{formatThousand(item?.selisih_do)}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Card>
  );
};

// Delivery Time Monthly
const DeliveryTimeMonthly = () => {
  const [month, setMonth] = useState({
    month: defaultDateD,
    order_month: defaultDateD1,
  });

  const [months, loadingMonths] = GetListMonthDelivery();

  const [listMonthly, loading] = GetListMonthlyDelivery(month?.month);

  const listMonth = months?.sort((a, b) => {
    const aA = parseInt(a?.order_month);

    const bB = parseInt(b?.order_month);

    return bB - aA;
  });

  return (
    <Card sx={{ py: 4, px: 3 }}>
      {loading || loadingMonths ? (
        <LoadingPage />
      ) : (
        <>
          {/* Header */}
          <Header>
            {/* Title */}
            <Typography variant="h4">Report Delivery Time Monthly</Typography>

            <div style={{ flexGrow: 1 }} />

            {/* Filter */}
            <Autocomplete
              options={listMonth}
              getOptionLabel={(option) => option?.month}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Filter Month" />
              )}
              onChange={(e, v) => {
                setMonth(v);
              }}
              value={month}
            />
          </Header>

          {/* Table */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nomor DO</TableCell>
                  <TableCell>Hauler</TableCell>
                  <TableCell>Nomor Order</TableCell>
                  <TableCell>Tanggal Order</TableCell>
                  <TableCell>Customer</TableCell>
                  <TableCell>Alamat Tujuan</TableCell>
                  <TableCell>Depo</TableCell>
                  <TableCell>Tipe Ops</TableCell>
                  <TableCell>Tanggal Ambil</TableCell>
                  <TableCell>Tanggal Terima</TableCell>
                  <TableCell>Selisih dalam hari</TableCell>
                  <TableCell>Selisih dalam jam</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {listMonthly?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item?.do_number}</TableCell>
                      <TableCell>{item?.hauler_name}</TableCell>
                      <TableCell>{item?.order_number}</TableCell>
                      <TableCell>{item?.order_dateon}</TableCell>
                      <TableCell>{item?.customer_name}</TableCell>
                      <TableCell>{item?.address}</TableCell>
                      <TableCell>{item?.depo_name}</TableCell>
                      <TableCell>{item?.ops_type}</TableCell>
                      <TableCell>{formatDateList(item?.pickup_date)}</TableCell>
                      <TableCell>
                        {formatDateList(item?.received_date)}
                      </TableCell>
                      <TableCell>{formatThousand(item?.time_do_day)}</TableCell>
                      <TableCell>
                        {formatThousand(item?.time_do_hours)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Card>
  );
};

// Header
const Header = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  alignItems: "center",
  marginBottom: theme.spacing(3),
}));

export default ReportOpeartion;
