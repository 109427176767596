//
import { useEffect, useState } from "react";
//
import {
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
//
import {
  Page,
  TableToolBar,
  ListHead,
  SearchNotFound,
  LoadingPage,
  LoadingOrder,
} from "../../../components";
//
import users from "../../../constant/users";
import customer from "../../../constant/customer";
import salesCustomer from "../../../constant/salesCustomer";
//
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
import downloadFill from "@iconify/icons-eva/download-fill";
import uploadFill from "@iconify/icons-eva/upload-fill";
//
import { applySortFilter, getComparator } from "../../../utils";
//
import AddCustomer from "./AddCustomer";
//
import { useHistory } from "react-router-dom";
//
import { useData } from "../../../context/DataProvider";
//
import PickCustomer from "./PickCustomer";
//
import Papa from "papaparse";
//
import { toast } from "react-toastify";
//
import array_chunk from "lodash/chunk";
//
import { GetAll } from "./API";

import "./style.css";

// Table Head
const TABLE_HEAD = [
  { id: "no", label: "No", alignRight: false, isSort: true },
  { id: "name", label: "Name", alignRight: false, isSort: true },
  {
    id: "nib",
    label: "Nomor Induk Berusaha",
    alignRight: false,
    isSort: false,
  },
  {
    id: "type",
    label: "Customer Type",
    alignRight: false,
    isSort: false,
  },
  {
    id: "business",
    label: "Bidang Usaha",
    alignRight: false,
    isSort: false,
  },
  { id: "sales", label: "Sales", alignRight: false, isSort: false },
  {
    id: "address",
    label: "Company Address",
    alignRight: false,
    isSort: false,
  },
  {
    id: "address_billing",
    label: "Billing Address",
    alignRight: false,
    isSort: false,
  },
  {
    id: "email",
    label: "Email",
    alignRight: false,
    isSort: false,
  },
  {
    id: "contact",
    label: "Contact",
    alignRight: false,
    isSort: false,
  },
  {
    id: "description",
    label: "Description",
    alignRight: false,
    isSort: false,
  },
  { id: "note", label: "Note", alignRight: false, isSort: false },
];

function TableCustomer() {
  const { user } = useData();

  const [listCustomer, loading, refresh] = GetAll();

  const history = useHistory();

  const [dialog, setDialog] = useState({
    open: false,
  });

  const [dialogPick, setDialogPick] = useState({
    open: false,
  });

  const [q, setQ] = useState("");

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("name");

  const [listSales, setlistSales] = useState([]);

  const [loadingSales, setLoadingSales] = useState(false);

  const [loadingImport, setLoadingImport] = useState(false);

  const [no, setNo] = useState(0);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [dialogImport, setDialogImport] = useState(false);

  const [files, setFiles] = useState(null);

  const customerList = applySortFilter(
    listCustomer,
    getComparator(order, orderBy),
    q,
    "name"
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setNo(no + 5);
    } else {
      setNo(no - 5);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const submitImport = async () => {
    Papa.parse(files, {
      dynamicTyping: true,
      header: false,
      complete: async function (results, file) {
        try {
          setLoadingImport(true);

          if (results.data.length > 0) {
            if (
              results.data[0][0] === "name" &&
              results.data[0][1] === "nib" &&
              results.data[0][2] === "custtype_id" &&
              results.data[0][3] === "businesstype_id" &&
              results.data[0][4] === "sales_id" &&
              results.data[0][5] === "address" &&
              results.data[0][6] === "address_billing" &&
              results.data[0][7] === "email" &&
              results.data[0][8] === "contact" &&
              results.data[0][9] === "description" &&
              results.data[0][10] === "note"
            ) {
              const chunk = array_chunk(results.data, 200);

              await Promise.all(
                chunk.map(async (currentData) => {
                  currentData.forEach(async (user) => {
                    if (
                      user[0] !== "name" &&
                      user[1] !== "nib" &&
                      user[2] !== "custtype_id" &&
                      user[3] !== "businesstype_id" &&
                      user[4] !== "sales_id" &&
                      user[5] !== "address" &&
                      user[6] !== "address_billing" &&
                      user[7] !== "email" &&
                      user[8] !== "contact" &&
                      user[9] !== "description" &&
                      user[10] !== "note"
                    ) {
                      const res = await customer.create({
                        name: user[0] ?? "-",
                        nib: user[1] ?? "-",
                        address: user[5] ?? "-",
                        address_billing: user[6] ?? "-",
                        email: user[7] ?? "-",
                        contact: user[8] ?? "-",
                        description: user[9] ?? "-",
                        note: user[10] ?? "-",
                        custtype_id: parseInt(user[2]),
                        businesstype_id: parseInt(user[3]),
                      });

                      const data = {
                        customer_id: res.data.id,
                        sales_id: parseInt(user[4]),
                        startdate: Date.now(),
                        enddate: new Date().setFullYear(
                          new Date().getFullYear() + 1
                        ),
                      };

                      // Sales Customer
                      await salesCustomer.create(data);
                    }
                  });
                })
              );

              setLoadingImport(false);

              toast.success("Customer Added");
            }
          }
        } catch (e) {
          console.log(e);

          setLoadingImport(false);
        }
      },
    });
  };

  useEffect(() => {
    const getSales = async () => {
      setLoadingSales(true);
      try {
        const res = await users.getSales();

        setlistSales(res.data);
      } catch (e) {
        console.log(e);
      }
      setLoadingSales(false);
    };

    if (user.role !== "sales rep") {
      getSales();
    }
  }, [user.role]);

  if (loading || loadingSales) {
    return <LoadingPage />;
  }

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - customerList.length) : 0;

  const isNotFound = customerList?.length === 0;

  return (
    <Page title="Customer Management | Bayu Sinergi">
      {loadingImport ? <LoadingOrder /> : null}
      <Container maxWidth="xl">
        {/* Header */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Customer Management
          </Typography>

          {user?.role !== "bod" && (
            <div>
              {user.role === "sales rep" && (
                <Button
                  onClick={(e) => {
                    setDialogPick((v) => ({
                      ...v,
                      open: true,
                    }));
                  }}
                  variant="contained"
                  startIcon={<Icon icon={plusFill} />}
                >
                  Pick Customer
                </Button>
              )}
              <Button
                component="span"
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
                sx={{ ml: 1.5 }}
                onClick={() => {
                  setDialogImport(true);
                }}
              >
                Import Customer
              </Button>
              <Button
                onClick={(e) => {
                  setDialog((v) => ({
                    ...v,
                    open: true,
                  }));
                }}
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
                sx={{ ml: 1.5 }}
              >
                New Customer
              </Button>
            </div>
          )}
        </Stack>

        {/* Table */}
        <Card>
          {/* Search */}
          <TableToolBar
            value={q}
            onChange={(e) => {
              setQ(e.target.value);
              setPage(0);
            }}
            placeholder="Search Customer"
            onClear={() => setQ("")}
          />

          {/* Table */}
          <TableContainer sx={{ minWidth: 200 }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {customerList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return (
                      <TableRow
                        hover
                        key={index}
                        onClick={() => {
                          history.push(`/customer/${item.id}`);
                        }}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell align="left">{index + 1 + no}</TableCell>
                        <TableCell align="left">{item?.name ?? "-"}</TableCell>
                        <TableCell align="left">{item?.nib ?? "-"}</TableCell>
                        <TableCell align="left">{item?.type ?? "-"}</TableCell>
                        <TableCell align="left">
                          {item?.business ?? "-"}
                        </TableCell>
                        <TableCell align="left">
                          {item?.sales ?? "No Sales"}
                        </TableCell>
                        <TableCell align="left">
                          {item?.address ?? "-"}
                        </TableCell>
                        <TableCell align="left">
                          {item?.address_billing ?? "-"}
                        </TableCell>
                        <TableCell align="left">{item?.email ?? "-"}</TableCell>
                        <TableCell align="left">
                          {item?.contact ?? "-"}
                        </TableCell>
                        <TableCell align="left">
                          {item?.description ?? "-"}
                        </TableCell>
                        <TableCell align="left">{item?.note ?? "-"}</TableCell>
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 67 * emptyRows }}>
                    <TableCell colSpan={12} />
                  </TableRow>
                )}
              </TableBody>
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={12} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={q} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={customerList?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* Dialog Add Customer */}
      <AddCustomer
        dialog={dialog}
        listSales={listSales}
        load={() => {
          refresh(true);
        }}
        onClose={() => {
          setDialog((v) => ({
            ...v,
            open: false,
          }));
        }}
      />

      {/* Dialog Pick Customer */}
      <PickCustomer
        dialog={dialogPick}
        load={() => {
          refresh(true);
        }}
        onClose={() => {
          setDialogPick((v) => ({
            ...v,
            open: false,
          }));
        }}
      />

      {/* Dialog */}
      <Dialog open={dialogImport}>
        <DialogTitle>Import Customer</DialogTitle>
        <DialogContent>
          <Typography>
            Jika belum memiliki template import silahkan download terlebih
            dahulu
          </Typography>
          <div style={{ marginTop: 24, display: "flex", flexDirection: "row" }}>
            {/* Box1 */}
            <label htmlFor="cust-import" className="Box">
              {files !== null ? (
                <Typography>{files?.name}</Typography>
              ) : (
                <>
                  <Icon icon={uploadFill} fontSize={40} />
                  <Typography style={{ marginTop: 6 }}>Import Data</Typography>
                </>
              )}
            </label>
            {/* Spacing */}
            <div style={{ width: 24 }} />
            {/* Box2 */}
            <div
              className="Box"
              onClick={() => {
                const link =
                  process.env.REACT_APP_PROD === "YES" ? "https" : "http";

                window.open(
                  `${link}://oms.bayusinergi.com/api/media/customer_template.tar.gz`
                );
              }}
            >
              <Icon icon={downloadFill} fontSize={40} />
              <Typography style={{ marginTop: 6 }}>
                Download Template
              </Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setFiles(null);
              setDialogImport(false);
            }}
          >
            Cancel
          </Button>
          <Button
            color="inherit"
            onClick={submitImport}
            disabled={files === null}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Input */}
      <input
        accept=".csv"
        style={{ display: "none" }}
        id="cust-import"
        type="file"
        onChange={(e) => {
          const file = e.target.files[0];

          setFiles(file);
        }}
      />
    </Page>
  );
}

export default TableCustomer;
