//
import { useRef, useState } from "react";
//
import { useHistory, Link } from "react-router-dom";
// material
import {
  Button,
  Box,
  Divider,
  Typography,
  Avatar,
  IconButton,
  alpha,
  MenuItem,
} from "@mui/material";
// components
import { AlertDialog, MenuPopover } from "../components";
//
import { useData } from "../context/DataProvider";
import socket from "../constant/socket";
//
import { Icon } from "@iconify/react";
import settings2Fill from "@iconify/icons-eva/settings-2-fill";
import bellFill from "@iconify/icons-eva/bell-fill";
//
const Notification = window.Notification || {};

export default function AccountPopover() {
  const history = useHistory();

  const anchorRef = useRef(null);

  const { user } = useData();

  const [dialog, setDialog] = useState(false);

  const [open, setOpen] = useState(false);

  const [permission, setPermission] = useState(Notification.permission);

  const showPrompt = async () => {
    if (Notification.requestPermission) {
      const permission = await Notification.requestPermission();

      setPermission(permission);

      setOpen(false);
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            "&:before": {
              zIndex: 1,
              content: "''",
              width: "100%",
              height: "100%",
              borderRadius: "50%",
              position: "absolute",
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            },
          }),
        }}
      >
        <Avatar alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user.name}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary" }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {permission !== "granted" && (
          <MenuItem
            onClick={showPrompt}
            sx={{ typography: "body2", py: 1, px: 2.5 }}
          >
            <Box
              component={Icon}
              icon={bellFill}
              sx={{
                mr: 2,
                width: 24,
                height: 24,
              }}
            />
            Notification
          </MenuItem>
        )}

        <MenuItem
          to="/settings"
          component={Link}
          onClick={handleClose}
          sx={{ typography: "body2", py: 1, px: 2.5 }}
        >
          <Box
            component={Icon}
            icon={settings2Fill}
            sx={{
              mr: 2,
              width: 24,
              height: 24,
            }}
          />
          Account Settings
        </MenuItem>

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button
            fullWidth
            color="inherit"
            variant="outlined"
            onClick={() => setDialog(true)}
          >
            Logout
          </Button>
        </Box>
      </MenuPopover>

      <AlertDialog
        open={dialog}
        title="Logout"
        content="Are you sure want to logout from this application?"
        handleOk={() => {
          socket.emit("leave-notif", user?.role?.replace(" ", "-"));

          localStorage.removeItem("bayusinergi-token");

          setDialog(false);

          history.push("/login");
        }}
        handleClose={() => setDialog(false)}
      />
    </>
  );
}
