//
import { Fragment, useEffect, useState } from "react";
// material
import {
  Autocomplete,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
//
import { AlertDialog } from "../../../components";
//
import user from "../../../constant/users";
import role from "../../../constant/role";
import { toast } from "react-toastify";
import { GetCustomer } from "./API";

function AddUser({ onClose, load, dialog: { open, data, mode, id } }) {
  const [openAlert, setOpenAlert] = useState(false);

  const [customer, loadingCust] = GetCustomer();

  const [roles, setRoles] = useState([]);

  const [loading, setLoading] = useState(false);

  const [form, setForm] = useState({
    name: "",
    email: "",
    password: "",
    role: null,
    additional_role: null,
    customer: null,
  });

  const [error, setError] = useState({
    name: "",
    email: "",
    password: "",
    role: "",
    additional_role: "",
    customer: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((f) => ({
      ...f,
      [name]: value,
    }));

    setError((f) => ({
      ...f,
      [name]: "",
    }));
  };

  const validate = () => {
    const newError = { ...error };
    const { name, email, password, role, additional_role } = form;

    if (mode !== "AddCust") {
      if (!name) {
        newError.name = "Name can't be empty";
      }

      if (!email) {
        newError.email = "Email can't be empty";
      }

      if (mode !== "Edit" && !password) {
        newError.password = "Password can't be empty";
      }

      if (role === null) {
        newError.role = "Role can't be empty";
      }

      if (additional_role?.id === role?.id) {
        newError.additional_role = "Additional Role can't be same with Role";
      }
    }

    if (mode === "AddCust" && !form.customer) {
      newError.customer = "Customer can't be empty";
    }

    return newError;
  };

  const handleSubmit = async () => {
    const findError = validate();

    if (Object.values(findError).some((m) => m !== "")) {
      console.log(findError);
      setError(findError);
    } else {
      setIsSubmitting(true);
      try {
        let newData = {
          name: form?.name,
          role_id: form?.role?.id,
        };

        if (form?.additional_role !== null) {
          newData.additional_role = form?.additional_role?.id;
        }

        if (mode === "Edit") {
          newData.email = form.email;

          // Api Update
          await user.update(id, newData);

          toast.success("User Edited!");
        } else if (mode === "AddCust") {
          // Api Register
          await user.create({
            name: form?.customer?.name,
            email: form?.customer?.email,
            password: "OMS#2022",
            role_id: 1,
            customer_id: form?.customer?.id,
          });

          toast.success("User Customer Added!");
        } else {
          // Add Data
          newData.email = form.email;
          newData.password = form.password;

          // Api Register
          await user.create(newData);

          toast.success("User Added!");
        }

        setIsSubmitting(false);

        setOpenAlert(false);

        setForm({
          name: "",
          email: "",
          password: "",
          role: null,
          additional_role: null,
          customer: null,
        });

        setError({
          name: "",
          email: "",
          password: "",
          role: "",
          additional_role: "",
          customer: "",
        });

        onClose();

        load();
      } catch (e) {
        console.log(e);
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (mode === "Edit" && data) {
      setForm(data);
    }
  }, [mode, data]);

  useEffect(() => {
    const getRoles = async () => {
      setLoading(true);
      try {
        const res = await role.getAll();

        let data = res?.data?.filter((item) => item?.id !== 1);

        setRoles(
          data?.map((item) => {
            return {
              id: item.id,
              name: item.role_desc,
            };
          })
        );
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    };

    getRoles();
  }, []);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isSubmitting) {
          onClose();
          setForm({
            name: "",
            email: "",
            password: "",
            role: null,
            additional_role: null,
          });
          setError({
            name: "",
            email: "",
            password: "",
            role: "",
            additional_role: "",
          });
        }
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{mode === "Edit" ? "Edit" : "Add New"} User</DialogTitle>
      <DialogContent dividers>
        {mode !== "AddCust" ? (
          <>
            <TextField
              name="name"
              label="Name"
              autoComplete="off"
              fullWidth
              sx={{ mb: 2 }}
              value={form.name}
              onChange={handleChange}
              error={error.name ? true : false}
              helperText={error.name}
              disabled={isSubmitting}
            />
            <TextField
              name="email"
              label="Email"
              autoComplete="off"
              fullWidth
              sx={{ mb: 2 }}
              error={error.email ? true : false}
              value={form.email}
              onChange={handleChange}
              helperText={error.email}
              disabled={isSubmitting || mode === "Edit"}
            />
            {!(mode === "Edit") ? (
              <TextField
                name="password"
                label="Password"
                type="password"
                fullWidth
                sx={{ mb: 2 }}
                error={error.password ? true : false}
                value={form.password}
                onChange={handleChange}
                helperText={error.password}
                disabled={isSubmitting}
              />
            ) : null}
            <Autocomplete
              fullWidth
              getOptionLabel={(option) => option.name}
              options={roles?.filter((item) => item.name !== "Mitra")}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Role"
                  error={error.role ? true : false}
                  helperText={error.role}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    ),
                  }}
                />
              )}
              onChange={(e, v) => {
                setForm((f) => ({
                  ...f,
                  role: v,
                }));

                setError((err) => ({
                  ...err,
                  role: "",
                }));
              }}
              value={form.role}
              disabled={isSubmitting}
              sx={{ mb: 2 }}
            />
            <Autocomplete
              fullWidth
              getOptionLabel={(option) => option.name}
              options={roles?.filter((item) => item.name !== "Mitra")}
              loading={loading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Additional Role"
                  error={error.additional_role ? true : false}
                  helperText={error.additional_role}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <Fragment>
                        {loading ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </Fragment>
                    ),
                  }}
                />
              )}
              onChange={(e, v) => {
                setForm((f) => ({
                  ...f,
                  additional_role: v,
                }));

                setError((err) => ({
                  ...err,
                  additional_role: "",
                }));
              }}
              value={form.additional_role}
              disabled={isSubmitting}
              sx={{ mb: 2 }}
            />
          </>
        ) : (
          <Autocomplete
            fullWidth
            getOptionLabel={(option) => option.name}
            options={customer}
            loading={loadingCust}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Customer"
                error={error.customer ? true : false}
                helperText={error.customer}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </Fragment>
                  ),
                }}
              />
            )}
            onChange={(e, v) => {
              setForm((f) => ({
                ...f,
                customer: v,
              }));

              setError((err) => ({
                ...err,
                customer: "",
              }));
            }}
            value={form.customer}
            disabled={isSubmitting}
            sx={{ mb: 2 }}
          />
        )}
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <LoadingButton
          onClick={mode === "Edit" ? () => setOpenAlert(true) : handleSubmit}
          loading={isSubmitting}
          variant="contained"
        >
          {mode === "Edit" ? "Edit" : "Add"} User
        </LoadingButton>
      </DialogActions>
      {/*Alert Dialog*/}
      <AlertDialog
        open={openAlert}
        title="Update User"
        content="Are you sure want to update this User?"
        handleOk={handleSubmit}
        handleClose={() => setOpenAlert(false)}
      />
    </Dialog>
  );
}

export default AddUser;
