let firebaseConfig = {
  apiKey: "AIzaSyBs8VBiZZnboMHoIpzsWWtoKO4FLicxgFs",
  authDomain: "bayusinergi-c05a3.firebaseapp.com",
  projectId: "bayusinergi-c05a3",
  storageBucket: "bayusinergi-c05a3.appspot.com",
  messagingSenderId: "217139463805",
  appId: "1:217139463805:web:21e80dc3f320332a195314",
};

let vapidKey =
  "BFuE1rnKgQnXNNGDk3EKkNQQjUxSFHmo7yFD6R8oP-o5QPLnTjw1DgjrCX4k7kzaBVumkMKYHUofAaOcIx8t3eU";

export const publicVapidKey = vapidKey;

export default firebaseConfig;
