import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { HelmetProvider } from "react-helmet-async";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "simplebar/src/simplebar.css";

ReactDOM.render(
  <HelmetProvider>
    <React.StrictMode>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <App />
        <ToastContainer
          autoClose={4000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover={false}
        />
      </LocalizationProvider>
    </React.StrictMode>
  </HelmetProvider>,
  document.getElementById("root")
);
