// React
import { useState } from "react";

// Material UI
import {
  Stack,
  IconButton,
  Typography,
  Card,
  Container,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@mui/material";

// Components
import {
  LoadingPage,
  Page,
  ListHead,
  ActionTable,
  TableToolBar,
  SearchNotFound,
} from "../../../components";

// Iconify
import { Icon } from "@iconify/react";
import arrowBackFill from "@iconify/icons-eva/arrow-back-fill";

// Raect Router Dom
import { useHistory, useParams, useLocation } from "react-router-dom";

// API
import { GetOnce } from "./API";

//
import { applySortFilter, getComparator } from "../../../utils";

//
import AddAccessRole from "./AddAccessRole";
import { toast } from "react-toastify";
import accessRole from "../../../constant/accessRole";

function DetailAccessRole() {
  const { id } = useParams();

  const location = useLocation();

  const history = useHistory();

  const [listPage, loading, refresh] = GetOnce(id);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Page title="Detail Access Role | Bayu Sinergi">
      {/* Navigation */}
      <Stack direction="row" alignItems="center" sx={{ px: 3, mb: 2 }}>
        <IconButton
          size="small"
          onClick={() => {
            history.goBack();
          }}
        >
          <Icon icon={arrowBackFill} fontSize={24} />
        </IconButton>
        <Typography sx={{ ml: 1 }} variant="h5">
          Detail Access Role {location?.state?.title ?? ""}
        </Typography>
      </Stack>

      <TablePage listPage={listPage} refresh={refresh} />
    </Page>
  );
}

function TablePage({ listPage, refresh }) {
  const [q, setQ] = useState("");

  const [dialog, setDialog] = useState({
    open: false,
    mode: "Add",
    data: {},
  });

  const [no, setNo] = useState(0);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("name");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setNo(no + 5);
    } else {
      setNo(no - 5);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const pageList = applySortFilter(
    listPage,
    getComparator(order, orderBy),
    q,
    "name"
  );

  const isNotFound = pageList?.length === 0;

  // Table Head
  const TABLE_HEAD = [
    { id: "no", label: "No", alignRight: false, isSort: true },
    { id: "name", label: "Page", alignRight: false, isSort: true },
    { id: "" },
  ];

  return (
    <Container maxWidth="xl">
      <Card>
        {/* Search */}
        <TableToolBar
          title="List Access Page"
          value={q}
          onChange={(e) => {
            setQ(e.target.value);
            setPage(0);
          }}
          placeholder="Search Access Page"
          onClear={() => setQ("")}
          onClick={(e) => {
            setDialog((d) => ({
              ...d,
              open: true,
              mode: "Add",
            }));
          }}
        />

        {/* Table */}
        <TableContainer sx={{ minWidth: 200 }}>
          <Table>
            <ListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              onRequestSort={handleRequestSort}
              colored
            />
            <TableBody>
              {pageList
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{index + 1 + no}</TableCell>
                      <TableCell>{item?.name}</TableCell>
                      <ActionTable
                        onEdit={() => {
                          setDialog({
                            open: true,
                            data: item,
                            mode: "Edit",
                          });
                        }}
                        onDelete={async () => {
                          try {
                            await accessRole.delete(item?.id);

                            refresh(true);

                            toast.success("Page Deleted!");
                          } catch (e) {
                            console.log(e);
                          }
                        }}
                      />
                    </TableRow>
                  );
                })}
            </TableBody>
            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={3} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={q} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={pageList?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      {/* Dialog */}
      <AddAccessRole
        dialog={dialog}
        load={() => {
          refresh(true);
        }}
        onClose={() => {
          setDialog({
            open: false,
            data: {},
            mode: "Add",
          });
        }}
      />
    </Container>
  );
}

export default DetailAccessRole;
