//
import { Grid, Typography, Box, Container, styled } from "@mui/material";
//
import { DashboardCard, LoadingPage, Page } from "../../../components";
//
import shoppingCartFill from "@iconify/icons-eva/shopping-cart-fill";
import clockFill from "@iconify/icons-eva/clock-fill";
import archiveFill from "@iconify/icons-eva/archive-fill";
import carFill from "@iconify/icons-eva/car-fill";
import fileTextFill from "@iconify/icons-eva/file-text-fill";
import checkmarkCircle2Fill from "@iconify/icons-eva/checkmark-circle-2-fill";
import undoFill from "@iconify/icons-eva/undo-fill";
import closeCircleFill from "@iconify/icons-eva/close-circle-fill";
import closeSquareFill from "@iconify/icons-eva/close-square-fill";
import alertTriangleFill from '@iconify/icons-eva/alert-triangle-fill';
//
import { useData } from "../../../context/DataProvider";
//
import { useHistory } from "react-router-dom";
//
import ListOrderComponent from "../ListOrder/ListOrderComponent";
//
import { GetSummary } from "./API";

function Dashboard() {
  const history = useHistory();

  const { user } = useData();

  const [
    newOrder,
    onApproval,
    revoke,
    onProgress,
    salesOrder,
    onPlan,
    completed,
    rejected,
    willExpire,
    expired,
    loading,
  ] = GetSummary();

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Page title="Dashboard | Bayu Sinergi">
      <Container maxWidth="xl">
        {/* Header */}
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">Welcome back {user.name}</Typography>
        </Box>

        {/* Order Summary */}
        <Grid container spacing={2}>
          <Grid item xs={6} sm={6} md={2.4}>
            <DashboardCard
              title="New Orders"
              number={newOrder}
              icon={shoppingCartFill}
              onClick={() => {
                history.push("/order", {
                  tabs: "pending",
                });
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2.4}>
            <DashboardCard
              title="On Approval"
              number={onApproval}
              icon={clockFill}
              onClick={() => {
                history.push("/order", {
                  tabs: "on-approval",
                });
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2.4}>
            <DashboardCard
              title="Revoke"
              number={revoke}
              icon={undoFill}
              onClick={() => {
                history.push("/order", {
                  tabs: "revoke",
                });
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2.4}>
            <DashboardCard
              title="Rejected"
              number={rejected}
              icon={closeCircleFill}
              onClick={() => {
                history.push("/order", {
                  tabs: "rejected",
                });
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2.4}>
            <DashboardCard
              title="Open SO"
              number={salesOrder}
              icon={fileTextFill}
              onClick={() => {
                history.push("/order", {
                  tabs: "open-so",
                });
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2.4}>
            <DashboardCard
              title="On Plan"
              number={onPlan}
              icon={archiveFill}
              onClick={() => {
                history.push("/order", {
                  tabs: "on-plan",
                });
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2.4}>
            <DashboardCard
              title="On Progress"
              number={onProgress}
              icon={carFill}
              onClick={() => {
                history.push("/order", {
                  tabs: "on-progress",
                });
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2.4}>
            <DashboardCard
              title="Completed"
              number={completed}
              icon={checkmarkCircle2Fill}
              onClick={() => {
                history.push("/order", {
                  tabs: "completed",
                });
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2.4}>
            <DashboardCard
              title="Will Expire"
              number={willExpire}
              icon={alertTriangleFill}
              onClick={() => {
                history.push("/order", {
                  tabs: "willExpire",
                });
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={2.4}>
            <DashboardCard
              title="Expired"
              number={expired}
              icon={closeSquareFill}
              onClick={() => {
                history.push("/order", {
                  tabs: "expired",
                });
              }}
            />
          </Grid>
        </Grid>
      </Container>

      {/* List Order */}
      <OrderWrapper>
        <ListOrderComponent />
      </OrderWrapper>
    </Page>
  );
}

const OrderWrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(6),
}));

export default Dashboard;
