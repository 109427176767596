import axios from "../config";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getListMonthlyProduct: (month) => axios.get(`/report/monthly?month=${month}`),
  getListMonthlyTOS: (month) => axios.get(`/report/monthly-tos?month=${month}`),
  getListMonthlyTerm: (month) =>
    axios.get(`/report/monthly-term?month=${month}`),
  getListMonthlyCT: (month) => axios.get(`/report/monthly-ct?month=${month}`),
  getListMonthlySkp: (month) => axios.get(`/report/monthly-skp?month=${month}`),
  getListQuarterly: (year) => axios.get(`/report/quarterly?year=${year}`),
  getListMonthlySales: (month) =>
    axios.get(`/report/monthly-sales?month=${month}`),
  getListMonthlyHauler: (month) =>
    axios.get(`/report/monthly-hauler?month=${month}`),
  getListMonthlyDelivery: (month) =>
    axios.get(`/report/monthly-delivery?month=${month}`),
  getReportAdmin: (month) => axios.get(`/report/admin?month=${month}`),
  getReportSalesRep: (month) => axios.get(`/report/salesrep?month=${month}`),
  getListMonthAdmin: () => axios.get(`/report/month-admin`),
  getListMonthBOD: () => axios.get(`/report/month-bod`),
  getListYear: () => axios.get(`/report/year`),
  getListMonthDelivery: () => axios.get(`/report/month-delivery`),
  getRevenueAnnual: (type, year, month) => axios.get(`/report/revenue-annual?type=${type}&year=${year}&month=${month}`),
  getVolumeAnnual: (type, year, month) => axios.get(`/report/volume-annual?type=${type}&year=${year}&month=${month}`),
  getProductAnnual: (type, year, month) => axios.get(`/report/product-annual?type=${type}&year=${year}&month=${month}`),
  getSKPAnnual: (type, year, month) => axios.get(`/report/skp-annual?type=${type}&year=${year}&month=${month}`),
  getSupplyPointAnnual: (type, year, month) => axios.get(`/report/supply-annual?type=${type}&year=${year}&month=${month}`),
  getRevenueAlir: (type, year, month) => axios.get(`/report/revenue-alir?type=${type}&year=${year}&month=${month}`),
  getTarget: (year) => axios.get(`/report/target?year=${year}`),
  getListYearBOD: () => axios.get(`/report/year-bod`),
};
