import PropTypes from "prop-types";
import { Icon } from "@iconify/react";
import editFill from "@iconify/icons-eva/edit-fill";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";
// material
import { IconButton, TableCell } from "@mui/material";

ActionTable.propTypes = {
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default function ActionTable({ onEdit, onDelete }) {
  return (
    <TableCell style={{ display: "flex" }}>
      <IconButton size="small" color="primary" onClick={onEdit}>
        <Icon icon={editFill} fontSize={24} />
      </IconButton>
      <IconButton
        size="small"
        sx={{ marginLeft: "6px" }}
        color="error"
        onClick={onDelete}
      >
        <Icon icon={trash2Fill} fontSize={24} />
      </IconButton>
    </TableCell>
  );
}
