//
import { useMemo, useState, useEffect } from "react";

//
import customer from "../../../../constant/customer";

function GetDetailCustomer(id) {
  const [data, setData] = useState({});

  const [loading, setLoading] = useState(false);

  const [load, setLoad] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    const getAll = async () => {
      setLoading(true);
      try {
        const res = await customer.getOnce(id);

        const { customer_id, ...rest } = res.data;

        setData({
          id: customer_id,
          ...rest,
        });
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };

    getAll();
  }, [id]);

  useEffect(() => {
    const getAll = async () => {
      try {
        const res = await customer.getOnce(id);

        const { customer_id, ...rest } = res.data;

        setData({
          id: customer_id,
          ...rest,
        });
      } catch (e) {
        setError(e);
      }
      setLoad(false);
    };

    if (load && id) {
      getAll();
    }
  }, [load, id]);

  return useMemo(
    () => [data, loading, setData, setLoad, error],
    [data, loading, setData, setLoad, error]
  );
}

export default GetDetailCustomer;
