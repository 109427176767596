// React
import { useState } from "react";

// Material UI
import {
  Card,
  Stack,
  Button,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";

// Components
import { ListHead, SearchNotFound } from "../../../components";

//
import { formatDate } from "../../../utils";

//
import CreateReceiptOrder from "./CreateReceiptOrder";

//
const TABLE_HEAD = [
  { id: "no", label: "No", alignRight: false, isSort: false },
  { id: "ro_number", label: "RO Number", alignRight: false, isSort: false },
  { id: "do_number", label: "DO Number", alignRight: false, isSort: false },
  {
    id: "received_date",
    label: "Received Date",
    alignRight: false,
    isSort: false,
  },
  {
    id: "",
  },
];

function ListReceiptOrder({ listItem = [] }) {
  const [dialog, setDialog] = useState({
    open: false,
    data: null,
  });

  const [no, setNo] = useState(0);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setNo(no + 5);
    } else {
      setNo(no - 5);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Card sx={{ py: 4, px: 3 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="subtitle1">List Receipt Order</Typography>
        </Stack>

        <TableContainer sx={{ minWidth: 200 }}>
          <Table>
            <ListHead headLabel={TABLE_HEAD} colored />
            <TableBody>
              {listItem
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((item, index) => {
                  return (
                    <ReceiptItem
                      key={index}
                      item={item}
                      index={index}
                      no={no}
                      setDialog={(v) => setDialog(v)}
                    />
                  );
                })}
            </TableBody>
            {listItem?.length === 0 && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery="" />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={listItem?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      {/* Receipt Order */}
      <CreateReceiptOrder
        open={dialog.open}
        mode="View"
        data={dialog.data}
        onClose={() =>
          setDialog((d) => ({
            ...d,
            open: false,
            data: null,
          }))
        }
      />
    </>
  );
}

const ReceiptItem = ({ item, index, no, setDialog }) => {
  return (
    <TableRow>
      <TableCell>{index + 1 + no}</TableCell>
      <TableCell>{item?.ro_number ?? "-"}</TableCell>
      <TableCell>{item?.do_number ?? "-"}</TableCell>
      <TableCell>{formatDate(item?.received_date) ?? "-"}</TableCell>
      <TableCell>
        <Button
          fullWidth
          size="small"
          variant="outlined"
          onClick={() => setDialog((d) => ({ ...d, open: true, data: item }))}
        >
          View Receipt
        </Button>
      </TableCell>
    </TableRow>
  );
};

export default ListReceiptOrder;
