//
import jwtDecode from "jwt-decode";
import { Route, Redirect } from "react-router-dom";
import { DataProvider } from "../context/DataProvider";
import Unauthorized from "../pages/Unauthorized";
import { NotifikasiProvider } from "../context/NotifikasiProvider";

function RoleBasedRoute({ component: Component, redirect = false, ...rest }) {
  const token = localStorage.getItem("bayusinergi-token");

  const decoded = token ? jwtDecode(token) : {};

  const role =
    decoded?.data?.akses
      ?.map((item) => {
        const user = decoded.data;

        const { page } = item;

        if (
          user?.role === "mitra" &&
          user?.type === "hauler" &&
          (page?.path === "/dashboard" ||
            page?.path === "/new-order" ||
            page?.path === "/order")
        ) {
          return null;
        }

        return page;
      })
      ?.filter((item) => item !== null) ?? [];

  const isCan =
    rest.path === "/" ||
    rest.path === "/settings" ||
    role.find((item) => item.path === rest.path)
      ? true
      : false;

  if (redirect && token) {
    const role = decoded?.data?.role?.toLowerCase();

    const type = decoded?.data?.type ?? "";

    const path =
      role === "operation" || (role === "mitra" && type === "hauler")
        ? "/loading-order"
        : "/dashboard";

    return <Route {...rest} render={() => <Redirect to={path} />} />;
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? (
          isCan ? (
            <DataProvider>
              <NotifikasiProvider>
                <Component {...props} />
              </NotifikasiProvider>
            </DataProvider>
          ) : (
            <Unauthorized />
          )
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
}

export default RoleBasedRoute;
