// React
import { useState } from "react";

// Material UI
import {
  Card,
  Stack,
  Button,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";

// Components
import { AlertDialog, ListHead, SearchNotFound } from "../../../components";

// Utils
import { formatThousand } from "../../../utils";

// API
import { GetLoSummary } from "./API";
import loadingOrder from "../../../constant/loadingOrder";
import socket from "../../../constant/socket";

//
import CreateLoadingOrder from "./CreateLoadingOrder";
import CreateDeliveryOrder from "./CreateDeliveryOrder";

//
import { useData } from "../../../context/DataProvider";

//
const TABLE_HEAD = [
  { id: "no", label: "No", alignRight: false, isSort: false },
  { id: "lo_number", label: "LO Number", alignRight: false, isSort: false },
  {
    id: "do_created",
    label: "DO Created (L)",
    alignRight: false,
    isSort: false,
  },
  {
    id: "remaining_qty",
    label: "Remaining Qty (L)",
    alignRight: false,
    isSort: false,
  },
  {
    id: "satatus",
    label: "Status",
    alignRight: false,
    isSort: false,
  },
  {
    id: "",
  },
];

function ListLoadingOrder({
  expired_so,
  expired_so_date,
  oat,
  listItem,
  refresh,
  refreshDo,
  isSubmitting,
  setIsSubmitting,
}) {
  const [dialog, setDialog] = useState({
    open: false,
    data: null,
    line: null,
    mode: "View",
  });

  const [no, setNo] = useState(0);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setNo(no + 5);
    } else {
      setNo(no - 5);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      <Card sx={{ py: 4, px: 3 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="subtitle1">List Loading Order</Typography>
        </Stack>

        <TableContainer sx={{ minWidth: 200 }}>
          <Table>
            <ListHead headLabel={TABLE_HEAD} colored />
            <TableBody>
              {listItem
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((item, index) => {
                  return (
                    <LoadingItem
                      key={index}
                      expired_so={expired_so}
                      expired_so_date={expired_so_date}
                      item={item}
                      index={index}
                      no={no}
                      setDialog={(v) => setDialog(v)}
                      refreshDo={refreshDo}
                      isSubmitting={isSubmitting}
                      setIsSubmitting={(v) => setIsSubmitting(v)}
                    />
                  );
                })}
            </TableBody>
            {listItem?.length === 0 && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery="" />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={listItem?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      {/* Loading Order */}
      <CreateLoadingOrder
        open={dialog.open}
        mode={dialog.mode}
        data={dialog.data}
        orderLine={dialog.line}
        refresh={refresh}
        onClose={() => {
          setDialog((d) => ({
            ...d,
            open: false,
            data: null,
            line: null,
            mode: "View",
          }));
        }}
        oat={oat}
      />
    </>
  );
}

const LoadingItem = ({
  expired_so,
  expired_so_date,
  item,
  index,
  no,
  setDialog,
  refreshDo,
  setIsSubmitting,
}) => {
  const { user } = useData();

  const [summary, loadingSummary, refreshSummary] = GetLoSummary(
    item?.lo_number
  );

  const [dialogDo, setDialogDo] = useState({
    open: false,
    data: null,
    line: null,
  });

  const [alertDialog, setAlertDialog] = useState({
    open: false,
    mode: "delete",
  });

  const handleDelete = async () => {
    setIsSubmitting(true);
    try {
      await loadingOrder.delete(item?.lo_id);

      refreshDo();

      socket.emit("send-load");

      setAlertDialog(false);
    } catch (error) {
      console.log(error);
    }
    setIsSubmitting(false);
  };

  const handleRevoke = async () => {
    setIsSubmitting(true);
    try {
      await loadingOrder.revoke(item?.lo_id);

      refreshDo();

      socket.emit("send-load");

      setAlertDialog(false);
    } catch (error) {
      console.log(error);
    }
    setIsSubmitting(false);
  };

  return (
    <>
      <TableRow>
        <TableCell>{index + 1 + no}</TableCell>
        <TableCell>{item?.lo_number}</TableCell>
        <TableCell>
          {formatThousand(item?.remaining?.volume_do ?? 0)} L
        </TableCell>
        <TableCell>
          {formatThousand(item?.remaining?.volume_end ?? 0)} L
        </TableCell>
        <TableCell style={{ textTransform: "capitalize" }}>
          {item?.remaining?.status ?? "-"}
        </TableCell>
        <TableCell
          style={{
            width:
              user.role !== "customer" &&
              user.role !== "sales manager" &&
              user.role !== "finance manager" &&
              300,
          }}
        >
          <Stack direction="row" spacing={2}>
            <Button
              fullWidth
              size="small"
              variant="outlined"
              disabled={loadingSummary}
              onClick={() => {
                setDialog((d) => ({
                  ...d,
                  open: true,
                  data: item,
                  line: item?.detail,
                  mode: "View",
                }));
              }}
            >
              View LO
            </Button>

            {summary?.volume_do === 0 &&
              item?.status === "open" &&
              !expired_so &&
              (user.role === "sales manager" ||
                user.role === "finance manager") && (
                <Button
                  fullWidth
                  size="small"
                  color="error"
                  variant="contained"
                  disabled={loadingSummary || item?.status === "expired"}
                  onClick={() => {
                    setAlertDialog((d) => ({
                      ...d,
                      open: true,
                      mode: "delete",
                    }));
                  }}
                >
                  Delete
                </Button>
              )}

            {(user.role === "sales manager" ||
              user.role === "finance manager") &&
              item?.status === "expired" &&
              !expired_so && (
                <Button
                  fullWidth
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setDialog((d) => ({
                      ...d,
                      open: true,
                      data: { ...item, so_expiredate: expired_so_date },
                      line: item?.detail,
                      mode: "Edit",
                    }));
                  }}
                  disabled={loadingSummary || summary?.volume_do > 0}
                >
                  Edit LO
                </Button>
              )}

            {user.role === "finance manager" &&
              item?.status !== "expired" &&
              item.status === "open" && (
                <Button
                  fullWidth
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setDialog((d) => ({
                      ...d,
                      open: true,
                      data: { ...item, so_expiredate: expired_so_date },
                      line: item?.detail,
                      mode: "Edit",
                    }));
                  }}
                  disabled={loadingSummary || summary?.volume_do > 0}
                >
                  Edit LO
                </Button>
              )}

            {(user.role === "sales manager" ||
              user.role === "finance manager") &&
              item?.status === "request" && (
                <Button
                  fullWidth
                  size="small"
                  variant="contained"
                  onClick={() => {
                    setDialog((d) => ({
                      ...d,
                      open: true,
                      data: item,
                      line: item?.detail,
                      mode: "Add",
                    }));
                  }}
                  disabled={loadingSummary}
                >
                  Create LO
                </Button>
              )}

            {(user?.role === "sales manager" ||
              user?.role === "finance manager") &&
              item?.status === "request" && (
                <Button
                  fullWidth
                  size="small"
                  color="error"
                  variant="contained"
                  disabled={loadingSummary || item?.status === "expired"}
                  onClick={() => {
                    setAlertDialog((d) => ({
                      ...d,
                      open: true,
                      mode: "revoke",
                    }));
                  }}
                >
                  Revoke
                </Button>
              )}
          </Stack>
        </TableCell>
      </TableRow>

      {/* Delivery Order */}
      <CreateDeliveryOrder
        open={dialogDo.open}
        data={dialogDo.data}
        orderLine={dialogDo.line}
        remainingQty={summary?.volume_end}
        refresh={() => {
          refreshSummary(true);
          refreshDo();
        }}
        onClose={() => {
          setDialogDo((d) => ({
            ...d,
            open: false,
            data: null,
            line: null,
          }));
        }}
      />

      {/* Alert */}
      <AlertDialog
        open={alertDialog?.open}
        title={`${
          alertDialog?.mode === "delete" ? "Delete" : "Revoke"
        } Loading Order`}
        content={`Are you sure want to ${
          alertDialog?.mode === "delete" ? "delete" : "revoke"
        } this loading order ?`}
        handleClose={() => setAlertDialog(false)}
        handleOk={alertDialog?.mode === "delete" ? handleDelete : handleRevoke}
      />
    </>
  );
};

export default ListLoadingOrder;
