// React
import React, { useEffect, useState } from "react";

// Material UI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
  Divider,
  styled,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogActions,
  Button,
  Autocomplete,
  Chip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

// Components
import { NumberFormatQty, LoadingOrder } from "../../../components";

// Utils
import { formatDate, formatThousand } from "../../../utils";

// API
import { GetAll } from "./API";
import { GetAll as GetDepo } from "../Depo/API";
import socket from "../../../constant/socket";

//
import loadingOrder from "../../../constant/loadingOrder";

//
import { toast } from "react-toastify";

//
import { useData } from "../../../context/DataProvider";
import telegram from "../../../constant/telegram";
import { FM_ID, SA_ID, SM_ID } from "../../../config/telegram";

function CreateLoadingOrder({
  open,
  mode = "Add",
  data,
  orderLine,
  remainingQty,
  refresh,
  onClose,
  oat,
}) {
  const { user } = useData();

  const [listHauler, loading] = GetAll();

  const [listDepo, loadingDepo] = GetDepo();

  const [listEmail, setListEmail] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form, setForm] = useState({
    stock_id: "",
    skp_id: "",
    hauler: null,
    depo: null,
    earliest_date: "",
    latest_date: "",
    recipient: "",
    recipient_contact: "",
    qty: "",
    note: "",
    address: "",
  });

  const [error, setError] = useState({
    stock_id: "",
    skp_id: "",
    hauler: "",
    depo: "",
    earliest_date: "",
    latest_date: "",
    qty: "",
    address: "",
  });

  useEffect(() => {
    if (mode === "Edit" || mode === "Add") {
      setForm((f) => ({
        ...f,
        address: data?.address,
        latest_date: data?.latest_date,
        hauler: data?.hauler,
        skp_id: data?.skp_id,
        recipient: data?.recipient,
        recipient_contact: data?.recipient_contact,
      }));
    } else if (mode === "Request") {
      setForm((f) => ({
        ...f,
        depo: data?.depo,
        address: data?.address,
      }));
    }
  }, [mode, data]);

  const validate = () => {
    const newError = { ...error };

    if (mode === "Add") {
      if (
        (user.role !== "sales admin" ||
          user.role !== "sales rep" ||
          user.role !== "mitra") &&
        !form.stock_id
      ) {
        newError.stock_id = "Stock ID required!";
      }

      if (
        (user.role !== "sales admin" ||
          user.role !== "sales rep" ||
          user.role !== "mitra") &&
        !form.skp_id
      ) {
        newError.skp_id = "SKP ID required!";
      }

      if (form.hauler === null) {
        newError.hauler = "Hauler required!";
      }
    }

    if (mode === "Edit") {
      if (!form.latest_date) {
        newError.latest_date = "Latest Pickup Date required!";
      }

      if (form.hauler === null) {
        newError.hauler = "Hauler required!";
      }
    }

    if (mode === "Request") {
      if (
        (user.role === "sales admin" ||
          user.role === "sales rep" ||
          user.role === "mitra") &&
        form.hauler === null
      ) {
        newError.hauler = "Hauler required!";
      }

      if (
        (user.role === "sales admin" ||
          user.role === "sales rep" ||
          user.role === "mitra") &&
        form.depo === null
      ) {
        newError.depo = "Supply Point required!";
      }

      if (
        (user.role === "sales admin" ||
          user.role === "sales rep" ||
          user.role === "mitra") &&
        !form.earliest_date
      ) {
        newError.earliest_date = "Earliest Pickup Date required!";
      }

      if (
        (user.role === "sales admin" ||
          user.role === "sales rep" ||
          user.role === "mitra") &&
        !form.latest_date
      ) {
        newError.latest_date = "Latest Pickup Date required!";
      }

      if (
        (user.role === "sales admin" ||
          user.role === "sales rep" ||
          user.role === "mitra") &&
        !form.qty
      ) {
        newError.qty = "Qty To Load required!";
      } else if (
        (user.role === "sales admin" ||
          user.role === "sales rep" ||
          user.role === "mitra") &&
        parseInt(form.qty) > remainingQty
      ) {
        newError.qty = "Qty greater than remaining qty!";
      } else if (user.role === "sales admin" && parseInt(form.qty) <= 0) {
        newError.qty = "Qty lower than remaining qty!";
      }

      if (
        (user.role === "sales admin" ||
          user.role === "sales rep" ||
          user.role === "mitra") &&
        !form.address
      ) {
        newError.address = "Ship To Address required!";
      }
    }

    return newError;
  };

  const handleSubmit = async () => {
    const findErrors = validate();

    if (Object.values(findErrors).some((e) => e !== "")) {
      setError(findErrors);
    } else {
      try {
        setIsSubmitting(true);

        const {
          order_number: so_number,
          po_number,
          sales_id,
          customer_id,
          type_of_sales,
          province,
          skp_id,
          ops_type,
        } = data;

        const {
          hauler,
          qty,
          earliest_date,
          latest_date,
          note,
          depo,
          recipient,
          recipient_contact,
          address,
        } = form;

        let expired = new Date(latest_date).setHours(23, 59, 59, 59);

        const newData = {
          so_number,
          po_number,
          sales_id,
          customer_id,
          address,
          province_id: province?.id,
          depo_id: depo?.id,
          type_of_sales,
          ops_type,
          skp_id,
          hauler_id: hauler?.id,
          product_id: orderLine?.products?.product_id,
          qty: parseInt(qty),
          earliest_date,
          latest_date: new Date(expired),
          recipient,
          recipient_contact,
          note,
        };

        if (
          user?.role === "sales manager" ||
          user?.role === "finance manager"
        ) {
          await loadingOrder.create(data?.lo_number, {
            skp_id: form?.skp_id,
            hauler_id: hauler?.id,
            lo_number: data?.lo_number,
            customer: data?.customer?.name,
            qty: formatThousand(orderLine?.qty),
            po_number,
            stock_id: form.stock_id,
            sales: data?.sales?.name,
            product: orderLine?.product?.name?.toUpperCase(),
            hauler: hauler?.name,
            supply_point: data?.depo?.name,
            address,
            earliest_date: formatDate(data?.earliest_date),
            lo_expired: formatDate(data?.latest_date),
            oat: formatThousand(oat),
            note: data?.note,
            recipient,
            recipient_contact,
          });

          await telegram.sendMessage(
            SA_ID,
            `Loading Order created for SO No. ${data?.so_number} by ${user?.name} (${user?.role})`
          );

          socket.emit("send-notif", {
            room: "sales-admin",
            data: {
              title: "Loading Order Created",
              message: `Loading Order created for SO No. ${data?.so_number}`,
            },
          });
        } else if (user?.role === "sales admin") {
          await loadingOrder.request({ ...newData, list_email: listEmail });

          await telegram.sendMessage(
            SM_ID,
            `Loading Order request for SO No. ${so_number} by ${user?.name} (Sales Admin)`
          );

          await telegram.sendMessage(
            FM_ID,
            `Loading Order request for SO No. ${so_number} by ${user?.name} (Sales Admin)`
          );

          socket.emit("send-notif", {
            room: "sales-manager",
            data: {
              title: "Request Loading Order",
              message: `Loading Order request for SO No. ${so_number}`,
            },
          });

          socket.emit("send-notif", {
            room: "finance-manager",
            data: {
              title: "Request Loading Order",
              message: `Loading Order request for SO No. ${so_number}`,
            },
          });
        }

        socket.emit("send-load");

        setForm({
          stock_id: "",
          hauler: null,
          earliest_date: "",
          latest_date: "",
          qty: "",
          note: "",
          recipient: "",
          recipient_contact: "",
          address: "",
        });

        setError({
          stock_id: "",
          hauler: "",
          earliest_date: "",
          latest_date: "",
          qty: "",
          address: "",
        });

        setListEmail([]);

        refresh();

        onClose();

        setIsSubmitting(false);

        toast.success("Loading Order Submitted!");
      } catch (e) {
        console.log(e);
        setIsSubmitting(false);
      }
    }
  };

  const handleEdit = async () => {
    const findErrors = validate();

    if (Object.values(findErrors).some((e) => e !== "")) {
      setError(findErrors);
    } else {
      try {
        setIsSubmitting(true);

        let expired = new Date(form?.latest_date).setHours(23, 59, 59, 59);

        await loadingOrder.update(data?.lo_id, {
          lo_number: data?.lo_number,
          hauler_id: form?.hauler?.id,
          latest_date: new Date(expired),
        });

        socket.emit("send-load");

        setForm({
          stock_id: "",
          hauler: null,
          earliest_date: "",
          latest_date: "",
          qty: "",
          note: "",
          recipient: "",
          recipient_contact: "",
        });

        setError({
          stock_id: "",
          hauler: "",
          earliest_date: "",
          latest_date: "",
          qty: "",
        });

        refresh();

        onClose();

        setIsSubmitting(false);

        toast.success("Loading Order Edited!");
      } catch (e) {
        console.log(e);
        setIsSubmitting(false);
      }
    }
  };

  return (
    <>
      {isSubmitting && <LoadingOrder />}
      <Dialog open={open} fullWidth maxWidth="xl" onClose={onClose}>
        <DialogTitle>
          {mode === "Add" ? "Create" : mode === "Request" && "Request"} Loading
          Order
        </DialogTitle>
        <DialogContent>
          <Grid container direction="row" spacing={3}>
            {/* Section 1 */}
            <Grid item lg={6}>
              {/* SO / LO Number */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
              >
                <Typography style={{ width: 150 }}>
                  {mode === "Request" ? "SO" : "LO"} Number
                </Typography>
                <CustomTypography>
                  {(mode === "Request"
                    ? data?.order_number
                    : data?.lo_number) ?? "-"}
                </CustomTypography>
              </Stack>

              {/* Customer */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Customer</Typography>
                <CustomTypography>
                  {data?.customer?.name ?? "-"}
                </CustomTypography>
              </Stack>

              {/* Cust Type */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Cust Type</Typography>
                <CustomTypography>
                  {data?.customer?.custtype?.name ?? "-"}
                </CustomTypography>
              </Stack>

              {/* Business Type */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Business Type</Typography>
                <CustomTypography>
                  {data?.customer?.businesstype?.name ?? "-"}
                </CustomTypography>
              </Stack>

              {/* Ship To Address */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Ship To Address</Typography>
                {(user.role === "sales admin" ||
                  user.role === "sales rep" ||
                  user.role === "mitra") &&
                mode === "Request" ? (
                  <CustomTextField
                    size="small"
                    value={form.address}
                    onChange={(e) => {
                      setForm((f) => ({
                        ...f,
                        address: e.target.value,
                      }));

                      setError((e) => ({
                        ...e,
                        stock_id: "",
                      }));
                    }}
                    error={error.address ? true : false}
                    helperText={error.address}
                    disabled={isSubmitting}
                  />
                ) : (
                  <CustomTypographyAddress>
                    {data?.address ?? "-"}
                  </CustomTypographyAddress>
                )}
              </Stack>

              {/* Province*/}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Province</Typography>
                <CustomTypographyAddress>
                  {data?.province?.name ?? "-"}
                </CustomTypographyAddress>
              </Stack>

              {/* Supply Point */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Supply Point</Typography>
                {(user.role === "sales admin" ||
                  user.role === "sales rep" ||
                  user.role === "mitra") &&
                mode === "Request" ? (
                  <CustomAutoComplete
                    getOptionLabel={(option) => option.name}
                    options={listDepo}
                    onChange={(e, v) => {
                      setForm((f) => ({
                        ...f,
                        depo: v,
                      }));

                      setError((err) => ({
                        ...err,
                        depo: "",
                      }));
                    }}
                    value={form.depo}
                    loading={loadingDepo}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose Supply Point"
                        size="small"
                        error={error.depo ? true : false}
                        helperText={error.depo}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    fullWidth
                    disabled={isSubmitting}
                  />
                ) : (
                  <CustomTypographyAddress>
                    {data?.depo?.name ?? "-"}
                  </CustomTypographyAddress>
                )}
              </Stack>

              {/* Stock ID */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Stock ID</Typography>
                {(user.role !== "sales admin" ||
                  user.role !== "sales rep" ||
                  user.role !== "mitra") &&
                mode === "Add" ? (
                  <CustomTextField
                    size="small"
                    value={form.stock_id}
                    onChange={(e) => {
                      setForm((f) => ({
                        ...f,
                        stock_id: e.target.value,
                      }));

                      setError((e) => ({
                        ...e,
                        stock_id: "",
                      }));
                    }}
                    error={error.stock_id ? true : false}
                    helperText={error.stock_id}
                    disabled={isSubmitting}
                  />
                ) : (
                  <CustomTypographyAddress>
                    {data?.stock_id ?? "-"}
                  </CustomTypographyAddress>
                )}
              </Stack>

              {/* Recipient */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Recipient</Typography>
                {((user.role === "sales admin" ||
                  user.role === "sales rep" ||
                  user.role === "mitra") &&
                  mode === "Request") ||
                ((user.role !== "sales admin" ||
                  user.role !== "sales rep" ||
                  user.role !== "mitra") &&
                  mode === "Add") ? (
                  <CustomTextField
                    size="small"
                    value={form.recipient}
                    onChange={(e) => {
                      setForm((f) => ({
                        ...f,
                        recipient: e.target.value,
                      }));
                    }}
                    disabled={isSubmitting}
                  />
                ) : (
                  <CustomTypographyAddress>
                    {data?.recipient ?? "-"}
                  </CustomTypographyAddress>
                )}
              </Stack>
            </Grid>

            {/* Section 2 */}
            <Grid item lg={6}>
              {/* PO Number */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
              >
                <Typography style={{ width: 150 }}>PO Number</Typography>
                <CustomTypography>{data?.po_number ?? "-"}</CustomTypography>
              </Stack>

              {/* Sales */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Sales</Typography>
                <CustomTypography>{data?.sales?.name ?? "-"}</CustomTypography>
              </Stack>

              {/* SKP ID */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>SKP ID</Typography>
                {(user.role !== "sales admin" ||
                  user.role !== "sales rep" ||
                  user.role !== "mitra") &&
                mode === "Add" ? (
                  <CustomTextField
                    size="small"
                    value={form.skp_id}
                    onChange={(e) => {
                      setForm((f) => ({
                        ...f,
                        skp_id: e.target.value,
                      }));

                      setError((e) => ({
                        ...e,
                        skp_id: "",
                      }));
                    }}
                    error={error.skp_id ? true : false}
                    helperText={error.skp_id}
                    disabled={isSubmitting}
                  />
                ) : (
                  <CustomTypography>{data?.skp_id ?? "-"}</CustomTypography>
                )}
              </Stack>

              {/* Type Of Sales */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Type Of Sales</Typography>
                <CustomTypography>
                  {data?.type_of_sales ? "On Site" : data?.type_of_sales ?? "-"}
                </CustomTypography>
              </Stack>

              {/* Earliest Pickup Date */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>
                  Earliest Pickup Date
                </Typography>
                {(user.role === "sales admin" ||
                  user.role === "sales rep" ||
                  user.role === "mitra") &&
                mode === "Request" ? (
                  <DatePicker
                    minDate={new Date()}
                    maxDate={new Date(data?.so_expiredate)}
                    onChange={(v) => {
                      setForm((f) => ({
                        ...f,
                        earliest_date: v,
                      }));
                      setError((e) => ({
                        ...e,
                        earliest_date: "",
                      }));
                    }}
                    value={form.earliest_date}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        size="small"
                        error={error.earliest_date ? true : false}
                        helperText={error.earliest_date}
                        fullWidth
                      />
                    )}
                    disabled={isSubmitting}
                  />
                ) : (
                  <CustomTypographyAddress>
                    {formatDate(data?.earliest_date) ?? "-"}
                  </CustomTypographyAddress>
                )}
              </Stack>

              {/* Latest Pickup Date */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>
                  Latest Pickup Date
                </Typography>
                {(user.role === "sales admin" ||
                  user.role === "sales rep" ||
                  user.role === "mitra") &&
                mode === "Request" ? (
                  <DatePicker
                    minDate={new Date()}
                    maxDate={new Date(data?.so_expiredate)}
                    onChange={(v) => {
                      setForm((f) => ({
                        ...f,
                        latest_date: v,
                      }));
                      setError((e) => ({
                        ...e,
                        latest_date: "",
                      }));
                    }}
                    value={form.latest_date}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        size="small"
                        error={error.latest_date ? true : false}
                        helperText={error.latest_date}
                        fullWidth
                      />
                    )}
                    disabled={isSubmitting}
                  />
                ) : (user.role === "sales manager" ||
                    user.role === "finance manager") &&
                  mode === "Edit" ? (
                  <DatePicker
                    minDate={new Date()}
                    maxDate={new Date(data?.so_expiredate)}
                    onChange={(v) => {
                      setForm((f) => ({
                        ...f,
                        latest_date: v,
                      }));
                      setError((e) => ({
                        ...e,
                        latest_date: "",
                      }));
                    }}
                    value={form.latest_date}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        size="small"
                        error={error.latest_date ? true : false}
                        helperText={error.latest_date}
                        fullWidth
                      />
                    )}
                    disabled={isSubmitting}
                  />
                ) : (
                  <CustomTypographyAddress>
                    {formatDate(data?.latest_date) ?? "-"}
                  </CustomTypographyAddress>
                )}
              </Stack>

              {/* Hauler */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Hauler</Typography>
                {(user.role === "sales admin" ||
                  user.role === "sales rep" ||
                  user.role === "mitra") &&
                mode === "Request" ? (
                  <CustomAutoComplete
                    getOptionLabel={(option) => option.name}
                    options={listHauler}
                    onChange={(e, v) => {
                      setForm((f) => ({
                        ...f,
                        hauler: v,
                      }));

                      setError((err) => ({
                        ...err,
                        hauler: "",
                      }));
                    }}
                    value={form.hauler}
                    loading={loading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose Hauler"
                        size="small"
                        error={error.hauler ? true : false}
                        helperText={error.hauler}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    fullWidth
                    disabled={isSubmitting}
                  />
                ) : (mode === "Edit" || mode === "Add") &&
                  (user.role === "sales manager" ||
                    user.role === "finance manager") ? (
                  <CustomAutoComplete
                    getOptionLabel={(option) => option.name}
                    options={listHauler}
                    onChange={(e, v) => {
                      setForm((f) => ({
                        ...f,
                        hauler: v,
                      }));

                      setError((err) => ({
                        ...err,
                        hauler: "",
                      }));
                    }}
                    value={form.hauler}
                    loading={loading}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose Hauler"
                        size="small"
                        error={error.hauler ? true : false}
                        helperText={error.hauler}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    fullWidth
                    disabled={isSubmitting}
                  />
                ) : (
                  <CustomTypographyAddress>
                    {data?.hauler?.name ?? "-"}
                  </CustomTypographyAddress>
                )}
              </Stack>

              {/* Ops Type */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Ops Type</Typography>
                <CustomTypography>{data?.ops_type ?? "-"}</CustomTypography>
              </Stack>

              {/* Recipient Contact */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>
                  Recipient Contact
                </Typography>
                {((user.role === "sales admin" ||
                  user.role === "sales rep" ||
                  user.role === "mitra") &&
                  mode === "Request") ||
                ((user.role !== "sales admin" ||
                  user.role !== "sales rep" ||
                  user.role !== "mitra") &&
                  mode === "Add") ? (
                  <CustomTextField
                    size="small"
                    value={form.recipient_contact}
                    onChange={(e) => {
                      setForm((f) => ({
                        ...f,
                        recipient_contact: e.target.value,
                      }));
                    }}
                    disabled={isSubmitting}
                  />
                ) : (
                  <CustomTypographyAddress>
                    {data?.recipient_contact ?? "-"}
                  </CustomTypographyAddress>
                )}
              </Stack>
            </Grid>
          </Grid>

          {mode === "Request" && (
            <>
              <Divider style={{ marginTop: 24 }} />

              <div style={{ marginTop: 24 }}>
                <Autocomplete
                  multiple
                  options={[]}
                  freeSolo
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        variant="outlined"
                        label={option}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Send Email To"
                    />
                  )}
                  onChange={(e, v) => v.length < 6 && setListEmail(v)}
                  value={listEmail}
                  disabled={isSubmitting}
                />
              </div>
            </>
          )}

          <div style={{ marginTop: 24 }}>
            <TableContainer>
              <Table>
                <TableHead style={{ backgroundColor: "#EFEFEF" }}>
                  <TableRow>
                    <TableCell>Product</TableCell>
                    <TableCell>Qty To Load (L)</TableCell>
                    {mode === "Request" && (
                      <TableCell>Remaining Qty (L)</TableCell>
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {mode === "Request"
                        ? orderLine?.products?.name ?? "-"
                        : orderLine?.product?.name ?? "-"}
                    </TableCell>
                    <TableCell>
                      {(user.role === "sales admin" ||
                        user.role === "sales rep" ||
                        user.role === "mitra") &&
                      mode === "Request" ? (
                        <TextField
                          name="qty"
                          autoComplete="off"
                          fullWidth
                          size="small"
                          value={form.qty}
                          onChange={(e) => {
                            setForm((f) => ({
                              ...f,
                              qty: e.target.value,
                            }));

                            setError((e) => ({
                              ...e,
                              qty: "",
                            }));
                          }}
                          error={error.qty ? true : false}
                          helperText={error.qty}
                          InputProps={{ inputComponent: NumberFormatQty }}
                          disabled={isSubmitting}
                        />
                      ) : (
                        <>{formatThousand(orderLine?.qty ?? 0)} L</>
                      )}
                    </TableCell>
                    {mode === "Request" && (
                      <TableCell>
                        {formatThousand(remainingQty ?? 0)} L
                      </TableCell>
                    )}
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          {/* Note */}
          {mode === "Request" ? (
            <TextField
              label="Note"
              onChange={(e) => {
                setForm((f) => ({
                  ...f,
                  note: e.target.value,
                }));
              }}
              value={form?.note}
              multiline
              minRows={4}
              maxRows={4}
              fullWidth
              sx={{ mt: 3 }}
              disabled={isSubmitting}
            />
          ) : (
            <TextField
              label="Note"
              disabled
              value={data?.note}
              multiline
              minRows={4}
              maxRows={4}
              fullWidth
              sx={{ mt: 3 }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="inherit"
            onClick={() => {
              if (mode === "Add" || mode === "Edit") {
                setForm({
                  stock_id: "",
                  hauler: null,
                  earliest_date: "",
                  latest_date: "",
                  qty: "",
                  note: "",
                });

                setError({
                  stock_id: "",
                  hauler: "",
                  earliest_date: "",
                  latest_date: "",
                  qty: "",
                });

                setListEmail([]);
              }

              onClose();
            }}
            disabled={isSubmitting}
          >
            {mode === "Add" || mode === "Edit" ? "Cancel" : "Close"}
          </Button>
          {(mode === "Add" || mode === "Request") && (
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Submit
            </Button>
          )}
          {mode === "Edit" && (
            <Button
              variant="contained"
              onClick={handleEdit}
              disabled={isSubmitting}
            >
              Edit Loading Order
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
}

// Custom
const CustomAutoComplete = styled(Autocomplete)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  lineHeight: "40px",
  wordBreak: "break-word",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const CustomTypographyAddress = styled(Typography)(({ theme }) => ({
  lineHeight: "40px",
  wordBreak: "break-word",
  width: "65%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export default CreateLoadingOrder;
