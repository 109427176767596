//
import {
  Dialog,
  Button,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
} from "@mui/material";

export default function AlertDialog({
  handleOk,
  handleClose,
  open,
  title,
  content,
  loading,
}) {
  return (
    <Dialog open={open} onClose={!loading ? handleClose : () => {}}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={handleClose}>
          Cancel
        </Button>
        <Button disabled={loading} onClick={handleOk}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
}
