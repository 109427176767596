// React
import { useState } from "react";

// Material UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Stack,
  Divider,
  styled,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers'

//
import { NumberFormatQty } from "../../../components";

//
import { formatDate, formatThousand } from "../../../utils";

//
import { toast } from "react-toastify";

//
import deliveryOrder from "../../../constant/deliveryOrder";
import socket from "../../../constant/socket";

function CreateDeliveryOrder({
  open,
  mode = "Add",
  data,
  orderLine,
  remainingQty,
  refresh,
  onClose,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form, setForm] = useState({
    vehicle_number: "",
    segel_atas: "",
    segel_bawah: "",
    pickup_date: "",
    recipient: "",
    recipient_contact: "",
    driver: "",
    driver_contact: "",
    qty: "",
    note: "",
  });

  const [error, setError] = useState({
    vehicle_number: "",
    segel_atas: "",
    segel_bawah: "",
    pickup_date: "",
    recipient: "",
    recipient_contact: "",
    driver: "",
    driver_contact: "",
    qty: "",
  });

  const validate = () => {
    const newError = { ...error };

    if (!form.vehicle_number) {
      newError.vehicle_number = "Vehicle Number required!";
    }

    if (!form.segel_atas) {
      newError.segel_atas = "No. Segel Atas required!";
    }

    if (!form.segel_bawah) {
      newError.segel_bawah = "No. Segel Bawah required!";
    }

    if (!form.pickup_date) {
      newError.pickup_date = "Delivery Date required!";
    }

    if (!form.recipient) {
      newError.recipient = "Reqcipient required!";
    }

    if (!form.recipient_contact) {
      newError.recipient_contact = "Reqcipient Contact required!";
    }

    if (!form.driver) {
      newError.driver = "Driver required!";
    }

    if (!form.driver_contact) {
      newError.driver_contact = "Driver Contact required!";
    }

    if (!form.qty) {
      newError.qty = "Qty To Load required!";
    } else if (parseInt(form.qty) > remainingQty) {
      newError.qty = "Qty greater than remaining qty!";
    } else if (parseInt(form.qty) <= 0) {
      newError.qty = "Qty lower than remaining qty!";
    }

    return newError;
  };

  const handleSubmit = async () => {
    const findErrors = validate();

    if (Object.values(findErrors).some((e) => e !== "")) {
      setError(findErrors);
    } else {
      try {
        setIsSubmitting(true);

        const {
          so_number,
          lo_number,
          po_number,
          customer,
          depo,
          type_of_sales,
          address,
          skp_id,
          ops_type,
          stock_id,
          hauler,
        } = data;

        const { qty, ...rest } = form;

        const newData = {
          so_number,
          lo_number,
          po_number,
          customer_id: customer?.id,
          address,
          depo_id: depo?.id,
          hauler_id: hauler?.id,
          ops_type,
          type_of_sales,
          skp_id,
          stock_id,
          product_id: orderLine?.product?.id,
          qty: parseInt(qty),
          ...rest,
        };

        await deliveryOrder.create(newData);

        socket.emit("send-load");

        setForm({
          vehicle_number: "",
          segel_atas: "",
          segel_bawah: "",
          pickup_date: "",
          recipient: "",
          recipient_contact: "",
          driver: "",
          driver_contact: "",
          qty: "",
          note: "",
        });

        setError({
          vehicle_number: "",
          segel_atas: "",
          segel_bawah: "",
          pickup_date: "",
          recipient: "",
          recipient_contact: "",
          driver: "",
          driver_contact: "",
          qty: "",
        });

        refresh();

        onClose();

        setIsSubmitting(false);

        toast.success("Delivery Order Submitted!");
      } catch (e) {
        console.log(e);
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="xl">
      <DialogTitle>{mode === "Add" && "Create"} Delivery Order</DialogTitle>
      <DialogContent>
        <Grid container direction="row" spacing={3}>
          {/* Section 1 */}
          <Grid item lg={6}>
            {/* LO Number */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
            >
              <Typography style={{ width: 150 }}>LO Number</Typography>
              <CustomTypography>{data?.lo_number ?? "-"}</CustomTypography>
            </Stack>

            {/* SO Number */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>SO Number</Typography>
              <CustomTypography>{data?.so_number ?? "-"}</CustomTypography>
            </Stack>

            {/* Customer */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Customer</Typography>
              <CustomTypography>{data?.customer?.name ?? "-"}</CustomTypography>
            </Stack>

            {/* Ship To Address */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Ship To Address</Typography>
              <CustomTypographyAddress>
                {data?.address ?? "-"}
              </CustomTypographyAddress>
            </Stack>

            {/* Supply Point */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Supply Point</Typography>
              <CustomTypography>{data?.depo?.name ?? "-"}</CustomTypography>
            </Stack>

            {/* Hauler */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Hauler</Typography>
              <CustomTypography>{data?.hauler?.name ?? "-"}</CustomTypography>
            </Stack>

            {/* Ops Type */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Ops Type</Typography>
              <CustomTypography>{data?.ops_type ?? "-"}</CustomTypography>
            </Stack>

            {/* Vehicle Number */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Vehicle Number</Typography>
              {mode === "Add" ? (
                <CustomTextField
                  size="small"
                  value={form.vehicle_number}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      vehicle_number: e.target.value,
                    }));

                    setError((e) => ({
                      ...e,
                      vehicle_number: "",
                    }));
                  }}
                  error={error.vehicle_number ? true : false}
                  helperText={error.vehicle_number}
                  disabled={isSubmitting}
                />
              ) : (
                <CustomTypography>
                  {data?.vehicle_number ?? "-"}
                </CustomTypography>
              )}
            </Stack>

            {/* No. Segel Atas */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>No. Segel Atas</Typography>
              {mode === "Add" ? (
                <CustomTextField
                  size="small"
                  value={form.segel_atas}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      segel_atas: e.target.value,
                    }));

                    setError((e) => ({
                      ...e,
                      segel_atas: "",
                    }));
                  }}
                  error={error.segel_atas ? true : false}
                  helperText={error.segel_atas}
                  disabled={isSubmitting}
                />
              ) : (
                <CustomTypography>{data?.segel_atas ?? "-"}</CustomTypography>
              )}
            </Stack>

            {/* No. Segel Bawah */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>No. Segel Bawah</Typography>
              {mode === "Add" ? (
                <CustomTextField
                  size="small"
                  value={form.segel_bawah}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      segel_bawah: e.target.value,
                    }));

                    setError((e) => ({
                      ...e,
                      segel_bawah: "",
                    }));
                  }}
                  error={error.segel_bawah ? true : false}
                  helperText={error.segel_bawah}
                  disabled={isSubmitting}
                />
              ) : (
                <CustomTypography>{data?.segel_bawah ?? "-"}</CustomTypography>
              )}
            </Stack>
          </Grid>

          {/* Section 2 */}
          <Grid item lg={6}>
            {/* PO Number */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
            >
              <Typography style={{ width: 150 }}>PO Number</Typography>
              <CustomTypography>{data?.po_number ?? "-"}</CustomTypography>
            </Stack>

            {/* Type Of Sales */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Type Of Sales</Typography>
              <CustomTypography>
                {data?.type_of_sales === "CIF"
                  ? "On Site"
                  : data?.type_of_sales ?? "-"}
              </CustomTypography>
            </Stack>

            {/* Pickup Date */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Pickup Date</Typography>
              {mode === "Add" ? (
                <DatePicker
                  minDate={new Date(data?.earliest_date)}
                  maxDate={new Date(data?.latest_date)}
                  onChange={(v) => {
                    setForm((f) => ({
                      ...f,
                      pickup_date: v,
                    }));
                    setError((e) => ({
                      ...e,
                      pickup_date: "",
                    }));
                  }}
                  value={form.pickup_date}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      size="small"
                      error={error.pickup_date ? true : false}
                      helperText={error.pickup_date}
                      fullWidth
                    />
                  )}
                  disabled={isSubmitting}
                />
              ) : (
                <CustomTypography>
                  {formatDate(data?.pickup_date) ?? "-"}
                </CustomTypography>
              )}
            </Stack>

            {/* Recipient */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Recipient</Typography>
              {mode === "Add" ? (
                <CustomTextField
                  size="small"
                  value={form.recipient}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      recipient: e.target.value,
                    }));

                    setError((e) => ({
                      ...e,
                      recipient: "",
                    }));
                  }}
                  error={error.recipient ? true : false}
                  helperText={error.recipient}
                  disabled={isSubmitting}
                />
              ) : (
                <CustomTypography>{data?.recipient ?? "-"}</CustomTypography>
              )}
            </Stack>

            {/* Recipient Contact */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Recipient Contact</Typography>
              {mode === "Add" ? (
                <CustomTextField
                  size="small"
                  value={form.recipient_contact}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      recipient_contact: e.target.value,
                    }));

                    setError((e) => ({
                      ...e,
                      recipient_contact: "",
                    }));
                  }}
                  error={error.recipient_contact ? true : false}
                  helperText={error.recipient_contact}
                  disabled={isSubmitting}
                />
              ) : (
                <CustomTypography>
                  {data?.recipient_contact ?? "-"}
                </CustomTypography>
              )}
            </Stack>

            {/* Stock ID */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Stock ID</Typography>
              <CustomTypography>{data?.stock_id ?? "-"}</CustomTypography>
            </Stack>

            {/* Driver */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Driver</Typography>
              {mode === "Add" ? (
                <CustomTextField
                  size="small"
                  value={form.driver}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      driver: e.target.value,
                    }));

                    setError((e) => ({
                      ...e,
                      driver: "",
                    }));
                  }}
                  error={error.driver ? true : false}
                  helperText={error.driver}
                  disabled={isSubmitting}
                />
              ) : (
                <CustomTypography>{data?.driver ?? "-"}</CustomTypography>
              )}
            </Stack>

            {/* Driver Contact */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Driver Contact</Typography>
              {mode === "Add" ? (
                <CustomTextField
                  size="small"
                  value={form.driver_contact}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      driver_contact: e.target.value,
                    }));

                    setError((e) => ({
                      ...e,
                      driver_contact: "",
                    }));
                  }}
                  error={error.driver_contact ? true : false}
                  helperText={error.driver_contact}
                  disabled={isSubmitting}
                />
              ) : (
                <CustomTypography>
                  {data?.driver_contact ?? "-"}
                </CustomTypography>
              )}
            </Stack>
          </Grid>
        </Grid>

        <div style={{ marginTop: 24 }}>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: "#EFEFEF" }}>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell>Qty To Delivery (L)</TableCell>
                  {mode === "Add" && <TableCell>Remaining Qty (L)</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>{orderLine?.product?.name ?? "-"}</TableCell>
                  <TableCell>
                    {mode === "Add" ? (
                      <TextField
                        name="qty"
                        autoComplete="off"
                        fullWidth
                        size="small"
                        value={form.qty}
                        onChange={(e) => {
                          setForm((f) => ({
                            ...f,
                            qty: e.target.value,
                          }));

                          setError((e) => ({
                            ...e,
                            qty: "",
                          }));
                        }}
                        error={error.qty ? true : false}
                        helperText={error.qty}
                        InputProps={{ inputComponent: NumberFormatQty }}
                        disabled={isSubmitting}
                      />
                    ) : (
                      <>{formatThousand(orderLine?.qty ?? 0)} L</>
                    )}
                  </TableCell>
                  {mode === "Add" && (
                    <TableCell>{formatThousand(remainingQty ?? 0)} L</TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        {/* Note */}
        {mode === "Add" ? (
          <TextField
            label="Note"
            onChange={(e) => {
              setForm((f) => ({
                ...f,
                note: e.target.value,
              }));
            }}
            value={form?.note}
            multiline
            minRows={4}
            maxRows={4}
            fullWidth
            sx={{ mt: 3 }}
            disabled={isSubmitting}
          />
        ) : (
          <TextField
            label="Note"
            disabled
            value={data?.note}
            multiline
            minRows={4}
            maxRows={4}
            fullWidth
            sx={{ mt: 3 }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            setForm({
              vehicle_number: "",
              segel_atas: "",
              segel_bawah: "",
              pickup_date: "",
              recipient: "",
              recipient_contact: "",
              driver: "",
              driver_contact: "",
              qty: "",
              note: "",
            });

            setError({
              vehicle_number: "",
              segel_atas: "",
              segel_bawah: "",
              pickup_date: "",
              recipient: "",
              recipient_contact: "",
              driver: "",
              driver_contact: "",
              qty: "",
            });

            onClose();
          }}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        {mode === "Add" && (
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

// Custom
const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  lineHeight: "40px",
  wordBreak: "break-word",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const CustomTypographyAddress = styled(Typography)(({ theme }) => ({
  lineHeight: "40px",
  wordBreak: "break-word",
  width: "65%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export default CreateDeliveryOrder;
