//
import { useMemo, useState, useEffect } from "react";

//
import accessRole from "../../../../constant/accessRole";

function GetAll(id) {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [load, setLoad] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    const getAll = async () => {
      setLoading(true);
      try {
        const res = await accessRole.getOnce(id);

        setData(
          res.data?.map((item) => {
            const { access_id, ...rest } = item;

            return {
              id: access_id,
              name: rest?.page?.name,
              ...rest,
            };
          })
        );
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };

    if (id) {
      getAll();
    }
  }, [id]);

  useEffect(() => {
    const getAll = async () => {
      try {
        const res = await accessRole.getOnce(id);

        setData(
          res.data?.map((item) => {
            const { access_id, ...rest } = item;

            return {
              id: access_id,
              name: rest?.page?.name,
              ...rest,
            };
          })
        );
      } catch (e) {
        setError(e);
      }
      setLoad(false);
    };

    if (load && id) {
      getAll();
    }
  }, [load, id]);

  return useMemo(
    () => [data, loading, setLoad, error],
    [data, loading, setLoad, error]
  );
}

export default GetAll;
