import axios from "../config";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  create: (data) => axios.post("/product/", data),
  get: () => axios.get("/product/"),
  getOnce: (id) => axios.get(`/product/${id}`),
  update: (id, data) => axios.put(`/product/${id}`, data),
  delete: (id) => axios.delete(`/product/${id}`),
};
