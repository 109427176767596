// React
import { useState } from "react";

// Material UI
import {
  Container,
  Stack,
  IconButton,
  Typography,
  Card,
  Grid,
  TextField,
  Autocomplete,
  Button,
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Box,
  styled,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

// Components
import {
  ActionTable,
  ListHead,
  LoadingPage,
  Page,
  NumberFormatThousand,
  SearchNotFound,
  LoadingOrder,
  AlertDialog,
} from "../../../components";

// Iconify
import { Icon } from "@iconify/react";
import arrowBackFill from "@iconify/icons-eva/arrow-back-fill";
import trash2Fill from "@iconify/icons-eva/trash-2-fill";

// React Router Dom
import { useHistory, useParams } from "react-router-dom";
//
import { getBase64 } from "../../../utils";

// API
import {
  GetDetail,
  GetPartnerType,
  GetAllEquipment,
  GetAllDriver,
} from "./API";

//
import mitra from "../../../constant/mitra";

//
import { toast } from "react-toastify";

//
import { applySortFilter, formatThousand } from "../../../utils";

//
import AddEquipment from "./AddEquipment";
import AddDriver from "./AddDriver";

//
import equipment from "../../../constant/equipment";
import driver from "../../../constant/driver";

//
import { useData } from "../../../context/DataProvider";

function DetailMitra() {
  const { id } = useParams();

  const history = useHistory();

  const [detail, loading, setDetail, refreshDetail] = GetDetail(id);

  const [listEquipment, loadingEq, refreshEquipment] = GetAllEquipment(id);

  const [listDriver, loadingDr, refreshDriver] = GetAllDriver(id);

  const [isSubmitting, setIsSubmitting] = useState(false);

  if (loading || loadingEq || loadingDr) {
    return <LoadingPage />;
  }

  return (
    <Page title="Detail Mitra | Bayu Sinergi">
      {/* Navigation */}
      <Stack direction="row" alignItems="center" sx={{ px: 3, mb: 2 }}>
        <IconButton
          size="small"
          onClick={() => {
            history.goBack();
          }}
        >
          <Icon icon={arrowBackFill} fontSize={24} />
        </IconButton>
        <Typography sx={{ ml: 1 }} variant="h5">
          Detail Mitra Management
        </Typography>
      </Stack>

      {isSubmitting ? <LoadingOrder /> : null}

      <Container maxWidth="xl">
        {/* Detail */}
        <DetailForm
          detail={detail}
          setDetail={setDetail}
          id={id}
          refresh={refreshDetail}
          setLoad={(v) => setIsSubmitting(v)}
        />

        {/* Gap */}
        <div style={{ height: 40 }} />

        {/* Equipment */}
        <EquipmentForm
          listEquipment={listEquipment}
          refresh={refreshEquipment}
        />

        {/* Gap */}
        <div style={{ height: 40 }} />

        {/* Driver */}
        <DriverForm listDriver={listDriver} refresh={refreshDriver} />
      </Container>
    </Page>
  );
}

const DetailForm = ({ detail, setDetail, id, refresh, setLoad }) => {
  const Label = styled("label")(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  }));

  const { user } = useData();

  const history = useHistory();

  const [dialog, setDialog] = useState({
    open: false,
  });

  const [listType, loadingType] = GetPartnerType();

  const [error, setError] = useState({
    name: "",
    code: "",
    no_license: "",
    partner: "",
    area: "",
    volume_target: "",
    contract_end: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [preview, setPreview] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    setDetail((f) => ({
      ...f,
      [name]: value,
    }));

    setError((f) => ({
      ...f,
      [name]: "",
    }));
  };

  const validate = () => {
    const newError = { ...error };

    const {
      name,
      code,
      no_license,
      partner,
      area,
      volume_target,
      contract_end,
    } = detail;

    if (!name) {
      newError.name = "Name cannot be empty";
    }

    if (!code) {
      newError.code = "Code cannot be empty";
    }

    if (!no_license) {
      newError.no_license = "SKP/License number cannot be empty";
    }

    if (partner === null) {
      newError.partner = "Partner type cannot be empty";
    }

    if (!area) {
      newError.area = "Area cannot be empty";
    }

    if (volume_target < 0) {
      newError.volume_target = "Volume target cannot be empty";
    }

    if (!contract_end) {
      newError.contract_end = "Contract end cannot be empty";
    }

    return newError;
  };

  const handleSubmit = async () => {
    const findError = validate();

    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setIsSubmitting(true);
      try {
        const { partner, volume_target, ...rest } = detail;

        const newData = {
          partner_id: partner?.id,
          volume_target: parseInt(volume_target),
          ...rest,
        };

        await mitra.update(id, newData);

        toast.success("Mitra Edited");

        refresh(true);
      } catch (e) {
        console.log(e);
      }
      setIsSubmitting(false);
    }
  };

  const handleDelete = async () => {
    try {
      setLoad(true);

      await mitra.delete(id);

      setDialog((d) => ({
        ...d,
        open: false,
      }));

      toast.success("Mitra deleted!");

      history.push("/mitra");

      setLoad(false);
    } catch (error) {
      console.log(error);
      setLoad(false);
    }
  };

  const link = process.env.REACT_APP_PROD === "YES" ? "https" : "http";

  return (
    <>
      <Card sx={{ py: 4, px: 3 }}>
        {/* Detail */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={3}
        >
          <Typography variant="subtitle1">Detail Mitra</Typography>

          {user?.role !== "bod" && (
            <Button
              size="small"
              variant="contained"
              color="error"
              startIcon={<Icon icon={trash2Fill} />}
              onClick={() => {
                setDialog((d) => ({
                  ...d,
                  open: true,
                }));
              }}
            >
              Delete
            </Button>
          )}
        </Stack>

        {/* Form */}
        <Grid container spacing={3}>
          {/* Section 1 */}
          <Grid item lg={6} md={2} sm={12}>
            <TextField
              name="name"
              label="Name"
              autoComplete="off"
              fullWidth
              value={detail?.name}
              onChange={handleChange}
              error={error.name ? true : false}
              helperText={error.name}
              disabled={isSubmitting || user.role === "bod"}
              sx={{ mb: 2 }}
            />
            <TextField
              name="code"
              label="Code"
              autoComplete="off"
              fullWidth
              value={detail?.code}
              onChange={handleChange}
              error={error.code ? true : false}
              helperText={error.code}
              disabled={isSubmitting || user.role === "bod"}
              sx={{ mb: 2 }}
            />
            <TextField
              name="no_license"
              label="SKP/License Number"
              autoComplete="off"
              fullWidth
              value={detail?.no_license}
              onChange={handleChange}
              error={error.no_license ? true : false}
              helperText={error.no_license}
              disabled={isSubmitting || user.role === "bod"}
              sx={{ mb: 2 }}
            />
            <Autocomplete
              fullWidth
              getOptionLabel={(option) => option.name}
              options={listType}
              loading={loadingType}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Partner Type"
                  error={error.partner ? true : false}
                  helperText={error.partner}
                  InputProps={{
                    ...params.InputProps,
                  }}
                  onChange={(e) => console.log(e.target)}
                />
              )}
              onChange={(e, v) => {
                setDetail((f) => ({
                  ...f,
                  partner: v,
                }));

                setError((err) => ({
                  ...err,
                  partner: "",
                }));
              }}
              value={detail?.partner}
              disabled={isSubmitting || user.role === "bod"}
              sx={{ mb: 2 }}
            />
            <TextField
              name="area"
              label="Area"
              autoComplete="off"
              fullWidth
              value={detail?.area}
              onChange={handleChange}
              error={error.area ? true : false}
              helperText={error.area}
              disabled={isSubmitting || user.role === "bod"}
              sx={{ mb: 2 }}
            />
            <TextField
              name="volume_target"
              label="Target Vol (Month)"
              autoComplete="off"
              value={detail?.volume_target}
              onChange={handleChange}
              error={error.volume_target ? true : false}
              helperText={error.volume_target}
              disabled={isSubmitting || user.role === "bod"}
              fullWidth
              InputProps={{
                inputComponent: NumberFormatThousand,
              }}
            />
          </Grid>

          {/* Section 2 */}
          <Grid item lg={6} md={2} sm={12}>
            <DatePicker
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Contract End"
                  fullWidth
                  error={error.contract_end ? true : false}
                  helperText={error.contract_end}
                  sx={{ mb: 2 }}
                />
              )}
              onChange={(v) => {
                setDetail((f) => ({
                  ...f,
                  contract_end: v,
                }));
                setError((e) => ({
                  ...e,
                  contract_end: "",
                }));
              }}
              value={detail?.contract_end}
              disabled={isSubmitting || user.role === "bod"}
            />
            <TextField
              name="address"
              label="Address"
              autoComplete="off"
              fullWidth
              value={detail?.address}
              onChange={handleChange}
              disabled={isSubmitting || user.role === "bod"}
              sx={{ mb: 2 }}
            />
            <TextField
              name="contact"
              label="Contact"
              autoComplete="off"
              fullWidth
              value={detail?.contact}
              onChange={handleChange}
              disabled={isSubmitting || user.role === "bod"}
              sx={{ mb: 2 }}
            />
            <TextField
              name="email"
              label="Email"
              autoComplete="off"
              fullWidth
              value={detail?.email}
              onChange={handleChange}
              disabled={isSubmitting || user.role === "bod"}
              sx={{ mb: 2 }}
            />
            <TextField
              name="note"
              label="Note"
              autoComplete="off"
              fullWidth
              value={detail?.note}
              onChange={handleChange}
              disabled={isSubmitting || user.role === "bod"}
              multiline
              minRows={1}
              maxRows={4}
              sx={{ mb: 2 }}
            />
            {detail?.partner?.id === 2 && (
              <>
                <Label htmlFor="raised-button-file">
                  <Button
                    // fullWidth
                    variant="contained"
                    color="inherit"
                    component="span"
                    disabled={isSubmitting || user.role === "bod"}
                    startIcon={
                      <Icon
                        icon="material-symbols:image"
                        width={24}
                        height={24}
                      />
                    }
                    sx={{ mb: 2 }}
                  >
                    Change Image
                  </Button>
                </Label>
                <Box
                  label="Logo"
                  component="img"
                  src={preview === "" ? `${link}://${detail?.image}` : preview}
                  alt={"Preview Image"}
                  sx={{ height: "225px", width: "auto" }}
                />
              </>
            )}
          </Grid>
        </Grid>

        {user?.role !== "bod" && (
          <Stack mt={3} alignItems="end">
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={isSubmitting}
            >
              Edit Mitra
            </Button>
          </Stack>
        )}

        <AlertDialog
          open={dialog.open}
          title="Delete Mitra ?"
          content="Are you sure want to delete this mitra?"
          handleClose={() =>
            setDialog((d) => ({
              ...d,
              open: false,
            }))
          }
          handleOk={handleDelete}
        />
      </Card>
      <input
        accept=".png, .jpg, .jpeg"
        style={{ display: "none" }}
        id="raised-button-file"
        type="file"
        onChange={async (e) => {
          let selectedFile = e.target.files;

          const data = await Promise.all(
            Object.values(selectedFile)?.map(async (item) => {
              if (item.size >= 2097152) {
                toast.error(`${item.name} size more than 2 mb!`);
              } else {
                const base64 = await getBase64(item);
                return {
                  filename: item.name,
                  url: base64,
                  isNew: true,
                  isRemove: false,
                };
              }
            })
          );

          const image = e.target.files[0];
          if (image) {
            setPreview(URL.createObjectURL(image));
          }

          setDetail((f) => ({
            ...f,
            image: { list_image: data },
            // file: e.target.files[0],
          }));
        }}
      />
    </>
  );
};

const EquipmentForm = ({ listEquipment, refresh }) => {
  const { user } = useData();

  const [dialog, setDialog] = useState({
    open: false,
    mode: "Add",
    data: {},
  });

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [no, setNo] = useState(0);

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setNo(no + 5);
    } else {
      setNo(no - 5);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TABLE_HEAD = [
    { id: "no", label: "No", alignRight: false, isSort: true },
    { id: "name", label: "Name", alignRight: false, isSort: true },
    {
      id: "description",
      label: "Description",
      alignRight: false,
      isSort: false,
    },
    {
      id: "capacity",
      label: "Capacity",
      alignRight: false,
      isSort: false,
    },
    { id: "qty", label: "Quantity", alignRight: false, isSort: false },
    {
      id: "",
    },
  ];

  const TABLE_HEAD_BOD = [
    { id: "no", label: "No", alignRight: false, isSort: true },
    { id: "name", label: "Name", alignRight: false, isSort: true },
    {
      id: "description",
      label: "Description",
      alignRight: false,
      isSort: false,
    },
    {
      id: "capacity",
      label: "Capacity",
      alignRight: false,
      isSort: false,
    },
    { id: "qty", label: "Quantity", alignRight: false, isSort: false },
  ];

  const equipmentList = applySortFilter(listEquipment);

  const isNotFound = equipmentList?.length === 0;

  return (
    <>
      <Card sx={{ py: 4, px: 3 }}>
        {/* Detail */}
        <Stack
          mb={3}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle1">List Equipment</Typography>
          {user?.role !== "bod" && (
            <Button
              variant="contained"
              onClick={(e) => {
                setDialog((d) => ({
                  ...d,
                  open: true,
                  mode: "Add",
                }));
              }}
            >
              New Equipment
            </Button>
          )}
        </Stack>

        <TableContainer>
          <Table>
            <ListHead
              headLabel={user?.role === "bod" ? TABLE_HEAD_BOD : TABLE_HEAD}
              colored
            />
            <TableBody>
              {equipmentList
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((item, index) => {
                  return (
                    <TableRow key={index} hover>
                      <TableCell>{index + 1 + no}</TableCell>
                      <TableCell>{item?.name ?? "-"}</TableCell>
                      <TableCell>{item?.description ?? "-"}</TableCell>
                      <TableCell>
                        {formatThousand(item?.capacity) ?? "-"}
                      </TableCell>
                      <TableCell>{formatThousand(item?.qty) ?? "-"}</TableCell>
                      {user?.role !== "bod" && (
                        <ActionTable
                          onEdit={() => {
                            setDialog({
                              open: true,
                              data: item,
                              mode: "Edit",
                            });
                          }}
                          onDelete={async () => {
                            try {
                              await equipment.delete(item.equipment_id);

                              refresh(true);

                              toast.success("Equipment deleted");
                            } catch (e) {
                              console.log(e);
                            }
                          }}
                        />
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery="" />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={equipmentList?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      {/* Dialog */}
      <AddEquipment
        dialog={dialog}
        load={() => {
          refresh(true);
        }}
        onClose={() => {
          setDialog({
            open: false,
            mode: "Add",
            data: {},
          });
        }}
      />
    </>
  );
};

const DriverForm = ({ listDriver, refresh }) => {
  const { user } = useData();

  const [dialog, setDialog] = useState({
    open: false,
    mode: "Add",
    data: {},
  });

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [no, setNo] = useState(0);

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setNo(no + 5);
    } else {
      setNo(no - 5);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const TABLE_HEAD = [
    { id: "no", label: "No", alignRight: false, isSort: true },
    { id: "name", label: "Name", alignRight: false, isSort: true },
    {
      id: "address",
      label: "Address",
      alignRight: false,
      isSort: false,
    },
    {
      id: "email",
      label: "Email",
      alignRight: false,
      isSort: false,
    },
    {
      id: "phone_number",
      label: "Phone Number",
      alignRight: false,
      isSort: false,
    },
    { id: "note", label: "Note", alignRight: false, isSort: false },
    {
      id: "",
    },
  ];

  const TABLE_HEAD_BOD = [
    { id: "no", label: "No", alignRight: false, isSort: true },
    { id: "name", label: "Name", alignRight: false, isSort: true },
    {
      id: "address",
      label: "Address",
      alignRight: false,
      isSort: false,
    },
    {
      id: "email",
      label: "Email",
      alignRight: false,
      isSort: false,
    },
    {
      id: "phone_number",
      label: "Phone Number",
      alignRight: false,
      isSort: false,
    },
    { id: "note", label: "Note", alignRight: false, isSort: false },
  ];

  const driverList = applySortFilter(listDriver);

  const isNotFound = driverList?.length === 0;

  return (
    <>
      <Card sx={{ py: 4, px: 3 }}>
        {/* Detail */}
        <Stack
          mb={3}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography variant="subtitle1">List Driver</Typography>
          {user?.role !== "bod" && (
            <Button
              variant="contained"
              onClick={(e) => {
                setDialog((d) => ({
                  ...d,
                  open: true,
                  mode: "Add",
                }));
              }}
            >
              New Driver
            </Button>
          )}
        </Stack>

        <TableContainer>
          <Table>
            <ListHead
              headLabel={user?.role === "bod" ? TABLE_HEAD_BOD : TABLE_HEAD}
              colored
            />
            <TableBody>
              {driverList
                ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                ?.map((item, index) => {
                  return (
                    <TableRow key={index} hover>
                      <TableCell>{index + 1 + no}</TableCell>
                      <TableCell>{item?.name ?? "-"}</TableCell>
                      <TableCell>{item?.address ?? "-"}</TableCell>
                      <TableCell>{item?.email ?? "-"}</TableCell>
                      <TableCell>{item?.contact ?? "-"}</TableCell>
                      <TableCell>{item?.note ?? "-"}</TableCell>
                      {user?.role !== "bod" && (
                        <ActionTable
                          onEdit={() => {
                            setDialog({
                              open: true,
                              data: item,
                              mode: "Edit",
                            });
                          }}
                          onDelete={async () => {
                            try {
                              await driver.delete(item.driver_id);

                              refresh(true);

                              toast.success("Driver deleted");
                            } catch (e) {
                              console.log(e);
                            }
                          }}
                        />
                      )}
                    </TableRow>
                  );
                })}
            </TableBody>
            {isNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery="" />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={driverList?.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>

      {/* Dialog */}
      <AddDriver
        dialog={dialog}
        load={() => {
          refresh(true);
        }}
        onClose={() => {
          setDialog({
            open: false,
            mode: "Add",
            data: {},
          });
        }}
      />
    </>
  );
};

export default DetailMitra;
