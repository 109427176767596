//
import { useState } from "react";
// material
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
//
import users from "../../../constant/users";
import customer from "../../../constant/customer";
import salesCustomer from "../../../constant/salesCustomer";
//
import { useData } from "../../../context/DataProvider";
import { toast } from "react-toastify";

//
import { GetBusinessType, GetCustomerType } from "./API";

function AddCustomer({ onClose, load, dialog: { open }, listSales }) {
  const { user } = useData();

  const [listType, loading] = GetCustomerType();

  const [listBusiness, loadingBusiness] = GetBusinessType();

  const [form, setForm] = useState({
    name: "",
    nib: "",
    custtype: null,
    businesstype: null,
    sales: null,
    address: "",
    address_billing: "",
    email: "",
    contact: "",
    description: "",
    note: "",
  });

  const [error, setError] = useState({
    name: "",
    nib: "",
    custtype: "",
    businesstype: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((f) => ({
      ...f,
      [name]: value,
    }));

    setError((f) => ({
      ...f,
      [name]: "",
    }));
  };

  const validate = () => {
    const newError = { ...error };
    const { name, nib, custtype, businesstype } = form;

    if (!name) {
      newError.name = "Name can't be empty";
    }

    if (!nib) {
      newError.nib = "NIB can't be empty";
    }

    if (custtype === null) {
      newError.custtype = "Customer Type can't be empty";
    }

    if (businesstype === null) {
      newError.businesstype = "Bidang Usaha can't be empty";
    }

    return newError;
  };

  const handleSubmit = async () => {
    const findError = validate();

    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setIsSubmitting(true);
      try {
        const { sales, custtype, businesstype, ...rest } = form;

        // Customer Section
        const res = await customer.create({
          ...rest,
          custtype_id: custtype?.id,
          businesstype_id: businesstype?.id,
        });

        await users.create({
          email: form.email,
          name: form.name,
          password: "OMS#2022",
          role_id: 1,
          customer_id: res?.data?.id,
        });

        const data = {
          customer_id: res.data.id,
          startdate: Date.now(),
          enddate: new Date().setFullYear(new Date().getFullYear() + 1),
        };

        if (user.role === "sales rep") {
          data.sales_id = user.id;
        }

        if (sales?.id) {
          data.sales_id = sales.id;
        }

        // Sales Customer
        await salesCustomer.create(data);

        load();

        setForm({
          name: "",
          nib: "",
          custtype: null,
          businesstype: null,
          sales: null,
          address: "",
          address_billing: "",
          email: "",
          contact: "",
          description: "",
          note: "",
        });

        setError({
          name: "",
          nib: "",
          custtype: "",
          businesstype: "",
        });

        onClose();

        toast.success("Customer Added");
      } catch (e) {
        console.log(e);
      }
      setIsSubmitting(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isSubmitting) {
          onClose();

          setForm({
            name: "",
            nib: "",
            custtype: null,
            businesstype: null,
            sales: null,
            address: "",
            address_billing: "",
            email: "",
            contact: "",
            description: "",
            note: "",
          });

          setError({
            name: "",
            nib: "",
            custtype: "",
            businesstype: "",
          });
        }
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Add New Customer</DialogTitle>
      <DialogContent dividers>
        <TextField
          name="name"
          label="Name"
          autoComplete="off"
          fullWidth
          sx={{ mb: 2 }}
          value={form.name}
          onChange={handleChange}
          error={error.name ? true : false}
          helperText={error.name}
          disabled={isSubmitting}
        />
        <TextField
          name="nib"
          label="NIB (Nomor Induk Berusaha)"
          autoComplete="off"
          fullWidth
          sx={{ mb: 2 }}
          value={form.nib}
          onChange={handleChange}
          error={error.nib ? true : false}
          helperText={error.nib}
          disabled={isSubmitting}
        />
        <Autocomplete
          fullWidth
          getOptionLabel={(option) => option.name}
          options={listType}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Customer Type"
              error={error.custtype ? true : false}
              helperText={error.custtype}
            />
          )}
          onChange={(e, v) => {
            setForm((f) => ({
              ...f,
              custtype: v,
            }));
            setError((e) => ({
              ...e,
              custtype: "",
            }));
          }}
          value={form.custtype}
          disabled={isSubmitting}
          sx={{ mb: 2 }}
        />
        <Autocomplete
          fullWidth
          getOptionLabel={(option) => option.name}
          options={listBusiness}
          loading={loadingBusiness}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Bidang Usaha"
              error={error.businesstype ? true : false}
              helperText={error.businesstype}
            />
          )}
          onChange={(e, v) => {
            setForm((f) => ({
              ...f,
              businesstype: v,
            }));
            setError((e) => ({
              ...e,
              businesstype: "",
            }));
          }}
          value={form.businesstype}
          disabled={isSubmitting}
          sx={{ mb: 2 }}
        />
        {user.role !== "sales rep" && (
          <Autocomplete
            fullWidth
            getOptionLabel={(option) => option.name}
            options={listSales}
            renderInput={(params) => <TextField {...params} label="Sales" />}
            onChange={(e, v) => {
              setForm((f) => ({
                ...f,
                sales: v,
              }));
            }}
            value={form.sales}
            disabled={isSubmitting}
            sx={{ mb: 2 }}
          />
        )}
        <TextField
          name="address"
          label="Company Address"
          autoComplete="off"
          multiline
          minRows={1}
          maxRows={2}
          value={form.address}
          onChange={handleChange}
          disabled={isSubmitting}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          name="address_billing"
          label="Billing Address"
          autoComplete="off"
          multiline
          minRows={1}
          maxRows={2}
          value={form.address_billing}
          onChange={handleChange}
          disabled={isSubmitting}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          name="email"
          label="Email"
          autoComplete="off"
          fullWidth
          sx={{ mb: 2 }}
          value={form.email}
          onChange={handleChange}
          helperText={error.email}
          disabled={isSubmitting}
        />
        <TextField
          name="contact"
          label="Contact"
          fullWidth
          sx={{ mb: 2 }}
          error={error.contact ? true : false}
          value={form.contact}
          onChange={handleChange}
          disabled={isSubmitting}
        />
        <TextField
          name="description"
          label="Description"
          fullWidth
          sx={{ mb: 2 }}
          error={error.description ? true : false}
          value={form.description}
          onChange={handleChange}
          disabled={isSubmitting}
        />
        <TextField
          name="note"
          label="Note"
          autoComplete="off"
          fullWidth
          sx={{ mb: 2 }}
          error={error.note ? true : false}
          value={form.note}
          onChange={handleChange}
          helperText={error.note}
          disabled={isSubmitting}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <LoadingButton
          onClick={handleSubmit}
          loading={isSubmitting}
          variant="contained"
        >
          Add Customer
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddCustomer;
