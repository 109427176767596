//
import { useEffect, useState } from "react";
//
import {
  Container,
  Grid,
  Stack,
  IconButton,
  Typography,
  Card,
  Divider,
  styled,
  Autocomplete,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  Button,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
//
import {
  Page,
  LoadingOrder,
  LoadingPage,
  NumberFormatRp,
  AlertDialog,
} from "../../../components";
//
import { useParams, useHistory } from "react-router-dom";
//
import { Icon } from "@iconify/react";
import arrowBackFill from "@iconify/icons-eva/arrow-back-fill";
import fileAddFill from "@iconify/icons-eva/file-add-fill";
import trash2Outline from "@iconify/icons-eva/trash-2-outline";
//
import { formatDate, getBase64, formatThousand } from "../../../utils";
//
import { GetPajak } from "../PurchaseOrder/API";
import { GetAll as GetPaymentMethod } from "../PaymnetMethod/API";
import { GetAll as GetPaymentTerm } from "../PaymentTerm/API";
import { GetAll as GetDepo } from "../Depo/API";
import {
  GetDetail,
  GetSoSummary,
  GetLoadingOrder,
  GetDeliveryOrder,
  GetReceiptOrder,
} from "./API";
//
import { toast } from "react-toastify";
//
import socket from "../../../constant/socket";
import salesOrder from "../../../constant/salesOrder";
import telegram from "../../../constant/telegram";
import { FM_ID, SA_ID, SM_ID, SR_ID } from "../../../config/telegram";
//
import { useData } from "../../../context/DataProvider";
//
import orderAttachment from "../../../constant/orderAttachment";
//
import OrderLine from "./OrderLine";
import ChatOrder from "./ChatOrder";
import ListLoadingOrder from "./ListLoadingOrder";
import CreateLoadingOrder from "./CreateLoadingOrder";
import ListDeliveryOrder from "./ListDeliveryOrder";
import ListReceiptOrder from "./ListReceiptOrder";

function DetailOrder() {
  const history = useHistory();

  const { user } = useData();

  const { id } = useParams();

  const [open, setOpen] = useState(false);

  const [detail, loading, setDetail, refreshDetail] = GetDetail(id);

  const [summary, loadingSummary, refreshSummary] = GetSoSummary(
    detail?.order_number
  );

  const [listLo, loadingLo, refreshLo] = GetLoadingOrder(detail?.order_number);

  const [listDo, loadingDo, refreshDo] = GetDeliveryOrder(detail?.order_number);

  const [listRo, loadingRo, refreshRo] = GetReceiptOrder(detail?.order_number);

  const [listProvince, loadingProvince] = GetPajak();

  const [listPaymentMethod, loadingPaymentMethod] = GetPaymentMethod();

  const [listPayment, loadingPayment] = GetPaymentTerm();

  const [listDepo, loadingDepo] = GetDepo();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [alertDialog, setAlertDialog] = useState(false);

  const [closeDialog, setCloseDialog] = useState(false);

  const [closeAttach, setCloseAttach] = useState({});

  const handleChecked = (event) => {
    setDetail((c) => ({
      ...c,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleVerification = async () => {
    setIsSubmitting(true);
    try {
      await salesOrder.verify({
        so_id: id,
        status: "on-verification",
        take_by: user.id,
      });

      refreshDetail(true);

      await telegram.sendMessage(
        SR_ID,
        `Sales Order ${detail?.order_number} for ${detail?.sales?.name} is on verification by ${user?.name}`
      );

      socket.emit("send-notif", {
        room: "sales-rep",
        data: {
          title: "On Verification",
          message: `${detail?.order_number} is on verification by ${user?.name}`,
        },
      });

      socket.emit("send-detail", {
        room: id,
        data: {
          status: "on-verification",
          take_by: user.id,
        },
      });

      toast.success("Sales Order On Verification");
    } catch (e) {
      console.log(e);
    }
    setIsSubmitting(false);
  };

  const handleVerified = async () => {
    setIsSubmitting(true);

    try {
      await salesOrder.verify({
        so_id: id,
        status:
          detail?.sales_verify_date === null
            ? "on-verification"
            : "on-approval",
        app_admin: user.id,
        app_admin_date: Date.now(),
      });

      await telegram.sendMessage(
        detail?.sales_verify_date === null ? SR_ID : SM_ID,
        detail?.sales_verify_date === null
          ? `Sales Order ${detail?.order_number} need verification from ${detail?.sales?.name}`
          : `Sales Order ${detail?.order_number} need approval`
      );

      socket.emit("send-notif", {
        room:
          detail?.sales_verify_date === null ? "sales-rep" : "sales-manager",
        data: {
          title:
            detail?.sales_verify_date === null
              ? "Need Verification"
              : "Need Approval",
          message:
            detail?.sales_verify_date === null
              ? `${detail?.order_number} need verification`
              : `${detail?.order_number} need approval`,
        },
      });

      socket.emit("send-detail", {
        room: id,
        data: {
          status:
            detail?.sales_verify_date === null
              ? "on-verification"
              : "on-approval",
          app_admin: user.id,
          admin: {
            name: user.name,
          },
          app_admin_date: Date.now(),
        },
      });

      refreshDetail(true);

      socket.emit("send-load");

      toast.success("Sales Order Verified");
    } catch (e) {
      console.log(e);
    }
    setIsSubmitting(false);
  };

  const handleVerifiedSales = async () => {
    setIsSubmitting(true);

    try {
      await salesOrder.verify({
        so_id: id,
        status: detail?.app_admin === null ? "on-verification" : "on-approval",
        sales_verify_date: Date.now(),
      });

      await telegram.sendMessage(
        detail?.app_admin === null ? SA_ID : SM_ID,
        detail?.app_admin === null
          ? `Sales Order ${detail?.order_number} need verification`
          : `Sales Order ${detail?.order_number} need approval`
      );

      socket.emit("send-notif", {
        room: detail?.app_admin === null ? "sales-admin" : "sales-manager",
        data: {
          title:
            detail?.app_admin === null ? "Need Verification" : "Need Approval",
          message:
            detail?.app_admin === null
              ? `${detail?.order_number} need verification`
              : `${detail?.order_number} need approval`,
        },
      });

      socket.emit("send-detail", {
        room: id,
        data: {
          status:
            detail?.app_admin === null ? "on-verification" : "on-approval",
          sales_verify_date: Date.now(),
        },
      });

      refreshDetail(true);

      socket.emit("send-load");

      toast.success("Sales Order Verified");
    } catch (e) {
      console.log(e);
    }
    setIsSubmitting(false);
  };

  const handleApproved = async () => {
    setIsSubmitting(true);
    try {
      let data = {};

      let data1 = {};

      let notif = {};

      if (user.role === "sales manager") {
        data = {
          app_sales: user.id,
          app_sales_date: Date.now(),
          status: "on-approval",
        };

        data1 = {
          status: "on-approval",
          app_sales: user.id,
          manager: {
            name: user.name,
          },
          app_sales_date: Date.now(),
        };

        await telegram.sendMessage(
          SA_ID,
          `Sales Order ${detail?.order_number} has been approved by ${user?.name} (Sales Manager)`
        );

        socket.emit("send-notif", {
          room: "sales-admin",
          data: {
            title: "On Approval",
            message: `${detail?.order_number} has been approved by sales manager`,
          },
        });

        await telegram.sendMessage(
          FM_ID,
          `Sales Order ${detail?.order_number} need approval`
        );

        notif = {
          room: "finance-manager",
          data: {
            title: "Need Approval",
            message: `${detail?.order_number} need approval`,
          },
        };
      } else {
        data = {
          app_finance: user.id,
          app_finance_date: Date.now(),
          status: "open-so",
        };

        data1 = {
          status: "open-so",
          app_finance: user.id,
          finance: {
            name: user.name,
          },
          app_finance_date: Date.now(),
        };

        await telegram.sendMessage(
          SA_ID,
          `Sales Order ${detail?.order_number} has been approved by ${user?.name} (Finance Manager)`
        );

        notif = {
          room: "sales-admin",
          data: {
            title: "Approved",
            message: `${detail?.order_number} has been approved by finance manager`,
          },
        };
      }

      await salesOrder.verify({
        so_id: id,
        ...data,
      });

      socket.emit("send-detail", {
        room: id,
        data: data1,
      });

      socket.emit("send-notif", notif);

      socket.emit("send-load");

      refreshDetail(true);

      toast.success("Sales Order Approved");
    } catch (e) {
      console.log(e);
    }
    setIsSubmitting(false);
  };

  const handleRevoke = async () => {
    setIsSubmitting(true);
    try {
      let data = {
        status: "revoke",
        app_admin: null,
        app_admin_date: null,
        app_sales: null,
        app_sales_date: null,
        app_finance: null,
        app_finance_date: null,
        sales_verify_date: null,
        take_by: null,
      };

      await salesOrder.revoke({
        so_id: id,
      });

      await telegram.sendMessage(
        SA_ID,
        `Sales Order ${detail?.order_number} has been revoked by ${user?.name} (${user?.role})`
      );

      socket.emit("send-notif", {
        room: "sales-admin",
        data: {
          title: "Order Revoke",
          message: `${detail?.order_number} revoke by ${user?.role}`,
        },
      });

      socket.emit("send-detail", {
        room: id,
        data: data,
      });

      socket.emit("send-load");

      refreshDetail(true);

      toast.success("Sales Order Revoked");
    } catch (e) {
      console.log(e);
    }
    setIsSubmitting(false);
  };

  const handleReject = async () => {
    setIsSubmitting(true);
    try {
      let data = {
        status: "rejected",
      };

      await salesOrder.reject({
        so_id: id,
      });

      await telegram.sendMessage(
        SA_ID,
        `Sales Order ${detail?.order_number} has been rejected by ${user?.name} (${user?.role})`
      );

      socket.emit("send-notif", {
        room: "sales-admin",
        data: {
          title: "Order Rejected",
          message: `${detail?.order_number} rejected by ${user?.role}`,
        },
      });

      socket.emit("send-detail", {
        room: id,
        data: data,
      });

      socket.emit("send-load");

      refreshDetail(true);

      toast.success("Sales Order Rejected");
    } catch (e) {
      console.log(e);
    }
    setIsSubmitting(false);
  };

  const handleClose = async () => {
    setIsSubmitting(true);
    try {
      await salesOrder.close({
        so_id: id,
        order_number: detail?.order_number,
        filename: closeAttach?.filename,
        url: closeAttach?.file,
        upload_by: user?.id,
      });

      await telegram.sendMessage(
        SA_ID,
        `Sales Order ${detail?.order_number} has been closed by ${user?.name} (${user?.role})`
      );

      socket.emit("send-detail", {
        room: id,
        data: {
          status: "completed",
        },
      });

      socket.emit("send-load");

      refreshDetail(true);

      setAlertDialog(false);

      setCloseDialog(false);

      setCloseAttach({});

      toast.success("Sales Order Closed");
    } catch (e) {
      console.log(e);
    }
    setIsSubmitting(false);
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);

      if (detail?.app_admin === null) {
        const {
          province,
          pbbkb,
          depo,
          method,
          payment_method,
          payment,
          payment_term,
          so_expiredate,
          ...rest
        } = detail;

        let expired = new Date(so_expiredate).setHours(23, 59, 59, 59);

        await salesOrder.update(id, {
          province: province?.id,
          pbbkb: pbbkb?.id,
          depo: depo?.id,
          payment_method: method?.id,
          payment_term: payment?.id,
          so_expiredate: expired,
          ...rest,
        });
      } else {
        const { note } = detail;

        await salesOrder.update(id, {
          note,
        });
      }

      const attach = detail?.attach
        .filter((item) => item.isNew === true)
        ?.map((item) => {
          return {
            order_number: detail?.order_number,
            filename: item.filename,
            url: item.file,
            upload_by: user.id,
          };
        });

      if (attach.length > 0) {
        await orderAttachment.create({ list_attach: attach });
      }

      refreshDetail(true);

      toast.success("Sales Order Edited!");

      setIsSubmitting(false);
    } catch (e) {
      console.log(e);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    socket.emit("subscribe-detail", id);

    return () => {
      socket.emit("leave-collaboration", id);
    };
  }, [id]);

  if (loading || loadingSummary || loadingLo || loadingDo || loadingRo) {
    return <LoadingPage />;
  }

  const attach = detail?.attach?.filter((item) => item.isRemove === false);

  const listPajak =
    listProvince?.find((item) => item?.id === detail?.province?.id)?.pajak ??
    [];

  return (
    <Page title="Detail Order | Bayu Sinergi">
      {isSubmitting ? <LoadingOrder /> : null}

      {/* Navigation */}
      <Stack direction="row" alignItems="center" sx={{ px: 3, mb: 2 }}>
        <IconButton
          size="small"
          onClick={() => {
            history.goBack();
          }}
        >
          <Icon icon={arrowBackFill} fontSize={24} />
        </IconButton>
        <Typography sx={{ ml: 1 }} variant="h5">
          Detail Sales Order
        </Typography>
      </Stack>

      <Container maxWidth="xl">
        <Card sx={{ py: 4, px: 3 }}>
          {/* Form */}
          <Grid container direction="row" spacing={3}>
            {/* Section 1 */}
            <Grid item lg={6}>
              {/* SO Number */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
              >
                <Typography style={{ width: 150 }}>SO Number</Typography>
                <CustomTypography>
                  {detail?.order_number ?? "-"}
                </CustomTypography>
              </Stack>

              {/* PO Date */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>PO Date</Typography>
                <CustomTypography>
                  {formatDate(detail?.order_date) ?? "-"}
                </CustomTypography>
              </Stack>

              {/* PO Expire Date */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>PO Expire Date</Typography>
                {(user.role === "sales admin" || user.role === "sales rep") &&
                detail?.app_admin === null ? (
                  <DatePicker
                    onChange={(v) => {
                      setDetail((f) => ({
                        ...f,
                        po_expiredate: v,
                      }));
                      // setError((e) => ({
                      //   ...e,
                      //   po_expiredate: "",
                      // }));
                    }}
                    value={detail.po_expiredate}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        size="small"
                        // error={error.po_expiredate ? true : false}
                        // helperText={error.po_expiredate}
                        fullWidth
                      />
                    )}
                  />
                ) : (
                  <CustomTypography>
                    {formatDate(detail?.po_expiredate) ?? "-"}
                  </CustomTypography>
                )}
              </Stack>

              {/* Customer */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Customer</Typography>
                <CustomTypography>
                  {detail?.customer?.name ?? "-"}
                </CustomTypography>
              </Stack>

              {/* Cust Type */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Cust Type</Typography>
                <CustomTypography>
                  {detail?.customer?.custtype?.name ?? "-"}
                </CustomTypography>
              </Stack>

              {/* Business Fields */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Business Fields</Typography>
                <CustomTypography>
                  {detail?.customer?.businesstype?.name ?? "-"}
                </CustomTypography>
              </Stack>

              {/* Ship To Address */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Ship To Address</Typography>
                {(user.role === "sales admin" || user.role === "sales rep") &&
                detail?.app_admin === null ? (
                  <CustomTextField
                    value={detail.address}
                    onChange={(e) => {
                      setDetail((f) => ({
                        ...f,
                        address: e.target.value,
                      }));
                    }}
                    // error={error.address ? true : false}
                    // helperText={error.address}
                    multiline
                    minRows={6}
                    maxRows={6}
                    size="small"
                  />
                ) : (
                  <CustomTypographyAddress>
                    {detail?.address ?? "-"}
                  </CustomTypographyAddress>
                )}
              </Stack>

              {/* Province */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Province</Typography>
                {(user.role === "sales admin" || user.role === "sales rep") &&
                detail?.app_admin === null ? (
                  <CustomAutoComplete
                    getOptionLabel={(option) => option.name}
                    options={listProvince?.map((item) => {
                      return {
                        id: item?.id,
                        name: item?.name,
                      };
                    })}
                    onChange={(e, v) => {
                      setDetail((f) => ({
                        ...f,
                        province: v,
                      }));

                      // setError((err) => ({
                      //   ...err,
                      //   province: "",
                      // }));
                    }}
                    value={detail.province}
                    loading={loadingProvince}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose Province"
                        size="small"
                        // error={error.province ? true : false}
                        // helperText={error.province}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    fullWidth
                  />
                ) : (
                  <CustomTypography>
                    {detail?.province?.name ?? "-"}
                  </CustomTypography>
                )}
              </Stack>

              {/* PBBKB */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>PBBKB</Typography>
                {(user.role === "sales admin" || user.role === "sales rep") &&
                detail?.app_admin === null ? (
                  <CustomAutoComplete
                    getOptionLabel={(option) => option.name}
                    options={listPajak?.map((item) => {
                      return {
                        id: item?.pajak_id,
                        name: `${item?.value * 100} %`,
                        value: item?.value,
                      };
                    })}
                    onChange={(e, v) => {
                      setDetail((f) => ({
                        ...f,
                        pbbkb: v,
                      }));

                      // setError((err) => ({
                      //   ...err,
                      //   pbbkb: "",
                      // }));
                    }}
                    value={detail.pbbkb}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose PBBKB"
                        size="small"
                        // error={error.pbbkb ? true : false}
                        // helperText={error.pbbkb}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    fullWidth
                    disabled={detail.province === null}
                  />
                ) : (
                  <CustomTypography>
                    {detail?.pbbkb?.value * 100 ?? 0} %
                  </CustomTypography>
                )}
              </Stack>

              {/* Payment Method */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Payment Method</Typography>
                {(user.role === "sales admin" || user.role === "sales rep") &&
                detail?.app_admin === null ? (
                  <CustomAutoComplete
                    getOptionLabel={(option) => option.name}
                    options={listPaymentMethod}
                    loading={loadingPaymentMethod}
                    onChange={(e, v) => {
                      setDetail((f) => ({
                        ...f,
                        method: v,
                      }));

                      // setError((err) => ({
                      //   ...err,
                      //   method: "",
                      // }));
                    }}
                    value={detail.method}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose Payment Method"
                        size="small"
                        // error={error.method ? true : false}
                        // helperText={error.method}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    fullWidth
                  />
                ) : (
                  <CustomTypography>
                    {detail?.method?.name ?? "-"}
                  </CustomTypography>
                )}
              </Stack>

              {/* Payment Term */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Payment Term</Typography>
                {(user.role === "sales admin" || user.role === "sales rep") &&
                detail?.app_admin === null ? (
                  <CustomAutoComplete
                    getOptionLabel={(option) => option.name}
                    options={listPayment}
                    loading={loadingPayment}
                    onChange={(e, v) => {
                      setDetail((f) => ({
                        ...f,
                        payment: v,
                      }));

                      // setError((err) => ({
                      //   ...err,
                      //   payment: "",
                      // }));
                    }}
                    value={detail.payment}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose Payment Term"
                        size="small"
                        // error={error.payment ? true : false}
                        // helperText={error.payment}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    fullWidth
                  />
                ) : (
                  <CustomTypography>
                    {detail?.payment?.name ?? "-"}
                  </CustomTypography>
                )}
              </Stack>

              {/* Ops Type */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Ops Type</Typography>
                {(user.role === "sales admin" || user.role === "sales rep") &&
                detail?.app_admin === null ? (
                  <CustomTextField
                    size="small"
                    select
                    value={detail.ops_type}
                    onChange={(e) => {
                      setDetail((f) => ({
                        ...f,
                        ops_type: e.target.value,
                      }));
                      // setError((e) => ({
                      //   ...e,
                      //   ops_type: "",
                      // }));
                    }}
                    // error={error.ops_type ? true : false}
                    // helperText={error.ops_type}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="Truck">Truck</MenuItem>
                    <MenuItem value="Vessel">Vessel</MenuItem>
                  </CustomTextField>
                ) : (
                  <CustomTypography>{detail?.ops_type ?? "-"}</CustomTypography>
                )}
              </Stack>
            </Grid>

            {/* Section 2 */}
            <Grid item lg={6}>
              {/* PO Number */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
              >
                <Typography style={{ width: 150 }}>PO Number</Typography>
                <CustomTypography>{detail?.po_number ?? "-"}</CustomTypography>
              </Stack>

              {/* SO Expire Date */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>SO Expire Date</Typography>
                {(user.role === "sales admin" || user.role === "sales rep") &&
                detail?.app_admin === null ? (
                  <DatePicker
                    onChange={(v) => {
                      setDetail((f) => ({
                        ...f,
                        so_expiredate: v,
                      }));
                      // setError((e) => ({
                      //   ...e,
                      //   so_expiredate: "",
                      // }));
                    }}
                    value={detail?.so_expiredate}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        size="small"
                        // error={error.so_expiredate ? true : false}
                        // helperText={error.so_expiredate}
                        fullWidth
                      />
                    )}
                  />
                ) : (
                  <CustomTypography>
                    {formatDate(detail?.so_expiredate) ?? "-"}
                  </CustomTypography>
                )}
              </Stack>

              {/* Sales */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Sales</Typography>
                <CustomTypography>
                  {detail?.sales?.name ?? "-"}
                </CustomTypography>
              </Stack>

              {/* SKP ID */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>SKP ID</Typography>
                {(user.role === "sales admin" || user.role === "sales rep") &&
                detail?.app_admin === null ? (
                  <CustomTextField
                    size="small"
                    value={detail.skp_id}
                    onChange={(e) => {
                      setDetail((f) => ({
                        ...f,
                        skp_id: e.target.value,
                      }));
                      // setError((e) => ({
                      //   ...e,
                      //   skp_id: "",
                      // }));
                    }}
                    // error={error.skp_id ? true : false}
                    // helperText={error.skp_id}
                  />
                ) : (
                  <CustomTypography>{detail?.skp_id ?? "-"}</CustomTypography>
                )}
              </Stack>

              {/* Type Of Sales */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Type Of Sales</Typography>
                {(user.role === "sales admin" || user.role === "sales rep") &&
                detail?.app_admin === null ? (
                  <CustomTextField
                    size="small"
                    select
                    value={detail.type_of_sales}
                    onChange={(e) => {
                      setDetail((f) => ({
                        ...f,
                        type_of_sales: e.target.value,
                      }));
                      // setError((e) => ({
                      //   ...e,
                      //   type_of_sales: "",
                      // }));
                    }}
                    // error={error.skp_id ? true : false}
                    // helperText={error.skp_id}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="FOB">FOB</MenuItem>
                    <MenuItem value="CIF">On Site</MenuItem>
                  </CustomTextField>
                ) : (
                  <CustomTypography>
                    {detail?.type_of_sales === "CIF"
                      ? "On Site"
                      : detail?.type_of_sales ?? "-"}
                  </CustomTypography>
                )}
              </Stack>

              {/* Contact */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Contact</Typography>
                {(user.role === "sales admin" || user.role === "sales rep") &&
                detail?.app_admin === null ? (
                  <CustomTextField
                    size="small"
                    value={detail.contact}
                    onChange={(e) => {
                      setDetail((f) => ({
                        ...f,
                        contact: e.target.value,
                      }));
                    }}
                  />
                ) : (
                  <CustomTypography>{detail?.contact ?? "-"}</CustomTypography>
                )}
              </Stack>

              {/* Telp/Email */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Telp/Email</Typography>
                {(user.role === "sales admin" || user.role === "sales rep") &&
                detail?.app_admin === null ? (
                  <CustomTextField
                    size="small"
                    value={detail.telp_email}
                    onChange={(e) => {
                      setDetail((f) => ({
                        ...f,
                        telp_email: e.target.value,
                      }));
                    }}
                  />
                ) : (
                  <CustomTypography>
                    {detail?.telp_email ?? "-"}
                  </CustomTypography>
                )}
              </Stack>

              {/* NPWP */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>NPWP</Typography>
                {(user.role === "sales admin" || user.role === "sales rep") &&
                detail?.app_admin === null ? (
                  <CustomTextField
                    size="small"
                    value={detail.npwp}
                    onChange={(e) => {
                      setDetail((f) => ({
                        ...f,
                        npwp: e.target.value,
                      }));
                    }}
                  />
                ) : (
                  <CustomTypography>{detail?.npwp ?? "-"}</CustomTypography>
                )}
              </Stack>

              {/* Expected Date */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Expected Date</Typography>
                {(user.role === "sales admin" || user.role === "sales rep") &&
                detail?.app_admin === null ? (
                  <DatePicker
                    onChange={(v) => {
                      setDetail((f) => ({
                        ...f,
                        expected_date: v,
                      }));
                      // setError((e) => ({
                      //   ...e,
                      //   expected_date: "",
                      // }));
                    }}
                    value={detail.expected_date}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        size="small"
                        // error={error.expected_date ? true : false}
                        // helperText={error.expected_date}
                        fullWidth
                      />
                    )}
                  />
                ) : (
                  <CustomTypography>
                    {formatDate(detail?.expected_date) ?? "-"}
                  </CustomTypography>
                )}
              </Stack>

              {/* No FO SKP */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>NO FO SKP</Typography>
                {(user.role === "sales admin" || user.role === "sales rep") &&
                detail?.app_admin === null ? (
                  <CustomTextField
                    size="small"
                    onChange={(e) => {
                      setDetail((f) => ({
                        ...f,
                        no_fo_skp: e.target.value,
                      }));
                    }}
                    value={detail?.no_fo_skp}
                  />
                ) : (
                  <CustomTypography>
                    {detail?.no_fo_skp ?? "-"}
                  </CustomTypography>
                )}
              </Stack>

              {/* Harga Tebus SKP */}
              {detail?.price_skp > 0 && (
                <Stack
                  direction="row"
                  alignItems="center"
                  divider={<Divider orientation="vertical" flexItem />}
                  spacing={3}
                  mt={2}
                >
                  <Typography style={{ width: 150 }}>
                    Harga Tebus SKP
                  </Typography>
                  {(user.role === "sales admin" || user.role === "sales rep") &&
                  detail?.app_admin === null ? (
                    <CustomTextField
                      size="small"
                      onChange={(e) => {
                        setDetail((f) => ({
                          ...f,
                          price_skp: e.target.value,
                        }));
                      }}
                      value={detail?.price_skp}
                    />
                  ) : (
                    <CustomTypography>
                      Rp. {formatThousand(detail?.price_skp) ?? "-"}
                    </CustomTypography>
                  )}
                </Stack>
              )}

              {/* OAT */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>OAT</Typography>
                {(user.role === "sales admin" || user.role === "sales rep") &&
                detail?.app_admin === null ? (
                  <CustomTextField
                    name="oat"
                    autoComplete="off"
                    multiline
                    size="small"
                    value={detail?.oat}
                    onChange={(e) => {
                      setDetail((f) => ({
                        ...f,
                        oat: e.target.value,
                      }));
                      // setError((e) => ({
                      //   ...e,
                      //   oat: "",
                      // }));
                    }}
                    // error={error.oat ? true : false}
                    // helperText={error.oat}
                    InputProps={{
                      inputComponent: NumberFormatRp,
                    }}
                  />
                ) : (
                  <CustomTypography>
                    Rp. {formatThousand(detail?.oat) ?? "-"}
                  </CustomTypography>
                )}
              </Stack>

              {/* Fee Agent */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Fee Agent</Typography>
                {(user.role === "sales admin" || user.role === "sales rep") &&
                detail?.app_admin === null ? (
                  <CustomTextField
                    name="fee_agent"
                    autoComplete="off"
                    multiline
                    size="small"
                    value={detail?.fee_agent}
                    onChange={(e) => {
                      setDetail((f) => ({
                        ...f,
                        fee_agent: e.target.value,
                      }));
                      // setError((e) => ({
                      //   ...e,
                      //   fee_agent: "",
                      // }));
                    }}
                    // error={error.fee_agent ? true : false}
                    // helperText={error.fee_agent}
                    InputProps={{
                      inputComponent: NumberFormatRp,
                    }}
                  />
                ) : (
                  <CustomTypography>
                    Rp. {formatThousand(detail?.fee_agent) ?? "-"}
                  </CustomTypography>
                )}
              </Stack>

              {/* Supply Point */}
              <Stack
                direction="row"
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                spacing={3}
                mt={2}
              >
                <Typography style={{ width: 150 }}>Supply Point</Typography>
                {(user.role === "sales admin" || user.role === "sales rep") &&
                detail?.app_admin === null ? (
                  <CustomAutoComplete
                    getOptionLabel={(option) => option.name}
                    options={listDepo}
                    onChange={(e, v) => {
                      setDetail((f) => ({
                        ...f,
                        depo: v,
                      }));

                      // setError((err) => ({
                      //   ...err,
                      //   depo: "",
                      // }));
                    }}
                    value={detail.depo}
                    loading={loadingDepo}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Choose Supply Point"
                        size="small"
                        // error={error.depo ? true : false}
                        // helperText={error.depo}
                        InputProps={{
                          ...params.InputProps,
                        }}
                      />
                    )}
                    fullWidth
                  />
                ) : (
                  <CustomTypography>
                    {detail?.depo?.name ?? "-"}
                  </CustomTypography>
                )}
              </Stack>
            </Grid>
          </Grid>

          {/* Completeness */}
          {user.role === "sales admin" && (
            <Wrapper>
              {/* Title */}
              <Typography mb={2}>Completeness</Typography>

              {/* Controller */}
              <FormControl fullWidth>
                {/* Grid */}
                <Grid container alignItems="center">
                  {/* Section 1 */}
                  <Grid item lg={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={detail?.sign_sts}
                          onChange={handleChecked}
                          name="sign_sts"
                          disabled={detail?.app_admin !== null}
                        />
                      }
                      label="Sign/Stamp"
                    />
                  </Grid>

                  {/* Section 2 */}
                  <Grid item lg={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={detail?.npwp_sts}
                          onChange={handleChecked}
                          name="npwp_sts"
                          disabled={detail?.app_admin !== null}
                        />
                      }
                      label="NPWP/SPPKP"
                    />
                  </Grid>

                  {/* Section 3 */}
                  <Grid item lg={4}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={detail?.attach_sts}
                          onChange={handleChecked}
                          name="attach_sts"
                          disabled={detail?.app_admin !== null}
                        />
                      }
                      label="Attachment PO (PDF)"
                    />
                  </Grid>
                </Grid>
              </FormControl>
            </Wrapper>
          )}

          {/* Order Line */}
          <OrderLine
            pbbkb={(detail?.pbbkb?.value ?? 0) * 100}
            orderLine={detail?.so_detail}
            setOrderLine={setDetail}
            hide={
              user?.role !== "finance manager" && user?.role !== "sales manager"
                ? true
                : false
            }
            oat={detail?.oat ?? 0}
            fee_agent={detail?.fee_agent ?? 0}
            disabled={detail?.app_admin !== null}
          />

          {/* Note */}
          <TextField
            label="Note"
            onChange={(e) => {
              setDetail((f) => ({
                ...f,
                note: e.target.value,
              }));

              // setError((e) => ({
              //   ...e,
              //   note: "",
              // }));
            }}
            value={detail?.note}
            // error={error.note ? true : false}
            // helperText={error.note}
            multiline
            minRows={4}
            maxRows={4}
            fullWidth
            sx={{ mt: 3 }}
            disabled={user.role === "customer"}
          />

          {/* Attach File */}
          {attach?.length > 0 ? (
            <Stack mt={6}>
              <Typography mb={3}>Attach List</Typography>
              {attach?.map((item, index) => {
                const link =
                  process.env.REACT_APP_PROD ||
                  process.env.REACT_APP_PREPROD === "YES"
                    ? "https"
                    : "http";

                return (
                  <Stack
                    key={index}
                    spacing={2}
                    direction="row"
                    alignItems="center"
                  >
                    <Typography
                      component="a"
                      href={`${link}://${item?.url}`}
                      target="_blank"
                      download={item?.filename}
                      noWrap
                      sx={{
                        cursor: "pointer",
                        color: "text.primary",
                        textDecoration: "none",
                        textOverflow: "elipsis",
                        maxWidth: 200,
                      }}
                    >
                      {item?.filename}
                    </Typography>
                    {user?.role !== "customer" ? (
                      <IconButton
                        disabled={
                          detail?.app_finance !== null ||
                          detail?.status === "rejected"
                        }
                        onClick={() => {
                          let data = detail?.attach[index];

                          data.isRemove = true;

                          const newVal = [...detail?.attach];

                          setDetail((d) => ({
                            ...d,
                            attach: newVal,
                          }));
                        }}
                      >
                        <Icon
                          icon={trash2Outline}
                          width={24}
                          height={24}
                          color={
                            detail?.app_finance !== null ? "inherit" : "red"
                          }
                        />
                      </IconButton>
                    ) : null}
                  </Stack>
                );
              })}
            </Stack>
          ) : null}

          {/* Approval */}
          <Grid mt={2} spacing={2} container>
            {/* Administrator */}
            <Grid item lg={4} md={4} xs={12}>
              <ApprovalBox
                title="Verified By"
                name={detail?.admin?.name ?? "-"}
                role="Administrator"
                date={detail?.app_admin_date}
              />
            </Grid>

            {/* Sales Manager */}
            <Grid item lg={4} md={4} xs={12}>
              <ApprovalBox
                title="Approved By"
                name={detail?.manager?.name ?? "-"}
                role="Sales Manager"
                date={detail?.app_sales_date}
              />
            </Grid>

            {/* Finance Manager */}
            <Grid item lg={4} md={4} xs={12}>
              <ApprovalBox
                title="Approved By"
                name={detail?.finance?.name ?? "-"}
                role="Finance Manager"
                date={detail?.app_finance_date}
              />
            </Grid>
          </Grid>

          {/* Button */}
          <CustomStack
            mt={3}
            alignItems="center"
            justifyContent="space-between"
          >
            {user?.role !== "customer" ? (
              <Label htmlFor="raised-button-file">
                <Button
                  fullWidth
                  variant="contained"
                  color="inherit"
                  component="span"
                  startIcon={<Icon icon={fileAddFill} width={24} height={24} />}
                >
                  Attach Files
                </Button>
              </Label>
            ) : null}

            <WrapperBtn>
              {/* Verify */}
              {user.role === "sales admin" &&
              (detail?.status === "pending" ||
                detail?.status === "on-verification" ||
                detail?.status === "revoke") &&
              detail?.take_by === null ? (
                <CustomButton
                  variant="contained"
                  onClick={handleVerification}
                  sx={{ mr: 1.5 }}
                >
                  Verification
                </CustomButton>
              ) : user.role === "sales admin" &&
                (detail?.status === "on-verification" ||
                  detail?.status === "revoke") &&
                detail?.app_admin === null &&
                detail?.take_by === user.id ? (
                <CustomButton
                  variant="contained"
                  onClick={handleVerified}
                  disabled={
                    !detail?.skp_id ||
                    !detail?.payment_term ||
                    !detail?.type_of_sales
                  }
                  sx={{ mr: 1.5 }}
                >
                  Verified
                </CustomButton>
              ) : (
                user.role === "sales rep" &&
                (detail?.status === "on-verification" ||
                  detail?.status === "pending") &&
                ((detail?.app_admin === null &&
                  detail?.sales_verify_date === null &&
                  detail?.created_by === user?.id) ||
                  (detail?.app_admin !== null &&
                    detail?.sales_verify_date === null &&
                    detail?.created_by !== user?.id)) && (
                  <CustomButton
                    variant="contained"
                    onClick={handleVerifiedSales}
                    sx={{ mr: 1.5 }}
                  >
                    Verified
                  </CustomButton>
                )
              )}

              {/* Approve */}
              {((user.role === "sales manager" &&
                detail?.status === "on-approval" &&
                detail?.app_sales === null) ||
                (user.role === "finance manager" &&
                  detail?.status === "on-approval" &&
                  detail?.app_sales !== null &&
                  detail?.app_finance === null)) && (
                <CustomButton
                  sx={{ mr: 1.5 }}
                  variant="contained"
                  onClick={handleApproved}
                >
                  Approve
                </CustomButton>
              )}

              {/* Revoke */}
              {((user.role === "sales manager" &&
                detail?.status === "on-approval" &&
                detail?.app_sales === null) ||
                (user.role === "finance manager" &&
                  detail?.status === "on-approval" &&
                  detail?.app_sales !== null &&
                  detail?.app_finance === null)) && (
                <CustomButton
                  sx={{ mr: 1.5 }}
                  variant="contained"
                  color="inherit"
                  onClick={handleRevoke}
                >
                  Revoke
                </CustomButton>
              )}

              {/* Reject */}
              {((user.role === "sales manager" &&
                detail?.status === "on-approval" &&
                detail?.app_sales === null) ||
                (user.role === "finance manager" &&
                  detail?.status === "on-approval" &&
                  detail?.app_sales !== null &&
                  detail?.app_finance === null)) && (
                <CustomButton
                  sx={{ mr: 1.5 }}
                  variant="contained"
                  color="error"
                  onClick={handleReject}
                >
                  Reject
                </CustomButton>
              )}

              {/* Update */}

              {(user.role === "sales admin" ||
                user.role === "sales rep" ||
                user.role === "mitra") &&
                detail?.app_finance !== null && (
                  <CustomButton
                    variant="contained"
                    disabled={
                      summary?.volume_end === 0 || summary?.status === "expired"
                    }
                    onClick={() => {
                      setOpen(true);
                    }}
                    sx={{ mr: 1.5 }}
                  >
                    Request Loading Order
                  </CustomButton>
                )}

              {(detail?.app_admin === null || detail?.app_admin !== null) &&
                (user.role === "sales admin" || user.role === "mitra") && (
                  <CustomButton variant="contained" onClick={handleSubmit}>
                    Update Sales Order
                  </CustomButton>
                )}

              {/* Close */}
              {detail?.app_finance !== null &&
                (user.role === "sales manager" ||
                  user.role === "finance manager") && (
                  <CustomButton
                    variant="contained"
                    disabled={
                      detail?.status === "completed" ||
                      detail?.status === "expired"
                    }
                    onClick={() => {
                      setCloseDialog(true);
                    }}
                  >
                    Close Order
                  </CustomButton>
                )}
            </WrapperBtn>
          </CustomStack>
        </Card>

        {/* Gap */}
        <div style={{ height: 40 }} />

        {/* Loading Order */}
        <ListLoadingOrder
          expired_so={detail?.status === "expired" ? true : false}
          expired_so_date={detail?.so_expiredate}
          oat={detail?.oat ?? 0}
          listItem={listLo}
          isSubmitting={isSubmitting}
          setIsSubmitting={(v) => setIsSubmitting(v)}
          refresh={() => {
            refreshSummary(true);
            refreshLo(true);
          }}
          refreshDo={() => {
            refreshLo(true);
            refreshDo(true);
          }}
        />

        {/* Gap */}
        <div style={{ height: 40 }} />

        {/* Delivery Order */}
        <ListDeliveryOrder
          listItem={listDo}
          refreshDo={() => {
            refreshLo(true);
            refreshDo(true);
            refreshRo(true);
          }}
        />

        {/* Gap */}
        <div style={{ height: 40 }} />

        {/* Receipt Order */}
        <ListReceiptOrder listItem={listRo} />

        {/* Chat Order */}
        {user.role !== "customer" ? (
          <ChatOrder
            id={detail?.order_number}
            page_id={id}
            disabled={
              detail?.status === "completed" || detail?.status === "rejected"
            }
            // load={load}
            // refresh={() => {
            //   setLoad(true);
            // }}
          />
        ) : null}
      </Container>

      {/* Dialog */}
      <CreateLoadingOrder
        open={open}
        data={detail}
        mode="Request"
        orderLine={detail?.so_detail}
        remainingQty={summary?.volume_end}
        refresh={() => {
          refreshDetail(true);
          refreshSummary(true);
          refreshLo(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
      />

      {/* Input */}
      <input
        accept=".pdf, .xlsx, .doc, .docx"
        style={{ display: "none" }}
        id="raised-button-file"
        multiple
        type="file"
        onChange={async (e) => {
          let selectedFile = e.target.files;

          const data = await Promise.all(
            Object.values(selectedFile)?.map(async (item) => {
              if (item.size >= 2097152) {
                toast.error(`${item.name} size more than 2 mb!`);
              } else {
                const base64 = await getBase64(item);
                return {
                  filename: item.name,
                  file: base64,
                  isNew: true,
                  isRemove: false,
                };
              }
            })
          );

          const newVal = [
            ...detail?.attach,
            ...data.filter((item) => item !== undefined),
          ];

          setDetail((d) => ({
            ...d,
            attach: newVal,
          }));
        }}
      />

      <CloseDialog
        open={closeDialog}
        data={closeAttach}
        setCloseAttach={(v) => setCloseAttach(v)}
        onClose={() => setCloseDialog(false)}
        onSubmit={() => {
          setAlertDialog(true);
        }}
      />

      <AlertDialog
        open={alertDialog}
        title="Close Order Transaction"
        content="Are you sure want to close this order transaction ?"
        handleClose={() => setAlertDialog(false)}
        handleOk={handleClose}
      />
    </Page>
  );
}

const CloseDialog = ({ open, data, setCloseAttach, onClose, onSubmit }) => {
  return (
    <Dialog open={open} fullWidth maxWidth="xs">
      <DialogTitle>Close Order</DialogTitle>
      <Divider />
      <DialogContent>
        <Typography>Upload file PO Revision for close this order</Typography>

        <div style={{ margin: "16px 0px" }}></div>

        <div
          style={{
            display: "flex",
            border: "2px dashed #E8E8E8",
            borderRadius: 6,
            padding: "34px 12px",
            alignItems: "center",
            gap: 16,
          }}
        >
          <Label htmlFor="close-file">
            <Button
              variant="contained"
              component="span"
              color="inherit"
              size="small"
            >
              Upload File
            </Button>
          </Label>
          {data?.filename && (
            <Stack spacing={2} direction="row" alignItems="center">
              <Typography
                noWrap
                sx={{
                  cursor: "pointer",
                  color: "text.primary",
                  textDecoration: "none",
                  textOverflow: "elipsis",
                  maxWidth: 200,
                  fontSize: 14,
                }}
              >
                {data?.filename}
              </Typography>
              <IconButton>
                <Icon
                  icon={trash2Outline}
                  width={24}
                  height={24}
                  color="red"
                  onClick={() => setCloseAttach({})}
                />
              </IconButton>
            </Stack>
          )}
        </div>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button variant="contained" color="inherit" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={onSubmit}>
          Submit Close Order
        </Button>
      </DialogActions>

      {/* Input Close */}
      <input
        accept=".pdf, .xlsx, .doc, .docx"
        style={{ display: "none" }}
        id="close-file"
        type="file"
        onChange={async (e) => {
          let selectedFile = e.target.files[0];

          if (selectedFile?.size >= 2097152) {
            toast.error(`${selectedFile.name} size more than 2 mb!`);
          } else {
            const base64 = await getBase64(selectedFile);

            const data = {
              filename: selectedFile.name,
              file: base64,
            };

            setCloseAttach(data);
          }
        }}
      />
    </Dialog>
  );
};

const ApprovalBox = ({ title, name, date, role }) => {
  return (
    <Container
      style={{
        width: "100%",
        height: 200,
        border: "2px solid #EAEAEA",
        borderRadius: 6,
        padding: 16,
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Typography>{title}</Typography>
      <div>
        <Typography fontWeight="bold">{name}</Typography>
        <Typography>{role}</Typography>
        {date && <Typography>{formatDate(date)}</Typography>}
      </div>
    </Container>
  );
};

const CustomStack = styled(Stack)(({ theme }) => ({
  flexDirection: "row",
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
  },
}));

const Label = styled("label")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const CustomButton = styled(Button)(({ theme }) => ({
  marginTop: 0,
  [theme.breakpoints.down("md")]: {
    marginTop: 16,
    marginRight: 0,
    width: "100%",
  },
}));

const WrapperBtn = styled("div")(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const CustomAutoComplete = styled(Autocomplete)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  lineHeight: "40px",
  wordBreak: "break-word",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const CustomTypographyAddress = styled(Typography)(({ theme }) => ({
  lineHeight: "40px",
  wordBreak: "break-word",
  width: "65%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const Wrapper = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(4),
  border: `2px solid ${theme.palette.grey[300]}`,
  borderRadius: 6,
  padding: 16,
}));

export default DetailOrder;
