//
import { Route, Redirect, withRouter } from "react-router-dom";

const PublicRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("bayusinergi-token");

  return (
    <Route
      {...rest}
      render={(props) =>
        token ? <Redirect to={`/`} /> : <Component {...props} />
      }
    />
  );
};

export default withRouter(PublicRoute);
