import { useEffect, useMemo, useState } from "react";
import deliveryOrder from "../../../../constant/deliveryOrder";

function GetAll(status) {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [load, setLoad] = useState(false);

  useEffect(() => {
    let mount = true;

    const getAll = async () => {
      try {
        if (mount) {
          setLoading(true);
        }

        const res = await deliveryOrder.getALl(status);

        if (mount) {
          setData(res.data);

          setLoading(false);
        }
      } catch (error) {
        console.log(error);

        if (mount) {
          setLoading(false);
        }
      }
    };

    getAll();
  }, [status]);

  useEffect(() => {
    let mount = true;

    const getAll = async () => {
      try {
        const res = await deliveryOrder.getALl(status);

        if (mount) {
          setData(res.data);

          setLoading(false);

          setLoad(false);
        }
      } catch (error) {
        console.log(error);
      }
    };

    if (load) {
      getAll();
    }
  }, [status, load]);

  return useMemo(() => [data, loading, setLoad], [data, loading, setLoad]);
}

export default GetAll;
