//
import { useEffect, useMemo, useState } from "react";
//
import report from "../../../../constant/report";

function GetProductAnnual(type, year, month) {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAll = async () => {
      setLoading(true);
      try {
        const res = await report.getProductAnnual(type, year, month);

        setData(
          res?.data?.map((item) => {
            return {
              name: item?.name,
              year: item?.year,
              total: item?.jumlah,
            };
          })
        );
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    getAll();
  }, [type, year, month]);

  return useMemo(() => [data, loading], [data, loading]);
}

export default GetProductAnnual;
