import React, { useContext, useEffect } from "react";

import { messaging } from "./FirebaseProvider";

import { useData } from "./DataProvider";

import notification from "../constant/notification";

const NotifikasiContext = React.createContext();

export function useNotifikasi() {
  return useContext(NotifikasiContext);
}

export function NotifikasiProvider(props) {
  const { user } = useData();

  useEffect(() => {
    if (messaging && user?.id) {
      const getToken = async () => {
        try {
          const token = await messaging.getToken();

          if (token) {
            await notification.subscribe({
              user_id: parseInt(user?.id),
              token,
            });
          }
        } catch (err) {
          console.log(err);
        }
      };

      messaging.onTokenRefresh(async () => {
        await getToken();
      });

      getToken();
    }
  }, [user]);

  return (
    <NotifikasiContext.Provider value={{}}>
      {props.children}
    </NotifikasiContext.Provider>
  );
}
