// React
import React, { useState } from "react";

// Material UI
import {
  Autocomplete,
  Card,
  Container,
  styled,
  Typography,
  TextField,
  TableContainer,
  TableHead,
  TableCell,
  Table,
  TableBody,
  TableRow,
} from "@mui/material";

// Components
import { LoadingPage, Page } from "../../../components";

// Lodash
import { groupBy } from "lodash";

// API
import {
  GetListMonthlyProduct,
  GetListMonthlyTos,
  GetListMonthlyTerm,
  GetListMonthlyCT,
  GetListQuarterly,
  GetListMonthlySkp,
  GetListMonthReport,
  GetListYearReport,
  GetListMonthlySales,
} from "./API";

// Utils
import { formatThousand } from "../../../utils";

// Default
const defaultDate = `${new Date().getFullYear()}-${(new Date().getMonth() + 1)
  .toString()
  .padStart(2, "0")}`;

const defaultDate1 = `${new Date().getFullYear()}${(new Date().getMonth() + 1)
  .toString()
  .padStart(2, "0")}`;

const defaultYear = new Date().getFullYear();

function Report() {
  return (
    <Page title="Report | Bayu Sinergi">
      <Container maxWidth="xl">
        {/* Product Monthly */}
        <ProductMonthly />

        {/* Gap */}
        <div style={{ height: 24 }} />

        {/* Type Of Sales Monthly */}
        <TypeOfSalesMonthly />

        {/* Gap */}
        <div style={{ height: 24 }} />

        {/* Payment Term Monthly */}
        <PaymentTermMonthly />

        {/* Gap */}
        <div style={{ height: 24 }} />

        {/* Customer Type Monthly */}
        <CustomerTypeMonthly />

        {/* Gap */}
        <div style={{ height: 24 }} />

        {/* Quarterly */}
        <Quarterly />

        {/* Gap */}
        <div style={{ height: 24 }} />

        {/* SKP ID Monthly */}
        <SKPMonthly />

        {/* Gap */}
        <div style={{ height: 24 }} />

        {/* Sales Rep Monthly */}
        <SalesRepMonthly />
      </Container>
    </Page>
  );
}

// Product Monthly
const ProductMonthly = () => {
  const [month, setMonth] = useState({
    month: defaultDate,
    order_month: defaultDate1,
  });

  const [months, loadingMonths] = GetListMonthReport();

  const [listMonthly, loading] = GetListMonthlyProduct(month?.month);

  const listProductRaw = [
    { values: "Volume PO" },
    { values: "Volume Sisa PO" },
    { values: "Volume Perintah Alir" },
    { values: "Volume Alir" },
    { values: "Volume Diterima" },
    { values: "Rata Harga PO" },
    { values: "Omset PO" },
    { values: "Biaya" },
    { values: "Rata Harga BS" },
    { values: "Revenue Alir BS" },
  ];

  const grouped = groupBy(listMonthly, "product_name");

  const listProduct = Object.keys(grouped).map((key) => {
    const item = grouped[key]?.[0];

    return {
      vp: formatThousand(item?.sum_qtypo),
      vsp: formatThousand(item?.sum_sisapo),
      vpa: formatThousand(item?.sum_perialir),
      va: formatThousand(item?.sum_qty_alir),
      vd: formatThousand(item?.sum_qty_gr),
      rhp: formatThousand(item?.avg_unit_pricepo),
      op: formatThousand(item?.sum_revenuepo),
      b: formatThousand(item?.avg_sum_biaya),
      rhb: formatThousand(item?.avg_pricebs),
      rab: formatThousand(item?.sumrevenue_alirbs),
    };
  });

  const listMonth = months?.sort((a, b) => {
    const aA = parseInt(a?.order_month);

    const bB = parseInt(b?.order_month);

    return bB - aA;
  });

  return (
    <Card sx={{ py: 4, px: 3 }}>
      {loading || loadingMonths ? (
        <LoadingPage />
      ) : (
        <>
          {/* Header */}
          <Header>
            {/* Title */}
            <Typography variant="h4">Report Product Monthly</Typography>

            <div style={{ flexGrow: 1 }} />

            {/* Filter */}
            <Autocomplete
              options={listMonth}
              getOptionLabel={(option) => option?.month}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Filter Month" />
              )}
              onChange={(e, v) => {
                setMonth(v);
              }}
              value={month}
            />
          </Header>

          {/* Table */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Values</TableCell>
                  {Object.keys(grouped)?.map((item, index) => {
                    return <TableCell key={index}>{item}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {listProductRaw?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item?.values}</TableCell>
                      {listProduct?.map((newItem, newIndex) => {
                        let data;

                        switch (index) {
                          case 0:
                            data = newItem.vp;
                            break;

                          case 1:
                            data = newItem.vsp;
                            break;

                          case 2:
                            data = newItem.vpa;
                            break;

                          case 3:
                            data = newItem.va;
                            break;

                          case 4:
                            data = newItem.vd;
                            break;

                          case 5:
                            data = newItem.rhp;
                            break;

                          case 6:
                            data = newItem.op;
                            break;

                          case 7:
                            data = newItem.b;
                            break;

                          case 8:
                            data = newItem.rhb;
                            break;

                          case 9:
                            data = newItem.rab;
                            break;

                          default:
                            data = newItem.vp;
                            break;
                        }

                        return <TableCell key={newIndex}>{data}</TableCell>;
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Card>
  );
};

// Type Of Sales Monthly
const TypeOfSalesMonthly = () => {
  const [month, setMonth] = useState({
    month: defaultDate,
    order_month: defaultDate1,
  });

  const [months, loadingMonths] = GetListMonthReport();

  const [listMonthly, loading] = GetListMonthlyTos(month?.month);

  const listProductRaw = [
    { values: "Volume PO" },
    { values: "Volume Sisa PO" },
    { values: "Volume Perintah Alir" },
    { values: "Volume Alir" },
    { values: "Volume Diterima" },
  ];

  const grouped = groupBy(listMonthly, "type_of_sales");

  const listProduct = Object.keys(grouped)
    ?.sort()
    ?.map((key) => {
      const item = grouped[key]?.[0];

      return {
        vp: formatThousand(item?.sum_qtypo),
        vsp: formatThousand(item?.sum_sisapo),
        vpa: formatThousand(item?.sum_perialir),
        va: formatThousand(item?.sum_qty_alir),
        vd: formatThousand(item?.sum_qty_gr),
      };
    });

  const listMonth = months?.sort((a, b) => {
    const aA = parseInt(a?.order_month);

    const bB = parseInt(b?.order_month);

    return bB - aA;
  });

  return (
    <Card sx={{ py: 4, px: 3 }}>
      {loading || loadingMonths ? (
        <LoadingPage />
      ) : (
        <>
          {/* Header */}
          <Header>
            {/* Title */}
            <Typography variant="h4">Report Type Of Sales Monthly</Typography>

            <div style={{ flexGrow: 1 }} />

            {/* Filter */}
            <Autocomplete
              options={listMonth}
              getOptionLabel={(option) => option?.month}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Filter Month" />
              )}
              onChange={(e, v) => {
                setMonth(v);
              }}
              value={month}
            />
          </Header>

          {/* Table */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Values</TableCell>
                  {Object.keys(grouped).map((item, index) => {
                    return (
                      <TableCell key={index}>
                        {item === "CIF" ? "On Site" : item}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {listProductRaw?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item?.values}</TableCell>
                      {listProduct?.map((newItem, newIndex) => {
                        let data;

                        switch (index) {
                          case 0:
                            data = newItem.vp;
                            break;

                          case 1:
                            data = newItem.vsp;
                            break;

                          case 2:
                            data = newItem.vpa;
                            break;

                          case 3:
                            data = newItem.va;
                            break;

                          case 4:
                            data = newItem.vd;
                            break;

                          default:
                            data = newItem.vp;
                            break;
                        }

                        return <TableCell key={newIndex}>{data}</TableCell>;
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Card>
  );
};

// Payment Term Monthly
const PaymentTermMonthly = () => {
  const [month, setMonth] = useState({
    month: defaultDate,
    order_month: defaultDate1,
  });

  const [months, loadingMonths] = GetListMonthReport();

  const [listMonthly, loading] = GetListMonthlyTerm(month?.month);

  const listProductRaw = [
    { values: "Omset%" },
    { values: "Omset Rp" },
    { values: "Rata Harga Omset" },
  ];

  const grouped = groupBy(listMonthly, "payment_term_desc");

  const listProduct = Object.keys(grouped)?.map((key) => {
    const item = grouped[key]?.[0];

    return {
      o: `${item?.percent_revpo}%`,
      or: formatThousand(item?.sum_revenuepo),
      rho: formatThousand(item?.avg_unit_pricepo),
    };
  });

  const listMonth = months?.sort((a, b) => {
    const aA = parseInt(a?.order_month);

    const bB = parseInt(b?.order_month);

    return bB - aA;
  });

  return (
    <Card sx={{ py: 4, px: 3 }}>
      {loading || loadingMonths ? (
        <LoadingPage />
      ) : (
        <>
          {/* Header */}
          <Header>
            {/* Title */}
            <Typography variant="h4">Report Payment Term Monthly</Typography>

            <div style={{ flexGrow: 1 }} />

            {/* Filter */}
            <Autocomplete
              options={listMonth}
              getOptionLabel={(option) => option?.month}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Filter Month" />
              )}
              onChange={(e, v) => {
                setMonth(v);
              }}
              value={month}
            />
          </Header>

          {/* Table */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Values</TableCell>
                  {Object.keys(grouped).map((item, index) => {
                    return <TableCell key={index}>{item}</TableCell>;
                  })}
                </TableRow>
              </TableHead>
              <TableBody>
                {listProductRaw?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item?.values}</TableCell>
                      {listProduct?.map((newItem, newIndex) => {
                        let data;

                        switch (index) {
                          case 0:
                            data = newItem.o;
                            break;

                          case 1:
                            data = newItem.or;
                            break;

                          case 2:
                            data = newItem.rho;
                            break;

                          default:
                            data = newItem.o;
                            break;
                        }

                        return <TableCell key={newIndex}>{data}</TableCell>;
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Card>
  );
};

// Customer Type Monthly
const CustomerTypeMonthly = () => {
  const [month, setMonth] = useState({
    month: defaultDate,
    order_month: defaultDate1,
  });

  const [months, loadingMonths] = GetListMonthReport();

  const [listMonthly, loading] = GetListMonthlyCT(month?.month);

  const listProductRaw = [
    { values: "Volume PO" },
    { values: "Volume Sisa PO" },
    { values: "Volume Perintah Alir" },
    { values: "Volume Alir" },
    { values: "Volume Diterima" },
    { values: "Rata Harga PO" },
  ];

  const grouped = groupBy(listMonthly, "customer_type");

  const listProduct = Object.keys(grouped)
    ?.sort()
    ?.map((key) => {
      const item = grouped[key]?.[0];

      return {
        vp: formatThousand(item?.sum_qtypo),
        vsp: formatThousand(item?.sum_sisapo),
        vpa: formatThousand(item?.sum_perialir),
        va: formatThousand(item?.sum_qty_alir),
        vd: formatThousand(item?.sum_qty_gr),
        rhp: formatThousand(item?.avg_unit_pricepo),
      };
    });

  const listMonth = months?.sort((a, b) => {
    const aA = parseInt(a?.order_month);

    const bB = parseInt(b?.order_month);

    return bB - aA;
  });

  return (
    <Card sx={{ py: 4, px: 3 }}>
      {loading || loadingMonths ? (
        <LoadingPage />
      ) : (
        <>
          {/* Header */}
          <Header>
            {/* Title */}
            <Typography variant="h4">Report Customer Type Monthly</Typography>

            <div style={{ flexGrow: 1 }} />

            {/* Filter */}
            <Autocomplete
              options={listMonth}
              getOptionLabel={(option) => option?.month}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Filter Month" />
              )}
              onChange={(e, v) => {
                setMonth(v);
              }}
              value={month}
            />
          </Header>

          {/* Table */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Values</TableCell>
                  {Object.keys(grouped)
                    ?.sort()
                    ?.map((item, index) => {
                      return <TableCell key={index}>{item}</TableCell>;
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {listProductRaw?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item?.values}</TableCell>
                      {listProduct?.map((newItem, newIndex) => {
                        let data;

                        switch (index) {
                          case 0:
                            data = newItem.vp;
                            break;

                          case 1:
                            data = newItem.vsp;
                            break;

                          case 2:
                            data = newItem.vpa;
                            break;

                          case 3:
                            data = newItem.va;
                            break;

                          case 4:
                            data = newItem.vd;
                            break;

                          case 5:
                            data = newItem.rhp;
                            break;

                          default:
                            data = newItem.vp;
                            break;
                        }

                        return <TableCell key={newIndex}>{data}</TableCell>;
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Card>
  );
};

// Quarterly
const Quarterly = () => {
  const [year, setYear] = useState({
    year: defaultYear,
  });

  const [years, loadingYears] = GetListYearReport();

  const [listMonthly, loading] = GetListQuarterly(year?.year);

  const listProductRaw = [
    { values: "Volume PO" },
    { values: "Volume Sisa PO" },
    { values: "Volume Perintah Alir" },
    { values: "Volume Alir" },
    { values: "Volume Diterima" },
    { values: "Rata Harga PO" },
    { values: "Omset PO" },
    { values: "Biaya" },
    { values: "Rata Harga BS" },
    { values: "Revenue Alir BS" },
  ];

  const grouped = groupBy(listMonthly, "order_quarter");

  const listProduct = Object.keys(grouped)
    ?.sort()
    ?.map((key) => {
      const item = grouped[key]?.[0];

      return {
        vp: formatThousand(item?.sum_qty),
        vsp: formatThousand(item?.sum_sisapo),
        vpa: formatThousand(item?.sum_perialir),
        va: formatThousand(item?.sum_qty_alir),
        vd: formatThousand(item?.sum_qty_gr),
        rhp: formatThousand(item?.avg_unit_pricepo),
        op: formatThousand(item?.sum_revenuepo),
        b: formatThousand(item?.avg_sum_biaya),
        rhb: formatThousand(item?.avg_pricebs),
        rab: formatThousand(item?.sumrevenue_alirbs),
      };
    });

  const listYear = years?.sort((a, b) => {
    const aA = parseInt(a?.year);

    const bB = parseInt(b?.year);

    return bB - aA;
  });

  return (
    <Card sx={{ py: 4, px: 3 }}>
      {loading || loadingYears ? (
        <LoadingPage />
      ) : (
        <>
          {/* Header */}
          <Header>
            {/* Title */}
            <Typography variant="h4">Report Quarterly</Typography>

            <div style={{ flexGrow: 1 }} />

            {/* Filter */}
            <Autocomplete
              options={listYear}
              getOptionLabel={(option) => option?.year}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Filter Year" />
              )}
              onChange={(e, v) => {
                setYear(v);
              }}
              value={year}
            />
          </Header>

          {/* Table */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Values</TableCell>
                  {Object.keys(grouped)
                    ?.sort()
                    ?.map((item, index) => {
                      return <TableCell key={index}>{item}</TableCell>;
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {listProductRaw?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item?.values}</TableCell>
                      {listProduct?.map((newItem, newIndex) => {
                        let data;

                        switch (index) {
                          case 0:
                            data = newItem.vp;
                            break;

                          case 1:
                            data = newItem.vsp;
                            break;

                          case 2:
                            data = newItem.vpa;
                            break;

                          case 3:
                            data = newItem.va;
                            break;

                          case 4:
                            data = newItem.vd;
                            break;

                          case 5:
                            data = newItem.rhp;
                            break;

                          case 6:
                            data = newItem.op;
                            break;

                          case 7:
                            data = newItem.b;
                            break;

                          case 8:
                            data = newItem.rhb;
                            break;

                          case 9:
                            data = newItem.rab;
                            break;

                          default:
                            data = newItem.vp;
                            break;
                        }

                        return <TableCell key={newIndex}>{data}</TableCell>;
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Card>
  );
};

// SKP ID Monthly
const SKPMonthly = () => {
  const [month, setMonth] = useState({
    month: defaultDate,
    order_month: defaultDate1,
  });

  const [months, loadingMonths] = GetListMonthReport();

  const [listMonthly, loading] = GetListMonthlySkp(month?.month);

  const listProductRaw = [
    { values: "Volume PO" },
    { values: "Volume Sisa PO" },
    { values: "Volume Perintah Alir" },
    { values: "Volume Alir" },
    { values: "Volume Diterima" },
    { values: "Rata Harga PO" },
    { values: "Omset PO" },
    { values: "Biaya" },
    { values: "Rata Harga BS" },
    { values: "Revenue Alir BS" },
  ];

  const grouped = groupBy(listMonthly, "skp_id");

  const listProduct = Object.keys(grouped)
    ?.sort()
    ?.map((key) => {
      const item = grouped[key]?.[0];

      return {
        vp: formatThousand(item?.sum_qtypo),
        vsp: formatThousand(item?.sum_sisapo),
        vpa: formatThousand(item?.sum_perialir),
        va: formatThousand(item?.sum_qty_alir),
        vd: formatThousand(item?.sum_qty_gr),
        rhp: formatThousand(item?.avg_unit_pricepo),
        op: formatThousand(item?.sum_revenuepo),
        b: formatThousand(item?.avg_sum_biaya),
        rhb: formatThousand(item?.avg_pricebs),
        rab: formatThousand(item?.sumrevenue_alirbs),
      };
    });

  const listMonth = months?.sort((a, b) => {
    const aA = parseInt(a?.order_month);

    const bB = parseInt(b?.order_month);

    return bB - aA;
  });

  return (
    <Card sx={{ py: 4, px: 3 }}>
      {loading || loadingMonths ? (
        <LoadingPage />
      ) : (
        <>
          {/* Header */}
          <Header>
            {/* Title */}
            <Typography variant="h4">Report SKP ID Monthly</Typography>

            <div style={{ flexGrow: 1 }} />

            {/* Filter */}
            <Autocomplete
              options={listMonth}
              getOptionLabel={(option) => option?.month}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Filter Month" />
              )}
              onChange={(e, v) => {
                setMonth(v);
              }}
              value={month}
            />
          </Header>

          {/* Table */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Values</TableCell>
                  {Object.keys(grouped)
                    ?.sort()
                    ?.map((item, index) => {
                      return <TableCell key={index}>{item}</TableCell>;
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {listProductRaw?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item?.values}</TableCell>
                      {listProduct?.map((newItem, newIndex) => {
                        let data;

                        switch (index) {
                          case 0:
                            data = newItem.vp;
                            break;

                          case 1:
                            data = newItem.vsp;
                            break;

                          case 2:
                            data = newItem.vpa;
                            break;

                          case 3:
                            data = newItem.va;
                            break;

                          case 4:
                            data = newItem.vd;
                            break;

                          case 5:
                            data = newItem.rhp;
                            break;

                          case 6:
                            data = newItem.op;
                            break;

                          case 7:
                            data = newItem.b;
                            break;

                          case 8:
                            data = newItem.rhb;
                            break;

                          case 9:
                            data = newItem.rab;
                            break;

                          default:
                            data = newItem.vp;
                            break;
                        }

                        return <TableCell key={newIndex}>{data}</TableCell>;
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Card>
  );
};

// Sales Rep Monthly
const SalesRepMonthly = () => {
  const [month, setMonth] = useState({
    month: defaultDate,
    order_month: defaultDate1,
  });

  const [months, loadingMonths] = GetListMonthReport();

  const [listMonthly, loading] = GetListMonthlySales(month?.month);

  const listProductRaw = [
    { values: "Volume PO" },
    { values: "Volume Sisa PO" },
    { values: "Volume Perintah Alir" },
    { values: "Volume Alir" },
    { values: "Volume Diterima" },
    { values: "Rata Harga PO" },
    { values: "Omset PO" },
    { values: "Biaya" },
    { values: "Rata Harga BS" },
    { values: "Revenue Alir BS" },
  ];

  const grouped = groupBy(listMonthly, "sales_name");

  const listProduct = Object.keys(grouped)
    ?.sort()
    ?.map((key) => {
      const item = grouped[key]?.[0];

      return {
        vp: formatThousand(item?.sum_qtypo),
        vsp: formatThousand(item?.sum_sisapo),
        vpa: formatThousand(item?.sum_perialir),
        va: formatThousand(item?.sum_qty_alir),
        vd: formatThousand(item?.sum_qty_gr),
        rhp: formatThousand(item?.avg_unit_pricepo),
        op: formatThousand(item?.sum_revenuepo),
        b: formatThousand(item?.sum_biaya),
        rhb: formatThousand(item?.avg_pricebs),
        rab: formatThousand(item?.sumrevenue_alirbs),
      };
    });

  const listMonth = months?.sort((a, b) => {
    const aA = parseInt(a?.order_month);

    const bB = parseInt(b?.order_month);

    return bB - aA;
  });

  return (
    <Card sx={{ py: 4, px: 3 }}>
      {loading || loadingMonths ? (
        <LoadingPage />
      ) : (
        <>
          {/* Header */}
          <Header>
            {/* Title */}
            <Typography variant="h4">Report Sales Rep Monthly</Typography>

            <div style={{ flexGrow: 1 }} />

            {/* Filter */}
            <Autocomplete
              options={listMonth}
              getOptionLabel={(option) => option?.month}
              sx={{ width: 300 }}
              renderInput={(params) => (
                <TextField {...params} label="Filter Month" />
              )}
              onChange={(e, v) => {
                setMonth(v);
              }}
              value={month}
            />
          </Header>

          {/* Table */}
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Values</TableCell>
                  {Object.keys(grouped)
                    ?.sort()
                    ?.map((item, index) => {
                      return <TableCell key={index}>{item}</TableCell>;
                    })}
                </TableRow>
              </TableHead>
              <TableBody>
                {listProductRaw?.map((item, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{item?.values}</TableCell>
                      {listProduct?.map((newItem, newIndex) => {
                        let data;

                        switch (index) {
                          case 0:
                            data = newItem.vp;
                            break;

                          case 1:
                            data = newItem.vsp;
                            break;

                          case 2:
                            data = newItem.vpa;
                            break;

                          case 3:
                            data = newItem.va;
                            break;

                          case 4:
                            data = newItem.vd;
                            break;

                          case 5:
                            data = newItem.rhp;
                            break;

                          case 6:
                            data = newItem.op;
                            break;

                          case 7:
                            data = newItem.b;
                            break;

                          case 8:
                            data = newItem.rhb;
                            break;

                          case 9:
                            data = newItem.rab;
                            break;

                          default:
                            data = newItem.vp;
                            break;
                        }

                        return <TableCell key={newIndex}>{data}</TableCell>;
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )}
    </Card>
  );
};

// Header
const Header = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  width: "100%",
  alignItems: "center",
  marginBottom: theme.spacing(3),
}));

export default Report;
