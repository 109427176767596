import PropTypes from "prop-types";
import { Link as RouterLink } from "react-router-dom";
// material
import {
  Box,
  Drawer,
  Typography,
  Stack,
  styled,
  Link,
  Avatar,
} from "@mui/material";
// components
import { MHidden, Logo, NavSection, Scrollbar } from "../components";
//
import sidebarConfig from "./SidebarConfig";
//
import { useData } from "../context/DataProvider";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme, isopen }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: isopen,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[200],
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { user, role } = useData();

  const sidebar = role
    .map((item) => {
      const items = sidebarConfig.find((i) => i.key === item.path);

      if (
        user?.role === "mitra" &&
        user?.type === "hauler" &&
        (item?.path === "/dashboard" ||
          item?.path === "/new-order" ||
          item?.path === "/order")
      ) {
        return null;
      }

      return {
        path: item?.path,
        name: item?.name,
        title: item?.name,
        icon: items?.icon,
        sort: items?.sort,
      };
    })
    .filter((item) => item !== null)
    .sort((a, b) => a.sort - b.sort);

  const renderContent = (
    <Scrollbar>
      <Box sx={{ px: 2.5, py: 3 }}>
        <Box sx={{ display: "inline-flex" }}>
          <Stack direction="row" gap={2}>
            <Logo />
            <Stack>
              <Typography color="primary">Ordering</Typography>
              <Typography color="primary">Management System</Typography>
            </Stack>
          </Stack>
        </Box>
      </Box>

      <Box sx={{ mb: 3, mx: 2.5 }}>
        <Link component={RouterLink} underline="none" to="#">
          <AccountStyle>
            <Avatar alt="photoURL" />
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
                {user.name}
              </Typography>
              <Typography variant="body2" sx={{ color: "text.secondary" }}>
                {user.role}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box>

      <NavSection navConfig={sidebar} />
    </Scrollbar>
  );

  return (
    <RootStyle isopen={!isOpenSidebar ? 0 : DRAWER_WIDTH}>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open={isOpenSidebar}
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
