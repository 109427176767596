//
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
//x
import { Navigator, NotFound, Login, ForgotPassword, ResetPassword } from "../pages";
//
import RoleBasedRoute from "./RoleBasedRoute";
import PublicRoute from "./PublicRoute";

function Routes() {
  return (
    <Router>
      <Switch>
        {/* Public */}
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/forgot-password" component={ForgotPassword} />
        <PublicRoute path="/reset-password/:id" component={ResetPassword} />
        {/* Private */}
        <RoleBasedRoute exact path="/" redirect={true} />
        <RoleBasedRoute path="/dashboard" component={Navigator} />
        <RoleBasedRoute path="/user" component={Navigator} />
        <RoleBasedRoute path="/customer" component={Navigator} />
        <RoleBasedRoute path="/customer/:id" component={Navigator} />
        <RoleBasedRoute path="/customer-type" component={Navigator} />
        <RoleBasedRoute path="/product" component={Navigator} />
        <RoleBasedRoute path="/product/:id" component={Navigator} />
        <RoleBasedRoute path="/pajak" component={Navigator} />
        <RoleBasedRoute path="/new-order" component={Navigator} />
        <RoleBasedRoute path="/order" component={Navigator} />
        <RoleBasedRoute path="/order/:id" component={Navigator} />
        <RoleBasedRoute path="/payment-method" component={Navigator} />
        <RoleBasedRoute path="/payment-term" component={Navigator} />
        <RoleBasedRoute path="/depo" component={Navigator} />
        <RoleBasedRoute path="/province" component={Navigator} />
        <RoleBasedRoute path="/mitra" component={Navigator} />
        <RoleBasedRoute path="/mitra/:id" component={Navigator} />
        <RoleBasedRoute path="/loading-order" component={Navigator} />
        <RoleBasedRoute path="/delivery-order" component={Navigator} />
        <RoleBasedRoute path="/page" component={Navigator} />
        <RoleBasedRoute path="/access-role" component={Navigator} />
        <RoleBasedRoute path="/access-role/:id" component={Navigator} />
        <RoleBasedRoute path="/region" component={Navigator} />
        <RoleBasedRoute path="/product-category" component={Navigator} />
        <RoleBasedRoute path="/partner-type" component={Navigator} />
        <RoleBasedRoute path="/business-type" component={Navigator} />
        <RoleBasedRoute path="/settings" component={Navigator} />
        <RoleBasedRoute path="/dashboard-report" component={Navigator} />
        <RoleBasedRoute path="/report" component={Navigator} />
        <RoleBasedRoute path="/report-admin" component={Navigator} />
        <RoleBasedRoute path="/report-salesrep" component={Navigator} />
        <RoleBasedRoute path="/report-operation" component={Navigator} />
        <RoleBasedRoute path="/target" component={Navigator} />
        {/* Restricted Route */}
        <Route path="*" component={NotFound} />
      </Switch>
    </Router>
  );
}

export default Routes;
