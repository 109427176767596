//
import { Page } from "../../../components/";
//
import ListOrderComponent from "./ListOrderComponent";

function TableListOrder() {
  return (
    <Page title="List Order | Bayu Sinergi">
      <ListOrderComponent />
    </Page>
  );
}

export default TableListOrder;
