// React
import { useState } from "react";

// Material UI
import {
  Container,
  Stack,
  Typography,
  Button,
  Card,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  TablePagination,
} from "@mui/material";

// Components
import {
  Page,
  TableToolBar,
  LoadingOrder,
  ListHead,
  LoadingPage,
  ActionTable,
  SearchNotFound,
} from "../../../components";

//
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";

//
import Papa from "papaparse";

//
import { toast } from "react-toastify";

//
import array_chunk from "lodash/chunk";

//
import province from "../../../constant/province";

//
import { GetAll } from "./API";
import { applySortFilter, getComparator } from "../../../utils";
import AddProvince from "./AddProvince";
//
import { useData } from "../../../context/DataProvider";

// Table Head
const TABLE_HEAD = [
  { id: "no", label: "No", alignRight: false, isSort: true },
  { id: "name", label: "Name", alignRight: false, isSort: true },
  { id: "" },
];

const TABLE_HEAD_BOD = [
  { id: "no", label: "No", alignRight: false, isSort: true },
  { id: "name", label: "Name", alignRight: false, isSort: true },
  { id: "" },
];

function Province() {
  const { user } = useData();

  const [listProvince, loading, refresh] = GetAll();

  const [q, setQ] = useState("");

  const [dialog, setDialog] = useState({
    open: false,
    mode: "Add",
    data: {},
  });

  const [loadingImport, setLoadingImport] = useState(false);

  const [no, setNo] = useState(0);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("name");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setNo(no + 5);
    } else {
      setNo(no - 5);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <LoadingPage />;
  }

  const provinceList = applySortFilter(
    listProvince,
    getComparator(order, orderBy),
    q,
    "name"
  );

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - provinceList.length) : 0;

  const isNotFound = provinceList?.length === 0;

  return (
    <Page title="Province | Bayu Sinergi">
      {loadingImport ? <LoadingOrder /> : null}
      <Container maxWidth="xl">
        {/* Header */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            List Province
          </Typography>
          {user?.role !== "bod" && (
            <div>
              <label htmlFor="prov-import">
                <Button
                  component="span"
                  variant="contained"
                  startIcon={<Icon icon={plusFill} />}
                >
                  Import Province
                </Button>
              </label>
              <Button
                onClick={(e) => {
                  setDialog((d) => ({
                    ...d,
                    open: true,
                    mode: "Add",
                  }));
                }}
                variant="contained"
                startIcon={<Icon icon={plusFill} />}
                sx={{ ml: 1.5 }}
              >
                New Province
              </Button>
            </div>
          )}
        </Stack>

        <Card>
          {/* Search */}
          <TableToolBar
            value={q}
            onChange={(e) => {
              setQ(e.target.value);
              setPage(0);
            }}
            placeholder="Search Province"
            onClear={() => setQ("")}
          />

          {/* Table */}
          <TableContainer sx={{ minWidth: 200 }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={user?.role === "bod" ? TABLE_HEAD_BOD : TABLE_HEAD}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {provinceList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{index + 1 + no}</TableCell>
                        <TableCell>{item?.name}</TableCell>
                        {user?.role !== "bod" && (
                          <ActionTable
                            onEdit={() => {
                              setDialog({
                                open: true,
                                data: item,
                                mode: "Edit",
                              });
                            }}
                            onDelete={async () => {
                              try {
                                await province.delete(item?.id);

                                refresh(true);

                                toast.success("Province Deleted!");
                              } catch (e) {
                                console.log(e);
                              }
                            }}
                          />
                        )}
                      </TableRow>
                    );
                  })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 67 * emptyRows }}>
                    <TableCell colSpan={3} />
                  </TableRow>
                )}
              </TableBody>
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={3} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={q} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={provinceList?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* Input */}
      <input
        accept=".csv"
        style={{ display: "none" }}
        id="prov-import"
        type="file"
        onChange={async (e) => {
          const file = e.target.files[0];

          Papa.parse(file, {
            dynamicTyping: true,
            header: false,
            complete: async function (results, file) {
              setLoadingImport(true);

              try {
                if (results.data.length > 0) {
                  if (results.data[0][0] === "Name") {
                    const chunk = array_chunk(results.data, 500);

                    await Promise.all(
                      chunk.map(async (currentData) => {
                        currentData.forEach(async (data) => {
                          await province.create({
                            name: data[0] ?? "-",
                          });
                        });
                      })
                    );

                    toast.success("Province Added");
                  }
                }
              } catch (e) {
                console.log(e);
              }

              setLoadingImport(false);
            },
          });
        }}
      />

      {/* Dialog */}
      <AddProvince
        dialog={dialog}
        load={() => {
          refresh(true);
        }}
        onClose={() => {
          setDialog({
            open: false,
            data: {},
            mode: "Add",
          });
        }}
      />
    </Page>
  );
}

export default Province;
