// React
// Material UI
import {
  Button,
  Card,
  CircularProgress,
  Container,
  Stack,
  styled,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  Toolbar,
  Typography,
} from "@mui/material";
import { useState } from "react";
// Components
import {
  ListHead,
  LoadingOrder,
  Page,
  SearchNotFound,
  TableToolBar,
} from "../../../components";
import { useData } from "../../../context/DataProvider";
//
import { Icon } from "@iconify/react";
import arrowCircleUpFill from "@iconify/icons-eva/arrow-circle-up-fill";
//
import {
  applySortFilter,
  exportToExcel,
  formatDateBAST,
  formatDateList,
  formatThousand,
  getComparator,
} from "../../../utils";
//
import { GetAll } from "./API";
//
import CreateDeliveryOrder from "./CreateDeliveryOrder";
import CreateLoadingOrder from "./CreateLoadingOrder";
import loadingOrder from "../../../constant/loadingOrder";
//
import XLSX from "xlsx";

// Table Head
const TABLE_HEAD = [
  {
    id: "lo_number",
    label: "LO Number",
    alignRight: false,
    isSort: true,
  },
  {
    id: "earliest_date",
    label: "Earliest Date",
    alignRight: false,
    isSort: false,
  },
  {
    id: "lates_date",
    label: "Latest Date",
    alignRight: false,
    isSort: false,
  },
  {
    id: "do_created",
    label: "DO Created (L)",
    alignRight: false,
    isSort: true,
  },
  {
    id: "remaining_qty",
    label: "Remaining Qty (L)",
    alignRight: false,
    isSort: true,
  },
  {
    id: "",
  },
];

function ListLoadingOrder() {
  const { user } = useData();

  const [tabs, setTabs] = useState("open");

  const [listLoadingOrder, loading, refreshLO] = GetAll(tabs);

  const [loadingReport, setLoadingReport] = useState(false);

  const [dialogLO, setDialogLO] = useState({
    open: false,
    data: null,
    mode: "Add",
    orderLine: null,
  });

  const [dialog, setDialog] = useState({
    open: false,
    data: null,
    mode: "Add",
    remainingQty: null,
    orderLine: null,
  });

  const [q, setQ] = useState("");

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("lo_number");

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleExport = async () => {
    setLoadingReport(true);
    try {
      const res = await loadingOrder.getReport();

      const open = res?.data
        ?.filter((item) => item.status === "open")
        .map((item) => {
          const detail = item?.sales_order?.so_detail;

          const pbbkb = (item?.sales_order?.pajak?.value ?? 0) * 100;

          const priceWithTax = Math.round(
            (detail?.total ?? 0) / (detail?.qty ?? 0)
          );

          const d = new Date(item?.createdAt);

          const year = d.getFullYear();

          const month = d.getMonth();

          const ppnVal = year >= 2022 && month >= 3 ? 0.11 : 0.1;

          const pbbkbVal = pbbkb ? pbbkb / 100 : 0;

          const basicPrice = Math.round(priceWithTax / (1 + ppnVal + pbbkbVal));

          const pbbkbPrice = basicPrice * pbbkbVal;

          return {
            "Nomor PO": item?.po_number,
            "Nomor SO": item?.so_number,
            "Nomor LO": item?.lo_number,
            "Stock Id": item?.stock_id,
            Sales: item?.sales?.name,
            Customer: item?.customer?.name,
            "Customer Type": item?.customer?.custtype?.name,
            "Business Type": item?.customer?.businesstype?.name,
            "SKP ID": item?.skp_id,
            "Alamat Serah 1": item?.address,
            "Alamat Serah 2": item?.province?.name,
            Depo: item?.depo?.name,
            Hauler: item?.hauler?.name,
            "Ops Type": item?.ops_type,
            "Earliest Pickup Date": formatDateBAST(item?.earliest_date),
            "Latest Pickup Date": formatDateBAST(item?.earliest_date),
            Product: item?.detail?.product?.name,
            "Qty (L)": item?.remaining?.volume_start,
            "Remaining Qty (L)": item?.remaining?.volume_end,
            OAT: item?.sales_order?.oat,
            "Fee Agent": item?.sales_order?.fee_agent,
            PBBKB: pbbkbPrice,
            "Base Price": basicPrice,
            "Grand Total": item?.sales_order?.so_detail?.total,
          };
        });

      const close = res?.data
        ?.filter((item) => item.status === "close")
        .map((item) => {
          const detail = item?.sales_order?.so_detail;

          const pbbkb = (item?.sales_order?.pajak?.value ?? 0) * 100;

          const priceWithTax = Math.round(
            (detail?.total ?? 0) / (detail?.qty ?? 0)
          );

          const d = new Date(item?.createdAt);

          const year = d.getFullYear();

          const month = d.getMonth();

          const ppnVal = year >= 2022 && month >= 3 ? 0.11 : 0.1;

          const pbbkbVal = pbbkb ? pbbkb / 100 : 0;

          const basicPrice = Math.round(priceWithTax / (1 + ppnVal + pbbkbVal));

          const pbbkbPrice = basicPrice * pbbkbVal;

          return {
            "Nomor PO": item?.po_number,
            "Nomor SO": item?.so_number,
            "Nomor LO": item?.lo_number,
            "Stock Id": item?.stock_id,
            Sales: item?.sales?.name,
            Customer: item?.customer?.name,
            "Customer Type": item?.customer?.custtype?.name,
            "Business Type": item?.customer?.businesstype?.name,
            "SKP ID": item?.skp_id,
            "Alamat Serah 1": item?.address,
            "Alamat Serah 2": item?.province?.name,
            Depo: item?.depo?.name,
            Hauler: item?.hauler?.name,
            "Ops Type": item?.ops_type,
            "Earliest Pickup Date": formatDateBAST(item?.earliest_date),
            "Latest Pickup Date": formatDateBAST(item?.earliest_date),
            Product: item?.detail?.product?.name,
            "Qty (L)": item?.remaining?.volume_start,
            "Remaining Qty (L)": item?.remaining?.volume_end,
            OAT: item?.sales_order?.oat,
            "Fee Agent": item?.sales_order?.fee_agent,
            PBBKB: pbbkbPrice,
            "Base Price": basicPrice,
            "Grand Total": item?.sales_order?.so_detail?.total,
          };
        });

      const expired = res?.data
        ?.filter((item) => item.status === "expired")
        .map((item) => {
          const detail = item?.sales_order?.so_detail;

          const pbbkb = (item?.sales_order?.pajak?.value ?? 0) * 100;

          const priceWithTax = Math.round(
            (detail?.total ?? 0) / (detail?.qty ?? 0)
          );

          const d = new Date(item?.createdAt);

          const year = d.getFullYear();

          const month = d.getMonth();

          const ppnVal = year >= 2022 && month >= 3 ? 0.11 : 0.1;

          const pbbkbVal = pbbkb ? pbbkb / 100 : 0;

          const basicPrice = Math.round(priceWithTax / (1 + ppnVal + pbbkbVal));

          const pbbkbPrice = basicPrice * pbbkbVal;

          return {
            "Nomor PO": item?.po_number,
            "Nomor SO": item?.so_number,
            "Nomor LO": item?.lo_number,
            "Stock Id": item?.stock_id,
            Sales: item?.sales?.name,
            Customer: item?.customer?.name,
            "Customer Type": item?.customer?.custtype?.name,
            "Business Type": item?.customer?.businesstype?.name,
            "SKP ID": item?.skp_id,
            "Alamat Serah 1": item?.address,
            "Alamat Serah 2": item?.province?.name,
            Depo: item?.depo?.name,
            Hauler: item?.hauler?.name,
            "Ops Type": item?.ops_type,
            "Earliest Pickup Date": formatDateBAST(item?.earliest_date),
            "Latest Pickup Date": formatDateBAST(item?.earliest_date),
            Product: item?.detail?.product?.name,
            "Qty (L)": item?.remaining?.volume_start,
            "Remaining Qty (L)": item?.remaining?.volume_end,
            OAT: item?.sales_order?.oat,
            "Fee Agent": item?.sales_order?.fee_agent,
            PBBKB: pbbkbPrice,
            "Base Price": basicPrice,
            "Grand Total": item?.sales_order?.so_detail?.total,
          };
        });

      const wscols = [
        { width: 20 }, // Nomor PO
        { width: 20 }, // Nomor SO
        { width: 20 }, // Nomor LO
        { width: 20 }, // Stock ID
        { width: 20 }, // Sales
        { width: 40 }, // Customer
        { width: 20 }, // Customer Type
        { width: 20 }, // Business Type
        { width: 20 }, // SKP ID
        { width: 40 }, // Alamat Serah 1
        { width: 20 }, // Alamat Serah 2
        { width: 20 }, // Depo
        { width: 40 }, // Hauler
        { width: 20 }, // Ops Type
        { width: 20 }, // Earliest Pickup Date
        { width: 20 }, // Latest Pickup Date
        { width: 20 }, // Product
        { width: 20 }, // Qty (L)
        { width: 20 }, // Remaining Qty (L)
        { width: 20 }, // OAT
        { width: 20 }, // Fee Agent
        { width: 30 }, // PBBKB
        { width: 40 }, // Base Price
        { width: 40 }, // Grand Total
      ];

      const ws1 = XLSX.utils.json_to_sheet(open);
      const ws2 = XLSX.utils.json_to_sheet(close);
      const ws3 = XLSX.utils.json_to_sheet(expired);
      ws1["!cols"] = wscols;
      ws2["!cols"] = wscols;
      ws3["!cols"] = wscols;

      const wb = {
        Sheets: { Open: ws1, Close: ws2, Expired: ws3 },
        SheetNames: ["Open", "Close", "Expired"],
      };

      exportToExcel(wb, `Loading_Order_Report-${formatDateBAST(new Date())}`);
    } catch (error) {
      console.log(error);
    }
    setLoadingReport(false);
  };

  const loadingOrderList = applySortFilter(
    listLoadingOrder,
    getComparator(order, orderBy),
    q,
    "lo_number"
  );

  const isNotFound = loadingOrderList?.length === 0;

  return (
    <Page title="List Loading Order">
      <Container maxWidth="xl">
        {/* Export */}
        {user?.role !== "sales rep" && (
          <Stack mb={2} alignItems="end">
            <Button
              variant="contained"
              startIcon={<Icon icon={arrowCircleUpFill} />}
              onClick={handleExport}
              disabled={loadingReport}
            >
              Export Loading Order
            </Button>
          </Stack>
        )}

        {loadingReport && <LoadingOrder />}

        <Card>
          {/* Search */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Toolbar>
              <Typography variant="h5">List Loading Order</Typography>
            </Toolbar>
            <TableToolBar
              value={q}
              onChange={(e) => {
                setQ(e.target.value);
                setPage(0);
              }}
              placeholder="Search Data"
              onClear={() => setQ("")}
            />
          </Stack>

          {/* Tabs */}
          <Tabs
            value={tabs}
            onChange={(i, v) => setTabs(v)}
            variant="fullWidth"
            sx={{ border: "1px solid #EAEAEA", borderRadius: "6px", mb: 1 }}
          >
            <Tab label="Open" value="open" />
            <Tab label="Close" value="close" />
            <Tab label="Expired" value="expired" />
          </Tabs>

          {/* Table */}
          {loading ? (
            <LoadingWrap>
              <CircularProgress />
            </LoadingWrap>
          ) : (
            <>
              <TableContainer sx={{ minWidth: 200 }}>
                <Table>
                  <ListHead
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    onRequestSort={handleRequestSort}
                    colored
                  />
                  <TableBody>
                    {loadingOrderList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => {
                        return (
                          <TableRow hover key={index}>
                            <TableCell align="left">
                              {item?.lo_number ?? "-"}
                            </TableCell>
                            <TableCell align="left">
                              {formatDateList(item?.earliest_date) ?? "-"}
                            </TableCell>
                            <TableCell align="left">
                              {formatDateList(item?.latest_date) ?? "-"}
                            </TableCell>
                            <TableCell align="left">
                              {formatThousand(item?.remaining?.volume_do || 0)}{" "}
                              L
                            </TableCell>
                            <TableCell align="left">
                              {formatThousand(item?.remaining?.volume_end || 0)}{" "}
                              L
                            </TableCell>
                            <TableCell style={{ width: 300 }}>
                              <Stack direction="row" spacing={2}>
                                <Button
                                  size="small"
                                  variant="outlined"
                                  fullWidth
                                  onClick={() => {
                                    setDialogLO((d) => ({
                                      ...d,
                                      open: true,
                                      data: item,
                                      mode: "View",
                                      orderLine: item?.detail,
                                    }));
                                  }}
                                >
                                  View LO
                                </Button>
                                {tabs === "open" && user.role === "operation" && (
                                  <Button
                                    size="small"
                                    variant="contained"
                                    disabled={item?.remaining?.volume_end === 0}
                                    fullWidth
                                    onClick={() => {
                                      setDialog((d) => ({
                                        ...d,
                                        open: true,
                                        data: item,
                                        mode: "Add",
                                        orderLine: item?.detail,
                                        remainingQty:
                                          item?.remaining?.volume_end,
                                      }));
                                    }}
                                  >
                                    Create DO
                                  </Button>
                                )}
                              </Stack>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                  {isNotFound && (
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" colSpan={7} sx={{ py: 3 }}>
                          <SearchNotFound searchQuery={q} />
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  )}
                </Table>
              </TableContainer>

              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={loadingOrderList?.length ?? 0}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          )}
        </Card>
      </Container>

      {/* Dialog LO */}
      <CreateLoadingOrder
        dialog={dialogLO}
        onClose={() => {
          setDialogLO((d) => ({
            ...d,
            open: false,
            data: null,
            mode: "View",
            orderLine: null,
          }));
        }}
      />

      {/* Dialog DO */}
      <CreateDeliveryOrder
        dialog={dialog}
        refresh={() => {
          refreshLO(true);
        }}
        onClose={() => {
          setDialog((d) => ({
            ...d,
            open: false,
            data: null,
            mode: "Add",
            orderLine: null,
            remainingQty: null,
          }));
        }}
      />
    </Page>
  );
}

const LoadingWrap = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: 250,
  alignItems: "center",
  justifyContent: "center",
}));

export default ListLoadingOrder;
