import { useEffect, useMemo, useState } from "react";
import receiptOrder from "../../../../constant/receiptOrder";
import socket from "../../../../constant/socket";

function GetReceiptOrder(id) {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [load, setLoad] = useState(false);

  useEffect(() => {
    const getAll = async () => {
      try {
        setLoading(true);

        const res = await receiptOrder.getOnce(id);

        setData(res.data);

        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    };

    if (id) {
      getAll();
    }
  }, [id]);

  useEffect(() => {
    const getAll = async () => {
      try {
        const res = await receiptOrder.getOnce(id);

        setData(res.data);
      } catch (e) {
        console.log(e);
      }
      setLoad(false);
    };

    if (id && load) {
      getAll();
    }
  }, [id, load]);

  useEffect(() => {
    const getAll = async () => {
      try {
        const res = await receiptOrder.getOnce(id);

        setData(res.data);
      } catch (e) {
        console.log(e);
      }
    };

    socket.on("load", () => {
      getAll();
    });
  }, [id]);

  return useMemo(() => [data, loading, setLoad], [data, loading, setLoad]);
}

export default GetReceiptOrder;
