//
import { useMemo, useState, useEffect } from "react";

//
import equipment from "../../../../constant/equipment";

function GetAllEquipment(id) {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [load, setLoad] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    const getAll = async () => {
      setLoading(true);
      try {
        const res = await equipment.getAll(id);

        setData(res.data);
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };

    if (id) {
      getAll();
    }
  }, [id]);

  useEffect(() => {
    const getAll = async () => {
      try {
        const res = await equipment.getAll(id);

        setData(res.data);
      } catch (e) {
        setError(e);
      }
      setLoad(false);
    };

    if (load && id) {
      getAll();
    }
  }, [load, id]);

  return useMemo(
    () => [data, loading, setLoad, error],
    [data, loading, setLoad, error]
  );
}

export default GetAllEquipment;
