export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function convBase64ToFile(strBase64) {
  var tmp = strBase64.split(",");
  var prefix = tmp[0];
  var contentType = prefix.split(/[:;]+/)[1];
  var byteCharacters = atob(tmp[1]);

  var byteNumbers = new Array(byteCharacters.length);
  for (var i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  var byteArray = new Uint8Array(byteNumbers);
  var blob = new Blob([byteArray], { type: contentType });
  var blobUrl = URL.createObjectURL(blob);
  window.open(blobUrl);
}
