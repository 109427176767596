//
import { Fragment, useEffect, useLayoutEffect, useMemo, useState } from "react";
//
import {
  Card,
  Typography,
  styled,
  TextField,
  Stack,
  Button,
  Badge,
  Autocomplete,
  Chip,
  Avatar,
} from "@mui/material";
//
import { animateScroll as scroll, Element } from "react-scroll";
//
import chat from "../../../constant/chat";
import users from "../../../constant/users";
import socket from "../../../constant/socket";
//
import { groupBy } from "lodash";
//
import { isoDate, isoToRelative, isoToTime } from "../../../utils/formatDate";
import { getBase64 } from "../../../utils/base64";
//
import { useData } from "../../../context/DataProvider";
//
import { Icon } from "@iconify/react";
import paperPlaneFill from "@iconify/icons-eva/paper-plane-fill";
import fileAddFill from "@iconify/icons-eva/file-add-fill";
import fileTextFill from "@iconify/icons-eva/file-text-fill";
//
import { toast } from "react-toastify";

function ChatOrder({ id, page_id, disabled }) {
  const { user } = useData();

  const [form, setForm] = useState({
    departement: null,
    to: [],
    reply: null,
  });

  const [listChat, setListChat] = useState([]);

  const [attachments, setAttachments] = useState([]);

  const [message, setMessage] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [listDepartement, setListDepartement] = useState([]);

  const [listManager, setListManager] = useState([]);

  const [loadingDepartement, setLoadingDepartement] = useState(false);

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    try {
      let data = {
        order_number: id,
        user_id: user?.id,
        content: message,
        page_id,
      };

      const attach = attachments.map((item) => {
        return {
          filename: item.filename,
          url: item.file,
          upload_by: user.id,
        };
      });

      if (form.departement === null && form.to.length > 0) {
        data.to = form.to;
      }

      if (form.departement !== null && form.to.length > 0) {
        data.to = form.to;
      }

      if (attach.length > 0) {
        data.list_attach = attach;
      }

      const res = await chat.create(data);

      socket.emit("send-message", {
        room: id,
        data: {
          user: { name: user.name },
          to: data?.to ?? null,
          ...res.data,
        },
      });

      setMessage("");

      setForm({
        departement: null,
        to: [],
        reply: null,
      });

      setAttachments([]);
    } catch (e) {
      console.log(e);
    }
    setIsSubmitting(false);
  };

  useLayoutEffect(() => {
    scroll.scrollToBottom({
      containerId: "chatWindow",
      offset: 0,
      isDynamic: true,
      duration: 0,
    });
  }, [listChat]);

  useEffect(() => {
    const getChat = async () => {
      try {
        const res = await chat.getChat(id);

        setListChat(res.data);
      } catch (e) {
        console.log(e);
      }
    };

    if (id) {
      getChat();
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      socket.emit("join-collaboration", id);

      return () => {
        socket.emit("leave-collaboration", id);
      };
    }
  }, [id]);

  useEffect(() => {
    socket.on("message", (data) => {
      const newVal = [...listChat, data];

      setListChat(newVal);

      scroll.scrollToBottom({
        containerId: "chatWindow",
        offset: 0,
        isDynamic: true,
        duration: 0,
      });
    });
  }, [listChat]);

  useEffect(() => {
    const getDepartement = async () => {
      setLoadingDepartement(true);
      try {
        const res = await users.getDepartement();

        setListDepartement(res.data);
      } catch (e) {
        console.log(e);
      }
      setLoadingDepartement(false);
    };

    const get = async () => {
      setLoading(true);
      try {
        const res = await users.getManager();

        setListManager(
          res.data.map((item) => {
            return {
              label: `${item.name} ( ${item?.role?.role_desc} )`,
              ...item,
            };
          })
        );
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    };

    getDepartement();
    get();
  }, []);

  const itemsGroup = useMemo(() => {
    if (listChat) {
      return groupBy(listChat, (item) => {
        return isoDate(item.createdAt);
      });
    }
    return {};
  }, [listChat]);

  const manager = (
    form?.departement !== null
      ? listManager.filter(
          (item) => item.role_id === form?.departement?.role_id
        )
      : listManager
  ).filter((item) => item.user_id !== user.id);

  return (
    <Card sx={{ py: 4, px: 3, mt: 6 }}>
      {/* Title */}
      <Typography variant="h4" mb={4}>
        Collaboration Chat
      </Typography>

      {/* Content */}
      <ChatElemet name="container" id="chatWindow">
        {/* Condition if empty */}
        {listChat?.length === 0 && (
          <EmptyWrap>
            <Typography>You don't have a collaboration chat</Typography>
          </EmptyWrap>
        )}

        {/* Mapping Chat */}
        {Object.keys(itemsGroup).map((dateStr) => {
          return (
            <Fragment key={dateStr}>
              <ChatDayWrap>
                <ChatDay>{isoToRelative(dateStr)}</ChatDay>
              </ChatDayWrap>

              {itemsGroup[dateStr].map((item, index) => {
                if (item.user_id !== user.id) {
                  return (
                    <OtherMessage
                      key={index}
                      message={item.content}
                      time={item.createdAt}
                      name={item?.user?.name}
                      to={item?.to !== null ? item?.to : []}
                      attachments={item.attachments}
                    />
                  );
                }

                return (
                  <MyMessage
                    key={index}
                    message={item.content}
                    time={item.createdAt}
                    attachments={item.attachments}
                    name={item?.user?.name}
                    to={item?.to !== null ? item?.to : []}
                  />
                );
              })}
            </Fragment>
          );
        })}
      </ChatElemet>

      {/* Select */}
      <Stack direction="row" mt={4} spacing={2}>
        <Autocomplete
          sx={{ width: 200 }}
          onChange={(e, value) =>
            setForm((f) => ({
              ...f,
              departement: value,
            }))
          }
          value={form.departement}
          disabled={isSubmitting || disabled}
          loading={loadingDepartement}
          options={listDepartement}
          getOptionLabel={(option) => option.role_desc}
          renderInput={(params) => (
            <TextField {...params} size="small" placeholder="Departement" />
          )}
        />
        <Autocomplete
          multiple
          fullWidth
          value={form.to}
          onChange={(e, v) =>
            setForm((f) => ({
              ...f,
              to: v,
            }))
          }
          disabled={isSubmitting || disabled}
          options={manager}
          loading={loading}
          getOptionLabel={(option) => option.label}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                size="small"
                avatar={<Avatar>{option.name[0]}</Avatar>}
                label={option.name}
                {...getTagProps({ index })}
              />
            ))
          }
          renderInput={(params) => (
            <TextField {...params} size="small" placeholder="To :" />
          )}
        />
      </Stack>

      {/* TextField */}
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
      >
        <Stack direction="row" mt={2} spacing={2}>
          <Badge badgeContent={attachments.length} color="primary">
            <label htmlFor="raised-file">
              <Button
                color="inherit"
                variant="contained"
                size="large"
                component="span"
                disabled={isSubmitting || disabled}
              >
                <Icon icon={fileAddFill} width={24} height={24} />
              </Button>
            </label>
          </Badge>

          <TextField
            placeholder="Type a comment"
            fullWidth
            maxRows={2}
            multiline
            size="large"
            value={message}
            disabled={isSubmitting || disabled}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
            onKeyPress={(e) => {
              if (e.key === "Enter" && !e.shiftKey) {
                handleSubmit(e);
              }
            }}
          />
          <Button
            color="primary"
            variant="contained"
            type="submit"
            disabled={isSubmitting || disabled}
          >
            <Icon icon={paperPlaneFill} width={24} height={24} />
          </Button>
        </Stack>
      </form>

      {/* Input */}
      <input
        accept=".pdf, .xlsx, .doc, .docx, image/*"
        style={{ display: "none" }}
        id="raised-file"
        multiple
        type="file"
        onChange={async (e) => {
          let selectedFile = e.target.files;

          const data = await Promise.all(
            Object.values(selectedFile)?.map(async (item) => {
              if (item.size >= 2097152) {
                toast.error(`${item.name} size more than 2 mb!`);
              } else {
                const base64 = await getBase64(item);
                return {
                  filename: item.name,
                  file: base64,
                };
              }
            })
          );

          const newVal = [...attachments, ...data];

          setAttachments(newVal);
        }}
      />
    </Card>
  );
}

const OtherMessage = ({ message, time, name, to, attachments }) => {
  const recivier =
    to?.length === 0
      ? "all"
      : to?.map((item) => item?.name?.split?.(" ")?.[0])?.join?.(", ");

  return (
    <>
      {attachments?.length > 0 &&
        attachments?.map((item, index) => {
          const image =
            item?.filename?.toLowerCase()?.includes(".jpg") ||
            item?.filename?.toLowerCase()?.includes(".jpeg") ||
            item?.filename?.toLowerCase()?.includes(".png");

          if (image) {
            return (
              <ClientChatBubble key={index} style={{ cursor: "pointer" }}>
                <ClientTextBody>
                  <Typography
                    style={{
                      fontSize: 14,
                      marginBottom: 4,
                      fontWeight: "bold",
                    }}
                  >
                    From: {name}
                  </Typography>
                  {to !== false && (
                    <Typography
                      style={{
                        fontSize: 12,
                        marginBottom: 8,
                        fontWeight: "500",
                      }}
                    >
                      To: {recivier ?? ""}
                    </Typography>
                  )}
                  <img
                    alt={item?.filename}
                    src={`http://${item?.url}`}
                    onClick={() => window.open(`http://${item?.url}`)}
                    style={{
                      width: 400,
                      marginBottom: 8,
                      borderRadius: 10,
                    }}
                  />
                  <ClientTimestamp>{isoToTime(item.createdAt)}</ClientTimestamp>
                </ClientTextBody>
              </ClientChatBubble>
            );
          }

          return (
            <ClientChatBubble
              key={index}
              onClick={() => window.open(`http://${item?.url}`)}
              style={{ cursor: "pointer" }}
            >
              <ClientTextBody>
                <FileWrap>
                  <Icon icon={fileTextFill} width={30} height={30} />
                  <div style={{ width: 4 }} />
                  {item?.filename?.split("\n").map((text, i) => {
                    return (
                      <ClientText
                        key={i}
                        variant="body1"
                        translate="no"
                        noWrap
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: 200,
                        }}
                      >
                        {text}
                      </ClientText>
                    );
                  })}
                </FileWrap>
                <ClientTimestamp>{isoToTime(item.createdAt)}</ClientTimestamp>
              </ClientTextBody>
            </ClientChatBubble>
          );
        })}
      <ClientChatBubble>
        <ClientTextBody>
          <Typography
            style={{
              fontSize: 14,
              marginBottom: 4,
              fontWeight: "bold",
            }}
          >
            From: {name}
          </Typography>
          {to !== false && (
            <Typography
              style={{
                fontSize: 12,
                marginBottom: 4,
                fontWeight: "500",
              }}
            >
              To: {recivier ?? ""}
            </Typography>
          )}
          {message.split("\n").map((text, i) => {
            return (
              <ClientText key={i} variant="body1" translate="no">
                {text}
              </ClientText>
            );
          })}
          <ClientTimestamp>{isoToTime(time)}</ClientTimestamp>
        </ClientTextBody>
      </ClientChatBubble>
    </>
  );
};

const MyMessage = ({ message, time, attachments, name, to }) => {
  const recivier =
    to?.length === 0
      ? "all"
      : to?.map((item) => item?.name?.split?.(" ")?.[0])?.join?.(", ");

  return (
    <>
      {attachments?.length > 0 &&
        attachments?.map((item, index) => {
          const image =
            item?.filename?.toLowerCase()?.includes(".jpg") ||
            item?.filename?.toLowerCase()?.includes(".jpeg") ||
            item?.filename?.toLowerCase()?.includes(".png");

          console.log(item);

          if (image) {
            return (
              <MyChatBubble key={index} style={{ cursor: "pointer" }}>
                <MyTextBody>
                  <Typography
                    style={{
                      fontSize: 14,
                      marginBottom: 4,
                      fontWeight: "bold",
                    }}
                  >
                    From: {name}
                  </Typography>
                  {to !== false && (
                    <Typography
                      style={{
                        fontSize: 12,
                        marginBottom: 8,
                        fontWeight: "500",
                      }}
                    >
                      To: {recivier ?? ""}
                    </Typography>
                  )}
                  <img
                    alt={item?.filename}
                    src={`http://${item?.url}`}
                    onClick={() => window.open(`http://${item?.url}`)}
                    style={{
                      width: 400,
                      marginBottom: 8,
                      borderRadius: 10,
                    }}
                  />
                  <MyTimestamp>{isoToTime(item.createdAt)}</MyTimestamp>
                </MyTextBody>
              </MyChatBubble>
            );
          }

          return (
            <MyChatBubble
              key={index}
              onClick={() => window.open(`http://${item?.url}`)}
              style={{ cursor: "pointer" }}
            >
              <MyTextBody>
                <FileWrap>
                  <Icon icon={fileTextFill} width={30} height={30} />
                  <div style={{ width: 4 }} />
                  {item?.filename?.split("\n").map((text, i) => {
                    return (
                      <MyText
                        key={i}
                        variant="body1"
                        translate="no"
                        noWrap
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: 200,
                        }}
                      >
                        {text}
                      </MyText>
                    );
                  })}
                </FileWrap>
                <MyTimestamp>{isoToTime(item.createdAt)}</MyTimestamp>
              </MyTextBody>
            </MyChatBubble>
          );
        })}
      <MyChatBubble>
        <MyTextBody>
          <Typography
            style={{
              fontSize: 14,
              marginBottom: 4,
              fontWeight: "bold",
            }}
          >
            From: {name}
          </Typography>
          {to !== false && (
            <Typography
              style={{
                fontSize: 12,
                marginBottom: 4,
                fontWeight: "500",
              }}
            >
              To: {recivier ?? ""}
            </Typography>
          )}
          {message.split("\n").map((text, i) => {
            return (
              <MyText key={i} variant="body1" translate="no">
                {text}
              </MyText>
            );
          })}
          <MyTimestamp>{isoToTime(time)}</MyTimestamp>
        </MyTextBody>
      </MyChatBubble>
    </>
  );
};

// Components

const ChatElemet = styled(Element)(() => ({
  width: "100%",
  minHeight: 200,
  maxHeight: 500,
  border: "2px solid #EAEAEA",
  borderRadius: 6,
  overflowY: "auto",
  padding: 16,
}));

const EmptyWrap = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  minHeight: 200,
  maxHeight: 500,
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
}));

const ChatDayWrap = styled(Element)(() => ({
  margin: "10px auto 15px",
  width: 140,
  backgroundColor: "#EAEAEA",
  borderRadius: 10,
  textAlign: "center",
  paddingTop: 4,
  paddingBottom: 4,
}));

const ChatDay = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey[500],
  fontWeight: "bold",
  fontSize: "0.75rem",
}));

const ClientChatBubble = styled(Element)(() => ({
  width: "100%",
  position: "relative",
  display: "flex",
  flexFlow: "row nowrap",
  justifyContent: "flex-start",
  margin: "0 auto 30px",
}));

const ClientTextBody = styled("div")(() => ({
  width: "fit-content",
  backgroundColor: "#EAEAEA",
  textAlign: "left",
  padding: 10,
  borderRadius: 8,
  position: "relative",
  marginRight: 17,
  display: "flex",
  flexFlow: "column nowrap",
  "&::before": {
    content: '""',
    position: "absolute",
    width: 0,
    height: 0,
    left: -10,
    right: "auto",
    top: 0,
    bottom: "auto",
    border: "12px solid",
    borderColor: "#EAEAEA transparent transparent transparent",
  },
}));

const ClientText = styled(Typography)(() => ({
  color: "#0A0A0A",
  wordBreak: "break-word",
}));

const ClientTimestamp = styled(Typography)(({ theme }) => ({
  alignSelf: "flex-end",
  color: theme.palette.grey[500],
  fontSize: 9,
}));

const MyChatBubble = styled(Element)(() => ({
  width: "100%",
  position: "relative",
  display: "flex",
  flexFlow: "column nowrap",
  justifyContent: "space-between",
  margin: "0 auto 20px",
  paddingLeft: 25,
}));

const MyTextBody = styled("div")(() => ({
  width: "fit-content",
  maxWidth: "100%",
  backgroundColor: "#FEEFE5",
  textAlign: "left",
  padding: 10,
  borderRadius: 8,
  position: "relative",
  alignSelf: "flex-end",
  "&::before": {
    content: '""',
    position: "absolute",
    width: 0,
    height: 0,
    right: -8,
    top: 0,
    bottom: "auto",
    border: "12px solid",
    borderColor: "#FEEFE5 transparent transparent transparent ",
  },
}));

const MyText = styled(Typography)(() => ({
  color: "#0A0A0A",
  wordBreak: "break-word",
}));

const MyTimestamp = styled(Typography)(({ theme }) => ({
  alignSelf: "flex-end",
  color: theme.palette.grey[500],
  fontSize: 9,
  textAlign: "right",
}));

const FileWrap = styled("div")(({ theme }) => ({
  display: "flex",
  marginBottom: theme.spacing(1),
  alignItems: "center",
  justifyContent: "center",
}));

export default ChatOrder;
