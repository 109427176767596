import axios from "../config";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  request: (data) => axios.post(`/loading-order/`, data),
  create: (id, data) => axios.put(`/loading-order/${id}`, data),
  getOnce: (id) => axios.get(`/loading-order/${id}`),
  getAll: (status) => axios.get(`/loading-order?status=${status}`),
  getReport: () => axios.get(`/loading-order/report`),
  delete: (id) => axios.delete(`/loading-order/${id}`),
  revoke: (id) => axios.post(`/loading-order/revoke/${id}`),
  update: (id, data) => axios.put(`/loading-order/expired/${id}`, data),
};
