import io from "socket.io-client";
const ENDPOINT =
  process.env.REACT_APP_PREPROD === "YES"
    ? "https://oms-dev.bayusinergi.com"
    : process.env.REACT_APP_PROD === "YES"
    ? "https://oms.bayusinergi.com"
    : "http://localhost:8081";
export default io(ENDPOINT, {
  transports: ["polling"],
});
