//
import { useState } from "react";
// material
import {
  Stack,
  Container,
  Typography,
  Card,
  styled,
  TextField,
  InputAdornment,
  IconButton,
  Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { Page, Logo } from "../../components";
//
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
//
import users from "../../constant/users";
import socket from "../../constant/socket";
//
import { useHistory } from "react-router-dom";
import jwtDecode from "jwt-decode";

export default function Login() {
  const history = useHistory();

  const [isSubmitting, setSubmitting] = useState(false);

  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  const [error, setError] = useState({
    email: "",
    password: "",
  });

  const [showPassword, setShowPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((f) => ({
      ...f,
      [name]: value,
    }));

    setError((f) => ({
      ...f,
      [name]: "",
    }));
  };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const validate = () => {
    const newError = { ...error };
    const { email, password } = form;

    if (!email) {
      newError.email = "Please insert your email";
    }

    if (!password) {
      newError.password = "Please insert your password";
    }

    return newError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const findError = validate();

    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setSubmitting(true);
      try {
        const { email, password } = form;

        const res = await users.login({ email, password });

        localStorage.setItem("bayusinergi-token", res.data.token);

        const decoded = jwtDecode(res.data.token);

        const role = decoded?.data?.akses
          ?.map((item) => {
            const { page } = item;

            return page;
          })
          ?.sort((a, b) => a.id - b.id);

        socket.emit("subscribe-notif", decoded?.data?.role?.replace(" ", "-"));

        history.push(role?.[0]?.path);
      } catch (e) {
        const newError = {};
        if (e.message) {
          newError.password = "Email or Password is wrong!";
        }

        setSubmitting(false);

        setError(newError);
      }
    }
  };

  return (
    <RootStyle title="Login | Bayu Sinergi">
      <Container maxWidth="sm">
        <Card>
          <ContentStyle>
            {/* Header */}
            <Stack sx={{ mb: 5, textAlign: "center" }}>
              <Logo sx={{ width: 60, height: 60, margin: "auto", mb: 2 }} />
              <Typography variant="h4" gutterBottom>
                Ordering Management System
              </Typography>
              <Typography sx={{ color: "text.secondary" }}>
                Enter your details below to sign in.
              </Typography>
            </Stack>

            {/* Form */}
            <form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={2}>
                <TextField
                  fullWidth
                  autoComplete="off"
                  type="email"
                  label="Email address"
                  name="email"
                  value={form.email}
                  error={error.email ? true : false}
                  helperText={error.email}
                  onChange={handleChange}
                  disabled={isSubmitting}
                />
                <TextField
                  fullWidth
                  autoComplete="current-password"
                  type={showPassword ? "text" : "password"}
                  label="Password"
                  name="password"
                  value={form.password}
                  error={error.password ? true : false}
                  helperText={error.password}
                  onChange={handleChange}
                  disabled={isSubmitting}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleShowPassword} edge="end">
                          <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {/* <Typography
                  onClick={() => history.push("/forgot-password")}
                  style={{
                    fontSize: 12,
                    textAlign: "right",
                    textDecoration: "underline",
                    cursor: "pointer",
                    userSelect: "none",
                    WebkitUserSelect: "none",
                  }}
                >
                  Forgot Password?
                </Typography> */}

                {/* Button */}
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Login
                </LoadingButton>

                {/* Forgot Password Button */}
                <Link href="/forgot-password" style={{textAlign: "right"}} underline="hover">
                  {'Forgot Password?'}
                </Link>
                
              </Stack>
            </form>
          </ContentStyle>
        </Card>
      </Container>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(() => ({
  display: "flex",
  height: "100vh",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  // background: `url(${bg}) rgba(0, 0, 0, 0.4)`,
  // backgroundSize: "cover",
  // backgroundBlendMode: "multiply",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(8, 0),
}));

// ----------------------------------------------------------------------
