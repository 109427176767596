//
import { useState } from "react";
//
import {
  Container,
  Stack,
  Typography,
  Button,
  Card,
  Table,
  TableContainer,
  TableBody,
  TablePagination,
  TableRow,
  TableCell,
} from "@mui/material";
//
import {
  Page,
  ListHead,
  TableToolBar,
  ActionTable,
  LoadingPage,
  SearchNotFound,
} from "../../../components";
//
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
//
import { applySortFilter, getComparator } from "../../../utils";
//
import AddDepo from "./AddDepo";
//
import depo from "../../../constant/depo";
import { toast } from "react-toastify";
import { GetAll } from "./API";
//
import { useData } from "../../../context/DataProvider";

// Table Head
const TABLE_HEAD = [
  { id: "no", label: "No", alignRight: false, isSort: true },
  { id: "name", label: "Name", alignRight: false, isSort: true },
  { id: "address", label: "Address", alignRight: false, isSort: false },
  { id: "region_name", label: "Region", alignRight: false, isSort: false },
  { id: "" },
];

const TABLE_HEAD_BOD = [
  { id: "no", label: "No", alignRight: false, isSort: true },
  { id: "name", label: "Name", alignRight: false, isSort: true },
  { id: "address", label: "Address", alignRight: false, isSort: false },
  { id: "region_name", label: "Region", alignRight: false, isSort: false },
];

function Depo() {
  const { user } = useData();

  const [listDepo, loading, refresh] = GetAll();

  const [q, setQ] = useState("");

  const [dialog, setDialog] = useState({
    open: false,
    mode: "Add",
    data: {},
  });

  const [no, setNo] = useState(0);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("name");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setNo(no + 5);
    } else {
      setNo(no - 5);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <LoadingPage />;
  }

  const depoList = applySortFilter(
    listDepo,
    getComparator(order, orderBy),
    q,
    "name"
  );

  const isNotFound = depoList?.length === 0;

  return (
    <Page title="Supply Point | Bayu Sinergi">
      <Container maxWidth="xl">
        {/* Header */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            List Supply Point
          </Typography>
          {user?.role !== "bod" && (
            <Button
              onClick={(e) => {
                setDialog((d) => ({
                  ...d,
                  open: true,
                  mode: "Add",
                }));
              }}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
            >
              New Supply Point
            </Button>
          )}
        </Stack>

        <Card>
          {/* Search */}
          <TableToolBar
            value={q}
            onChange={(e) => {
              setQ(e.target.value);
              setPage(0);
            }}
            placeholder="Search Supply Point"
            onClear={() => setQ("")}
          />

          {/* Table */}
          <TableContainer sx={{ minWidth: 200 }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={user?.role === "bod" ? TABLE_HEAD_BOD : TABLE_HEAD}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {depoList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return (
                      <TableRow hover key={index}>
                        <TableCell align="left">{index + 1 + no}</TableCell>
                        <TableCell align="left">{item?.name}</TableCell>
                        <TableCell align="left" sx={{ maxWidth: 300 }}>
                          {item?.address}
                        </TableCell>
                        <TableCell align="left">
                          {item?.region_name ?? "-"}
                        </TableCell>
                        {user?.role !== "bod" && (
                          <ActionTable
                            onEdit={() => {
                              setDialog({
                                open: true,
                                data: item,
                                mode: "Edit",
                              });
                            }}
                            onDelete={async () => {
                              try {
                                await depo.delete(item.id);

                                refresh(true);

                                toast.success("Supply Point deleted");
                              } catch (e) {
                                console.log(e);
                              }
                            }}
                          />
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={q} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          {/* Pagination */}
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={depoList?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* Add Depo */}
      <AddDepo
        dialog={dialog}
        load={() => {
          refresh(true);
        }}
        onClose={() => {
          setDialog({
            open: false,
            mode: "Add",
            data: {},
          });
        }}
      />
    </Page>
  );
}

export default Depo;
