import axios from "../config";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  create: (data) => axios.post(`/customer`, data),
  update: (id, data) => axios.put(`/customer/${id}`, data),
  getAll: () => axios.get(`/customer`),
  getOnce: (id) => axios.get(`/customer/${id}`),
  destroy: (id) => axios.delete(`/customer/${id}`),
};
