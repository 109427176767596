//
import {
  Typography,
  styled,
  tableCellClasses,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Autocomplete,
  TextField,
} from "@mui/material";
//
import { NumberFormatRp, NumberFormatQty } from "../../../components";
//
import { formatThousand } from "../../../utils";
//
import { GetPriceProduct } from "./API";

function OrderLine({
  region,
  type,
  pbbkb,
  orderLine,
  setOrderLine,
  disabled,
  hide,
}) {
  const [listProduct, loading] = GetPriceProduct(region?.id, type);

  const priceWithTax =
    parseInt(orderLine?.total !== "" ? orderLine?.total : 0) /
      parseInt(orderLine?.qty !== "" ? orderLine?.qty : 0) || 0;

  const ppnVal = 0.11;

  const pbbkbVal = pbbkb ? pbbkb / 100 : 0;

  const basicPrice = priceWithTax / (1 + ppnVal + pbbkbVal);

  const pbbkbPrice = basicPrice * pbbkbVal;

  const ppnPrice = basicPrice * ppnVal;

  return (
    <>
      <Typography mt={4} mb={3}>
        Order Lines
      </Typography>

      {/* Table */}
      <TableContainer>
        <Table>
          {/* Head */}
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ width: 200 }}>Product</StyledTableCell>
              <StyledTableCell style={{ width: 200 }}>
                Quantity (L)
              </StyledTableCell>
              <StyledTableCell>Unit Price</StyledTableCell>
              <StyledTableCell>PBBKB ({pbbkb}%)</StyledTableCell>
              <StyledTableCell>PPN (11%)</StyledTableCell>
              <StyledTableCell style={{ width: 250 }}>
                Grand Total
              </StyledTableCell>
              {!hide && <StyledTableCell>Nett Product Price</StyledTableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Autocomplete
                  getOptionLabel={(option) => option.name}
                  options={listProduct}
                  value={orderLine.product}
                  onChange={(e, v) => {
                    setOrderLine((o) => ({
                      ...o,
                      product: v,
                    }));
                  }}
                  loading={loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose Product"
                      size="small"
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                  fullWidth
                  disabled={disabled}
                />
              </TableCell>
              <TableCell>
                <TextField
                  name="qty"
                  autoComplete="off"
                  placeholder="Quantity"
                  size="small"
                  fullWidth
                  value={orderLine.qty}
                  onChange={(e) => {
                    setOrderLine((o) => ({
                      ...o,
                      qty: e.target.value,
                    }));
                  }}
                  InputProps={{
                    inputComponent: NumberFormatQty,
                  }}
                  disabled={orderLine?.product === null}
                />
              </TableCell>
              <TableCell>Rp. {formatThousand(priceWithTax)}</TableCell>
              <TableCell>Rp. {formatThousand(pbbkbPrice)}</TableCell>
              <TableCell>Rp. {formatThousand(ppnPrice)}</TableCell>
              <TableCell>
                <TextField
                  name="total"
                  autoComplete="off"
                  placeholder="Grand Total"
                  size="small"
                  onChange={(e) => {
                    setOrderLine((o) => ({
                      ...o,
                      total: e.target.value,
                    }));
                  }}
                  value={orderLine?.total}
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatRp,
                  }}
                  disabled={!orderLine?.qty}
                />
              </TableCell>
              {!hide && <TableCell>Rp. {formatThousand(basicPrice)}</TableCell>}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

// Components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[200],
    color: "text.primary",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default OrderLine;
