//
import { useMemo, useState, useEffect } from "react";

//
import region from "../../../../constant/region";

function GetAll() {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [load, setLoad] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    const getAll = async () => {
      setLoading(true);
      try {
        const res = await region.getAll();

        setData(
          res.data
            ?.map((item) => {
              const { region_id, ...rest } = item;

              return {
                id: region_id,
                ...rest,
              };
            })
            ?.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
        );
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };

    getAll();
  }, []);

  useEffect(() => {
    const getAll = async () => {
      try {
        const res = await region.getAll();

        setData(
          res.data
            ?.map((item) => {
              const { region_id, ...rest } = item;

              return {
                id: region_id,
                ...rest,
              };
            })
            ?.sort((a, b) => {
              if (a.name < b.name) {
                return -1;
              }
              if (a.name > b.name) {
                return 1;
              }
              return 0;
            })
        );
      } catch (e) {
        setError(e);
      }
      setLoad(false);
    };

    if (load) {
      getAll();
    }
  }, [load]);

  return useMemo(
    () => [data, loading, setLoad, error],
    [data, loading, setLoad, error]
  );
}

export default GetAll;
