import PropTypes from "prop-types";
import { Route } from "react-router-dom";
// material
import {
  Box,
  List,
  ListItemText,
  ListItemIcon,
  ListItemButton,
  alpha,
  useTheme,
  styled,
} from "@mui/material";

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.bool,
};

function NavItem({ item, active, history }) {
  const theme = useTheme();

  const { title, path, icon } = item;

  const activeRootStyle = {
    color: "primary.main",
    fontWeight: "fontWeightMedium",
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
    "&:before": { display: "block" },
  };

  return (
    <ListItemStyle
      onClick={() => history.push(path)}
      sx={{
        ...(active && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  return (
    <Box {...other}>
      <List disablePadding>
        {navConfig.map((item, index) => (
          <Route
            path={item.path}
            key={index}
            children={({ match, history }) => {
              return (
                <NavItem
                  key={index}
                  item={item}
                  active={match ? true : false}
                  history={history}
                />
              );
            }}
          />
        ))}
      </List>
    </Box>
  );
}
