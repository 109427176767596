// React
import React, { useContext } from "react";

// Firebase
import firebase from "firebase/app";
import "firebase/messaging";

// Firebase Config
import firebaseConfig, { publicVapidKey } from "../config/firebase";

firebase.initializeApp(firebaseConfig);

let messagingSupported = null;

if (firebase.messaging.isSupported()) {
  messagingSupported = firebase.messaging();

  messagingSupported.usePublicVapidKey(publicVapidKey);
}

export const messaging = messagingSupported;

// React-Context
const FirebaseContext = React.createContext();

export function useFirebase() {
  return useContext(FirebaseContext);
}

function FirebaseProvider(props) {
  return (
    <FirebaseContext.Provider
      value={{
        firebase,
        messaging,
      }}
    >
      {props.children}
    </FirebaseContext.Provider>
  );
}

export default FirebaseProvider;
