import axios from "axios";

import errorHandler from "./errorHandler";

const instance = axios.create({
  baseURL:
    process.env.REACT_APP_PREPROD === "YES"
      ? "/api"
      : process.env.REACT_APP_PROD === "YES"
      ? "/api"
      : "http://localhost:8081/",
});

instance.interceptors.request.use((config) => {
  const token = localStorage.getItem("bayusinergi-token");
  config.headers.Authorization = token ?? "";

  return config;
});

instance.interceptors.response.use((response) => response.data, errorHandler);

export default instance;
