import React from "react";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";

const NumberFormatQty = React.forwardRef(function NumberFormatQty(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
            name: other.name,
          },
        });
      }}
      thousandSeparator="."
      decimalSeparator=","
      allowNegative={false}
      isNumericString
      suffix=" L"
    />
  );
});

NumberFormatQty.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default NumberFormatQty;
