// React
import React, { useEffect, useState, useRef } from "react";

//
import { useReactToPrint } from "react-to-print";

// Components
import {
  ListHead,
  Page,
  TableToolBar,
  SearchNotFound,
  AlertDialog,
  LoadingOrder,
} from "../../../components";

// Material UI
import {
  Card,
  Container,
  Stack,
  Toolbar,
  Typography,
  Tabs,
  Tab,
  TablePagination,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Button,
  styled,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

// API
import { GetAll } from "./API";
import deliveryOrder from "../../../constant/deliveryOrder";
import socket from "../../../constant/socket";

// Utils
import {
  applySortFilter,
  exportToExcel,
  formatDate,
  formatDateBAST,
  formatThousand,
  getComparator,
  numberToWords,
} from "../../../utils";
//
import { useData } from "../../../context/DataProvider";
//
import { toast } from "react-toastify";
//
import CreateDeliveryOrder from "../ListLoadingOrder/CreateDeliveryOrder";
import CreateReceiptOrder from "../ListOrder/CreateReceiptOrder";
//
import QRCode from "qrcode";
//
import "./index.css";
import "./index-1.css";
import "./index-2.css";
//
import { Icon } from "@iconify/react";
import arrowCircleUpFill from "@iconify/icons-eva/arrow-circle-up-fill";
//
import XLSX from "xlsx";

// Table Head
const TABLE_HEAD = [
  {
    id: "do_number",
    label: "DO Number",
    alignRight: false,
    isSort: true,
  },
  {
    id: "pickup_date",
    label: "Pickup Date",
    alignRight: false,
    isSort: false,
  },
  {
    id: "volume",
    label: "Volume (L)",
    alignRight: false,
    isSort: false,
  },
  {
    id: "",
  },
];

function ListDeliveryOrder() {
  const { user } = useData();

  const [tabs, setTabs] = useState("waiting-delivery");

  const [listDeliveryOrder, loading, refreshDO] = GetAll(tabs);

  const [dialog, setDialog] = useState({
    open: false,
    data: null,
    mode: "View",
    orderLine: null,
  });

  const [q, setQ] = useState("");

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("do_number");

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [loadingReport, setLoadingReport] = useState(false);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [dialogProccess, setDialogProcces] = useState({
    open: false,
    id: "",
  });

  const [dialogRo, setDialogRo] = useState({
    open: false,
    data: null,
  });

  const [dialogBAST, setDialogBAST] = useState({
    open: false,
    data: null,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleProcces = async (id) => {
    try {
      setIsSubmitting(true);

      await deliveryOrder.proccess({ do_number: id });

      setDialogProcces((d) => ({
        ...d,
        open: false,
        id: "",
      }));

      socket.emit("send-load");

      refreshDO(true);

      toast.success("Delivery Order Proccessed");

      setIsSubmitting(false);
    } catch (error) {
      console.log(error);
      setIsSubmitting(false);
    }
  };

  const handleExport = async () => {
    setLoadingReport(true);
    try {
      const res = await deliveryOrder.getReport();

      const waitingDelivery = res?.data
        ?.filter((item) => item.status === "waiting-delivery")
        .map((item) => {
          return {
            "Nomor PO": item?.po_number,
            "Nomor SO": item?.so_number,
            "Nomor LO": item?.lo_number,
            "Nomor DO": item?.do_number,
            "Stock Id": item?.stock_id,
            Customer: item?.customer?.name,
            "Customer Type": item?.customer?.custtype?.name,
            "Business Type": item?.customer?.businesstype?.name,
            "Alamat Serah": item?.address,
            Depo: item?.depo?.name,
            Hauler: item?.hauler?.name,
            "Ops Type": item?.ops_type,
            "Vehicle Number": item?.vehicle_number,
            "No. Segel Atas": item?.segel_atas,
            "No. Segel Bawah": item?.segel_bawah,
            "Pickup Date": formatDateBAST(item?.pickup_date),
            Recipient: item?.recipient,
            "Recipient Contact": item?.recipient_contact,
            Driver: item?.driver,
            "Driver Contact": item?.driver_contact,
            Product: item?.detail?.product?.name,
            "Qty (L)": item?.detail?.qty,
          };
        });

      const onDelivery = res?.data
        ?.filter((item) => item.status === "on-delivery")
        .map((item) => {
          return {
            "Nomor PO": item?.po_number,
            "Nomor SO": item?.so_number,
            "Nomor LO": item?.lo_number,
            "Nomor DO": item?.do_number,
            "Stock Id": item?.stock_id,
            Customer: item?.customer?.name,
            "Customer Type": item?.customer?.custtype?.name,
            "Business Type": item?.customer?.businesstype?.name,
            "Alamat Serah": item?.address,
            Depo: item?.depo?.name,
            Hauler: item?.hauler?.name,
            "Ops Type": item?.ops_type,
            "Vehicle Number": item?.vehicle_number,
            "No. Segel Atas": item?.segel_atas,
            "No. Segel Bawah": item?.segel_bawah,
            "Pickup Date": formatDateBAST(item?.pickup_date),
            Recipient: item?.recipient,
            "Recipient Contact": item?.recipient_contact,
            Driver: item?.driver,
            "Driver Contact": item?.driver_contact,
            Product: item?.detail?.product?.name,
            "Qty (L)": item?.detail?.qty,
          };
        });

      const received = res?.data
        ?.filter((item) => item.status === "received")
        .map((item) => {
          return {
            "Nomor PO": item?.po_number,
            "Nomor SO": item?.so_number,
            "Nomor LO": item?.lo_number,
            "Nomor DO": item?.do_number,
            "Stock Id": item?.stock_id,
            Customer: item?.customer?.name,
            "Customer Type": item?.customer?.custtype?.name,
            "Business Type": item?.customer?.businesstype?.name,
            "Alamat Serah": item?.address,
            Depo: item?.depo?.name,
            Hauler: item?.hauler?.name,
            "Ops Type": item?.ops_type,
            "Vehicle Number": item?.vehicle_number,
            "No. Segel Atas": item?.segel_atas,
            "No. Segel Bawah": item?.segel_bawah,
            "Pickup Date": formatDateBAST(item?.pickup_date),
            Recipient: item?.recipient,
            "Recipient Contact": item?.recipient_contact,
            Driver: item?.driver,
            "Driver Contact": item?.driver_contact,
            Product: item?.detail?.product?.name,
            "Qty (L)": item?.detail?.qty,
          };
        });

      const wscols = [
        { width: 20 }, // Nomor PO
        { width: 20 }, // Nomor SO
        { width: 20 }, // Nomor LO
        { width: 20 }, // Nomor DO
        { width: 20 }, // Stock ID
        { width: 40 }, // Customer
        { width: 20 }, // Customer Type
        { width: 20 }, // Business Type
        { width: 40 }, // Alamat Serah
        { width: 20 }, // Depo
        { width: 40 }, // Hauler
        { width: 20 }, // Ops Type
        { width: 20 }, // Vehicle Number
        { width: 20 }, // No. Segel Atas
        { width: 20 }, // No. Segel Bawah
        { width: 20 }, // Pickup Date
        { width: 40 }, // Recipient
        { width: 20 }, // Recipient Contact
        { width: 40 }, // Driver
        { width: 20 }, // Driver Contact
        { width: 20 }, // Product
        { width: 20 }, // Qty (L)
      ];

      const ws1 = XLSX.utils.json_to_sheet(waitingDelivery);
      const ws2 = XLSX.utils.json_to_sheet(onDelivery);
      const ws3 = XLSX.utils.json_to_sheet(received);
      ws1["!cols"] = wscols;
      ws2["!cols"] = wscols;
      ws3["!cols"] = wscols;

      const wb = {
        Sheets: { "Waiting Delivery": ws1, "On Delivery": ws2, Received: ws3 },
        SheetNames: ["Waiting Delivery", "On Delivery", "Received"],
      };

      exportToExcel(wb, `Delivery_Order_Report-${formatDateBAST(new Date())}`);
    } catch (error) {
      console.log(error);
    }
    setLoadingReport(false);
  };

  const deliveryOrderList = applySortFilter(
    listDeliveryOrder,
    getComparator(order, orderBy),
    q,
    "do_number"
  );

  const isNotFound = deliveryOrderList?.length === 0;

  return (
    <Page title="List Delivery Order | Bayu Sinergi">
      <Container maxWidth="xl">
        {/* Export */}
        {user?.role !== "sales rep" && (
          <Stack mb={2} alignItems="end">
            <Button
              variant="contained"
              startIcon={<Icon icon={arrowCircleUpFill} />}
              onClick={handleExport}
              disabled={loadingReport}
            >
              Export Delivery Order
            </Button>
          </Stack>
        )}

        {loadingReport && <LoadingOrder />}

        <Card>
          {/* Search */}
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Toolbar>
              <Typography variant="h5">List Delivery Order</Typography>
            </Toolbar>
            <TableToolBar
              value={q}
              onChange={(e) => {
                setQ(e.target.value);
                setPage(0);
              }}
              placeholder="Search Data"
              onClear={() => setQ("")}
            />
          </Stack>

          {/* Tabs */}
          <Tabs
            value={tabs}
            onChange={(i, v) => setTabs(v)}
            variant="fullWidth"
            sx={{ border: "1px solid #EAEAEA", borderRadius: "6px", mb: 1 }}
          >
            <Tab label="Waiting Delivery" value="waiting-delivery" />
            <Tab label="On Delivery" value="on-delivery" />
            <Tab label="Received" value="received" />
          </Tabs>

          {/* Table */}
          {loading ? (
            <LoadingWrap>
              <CircularProgress />
            </LoadingWrap>
          ) : (
            <TableContainer sx={{ minWidth: 200 }}>
              <Table>
                <ListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                  colored
                />
                <TableBody>
                  {deliveryOrderList
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((item, index) => {
                      return (
                        <TableRow hover key={index}>
                          <TableCell align="left">
                            {item?.do_number ?? "-"}
                          </TableCell>
                          <TableCell align="left">
                            {formatDate(item?.pickup_date) ?? "-"}
                          </TableCell>
                          <TableCell align="left">
                            {formatThousand(item?.detail?.qty || 0)} L
                          </TableCell>
                          <TableCell style={{ width: 430 }}>
                            <Stack direction="row" spacing={2}>
                              {tabs === "on-delivery" &&
                                user.role === "operation" && (
                                  <Button
                                    fullWidth
                                    size="small"
                                    color="inherit"
                                    variant="contained"
                                    onClick={() => {
                                      setDialogRo((d) => ({
                                        ...d,
                                        open: true,
                                        data: item,
                                      }));
                                    }}
                                  >
                                    Receipt DO
                                  </Button>
                                )}

                              <Button
                                fullWidth
                                size="small"
                                variant="outlined"
                                onClick={() => {
                                  setDialog((d) => ({
                                    ...d,
                                    open: true,
                                    data: item,
                                    orderLine: item?.detail,
                                  }));
                                }}
                              >
                                View DO
                              </Button>

                              {tabs === "waiting-delivery" &&
                                user.role === "operation" && (
                                  <Button
                                    fullWidth
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      setDialogProcces((d) => ({
                                        ...d,
                                        open: true,
                                        id: item?.do_number,
                                      }));
                                    }}
                                  >
                                    Proccess
                                  </Button>
                                )}

                              {tabs === "waiting-delivery" &&
                                user.role === "operation" && (
                                  <Button
                                    fullWidth
                                    size="small"
                                    variant="contained"
                                    color="inherit"
                                    onClick={() => {
                                      setDialogBAST({
                                        open: true,
                                        data: {
                                          po_number: item?.po_number,
                                          lo_number: item?.lo_number,
                                          do_number: item?.do_number,
                                          code: item?.sales_order?.skp_id,
                                          customer: item?.customer?.name,
                                          address: item?.address,
                                          transportir: item?.hauler?.name,
                                          transportir_address:
                                            item?.hauler?.address,
                                          transportir_pid:
                                            item?.hauler?.partner_id,
                                          transportir_image:
                                            item?.hauler?.image,
                                          transportir_email:
                                            item?.hauler?.email,
                                          date: formatDateBAST(
                                            item?.pickup_date
                                          ),
                                          stock_id: item?.stock_id,
                                          product: item?.detail?.product?.name,
                                          supply_point: item?.depo?.name,
                                          qty: item?.detail?.qty,
                                          vehicle_number: item?.vehicle_number,
                                          segel_atas: item?.segel_atas,
                                          segel_bawah: item?.segel_bawah,
                                          driver: item?.driver,
                                          operation: user?.name,
                                          type: item?.ops_type,
                                          transaction_type: item?.type_of_sales,
                                        },
                                      });
                                    }}
                                  >
                                    Print BAST & BL
                                  </Button>
                                )}
                            </Stack>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={q} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          )}

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={deliveryOrderList?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* Alert */}
      <AlertDialog
        open={dialogProccess.open}
        loading={isSubmitting}
        title="Delivery Order"
        content="Are you sure want to proccess this delivery order?"
        handleOk={() => {
          handleProcces(dialogProccess.id);
        }}
        handleClose={() => {
          setDialogProcces((d) => ({
            ...d,
            open: false,
            id: "",
          }));
        }}
      />

      {/* Dialog DO */}
      <CreateDeliveryOrder
        dialog={dialog}
        refresh={() => {
          refreshDO(true);
        }}
        onClose={() => {
          setDialog((d) => ({
            ...d,
            open: false,
            data: null,
            orderLine: null,
          }));
        }}
      />

      {/* Receipt Order */}
      <CreateReceiptOrder
        open={dialogRo.open}
        data={dialogRo.data}
        refresh={() => {
          refreshDO(true);
        }}
        onClose={() => {
          setDialogRo((d) => ({
            ...d,
            open: false,
            data: null,
          }));
        }}
      />

      <DialogBAST
        dialog={dialogBAST}
        onClose={() => setDialogBAST({ open: false, data: null })}
      />
    </Page>
  );
}

const DialogBAST = ({ dialog: { open, data }, onClose }) => {
  let bastRef = useRef();

  let blRef = useRef();

  const handlePrintBast = useReactToPrint({
    content: () => bastRef.current,
  });

  const handlePrintBl = useReactToPrint({
    content: () => blRef.current,
  });

  return (
    <Dialog open={open} fullWidth maxWidth="lg">
      <DialogTitle>BAST & BL</DialogTitle>
      <DialogContent>
        <BAST {...data} ref={bastRef} />
        <div style={{ height: 24 }} />
        {data?.type === "Truck" ? (
          <BLTruck {...data} ref={blRef} />
        ) : (
          <BLVessel {...data} ref={blRef} />
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="inherit" onClick={onClose}>
          Close
        </Button>
        <Button variant="contained" onClick={handlePrintBl}>
          Print BL
        </Button>
        <Button variant="contained" onClick={handlePrintBast}>
          Print BAST
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const BAST = React.forwardRef(
  (
    {
      lo_number,
      do_number,
      code = "BS",
      customer,
      address,
      transportir,
      transportir_address,
      transportir_pid = 0,
      transportir_image,
      transportir_email,
      date,
      po_number,
      stock_id,
      product,
      supply_point,
      qty = 0,
      vehicle_number,
      segel_atas,
      segel_bawah,
      operation,
      driver,
    },
    ref
  ) => {
    const [qr, setQr] = useState("");

    useEffect(() => {
      const getQr = async () => {
        try {
          const qrcode = await QRCode.toDataURL(
            `.${do_number} ${customer} ${product} ${supply_point} ${date} ${qty}`
          );

          setQr(qrcode);
        } catch (error) {
          console.log(error);
        }
      };

      if (customer && date && do_number && product && qty && supply_point) {
        getQr();
      }
    }, [customer, date, do_number, product, qty, supply_point]);

    const isCode = (val) => {
      if (val === "BS" || val === "BSS" || val === "CTI") {
        return val;
      }

      return "BS";
    };

    const data = {
      BS: {
        img: "https://oms.bayusinergi.com/api/media/bayu.png",
        website: "www.bayusinergi.co.id",
        email: "info@bayusinergi.co.id",
      },
      BSS: {
        img: "https://oms.bayusinergi.com/api/media/bss.png",
        website: "www.bs-sakti.co.id",
        email: "info@bs-sakti.co.id",
      },
      CTI: {
        img: "https://oms.bayusinergi.com/api/media/cti.png",
        website: "www.cakrabuana-ti.co.id",
        email: "info@cakrabuana-ti.co.id",
      },
    };

    const newCode = isCode(code);
    const transportir_name2 = transportir?.toLowerCase();
    const transportir_name1 = transportir_name2?.replace('(hauler)', '');
    const transportir_name = transportir_name1?.toUpperCase();
    const transportir_email2 = transportir_email?.substring(transportir_email?.indexOf("@") + 1);
    return (
      <div className="invoice-box" ref={ref}>
        <center>
          <table cellSpacing="0" cellPadding="0">
            <tr>
              <td style={{ width: 200 }}>
                <img
                  alt="petrasel"
                  src="https://oms.bayusinergi.com/api/media/petrasel.png"
                  style={{ width: 170, height: 80, float: "left" }}
                />
              </td>
              <td className="td-1">
                {transportir_pid === 1 && transportir_image !== 'NULL' ? (
                  <center>
                    <>
                      <h1>{transportir_name}</h1>
                      <br />
                      {transportir_address}
                      <br />
                    <a href={`www.${transportir_email2}`} className="a-1">
                      {transportir_email2}
                    </a>
                    &nbsp; email :{" "}
                    <a href={`mailto:${transportir_email}`} className="a-1">
                    {transportir_email}
                    </a>
                    </></center>
                ) : (
                  <center>
                    <img
                      alt="bayusinergi"
                      src={data?.[newCode]?.img}
                      style={{ width: "100%", height: 50 }}
                    />
                    <br />
                    h.o. : Surapati Core J-12, Ph. H. Mustofa 39 Bandung - 40192,
                    Jawa Barat, Indonesia {newCode === "CTI" && <br />}
                    {newCode !== "CTI" && (
                      <>
                        phone /
                        <br />
                        fax : +62 22 872 42 781 / +62 22 205 23592 <br />
                      </>
                    )}
                    r.o. : Kawasan Niaga CitraGran Blok R10/17 Jl. Alternatif
                    Cibubur, Bekasi - 17435, Indonesia
                    <br />
                    phone / fax : +62 21 2867 3233
                    <br />
                    Depo Palaran Panamean Terminal, Samarinda Kalimantan Timur ,
                    Indonesia
                    <br />
                    <br />
                    <a href="www.bayusinergi.co.id" className="a-1">
                      {data?.[newCode]?.website}
                    </a>
                    &nbsp; email :{" "}
                    <a href={`mailto:${data?.[newCode]?.email}`} className="a-1">
                      {data?.[newCode]?.email}
                    </a>
                  </center>
                )}
              </td>
              <td className="td-2">
                {transportir_pid === 1 && transportir_image !== 'NULL' ? (
                  <img
                    alt="bsgroup"
                    src="https://{transportir_image}"
                    style={{ height: 40, marginRight: 30, float: "right" }}
                  />
                ) : (
                  <img
                    alt="bsgroup"
                    src="https://oms.bayusinergi.com/api/media/bs-group.png"
                    style={{ height: 40, marginRight: 30, float: "right" }}
                  />
                )}
                <h4 style={{ clear: "both" }}>
                  <p className="bast">.{do_number}</p>
                  <p className="code">{code}&nbsp;</p>
                </h4>
              </td>
            </tr>
          </table>
        </center>
        <div className="div-1">
          <div className="div-2">
            <h4 className="title">
              <center>BERITA ACARA SERAH TERIMA</center>
            </h4>
          </div>
          <div className="div-3">
            <h4 className="sub-title">Surat Jalan</h4>
          </div>
        </div>
        <table>
          <tr>
            <td className="td-3">
              <table>
                <tr>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    Diserahkan kepada
                  </td>
                  <td valign="top">:</td>
                  <td valign="top" className="cust">
                    <b>{customer}</b>
                  </td>
                </tr>
                <tr>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    Alamat
                  </td>
                  <td valign="top">:</td>
                  <td valign="top">{address}</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td className="td-3">
              <table>
                <tr>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    Transportir
                  </td>
                  <td valign="top">:</td>
                  <td valign="top" className="cust">
                    <b>{transportir}</b>
                  </td>
                </tr>
                <tr>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    Alamat
                  </td>
                  <td valign="top">:</td>
                  <td valign="top">{transportir_address}</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td className="td-3">
              <table>
                <tr>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    Hari / Tanggal
                  </td>
                  <td valign="top">:</td>
                  <td valign="top" className="cust">
                    <b>{date}</b>
                  </td>
                </tr>
                <tr>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    No. PO
                  </td>
                  <td valign="top">:</td>
                  <td valign="top" className="cust">
                    <b>{po_number}</b>
                  </td>
                </tr>
                <tr>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    No. LO (B/L)
                  </td>
                  <td valign="top">:</td>
                  <td valign="top" className="cust">
                    <b>
                      {stock_id} ({lo_number})
                    </b>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td className="td-3">
              <table>
                <tr>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    <b>P R O D U K</b>
                  </td>
                  <td valign="top">:</td>
                  <td valign="top">{product}</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td className="td-3">
              <table>
                <tr>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    <b>SUPPLY POINT</b>
                  </td>
                  <td valign="top">:</td>
                  <td valign="top">{supply_point}</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td className="td-3">
              <table>
                <tr>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    K U A N T I T A S
                  </td>
                  <td valign="top">:</td>
                  <td valign="top" className="cust">
                    <b>{qty}</b> Liter
                  </td>
                </tr>
                <tr>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    Terbilang
                  </td>
                  <td valign="top">:</td>
                  <td valign="top" className="cust">
                    <b>{numberToWords(qty).toUpperCase()}</b>
                  </td>
                </tr>
                <tr>
                  <td>&nbsp;</td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td className="td-4">
              <b>DETAIL PENGIRIMAN</b>
            </td>
          </tr>
          <tr>
            <td className="td-3">
              <table>
                <tr>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    No. Kendaraan
                  </td>
                  <td valign="top">:</td>
                  <td valign="top" className="cust-1">
                    {vehicle_number}
                  </td>
                  <td valign="top" style={{ width: 10 }}>
                    &nbsp;
                  </td>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    Nama Penerima
                  </td>
                  <td valign="top">:</td>
                  <td valign="top" className="cust-1"></td>
                </tr>
                <tr>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    No. Segel Atas
                  </td>
                  <td valign="top">:</td>
                  <td valign="top" className="cust-1">
                    {segel_atas}
                  </td>
                  <td valign="top" style={{ width: 10 }}>
                    &nbsp;
                  </td>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    Phone
                  </td>
                  <td valign="top">:</td>
                  <td valign="top" className="cust-1"></td>
                </tr>
                <tr>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    No. Segel Bawah
                  </td>
                  <td valign="top">:</td>
                  <td valign="top" className="cust-1">
                    {segel_bawah}
                  </td>
                  <td valign="top" style={{ width: 10 }}>
                    &nbsp;
                  </td>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    Jam Mulai Bongkar
                  </td>
                  <td valign="top">:</td>
                  <td valign="top" className="cust-1"></td>
                </tr>
                <tr>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    SG Meter
                  </td>
                  <td valign="top">:</td>
                  <td valign="top" className="cust-1"></td>
                  <td valign="top" style={{ width: 10 }}>
                    &nbsp;
                  </td>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    Jam Selesai Bongkar
                  </td>
                  <td valign="top">:</td>
                  <td valign="top" className="cust-1"></td>
                </tr>
                <tr>
                  <td valign="top">&nbsp;</td>
                  <td valign="top">&nbsp;</td>
                  <td valign="top">&nbsp;</td>
                  <td valign="top" style={{ width: 10 }}>
                    &nbsp;
                  </td>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    Hasil Bongkar
                  </td>
                  <td valign="top">:</td>
                  <td valign="top" className="cust-1">
                    <div style={{ fontSize: 12, float: "right" }}>Liter</div>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
          <tr>
            <td style={{ width: 793 }}>
              <table>
                <tr>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    Catatan
                  </td>
                  <td valign="top">:</td>
                  <td valign="top" className="cust"></td>
                </tr>
                <tr>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    &nbsp;
                  </td>
                  <td valign="top">&nbsp;</td>
                  <td valign="top" className="cust"></td>
                </tr>
                <tr>
                  <td style={{ fontSize: 12, width: 125 }} valign="top">
                    &nbsp;
                  </td>
                  <td valign="top">&nbsp;</td>
                  <td valign="top" className="cust"></td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
        <p style={{ fontSize: 12 }}>
          <b>BS Representative</b>
          <br />
          <i style={{ fontSize: 11 }}>
            (This is computer generated document, no signature required)
          </i>
        </p>
        <table
          border="1"
          cellSpacing="0"
          cellPadding="0"
          style={{ width: "100%" }}
        >
          <tr>
            <td style={{ fontSize: 12, width: "33%", padding: "7px 0 7px 0" }}>
              <center>Mengetahui</center>
            </td>
            <td style={{ fontSize: 12, width: "33%" }}>
              <center>Pengemudi</center>
            </td>
            <td style={{ fontSize: 12, width: "33%" }}>
              <center>Penerima/Klien</center>
            </td>
          </tr>
          <tr>
            <td valign="bottom">
              <center>
                <img
                  alt="qr"
                  src={qr}
                  style={{ width: 75, height: 75, marginBottom: 70 }}
                />
              </center>
              <table style={{ width: "100%" }}>
                <tr>
                  <td style={{ fontSize: 12, width: "100%" }} align="center">
                    {operation}
                  </td>
                </tr>
              </table>
            </td>
            <td valign="bottom">
              <table style={{ width: "100%" }}>
                <tr>
                  <td align="center" style={{ fontSize: 12, width: "100%" }}>
                    {driver}
                  </td>
                </tr>
              </table>
            </td>
            <td style={{ padding: 3 }}>
              <b>
                <i style={{ fontSize: 12 }}>
                  Commodity Received in good order on behalf of customer
                </i>
              </b>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              Signature :
              <br />
              <br />
              Name :
            </td>
          </tr>
        </table>
        
        {transportir_pid === 1 && transportir_image !== 'NULL' ? (
        <h4>
          <b>
            <i>
              Pengaduan / Keluhan : {" "}
              <a
                href={`mailto:${transportir_email}`}
                style={{ textDecoration: "none", color: "#000" }}
              >
                {transportir_email}
              </a>
              (Email)
            </i>
          </b>
        </h4>
                ) : (
                  <h4>
                    <b>
                      <i>
                        Pengaduan / Keluhan : 081286351255 (Whatsap / call ) ,{" "}
                        <a
                          href={`mailto:${data?.[newCode]?.email}`}
                          style={{ textDecoration: "none", color: "#000" }}
                        >
                          {data?.[newCode]?.email}
                        </a>
                        (Email)
                      </i>
                    </b>
                  </h4>
                )}
      </div>
    );
  }
);

const BLTruck = React.forwardRef(
  (
    {
      po_number,
      do_number,
      supply_point,
      customer,
      address,
      transportir,
      transportir_address,
      product,
      qty,
      vehicle_number,
      driver,
      operation,
      stock_id,
    },
    ref
  ) => {
    const [qr, setQr] = useState("");

    useEffect(() => {
      const getQr = async () => {
        try {
          const qrcode = await QRCode.toDataURL(`${po_number}`);

          setQr(qrcode);
        } catch (error) {
          console.log(error);
        }
      };

      if (po_number) {
        getQr();
      }
    }, [po_number]);

    return (
      <div className="blt-box" ref={ref}>
        <center>
          <table id="table1" cellSpacing={0} cellPadding={0}>
            <tr>
              <td>
                <img
                  alt="petrasel"
                  src="https://oms.bayusinergi.com/api/media/petrasel.png"
                  style={{ width: 250, height: 120, float: "left" }}
                />
              </td>
              <td>
                <h2 id="h21">PT BAYU SINERGI</h2>
                <p style={{ fontSize: 14, marginTop: 8 }}>
                  Surapati Core J-12,
                  <br />
                  Ph. H. Mustofa 39 Bandung - 40192, <br />
                  West Java, Indonesia
                  <br />
                  Phone : +62 22 872 42781
                  <br />
                </p>
              </td>
              <td>
                <h2 id="h22">
                  <i>
                    Bill Of <br /> Loading
                    <br />
                    Truck Loading
                  </i>
                </h2>
              </td>
              <td>
                <table id="table2" cellSpacing={0} cellPadding={0}>
                  <tr>
                    <td>Trip No.</td>
                    <td>:</td>
                    <td width="205">{do_number}</td>
                  </tr>
                  <tr>
                    <td>Order Number</td>
                    <td>:</td>
                    <td width="205">{stock_id}</td>
                  </tr>
                  <tr>
                    <td>Enter Terminal</td>
                    <td>:</td>
                    <td
                      contentEditable="true"
                      width="205"
                      style={{ maxWidth: 205 }}
                    ></td>
                  </tr>
                  <tr>
                    <td>Exit Terminal</td>
                    <td>:</td>
                    <td
                      contentEditable="true"
                      width="205"
                      style={{ maxWidth: 205 }}
                    ></td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table id="table3">
            <tr>
              <td width="280">
                <b>Consignor</b>
                <table id="table4">
                  <tr>
                    <td width="75">Name</td>
                    <td>:</td>
                    <td>
                      <h4 id="h21">PT BAYU SINERGI</h4>
                    </td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>:</td>
                    <td>
                      Surapati Core J-12, Ph. H. Mustofa 39 Bandung - 40192,
                      West Java, Indonesia
                    </td>
                  </tr>
                  <tr>
                    <td>Tel</td>
                    <td>:</td>
                    <td>+62 22 872 47281</td>
                  </tr>
                  <tr>
                    <td>Fax</td>
                    <td>:</td>
                    <td>+62 22 205 23592</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>:</td>
                    <td>
                      <a href="mailto:sales@bayusinergi.co.id">
                        sales@bayusinergi.co.id
                      </a>{" "}
                      /{" "}
                      <a href="mailto:info@bayusinergi.co.id">
                        info@bayusinergi.co.id
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td style={{ height: 36, maxHeight: 36 }}></td>
                  </tr>
                </table>
              </td>
              <td width="280">
                <b>Consigne</b>
                <table id="table4">
                  <tr>
                    <td width="75">Name</td>
                    <td>:</td>
                    <td width="205">{customer}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>:</td>
                    <td style={{ height: 32, maxHeight: 32 }}>{address}</td>
                  </tr>
                  <tr>
                    <td>Tel</td>
                    <td>:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Fax</td>
                    <td>:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Attn</td>
                    <td>:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Reference</td>
                    <td>:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>PO #</td>
                    <td>:</td>
                    <td>{po_number}</td>
                  </tr>
                </table>
              </td>
              <td width="280">
                <b>Commodity</b>
                <table id="table4" width="280">
                  <tr>
                    <td>
                      <table id="table5">
                        <tr>
                          <td>
                            <u>Recipe</u> <u>Name</u>
                          </td>
                          <td>
                            <u>:</u>
                          </td>
                          <td></td>
                        </tr>
                      </table>
                      <br />
                      <table id="table6">
                        <tr>
                          <td width="125">
                            <u>Product</u>
                          </td>
                          <td>
                            <u>Dosage</u> <u>(mL/L)</u>
                          </td>
                          <td></td>
                        </tr>
                        <tr>
                          <td>{product}</td>
                          <td>0,845</td>
                          <td>Kg/&#13221;</td>
                        </tr>
                      </table>
                      <br />
                      <table id="table7">
                        <tr>
                          <td> Loading Terminal :</td>
                        </tr>
                        <tr>
                          <td style={{ height: 45, maxHeight: 45 }}>
                            {supply_point}
                          </td>
                        </tr>
                      </table>
                    </td>
                  </tr>
                </table>
              </td>
              <td width="280">
                <b>Hauler</b>
                <table id="table4">
                  <tr>
                    <td width="75">Name</td>
                    <td>:</td>
                    <td width="205">{transportir}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>:</td>
                    <td style={{ height: 46, maxHeight: 46 }}>
                      {transportir_address}
                    </td>
                  </tr>
                  <tr>
                    <td>Tel</td>
                    <td>:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Fax</td>
                    <td>:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td height="18">Email</td>
                    <td>:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Vehicle</td>
                    <td>:</td>
                    <td>{vehicle_number}</td>
                  </tr>
                  <tr>
                    <td>Driver</td>
                    <td>:</td>
                    <td>{driver}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          <table>
            <tr>
              <td>
                <b>Loading Details</b>
                <table border={1} cellPadding={0} cellSpacing={0} id="table8">
                  <tr>
                    <td width="110">SeqNo</td>
                    <td width="165">GrossLoaded</td>
                    <td width="165">Average Temperature</td>
                    <td width="300">Seal Number</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>{qty}</td>
                    <td contentEditable="true" style={{ maxWidth: 165 }}></td>
                    <td contentEditable="true" style={{ maxWidth: 220 }}></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td contentEditable="true" style={{ maxWidth: 165 }}></td>
                    <td contentEditable="true" style={{ maxwidth: 220 }}></td>
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    <td contentEditable="true" style={{ maxWidth: 165 }}></td>
                    <td contentEditable="true" style={{ maxWidth: 220 }}></td>
                  </tr>
                </table>
                <table
                  border={1}
                  cellPadding={0}
                  cellSpacing={0}
                  id="table8"
                  style={{ marginTop: -1 }}
                >
                  <tr>
                    <td width="108">Total</td>
                    <td width="162">{qty}</td>
                  </tr>
                </table>
              </td>
              <td>&nbsp;</td>
              <td vertical-align="top">
                <b>Remarks</b>
                <table id="table4">
                  <tr>
                    <td width="350" height="120"></td>
                  </tr>
                </table>
                <br />
              </td>
            </tr>
          </table>
          <table id="table10">
            <tr>
              <td width="360" valign="top">
                <b>BS Representative</b>
                <br />
                <i style={{ fontSize: 14 }}>
                  (This is Computer Generated B/L, no signature required)
                </i>
                <table style={{ marginTop: 10 }} id="table9">
                  <tr>
                    <td>Name</td>
                    <td>:</td>
                    <td>{operation}</td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td>:</td>
                    <td width="300">{formatDateBAST(new Date())}</td>
                  </tr>
                  <tr>
                    <td align="center" colSpan={3}>
                      <img
                        alt="qr"
                        src={qr}
                        style={{ width: 55, height: 55 }}
                      />
                    </td>
                  </tr>
                </table>
              </td>
              <td width="360">
                Perhatian :<br />
                <i style={{ fontSize: 12 }}>
                  Pemalsuan Surat atau manipulasi Informasi Elektronik dan/atau
                  Dokumen Elektronik dapat dikenakan Pasal 263 KUHP dengan
                  ancaman pidana paling lama 6 tahun penjara; dan/atau Pasal 35
                  dan Pasal 51 ayat 1, UU ITE No 11 Tahun 2008 dengan ancaman
                  hukuman paling lama 12 tahun penjara dan/atau denda paling
                  banyak Rp12 Miliar
                </i>
              </td>
              <td width="350">
                <b style={{ fontSize: 12 }}>
                  Commodity Received in good order and
                  <br /> condition by customer or hauler for or on <br /> behalf
                  of customer
                </b>
                <br />
                <div
                  width="350"
                  style={{ borderBottom: "2px solid #000" }}
                ></div>
                Authorized Signature
                <table id="table9">
                  <tr>
                    <td>Name</td>
                    <td>:</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td>:</td>
                    <td>&nbsp;</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </center>
        <div
          style={{
            display: "flex",
            flex: 1,
            fontSize: 12,
            justifyContent: "end",
          }}
        >
          <div style={{ width: 350 }}>
            Form No : form.oms.bs-{new Date().getFullYear()}
          </div>
        </div>
      </div>
    );
  }
);

const BLVessel = React.forwardRef(
  (
    {
      po_number,
      do_number,
      lo_number,
      supply_point,
      customer,
      address,
      transportir,
      transportir_address,
      product,
      qty,
      vehicle_number,
      driver,
      operation,
      stock_id,
      type,
      transaction_type,
    },
    ref
  ) => {
    const [qr, setQr] = useState("");

    useEffect(() => {
      const getQr = async () => {
        try {
          const qrcode = await QRCode.toDataURL(`${po_number}`);

          setQr(qrcode);
        } catch (error) {
          console.log(error);
        }
      };

      if (po_number) {
        getQr();
      }
    }, [po_number]);

    return (
      <div className="blt-box2" ref={ref}>
        <center>
          {/* Header */}
          <table id="table12" cellspacing={0} cellpadding={0}>
            <tr>
              <td style={{ width: 450 }}>
                <h1 id="h212" style={{ fontSize: 45 }}>
                  PT BAYU SINERGI
                </h1>
                <p>
                  Surapati Core J-12,
                  <br />
                  Ph. H. Mustofa 39 Bandung - 40192, West Java, Indonesia
                  <br />
                  Phone : +62 22 872 42781
                  <br />
                </p>
              </td>
              <td style={{ width: 250 }}>
                <img
                  alt="petrasel"
                  src="https://oms.bayusinergi.com/api/media/petrasel2.png"
                  style={{ width: 250, height: 100, float: "left" }}
                />
              </td>
              <td style={{ width: 350 }}>
                <div style={{ float: "right" }}>
                  <h1 id="h222">
                    <i>Bill Of Loading</i>
                  </h1>
                  <h2 id="h222" style={{ float: "right" }}>
                    <i>Ship Loading</i>
                  </h2>
                </div>
              </td>
            </tr>
          </table>
          {/* End Header */}
          {/* Middle */}
          <table id="table22">
            <tr>
              <td width="280">
                <b>Consignor</b>
                <table id="table32">
                  <tr>
                    <td width="100">Company Name</td>
                    <td>:</td>
                    <td>
                      <h4 id="h212">PT BAYU SINERGI</h4>
                    </td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>:</td>
                    <td>
                      Surapati Core J-12, Ph. H. Mustofa 39 Bandung - 40192,
                      West Java, Indonesia
                    </td>
                  </tr>
                  <tr>
                    <td>Telephone</td>
                    <td>:</td>
                    <td>+62 22 872 47281</td>
                  </tr>
                  <tr>
                    <td>Fax</td>
                    <td>:</td>
                    <td>+62 22 205 23592</td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>:</td>
                    <td>
                      <a href="mailto:sales@bayusinergi.co.id">
                        sales@bayusinergi.co.id
                      </a>{" "}
                      /{" "}
                      <a href="mailto:info@bayusinergi.co.id">
                        info@bayusinergi.co.id
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Website</td>
                    <td>:</td>
                    <td>
                      <a href="www.bayusinergi.co.id">www.bayusinergi.co.id</a>
                    </td>
                  </tr>
                </table>
              </td>
              <td width="280">
                <b>Consigne</b>
                <table id="table32">
                  <tr>
                    <td width="75">Name</td>
                    <td>:</td>
                    <td width="205">{customer}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>:</td>
                    <td style={{ height: 73, maxHeight: 73 }}>{address}</td>
                  </tr>
                  <tr>
                    <td>Tel</td>
                    <td>:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Fax</td>
                    <td>:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>Email</td>
                    <td>:</td>
                    <td></td>
                  </tr>
                  <tr>
                    <td>PIC #</td>
                    <td>:</td>
                    <td></td>
                  </tr>
                </table>
              </td>
              <td width="525">
                <b>Transport</b>
                <table id="table42">
                  <tr>
                    <td width="125">Company Name</td>
                    <td>:</td>
                    <td width="400">{transportir}</td>
                  </tr>
                  <tr>
                    <td>Vessel Name</td>
                    <td>:</td>
                    <td>{vehicle_number}</td>
                  </tr>
                  <tr>
                    <td>Carrier Flag</td>
                    <td>:</td>
                    <td
                      contentEditable="true"
                      width="150"
                      style={{ maxWidth: 150 }}
                    />
                  </tr>
                  <tr>
                    <td>Master Name</td>
                    <td>:</td>
                    <td>{driver}</td>
                  </tr>
                  <tr>
                    <td>
                      Loading Port /<br />
                      Supply Point
                    </td>
                    <td>:</td>
                    <td>{supply_point}</td>
                  </tr>
                  <tr>
                    <td>Discharge Port</td>
                    <td>:</td>
                    <td>{transportir_address}</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          {/* End Middle */}
          {/* Content */}
          <div style={{ float: "left", marginLeft: 15 }}>
            {" "}
            <b style={{ float: "left" }}>Loading Details</b>
            <table
              id="table52"
              cellspacing={0}
              cellpadding={0}
              border="1"
              style={{ clear: "both" }}
            >
              <tr>
                <td width="100">
                  <center>Unit Volume</center>
                </td>
                <td width="145">
                  <center>GrossLoaded</center>
                </td>
              </tr>
              <tr>
                <td>GOV (KL)</td>
                <td>{qty}</td>
              </tr>
              <tr>
                <td>GSV 15C (KL)</td>
                <td></td>
              </tr>
              <tr>
                <td>MASS (MT)</td>
                <td></td>
              </tr>
              <tr>
                <td>LONG TONS</td>
                <td></td>
              </tr>
            </table>
          </div>
          <div style={{ float: "left", marginLeft: 15 }}>
            {" "}
            <b style={{ float: "left" }}>Movement</b>
            <table id="table32a" style={{ clear: "both" }}>
              <tr>
                <td width="75">Operation #</td>
                <td>:</td>
                <td width="175">{stock_id}</td>
              </tr>
              <tr>
                <td>BL Number #</td>
                <td>:</td>
                <td>
                  {stock_id} ({lo_number})
                </td>
              </tr>
              <tr>
                <td>Starting Time</td>
                <td>:</td>
                <td
                  contentEditable="true"
                  width="150"
                  style={{ maxWidth: 150 }}
                />
              </tr>
              <tr>
                <td>End Time</td>
                <td>:</td>
                <td
                  contentEditable="true"
                  width="150"
                  style={{ maxWidth: 150 }}
                />
              </tr>
            </table>
          </div>
          <div style={{ float: "left", marginLeft: 15 }}>
            {" "}
            <b style={{ float: "left" }}>Commodity</b>
            <table id="table62" style={{ clear: "both" }}>
              <tr>
                <td width="75">Recipe Name</td>
                <td>:</td>
                <td width="125">{product}</td>
              </tr>
              <tr>
                <td>Product Type</td>
                <td>:</td>
                <td></td>
              </tr>
              <tr>
                <td>Density @ 15C</td>
                <td>:</td>
                <td>0,845 Kg/&#13221;</td>
              </tr>
            </table>
          </div>
          <div style={{ float: "left", marginLeft: 15 }}>
            {" "}
            <b style={{ float: "left" }}>Transaction</b>
            <table id="table72" style={{ clear: "both" }}>
              <tr>
                <td width="75" style={{ paddingTop: 4, paddingBottom: 4 }}>
                  Reference
                </td>
                <td>:</td>
                <td width="190"></td>
              </tr>
              <tr>
                <td style={{ paddingTop: 4, paddingBottom: 4 }}>PO #</td>
                <td>:</td>
                <td>{po_number}</td>
              </tr>
              <tr>
                <td colspan={3}>
                  <table>
                    <tr>
                      <td width="90">Transaction Type</td>
                      <td>:</td>
                      <td>{transaction_type}</td>
                    </tr>
                  </table>
                </td>
              </tr>
            </table>
          </div>
          <div style={{ float: "left", marginLeft: 15 }}>
            {" "}
            <b style={{ float: "left" }}>Remarks</b>
            <table id="table72" style={{ clear: "both" }}>
              <tr>
                <td width="795">
                  <br />
                  <br />
                  <br />
                  <br />
                </td>
              </tr>
            </table>
          </div>
          {/* Content End */}
          {/* Footer */}
          <div style={{ clear: "both" }}>&nbsp;</div>
          <table id="table102" style={{ clear: "both" }}>
            <tr>
              <td
                width="320"
                valign="top"
                style={{ backgroundColor: "blanchedalmond" }}
              >
                <b>BS Representative</b>
                <br />
                <i style={{ fontSize: 12 }}>
                  (This is Computer Generated B/L, no signature required)
                </i>
                <div
                  width="265"
                  style={{ borderBottom: "2px solid #000", marginBottom: 10 }}
                ></div>
                Authorized Signature
                <table style={{ marginTop: 10 }} id="table92">
                  <tr>
                    <td>Name</td>
                    <td>:</td>
                    <td>{operation}</td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td>:</td>
                    <td>{formatDateBAST(new Date())}</td>
                  </tr>
                </table>
              </td>
              <td
                width="480"
                style={{ textAlign: "justify", verticalAlign: "top" }}
              >
                <i style={{ fontSize: 10, color: "red" }}>
                  Pemalsuan Surat atau manipulasi Informasi Elektronik dan/atau
                  Dokumen Elektronik dapat dikenakan Pasal 263 KUHP dengan
                  ancaman pidana paling lama 6 tahun penjara; dan/atau Pasal 35
                  dan Pasal 51 ayat 1, UU ITE No 11 Tahun 2008 dengan ancaman
                  hukuman paling lama 12 tahun penjara dan/atau denda paling
                  banyak Rp12 Miliar
                </i>
                <img alt="qr" src={qr} style={{ width: 100, height: 100 }} />
              </td>
              <td width="265" style={{ verticalAlign: "top" }}>
                <b style={{ fontSize: 10 }}>
                  Commodity Received in good order and condition
                  <br /> by customer or hauler for or on behalf of customer
                </b>
                <br />
                <div
                  width="265"
                  style={{ borderBottom: "2px solid #000", marginBottom: 10 }}
                ></div>
                Authorized Signature
                <table style={{ marginTop: 10 }} id="table92">
                  <tr>
                    <td>Name</td>
                    <td>:</td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td>:</td>
                    <td>&nbsp;</td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
          {/* Footer End */}
        </center>
        <div
          style={{
            display: "flex",
            flex: 1,
            fontSize: 12,
            justifyContent: "end",
          }}
        >
          <div style={{ width: 270 }}>
            Form No : form.oms.bs-{new Date().getFullYear()}
          </div>
        </div>
      </div>
    );
  }
);

const LoadingWrap = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  height: 250,
  alignItems: "center",
  justifyContent: "center",
}));

export default ListDeliveryOrder;
