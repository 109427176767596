//
import { useMemo, useState, useEffect } from "react";

//
import users from "../../../../constant/users";

function GetSales(role) {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    const getAll = async () => {
      setLoading(true);
      try {
        const res = await users.getSales();

        setData(res.data);
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };

    if (role !== "sales rep") {
      getAll();
    }
  }, [role]);

  return useMemo(() => [data, loading, error], [data, loading, error]);
}

export default GetSales;
