import { Icon } from "@iconify/react";
// material
import { Card, Typography, styled, alpha } from "@mui/material";
//
import { formatThousand } from "../utils";

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: 16,
  color: theme.palette.text.primary,
  backgroundColor: "#FFFFFF",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#F5F5F5",
  },
}));

const IconWrapperStyle = styled("div")(({ theme }) => ({
  display: "flex",
  borderRadius: "50%",
  alignItems: "center",
  width: 48,
  height: 48,
  justifyContent: "center",
  color: theme.palette.primary.dark,
  backgroundImage: `linear-gradient(135deg, ${alpha(
    theme.palette.primary.dark,
    0
  )} 0%, ${alpha(theme.palette.primary.dark, 0.24)} 100%)`,
}));

// ----------------------------------------------------------------------

export default function DashboardCard({ onClick, title, number, icon }) {
  return (
    <RootStyle onClick={onClick}>
      <IconWrapperStyle>
        <Icon icon={icon} width={24} height={24} />
      </IconWrapperStyle>
      <div style={{ marginLeft: 16 }}>
        <Typography
          variant="subtitle2"
          sx={{ opacity: 0.72, fontSize: "12px" }}
        >
          {title}
        </Typography>
        <Typography variant="h5">{formatThousand(number)}</Typography>
      </div>
    </RootStyle>
  );
}
