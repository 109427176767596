// React
import { useEffect, useState } from "react";

// Material UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
  Stack,
  Divider,
  styled,
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers'

//
import { formatDate, formatThousand } from "../../../utils";

//
import { toast } from "react-toastify";

//
import receiptOrder from "../../../constant/receiptOrder";
import socket from "../../../constant/socket";
import { NumberFormatQty } from "../../../components";

function CreateReceiptOrder({ open, mode = "Add", data, refresh, onClose }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form, setForm] = useState({
    recipient: "",
    recipient_contact: "",
    received_date: "",
    received_volume: "",
    note: "",
  });

  const [error, setError] = useState({
    recipient: "",
    recipient_contact: "",
    received_date: "",
    received_volume: "",
  });

  const validate = () => {
    const newError = { ...error };

    if (!form.recipient) {
      newError.recipient = "Reqcipient required!";
    }

    if (!form.recipient_contact) {
      newError.recipient_contact = "Reqcipient Contact required!";
    }

    if (!form.received_date) {
      newError.received_date = "Received Date required!";
    }

    if (!form.received_volume) {
      newError.received_volume = "Received Volume required!";
    }

    return newError;
  };

  const handleSubmit = async () => {
    const findErrors = validate();

    if (Object.values(findErrors).some((e) => e !== "")) {
      setError(findErrors);
    } else {
      try {
        setIsSubmitting(true);

        const {
          do_number,
          lo_number,
          so_number,
          po_number,
          address,
          customer,
        } = data;

        const { received_volume, ...rest } = form;

        const newData = {
          do_number,
          lo_number,
          so_number,
          po_number,
          customer_id: customer?.id,
          address,
          received_volume: parseInt(received_volume),
          ...rest,
        };

        await receiptOrder.create(newData);

        socket.emit("send-load");

        setForm({
          recipient: "",
          recipient_contact: "",
          received_date: "",
          received_volume: "",
          note: "",
        });

        setError({
          recipient: "",
          recipient_contact: "",
          received_date: "",
          received_volume: "",
        });

        refresh();

        onClose();

        setIsSubmitting(false);

        toast.success("Receipt Order Submitted!");
      } catch (e) {
        console.log(e);
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (mode === "Add" && data) {
      setForm((f) => ({
        ...f,
        recipient: data?.recipient,
        recipient_contact: data?.recipient_contact,
        received_volume: data?.received_volume,
      }));
    }
  }, [data, mode]);

  return (
    <Dialog open={open} fullWidth maxWidth="xl">
      <DialogTitle>{mode === "Add" && "Create"} Receipt Order</DialogTitle>
      <DialogContent>
        <Grid container direction="row" spacing={3}>
          {/* Section 1 */}
          <Grid item lg={6}>
            {/* DO Number */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
            >
              <Typography style={{ width: 150 }}>DO Number</Typography>
              <CustomTypography>{data?.do_number ?? "-"}</CustomTypography>
            </Stack>

            {/* LO Number */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>LO Number</Typography>
              <CustomTypography>{data?.lo_number ?? "-"}</CustomTypography>
            </Stack>

            {/* Customer */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Customer</Typography>
              <CustomTypography>{data?.customer?.name ?? "-"}</CustomTypography>
            </Stack>

            {/* Ship To Address */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Ship To Address</Typography>
              <CustomTypographyAddress>
                {data?.address ?? "-"}
              </CustomTypographyAddress>
            </Stack>
          </Grid>

          {/* Section 2 */}
          <Grid item lg={6}>
            {/* SO Number */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
            >
              <Typography style={{ width: 150 }}>SO Number</Typography>
              <CustomTypography>{data?.so_number ?? "-"}</CustomTypography>
            </Stack>

            {/* PO Number */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>PO Number</Typography>
              <CustomTypography>{data?.po_number ?? "-"}</CustomTypography>
            </Stack>

            {/* Recipient */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Recipient</Typography>
              {mode === "Add" ? (
                <CustomTextField
                  size="small"
                  value={form.recipient}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      recipient: e.target.value,
                    }));

                    setError((e) => ({
                      ...e,
                      recipient: "",
                    }));
                  }}
                  error={error.recipient ? true : false}
                  helperText={error.recipient}
                  disabled={isSubmitting}
                />
              ) : (
                <CustomTypography>{data?.recipient ?? "-"}</CustomTypography>
              )}
            </Stack>

            {/* Recipient Contact */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Recipient Contact</Typography>
              {mode === "Add" ? (
                <CustomTextField
                  size="small"
                  value={form.recipient_contact}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      recipient_contact: e.target.value,
                    }));

                    setError((e) => ({
                      ...e,
                      recipient_contact: "",
                    }));
                  }}
                  error={error.recipient_contact ? true : false}
                  helperText={error.recipient_contact}
                  disabled={isSubmitting}
                />
              ) : (
                <CustomTypography>
                  {data?.recipient_contact ?? "-"}
                </CustomTypography>
              )}
            </Stack>

            {/* Received Date */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Received Date</Typography>
              {mode === "Add" ? (
                <DatePicker
                  onChange={(v) => {
                    setForm((f) => ({
                      ...f,
                      received_date: v,
                    }));
                    setError((e) => ({
                      ...e,
                      received_date: "",
                    }));
                  }}
                  value={form.received_date}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      size="small"
                      error={error.received_date ? true : false}
                      helperText={error.received_date}
                      fullWidth
                    />
                  )}
                  disabled={isSubmitting}
                />
              ) : (
                <CustomTypography>
                  {formatDate(data?.received_date) ?? "-"}
                </CustomTypography>
              )}
            </Stack>

            {/* Received Volume */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Received Volume</Typography>
              {mode === "Add" ? (
                <CustomTextField
                  name="qty"
                  size="small"
                  value={form.received_volume}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      received_volume: e.target.value,
                    }));

                    setError((e) => ({
                      ...e,
                      received_volume: "",
                    }));
                  }}
                  error={error.received_volume ? true : false}
                  helperText={error.received_volume}
                  InputProps={{
                    inputComponent: NumberFormatQty,
                  }}
                  disabled={isSubmitting}
                />
              ) : (
                <CustomTypography>
                  {formatThousand(data?.received_volume ?? 0)} L
                </CustomTypography>
              )}
            </Stack>
          </Grid>
        </Grid>

        {/* Note */}
        {mode === "Add" ? (
          <TextField
            label="Note"
            onChange={(e) => {
              setForm((f) => ({
                ...f,
                note: e.target.value,
              }));
            }}
            value={form?.note}
            multiline
            minRows={4}
            maxRows={4}
            fullWidth
            sx={{ mt: 3 }}
            disabled={isSubmitting}
          />
        ) : (
          <TextField
            label="Note"
            disabled
            value={data?.note}
            multiline
            minRows={4}
            maxRows={4}
            fullWidth
            sx={{ mt: 3 }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            setForm({
              recipient: "",
              recipient_contact: "",
              received_date: "",
              received_volume: "",
              note: "",
            });

            setError({
              recipient: "",
              recipient_contact: "",
              received_date: "",
              received_volume: "",
            });

            onClose();
          }}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        {mode === "Add" && (
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

// Custom
const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  lineHeight: "40px",
  wordBreak: "break-word",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const CustomTypographyAddress = styled(Typography)(({ theme }) => ({
  lineHeight: "40px",
  wordBreak: "break-word",
  width: "65%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export default CreateReceiptOrder;
