import axios from "axios";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  sendMessage: (chat_id, message) => {
    return process.env.REACT_APP_PREPROD === "YES"
      ? true
      : process.env.REACT_APP_PROD === "YES" &&
          axios.post(
            `https://api.telegram.org/bot5045693067:AAFJf0Ihg8tYQVwdBb9sIfh42IfQtZ2l9ls/sendMessage?chat_id=-${chat_id}&text=${message}`
          );
  },
};
