//
import { useState, useEffect } from "react";

// material
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";

//
import { NumberFormatRp } from "../../../components";

//
import priceProduct from "../../../constant/priceProduct";

//
import { toast } from "react-toastify";

//
import { GetRegion, GetCustomerType } from "./API";

//
import { useParams } from "react-router-dom";

function AddPriceProduct({ onClose, load, dialog: { open, mode, data } }) {
  const { id } = useParams();

  const [listRegion, loading] = GetRegion();

  const [listType, loadingType] = GetCustomerType();

  const [form, setForm] = useState({
    region: null,
    type: null,
    price: "",
    start_at: "",
  });

  const [error, setError] = useState({
    region: "",
    type: "",
    price: "",
    start_at: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((f) => ({
      ...f,
      [name]: value,
    }));

    setError((f) => ({
      ...f,
      [name]: "",
    }));
  };

  const validate = () => {
    const newError = { ...error };
    const { region, type, price, start_at } = form;

    if (region === null) {
      newError.region = "Region can't be empty";
    }

    if (type === null) {
      newError.type = "Type can't be empty";
    }

    if (!price) {
      newError.price = "Price can't be empty";
    }

    if (!start_at) {
      newError.start_at = "Start active date can't be empty";
    }

    return newError;
  };

  const handleSubmit = async () => {
    const findError = validate();

    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setIsSubmitting(true);
      try {
        const newData = {
          region_id: form?.region?.id,
          type_id: form?.type?.id,
          price: parseInt(form?.price),
          product_id: parseInt(id),
          start_at: form?.start_at,
        };

        if (mode === "Edit") {
          await priceProduct.update(data?.id, newData);

          toast.success("Price Product Edited");
        } else {
          await priceProduct.create(newData);

          toast.success("Price Product Added");
        }

        setForm({
          region: null,
          type: null,
          price: "",
          start_at: "",
        });

        setError({
          region: "",
          type: "",
          price: "",
          start_at: "",
        });

        onClose();

        load();
      } catch (e) {
        console.log(e);
      }
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (mode === "Edit" && data) {
      setForm(data);
    }
  }, [mode, data]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isSubmitting) {
          onClose();
          setForm({
            region: null,
            type: null,
            price: "",
            start_at: "",
          });
          setError({
            region: "",
            type: "",
            price: "",
            start_at: "",
          });
        }
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>
        {mode === "Edit" ? "Edit" : "Add New"} Price Product
      </DialogTitle>
      <DialogContent dividers>
        <Autocomplete
          fullWidth
          getOptionLabel={(option) => option.name}
          options={listRegion}
          loading={loading}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Region"
              error={error.region ? true : false}
              helperText={error.region}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
          onChange={(e, v) => {
            setForm((f) => ({
              ...f,
              region: v,
            }));

            setError((err) => ({
              ...err,
              region: "",
            }));
          }}
          value={form.region}
          disabled={isSubmitting}
          sx={{ mb: 2 }}
        />
        <Autocomplete
          fullWidth
          getOptionLabel={(option) => option.name}
          options={listType}
          loading={loadingType}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Customer Type"
              error={error.type ? true : false}
              helperText={error.type}
              InputProps={{
                ...params.InputProps,
              }}
            />
          )}
          onChange={(e, v) => {
            setForm((f) => ({
              ...f,
              type: v,
            }));

            setError((err) => ({
              ...err,
              type: "",
            }));
          }}
          value={form.type}
          disabled={isSubmitting}
          sx={{ mb: 2 }}
        />
        <TextField
          name="price"
          label="Price"
          InputProps={{
            inputComponent: NumberFormatRp,
          }}
          autoComplete="off"
          fullWidth
          sx={{ mb: 2 }}
          error={error.price ? true : false}
          value={form.price}
          onChange={handleChange}
          helperText={error.price}
          disabled={isSubmitting}
        />
        <DatePicker
          onChange={(v) => {
            setForm((f) => ({
              ...f,
              start_at: v,
            }));
            setError((e) => ({
              ...e,
              start_at: "",
            }));
          }}
          value={form.start_at}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Start Active Date"
              fullWidth
              error={error.start_at ? true : false}
              helperText={error.start_at}
            />
          )}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <LoadingButton
          onClick={handleSubmit}
          loading={isSubmitting}
          variant="contained"
        >
          {mode === "Edit" ? "Edit" : "Add"} Price Product
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddPriceProduct;
