// Raect
import { useState } from "react";

// Material UI
import {
  Container,
  Stack,
  Typography,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@mui/material";

// Components
import {
  ListHead,
  LoadingPage,
  Page,
  SearchNotFound,
  TableToolBar,
} from "../../../components";

//
import { GetAll } from "./API";

//
import { applySortFilter, getComparator } from "../../../utils";

//
import AddAccessRole from "./AddAccessRole";

//
import { useHistory } from "react-router-dom";

// Table Head
const TABLE_HEAD = [
  { id: "no", label: "No", alignRight: false, isSort: true },
  { id: "name", label: "Role", alignRight: false, isSort: true },
  { id: "total_page", label: "Total Page", alignRight: false, isSort: true },
];

function AccessRole() {
  const history = useHistory();

  const [listAccessRole, loading, refresh] = GetAll();

  const [q, setQ] = useState("");

  const [dialog, setDialog] = useState({
    open: false,
    mode: "Add",
    data: {},
  });

  const [no, setNo] = useState(0);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("name");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setNo(no + 5);
    } else {
      setNo(no - 5);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <LoadingPage />;
  }

  const accessRoleList = applySortFilter(
    listAccessRole,
    getComparator(order, orderBy),
    q,
    "name"
  );

  const isNotFound = accessRoleList?.length === 0;

  return (
    <Page title="Access Role | Bayu Sinergi">
      <Container maxWidth="xl">
        {/* Header */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            List Access Role
          </Typography>
        </Stack>

        <Card>
          {/* Search */}
          <TableToolBar
            value={q}
            onChange={(e) => {
              setQ(e.target.value);
              setPage(0);
            }}
            placeholder="Search Access Role"
            onClear={() => setQ("")}
          />

          {/* Table */}
          <TableContainer sx={{ minWidth: 200 }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {accessRoleList
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return (
                      <TableRow
                        key={index}
                        onClick={() => {
                          history.push(`/access-role/${item?.id}`, {
                            title: item?.name,
                          });
                        }}
                        sx={{ cursor: "pointer" }}
                        hover
                      >
                        <TableCell>{index + 1 + no}</TableCell>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell>
                          {item?.total_pages}{" "}
                          {item?.total_pages > 1 ? "Pages" : "Page"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={3} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={q} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={accessRoleList?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* Add Access Role */}
      <AddAccessRole
        dialog={dialog}
        load={() => {
          refresh(true);
        }}
        onClose={() => {
          setDialog({
            open: false,
            mode: "Add",
            data: {},
          });
        }}
      />
    </Page>
  );
}

export default AccessRole;
