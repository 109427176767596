// React
import React, { useState } from "react";

// Material UI
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Stack,
  Typography,
  Divider,
  styled,
  TextField,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  DialogActions,
  Button,
  Autocomplete,
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers'

// Components
import { NumberFormatQty } from "../../../components";

// Utils
import { formatDate, formatThousand } from "../../../utils";

// API
import { GetAll } from "../ListOrder/API";
import socket from "../../../constant/socket";

//
import loadingOrder from "../../../constant/loadingOrder";

//
import { toast } from "react-toastify";

//
import { useData } from "../../../context/DataProvider";

function CreateLoadingOrder({
  dialog: { open, mode, data, remainingQty, orderLine },
  refresh,
  onClose,
}) {
  const { user } = useData();

  const [listHauler, loading] = GetAll();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [form, setForm] = useState({
    stock_id: "",
    hauler: null,
    earliest_date: "",
    latest_date: "",
    qty: "",
    note: "",
  });

  const [error, setError] = useState({
    stock_id: "",
    hauler: "",
    earliest_date: "",
    latest_date: "",
    qty: "",
  });

  const validate = () => {
    const newError = { ...error };

    if (user.role !== "sales admin" && !form.stock_id) {
      newError.stock_id = "Stock ID required!";
    }

    if (user.role === "sales admin" && form.hauler === null) {
      newError.hauler = "Hauler required!";
    }

    if (user.role === "sales admin" && !form.earliest_date) {
      newError.earliest_date = "Earliest Pickup Date required!";
    }

    if (user.role === "sales admin" && !form.latest_date) {
      newError.latest_date = "Latest Pickup Date required!";
    }

    if (user.role === "sales admin" && !form.qty) {
      newError.qty = "Qty To Load required!";
    } else if (
      user.role === "sales admin" &&
      parseInt(form.qty) > remainingQty
    ) {
      newError.qty = "Qty greater than remaining qty!";
    } else if (user.role === "sales admin" && parseInt(form.qty) <= 0) {
      newError.qty = "Qty lower than remaining qty!";
    }

    return newError;
  };

  const handleSubmit = async () => {
    const findErrors = validate();

    if (Object.values(findErrors).some((e) => e !== "")) {
      setError(findErrors);
    } else {
      try {
        setIsSubmitting(true);

        const {
          order_number: so_number,
          po_number,
          sales_id,
          customer_id,
          depo,
          type_of_sales,
          province,
          address,
          skp_id,
          ops_type,
        } = data;

        const { hauler, qty, ...rest } = form;

        const newData = {
          so_number,
          po_number,
          sales_id,
          customer_id,
          address,
          province_id: province?.id,
          depo_id: depo?.id,
          type_of_sales,
          ops_type,
          skp_id,
          hauler_id: hauler?.id,
          product_id: orderLine?.products?.product_id,
          qty: parseInt(qty),
          ...rest,
        };

        if (user.role !== "sales admin") {
          await loadingOrder.create(data?.lo_id, {
            lo_number: data.lo_number,
            stock_id: form.stock_id,
            note: form.note,
          });
        } else {
          await loadingOrder.request(newData);
        }

        socket.emit("send-load");

        setForm({
          stock_id: "",
          hauler: null,
          earliest_date: "",
          latest_date: "",
          qty: "",
          note: "",
        });

        setError({
          stock_id: "",
          hauler: "",
          earliest_date: "",
          latest_date: "",
          qty: "",
        });

        refresh();

        onClose();

        setIsSubmitting(false);

        toast.success("Loading Order Submitted!");
      } catch (e) {
        console.log(e);
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="xl" onClose={onClose}>
      <DialogTitle>
        {mode === "Add" ? "Create" : mode === "Request" && "Request"} Loading
        Order
      </DialogTitle>
      <DialogContent>
        <Grid container direction="row" spacing={3}>
          {/* Section 1 */}
          <Grid item lg={6}>
            {/* LO Number */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
            >
              <Typography style={{ width: 150 }}>LO Number</Typography>
              <CustomTypography>{data?.lo_number ?? "-"}</CustomTypography>
            </Stack>

            {/* Customer */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Customer</Typography>
              <CustomTypography>{data?.customer?.name ?? "-"}</CustomTypography>
            </Stack>

            {/* Cust Type */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Cust Type</Typography>
              <CustomTypography>
                {data?.customer?.custtype?.name ?? "-"}
              </CustomTypography>
            </Stack>

            {/* Business Type */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Business Type</Typography>
              <CustomTypography>
                {data?.customer?.businesstype?.name ?? "-"}
              </CustomTypography>
            </Stack>

            {/* Ship To Address */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Ship To Address</Typography>
              <CustomTypographyAddress>
                {data?.address ?? "-"}
              </CustomTypographyAddress>
            </Stack>

            {/* Province*/}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Province</Typography>
              <CustomTypographyAddress>
                {data?.province?.name ?? "-"}
              </CustomTypographyAddress>
            </Stack>

            {/* Supply Point */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Supply Point</Typography>
              <CustomTypographyAddress>
                {data?.depo?.name ?? "-"}
              </CustomTypographyAddress>
            </Stack>

            {/* Stock ID */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Stock ID</Typography>
              {user.role !== "sales admin" && mode === "Add" ? (
                <CustomTextField
                  size="small"
                  value={form.stock_id}
                  onChange={(e) => {
                    setForm((f) => ({
                      ...f,
                      stock_id: e.target.value,
                    }));

                    setError((e) => ({
                      ...e,
                      stock_id: "",
                    }));
                  }}
                  error={error.stock_id ? true : false}
                  helperText={error.stock_id}
                  disabled={isSubmitting}
                />
              ) : (
                <CustomTypographyAddress>
                  {data?.stock_id ?? "-"}
                </CustomTypographyAddress>
              )}
            </Stack>
          </Grid>

          {/* Section 2 */}
          <Grid item lg={6}>
            {/* PO Number */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
            >
              <Typography style={{ width: 150 }}>PO Number</Typography>
              <CustomTypography>{data?.po_number ?? "-"}</CustomTypography>
            </Stack>

            {/* Sales */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Sales</Typography>
              <CustomTypography>{data?.sales?.name ?? "-"}</CustomTypography>
            </Stack>

            {/* SKP ID */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>SKP ID</Typography>
              <CustomTypography>{data?.skp_id ?? "-"}</CustomTypography>
            </Stack>

            {/* Type Of Sales */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Type Of Sales</Typography>
              <CustomTypography>
                {data?.type_of_sales === "CIF"
                  ? "On Site"
                  : data?.type_of_sales ?? "-"}
              </CustomTypography>
            </Stack>

            {/* Earliest Pickup Date */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>
                Earliest Pickup Date
              </Typography>
              {user.role === "sales admin" && mode === "Request" ? (
                <DatePicker
                  minDate={new Date()}
                  maxDate={new Date(data?.so_expiredate)}
                  onChange={(v) => {
                    setForm((f) => ({
                      ...f,
                      earliest_date: v,
                    }));
                    setError((e) => ({
                      ...e,
                      earliest_date: "",
                    }));
                  }}
                  value={form.earliest_date}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      size="small"
                      error={error.earliest_date ? true : false}
                      helperText={error.earliest_date}
                      fullWidth
                    />
                  )}
                  disabled={isSubmitting}
                />
              ) : (
                <CustomTypographyAddress>
                  {formatDate(data?.earliest_date) ?? "-"}
                </CustomTypographyAddress>
              )}
            </Stack>

            {/* Latest Pickup Date */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Latest Pickup Date</Typography>
              {user.role === "sales admin" && mode === "Request" ? (
                <DatePicker
                  minDate={new Date()}
                  maxDate={new Date(data?.so_expiredate)}
                  onChange={(v) => {
                    setForm((f) => ({
                      ...f,
                      latest_date: v,
                    }));
                    setError((e) => ({
                      ...e,
                      latest_date: "",
                    }));
                  }}
                  value={form.latest_date}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      size="small"
                      error={error.latest_date ? true : false}
                      helperText={error.latest_date}
                      fullWidth
                    />
                  )}
                  disabled={isSubmitting}
                />
              ) : (
                <CustomTypographyAddress>
                  {formatDate(data?.latest_date) ?? "-"}
                </CustomTypographyAddress>
              )}
            </Stack>

            {/* Hauler */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Hauler</Typography>
              {user.role === "sales admin" && mode === "Request" ? (
                <CustomAutoComplete
                  getOptionLabel={(option) => option.name}
                  options={listHauler}
                  onChange={(e, v) => {
                    setForm((f) => ({
                      ...f,
                      hauler: v,
                    }));

                    setError((err) => ({
                      ...err,
                      hauler: "",
                    }));
                  }}
                  value={form.hauler}
                  loading={loading}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Choose Hauler"
                      size="small"
                      error={error.hauler ? true : false}
                      helperText={error.hauler}
                      InputProps={{
                        ...params.InputProps,
                      }}
                    />
                  )}
                  fullWidth
                  disabled={isSubmitting}
                />
              ) : (
                <CustomTypographyAddress>
                  {data?.hauler?.name ?? "-"}
                </CustomTypographyAddress>
              )}
            </Stack>

            {/* Ops Type */}
            <Stack
              direction="row"
              alignItems="center"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={3}
              mt={2}
            >
              <Typography style={{ width: 150 }}>Ops Type</Typography>
              <CustomTypography>{data?.ops_type ?? "-"}</CustomTypography>
            </Stack>
          </Grid>
        </Grid>

        <div style={{ marginTop: 24 }}>
          <TableContainer>
            <Table>
              <TableHead style={{ backgroundColor: "#EFEFEF" }}>
                <TableRow>
                  <TableCell>Product</TableCell>
                  <TableCell>Qty To Load (L)</TableCell>
                  {mode === "Request" && (
                    <TableCell>Remaining Qty (L)</TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {mode === "Request"
                      ? orderLine?.products?.name ?? "-"
                      : orderLine?.product?.name ?? "-"}
                  </TableCell>
                  <TableCell>
                    {user.role === "sales admin" && mode === "Request" ? (
                      <TextField
                        name="qty"
                        autoComplete="off"
                        fullWidth
                        size="small"
                        value={form.qty}
                        onChange={(e) => {
                          setForm((f) => ({
                            ...f,
                            qty: e.target.value,
                          }));

                          setError((e) => ({
                            ...e,
                            qty: "",
                          }));
                        }}
                        error={error.qty ? true : false}
                        helperText={error.qty}
                        InputProps={{ inputComponent: NumberFormatQty }}
                        disabled={isSubmitting}
                      />
                    ) : (
                      <>{formatThousand(orderLine?.qty ?? 0)} L</>
                    )}
                  </TableCell>
                  {mode === "Request" && (
                    <TableCell>{formatThousand(remainingQty ?? 0)} L</TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        {/* Note */}
        {mode === "Add" || mode === "Request" ? (
          <TextField
            label="Note"
            onChange={(e) => {
              setForm((f) => ({
                ...f,
                note: e.target.value,
              }));
            }}
            value={form?.note}
            multiline
            minRows={4}
            maxRows={4}
            fullWidth
            sx={{ mt: 3 }}
            disabled={isSubmitting}
          />
        ) : (
          <TextField
            label="Note"
            disabled
            value={data?.note}
            multiline
            minRows={4}
            maxRows={4}
            fullWidth
            sx={{ mt: 3 }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="inherit"
          onClick={() => {
            if (mode === "Add") {
              setForm({
                stock_id: "",
                hauler: null,
                earliest_date: "",
                latest_date: "",
                qty: "",
                note: "",
              });

              setError({
                stock_id: "",
                hauler: "",
                earliest_date: "",
                latest_date: "",
                qty: "",
              });
            }

            onClose();
          }}
          disabled={isSubmitting}
        >
          {mode === "Add" ? "Cancel" : "Close"}
        </Button>
        {(mode === "Add" || mode === "Request") && (
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={isSubmitting}
          >
            Submit
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}

// Custom
const CustomAutoComplete = styled(Autocomplete)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const CustomTextField = styled(TextField)(({ theme }) => ({
  width: "50%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  lineHeight: "40px",
  wordBreak: "break-word",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

const CustomTypographyAddress = styled(Typography)(({ theme }) => ({
  lineHeight: "40px",
  wordBreak: "break-word",
  width: "65%",
  [theme.breakpoints.down("md")]: {
    width: "100%",
  },
}));

export default CreateLoadingOrder;
