import axios from "../config";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  login: (data) => axios.post("/user/login", data),
  create: (data) => axios.post(`/user/`, data),
  getAll: (id) => axios.get(`/user/all/${id}`),
  getSales: () => axios.get(`/user/sales`),
  update: (id, data) => axios.put(`/user/${id}`, data),
  changePassword: (data) => axios.put(`/user/change-password`, data),
  destroy: (id) => axios.delete(`/user/${id}`),
  getDetail: (id) => axios.get(`/user/detail/${id}`),
  getOnce: (id) => axios.get(`/user/${id}`),
  getDepartement: () => axios.get(`/user/departement`),
  getManager: () => axios.get(`/user/manager`),
  getMitra: () => axios.get(`/user/mitra`),
  forgotPassword: (data) => axios.post("/user/forgot-password", data),
  checkToken: (token) => axios.get(`/user/check-token/${token}`),
  resetPassword: (data) => axios.post(`/user/reset-password`, data),
};
