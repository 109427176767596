//
import { useState, useEffect } from "react";
// material
import {
  Stack,
  Container,
  Typography,
  Card,
  styled,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { Page, Logo } from "../../components";
//
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
//
import users from "../../constant/users";
//
import { useHistory, useParams } from "react-router-dom";
//
import { toast } from "react-toastify";

export default function ResetPassword() {
  const history = useHistory();

  const { id } = useParams();

  const [isSubmitting, setSubmitting] = useState(false);

  const [data, setData] = useState({});

  const [form, setForm] = useState({
    NewPassword: "",
    ConfirmPassword: "",
  });

  const [error, setError] = useState({
    NewPassword: "",
    ConfirmPassword: "",
  });

  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((f) => ({
      ...f,
      [name]: value,
    }));

    setError((f) => ({
      ...f,
      [name]: "",
    }));
  };

  const handleShowNewPassword = () => {
    setShowNewPassword((show) => !show);
  };
  const handleShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const validate = () => {
    const newError = { ...error };
    const { NewPassword, ConfirmPassword } = form;

    if (!NewPassword) {
      newError.NewPassword = "Please insert your password";
    } else if (NewPassword.length < 6) {
      newError.NewPassword = "New Password minimal 6 character";
    }

    if (!ConfirmPassword) {
      newError.ConfirmPassword = "Please confirm your paswword";
    } else if (ConfirmPassword !== NewPassword) {
      newError.ConfirmPassword = "Confirm Password not match";
    }

    return newError;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const findError = validate();

    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setSubmitting(true);
      try {
        setSubmitting(true);

        const newData = {
          id: data?.user_id,
          new: form.NewPassword,
        };

        await users.resetPassword(newData);

        toast.success("Password Changed");

        setForm({
          NewPassword: "",
          ConfirmPassword: "",
        });

        setError({
          NewPassword: "",
          ConfirmPassword: "",
        });

        history.push("/");

        setSubmitting(false);
      } catch (error) {
        toast.error(error.message);

        setSubmitting(false);
      }
    }
  };

  useEffect(() => {
    const checkToken = async () => {
      try {
        const result = await users.checkToken(id);

        setData(result.data);
      } catch (e) {
        console.log(e);
      }
    };

    if (id) {
      checkToken();
    }
  }, [id]);

  return (
    <RootStyle title="Reset Password | Bayu Sinergi">
      {data?.is_used === 0 ? (
        <Container maxWidth="sm">
          <Card>
            <ContentStyle>
              {/* Header */}
              <Stack sx={{ mb: 5, textAlign: "center" }}>
                <Logo sx={{ width: 60, height: 60, margin: "auto", mb: 2 }} />
              </Stack>

              {/* Form */}
              <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                <Stack spacing={2}>
                  <TextField
                    fullWidth
                    type={showNewPassword ? "text" : "password"}
                    label="New Password"
                    name="NewPassword"
                    value={form.NewPassword}
                    error={error.NewPassword ? true : false}
                    helperText={error.NewPassword}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleShowNewPassword}
                            edge="end"
                          >
                            <Icon
                              icon={showNewPassword ? eyeFill : eyeOffFill}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  <TextField
                    fullWidth
                    type={showConfirmPassword ? "text" : "password"}
                    label="Confirm your new password"
                    name="ConfirmPassword"
                    value={form.ConfirmPassword}
                    error={error.ConfirmPassword ? true : false}
                    helperText={error.ConfirmPassword}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handleShowConfirmPassword}
                            edge="end"
                          >
                            <Icon
                              icon={showConfirmPassword ? eyeFill : eyeOffFill}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />

                  {/* Button */}
                  <LoadingButton
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    loading={isSubmitting}
                  >
                    Submit
                  </LoadingButton>
                </Stack>
              </form>
            </ContentStyle>
          </Card>
        </Container>
      ) : (
        <Typography>Token Is Not Valid | 404 Error</Typography>
      )}
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(() => ({
  display: "flex",
  height: "100vh",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  // background: `url(${bg}) rgba(0, 0, 0, 0.4)`,
  // backgroundSize: "cover",
  // backgroundBlendMode: "multiply",
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  padding: theme.spacing(8, 0),
}));

// ----------------------------------------------------------------------
