// React
import { useMemo, useState, useEffect } from "react";

// Constant
import summary from "../../../../constant/summary";
import socket from "../../../../constant/socket";

function GetSummary() {
  const [newOrder, setNewOrder] = useState(0);
  const [onApproval, setOnApproval] = useState(0);
  const [revoke, setRevoke] = useState(0);
  const [salesOrder, setSalesOrder] = useState(0);
  const [onplan, setOnPlan] = useState(0);
  const [onProgress, setOnProgress] = useState(0);
  const [completed, setCompleted] = useState(0);
  const [rejected, setRejected] = useState(0);
  const [expired, setExpired] = useState(0);
  const [willExpire, setWillExpire] = useState(0);

  const [loadingNew, setLoadingNew] = useState(false);
  const [loadingRevoke, setLoadingRevoke] = useState(false);
  const [loadingOp, setLoadingOp] = useState(false);
  const [loadingSo, setLoadingSo] = useState(false);
  const [loadingPlan, setLoadingPlan] = useState(false);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [loadingReject, setLoadingReject] = useState(false);
  const [loadingOa, setLoadingOa] = useState(false);
  const [loadingWiEx, setLoadingWiEx] = useState(false);
  const [loadingEx, setLoadingEx] = useState(false);

  useEffect(() => {
    const getNewOrder = async () => {
      setLoadingNew(true);
      try {
        const res = await summary.getNewOrder();

        setNewOrder(res.data);
      } catch (e) {
        console.log(e);
      }
      setLoadingNew(false);
    };

    const getApprovalOrder = async () => {
      setLoadingOa(true);
      try {
        const res = await summary.getOnApproval();

        setOnApproval(res.data);
      } catch (e) {
        console.log(e);
      }
      setLoadingOa(false);
    };

    const getRevokeOrder = async () => {
      setLoadingRevoke(true);
      try {
        const res = await summary.getRevoke();

        setRevoke(res.data);
      } catch (e) {
        console.log(e);
      }
      setLoadingRevoke(false);
    };

    const getOnProgress = async () => {
      setLoadingOp(true);
      try {
        const res = await summary.getOnProgress();

        setOnProgress(res.data);
      } catch (e) {
        console.log(e);
      }
      setLoadingOp(false);
    };

    const getSO = async () => {
      setLoadingSo(true);
      try {
        const res = await summary.getTotalSO();

        setSalesOrder(res.data);
      } catch (e) {
        console.log(e);
      }
      setLoadingSo(false);
    };

    const getPlan = async () => {
      setLoadingPlan(true);
      try {
        const res = await summary.getOnPlan();

        setOnPlan(res.data);
      } catch (e) {
        console.log(e);
      }
      setLoadingPlan(false);
    };

    const getCompleted = async () => {
      setLoadingComplete(true);
      try {
        const res = await summary.getCompleted();

        setCompleted(res.data);
      } catch (e) {
        console.log(e);
      }
      setLoadingComplete(false);
    };

    const getRejected = async () => {
      setLoadingReject(true);
      try {
        const res = await summary.getRejected();

        setRejected(res.data);
      } catch (e) {
        console.log(e);
      }
      setLoadingReject(false);
    };

    const getWillExpire = async () => {
      setLoadingWiEx(true);
      try {
        const res = await summary.getWillExpire();

        setWillExpire(res.data);
      } catch (e) {
        console.log(e);
      }
      setLoadingWiEx(false);
    };

    const getExpired = async () => {
      setLoadingEx(true);
      try {
        const res = await summary.getExpired();

        setExpired(res.data);
      } catch (e) {
        console.log(e);
      }
      setLoadingEx(false);
    };

    getNewOrder();
    getApprovalOrder();
    getRevokeOrder();
    getOnProgress();
    getSO();
    getPlan();
    getCompleted();
    getRejected();
    getWillExpire();
    getExpired();
  }, []);

  useEffect(() => {
    const getNewOrder = async () => {
      try {
        const res = await summary.getNewOrder();

        setNewOrder(res.data);
      } catch (e) {
        console.log(e);
      }
    };

    const getApprovalOrder = async () => {
      try {
        const res = await summary.getOnApproval();

        setOnApproval(res.data);
      } catch (e) {
        console.log(e);
      }
    };

    const getRevokeOrder = async () => {
      try {
        const res = await summary.getRevoke();

        setRevoke(res.data);
      } catch (e) {
        console.log(e);
      }
    };

    const getOnProgress = async () => {
      try {
        const res = await summary.getOnProgress();

        setOnProgress(res.data);
      } catch (e) {
        console.log(e);
      }
    };

    const getSO = async () => {
      try {
        const res = await summary.getTotalSO();

        setSalesOrder(res.data);
      } catch (e) {
        console.log(e);
      }
    };

    const getPlan = async () => {
      try {
        const res = await summary.getOnPlan();

        setOnPlan(res.data);
      } catch (e) {
        console.log(e);
      }
    };

    const getCompleted = async () => {
      try {
        const res = await summary.getCompleted();

        setCompleted(res.data);
      } catch (e) {
        console.log(e);
      }
    };

    const getRejected = async () => {
      try {
        const res = await summary.getRejected();

        setRejected(res.data);
      } catch (e) {
        console.log(e);
      }
    };

    const getExpired = async () => {
      try {
        const res = await summary.getExpired();

        setExpired(res.data);
      } catch (e) {
        console.log(e);
      }
    };
    const getWillExpire = async () => {
      try {
        const res = await summary.getWillExpire();

        setWillExpire(res.data);
      } catch (e) {
        console.log(e);
      }
    };

    socket.on("load", () => {
      getNewOrder();
      getRevokeOrder();
      getOnProgress();
      getSO();
      getPlan();
      getCompleted();
      getRejected();
      getApprovalOrder();
      getWillExpire();
      getExpired();
    });
  }, []);

  const loading =
    loadingNew ||
    loadingRevoke ||
    loadingOp ||
    loadingSo ||
    loadingPlan ||
    loadingComplete ||
    loadingReject ||
    loadingOa ||
    loadingWiEx ||
    loadingEx;

  return useMemo(
    () => [
      newOrder,
      onApproval,
      revoke,
      onProgress,
      salesOrder,
      onplan,
      completed,
      rejected,
      willExpire,
      expired,
      loading,
    ],
    [
      newOrder,
      onApproval,
      revoke,
      onProgress,
      salesOrder,
      onplan,
      completed,
      rejected,
      willExpire,
      expired,
      loading,
    ]
  );
}

export default GetSummary;
