import axios from "../config";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  create: (data) => axios.post("/sales-order/", data),
  verify: (data) => axios.post("/sales-order/verify", data),
  approve: (data) => axios.post("/sales-order/approve", data),
  close: (data) => axios.post("/sales-order/close", data),
  revoke: (data) => axios.post("/sales-order/revoke", data),
  reject: (data) => axios.post("/sales-order/reject", data),
  getDetail: (id) => axios.get(`/sales-order/${id}`),
  getAll: (status) => axios.get(`/sales-order?status=${status}`),
  update: (id, data) => axios.put(`/sales-order/${id}`, data),
  getReport: () => axios.get(`/sales-order/report`),
};
