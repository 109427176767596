import axios from "../config";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  create: (data) => axios.post("/mitra", data),
  getAll: () => axios.get("/mitra"),
  getOnce: (id) => axios.get(`/mitra/${id}`),
  update: (id, data) => axios.put(`/mitra/${id}`, data),
  delete: (id) => axios.delete(`/mitra/${id}`),
};
