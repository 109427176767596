//
import { Switch, Route, Redirect } from "react-router-dom";
//
import DetailCustomer from "./DetailCustomer";
import TableCustomer from "./TableCustomer";

function Customer() {
  return (
    <Switch>
      <Route path="/customer/detail/:id" component={DetailCustomer} />
      <Route path="/customer" component={TableCustomer} />
      <Redirect to="/customer" />
    </Switch>
  );
}

export default Customer;
