//
import { useState, useEffect } from "react";
// material
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
//
import driver from "../../../constant/driver";
//
import { toast } from "react-toastify";
//
import { useParams } from "react-router-dom";

function AddDriver({ onClose, load, dialog: { open, mode, data } }) {
  const { id } = useParams();

  const [form, setForm] = useState({
    name: "",
    address: "",
    email: "",
    contact: "",
    note: "",
  });

  const [error, setError] = useState({
    name: "",
    email: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((f) => ({
      ...f,
      [name]: value,
    }));

    setError((f) => ({
      ...f,
      [name]: "",
    }));
  };

  const validate = () => {
    const newError = { ...error };

    const { name, email } = form;

    if (!name) {
      newError.name = "Name can't be empty";
    }

    if (!email) {
      newError.email = "Email can't be empty";
    }

    return newError;
  };

  const handleSubmit = async () => {
    const findError = validate();

    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setIsSubmitting(true);
      try {
        const newData = {
          mitra_id: parseInt(id),
          ...form,
        };

        if (mode === "Edit") {
          await driver.update(data.equipment_id, newData);

          toast.success("Driver Edited");
        } else {
          await driver.create(newData);

          toast.success("Driver Added");
        }

        setForm({
          name: "",
          address: "",
          email: "",
          contact: "",
          note: "",
        });

        setError({
          name: "",
          email: "",
        });

        onClose();

        load();
      } catch (e) {
        console.log(e);
      }
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (mode === "Edit" && data) {
      setForm(data);
    }
  }, [mode, data]);

  return (
    <Dialog
      open={open}
      onClose={() => {
        if (!isSubmitting) {
          onClose();
          setForm({
            name: "",
            address: "",
            email: "",
            contact: "",
            note: "",
          });

          setError({
            name: "",
            email: "",
          });
        }
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{mode === "Edit" ? "Edit" : "Add New"} Driver</DialogTitle>
      <DialogContent dividers>
        <TextField
          name="name"
          label="Name"
          autoComplete="off"
          fullWidth
          value={form.name}
          onChange={handleChange}
          error={error.name ? true : false}
          helperText={error.name}
          disabled={isSubmitting}
          sx={{ mb: 2 }}
        />
        <TextField
          name="address"
          label="Address"
          autoComplete="off"
          fullWidth
          multiline
          minRows={1}
          maxRows={4}
          value={form.address}
          onChange={handleChange}
          disabled={isSubmitting}
          sx={{ mb: 2 }}
        />
        <TextField
          name="email"
          label="Email"
          autoComplete="off"
          fullWidth
          multiline
          minRows={1}
          maxRows={4}
          value={form.email}
          onChange={handleChange}
          disabled={isSubmitting}
          sx={{ mb: 2 }}
        />
        <TextField
          name="contact"
          label="Phone Number"
          autoComplete="off"
          fullWidth
          multiline
          minRows={1}
          maxRows={4}
          value={form.contact}
          onChange={handleChange}
          disabled={isSubmitting}
          sx={{ mb: 2 }}
        />
        <TextField
          name="note"
          label="Note"
          autoComplete="off"
          fullWidth
          multiline
          minRows={1}
          maxRows={4}
          value={form.note}
          onChange={handleChange}
          disabled={isSubmitting}
        />
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <LoadingButton
          onClick={handleSubmit}
          loading={isSubmitting}
          variant="contained"
        >
          {mode === "Edit" ? "Edit" : "Add"} Driver
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default AddDriver;
