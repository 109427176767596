//
import { useEffect, useState } from "react";
// material
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  styled,
  Button,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { LoadingButton } from "@mui/lab";
//
import { AlertDialog, NumberFormatThousand } from "../../../components";
//
import mitra from "../../../constant/mitra";
//
import { getBase64 } from "../../../utils";
//
import { toast } from "react-toastify";
//
import { Icon } from "@iconify/react";
//
import { GetPartnerType } from "./API";

function AddMitra({ onClose, load, dialog: { open, data, mode } }) {
  const [listType, loading] = GetPartnerType();
  const [openAlert, setOpenAlert] = useState(false);

  const [image, setImage] = useState("");
  const [preview, setPreview] = useState("");

  const Label = styled("label")(({ theme }) => ({
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  }));

  const [form, setForm] = useState({
    name: "",
    code: "",
    no_license: "",
    partner: null,
    area: "",
    volume_target: "",
    contract_end: "",
    address: "",
    contact: "",
    email: "",
    file: "",
    note: "",
  });

  const [error, setError] = useState({
    name: "",
    code: "",
    no_license: "",
    partner: "",
    area: "",
    volume_target: "",
    contract_end: "",
    email: "",
    file: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  const loadImage = (e) => {
    const image = e.target.files[0];
    setPreview(URL.createObjectURL(image));
    setImage(image);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setForm((f) => ({
      ...f,
      [name]: value,
    }));

    setError((f) => ({
      ...f,
      [name]: "",
    }));
  };

  const validate = () => {
    const newError = { ...error };

    const {
      name,
      code,
      no_license,
      partner,
      area,
      volume_target,
      contract_end,
      email,
      file,
    } = form;

    if (!name) {
      newError.name = "Name cannot be empty";
    }

    if (!code) {
      newError.code = "Code cannot be empty";
    }

    if (!no_license) {
      newError.no_license = "SKP/License number cannot be empty";
    }

    if (partner === null) {
      newError.partner = "Partner type cannot be empty";
    }

    if (!area) {
      newError.area = "Area cannot be empty";
    }

    if (!volume_target) {
      newError.volume_target = "Volume target cannot be empty";
    }

    if (!contract_end) {
      newError.contract_end = "Contract end cannot be empty";
    }

    if (!email) {
      newError.email = "Email cannot be empty";
    }

    return newError;
  };

  const handleSubmit = async () => {
    const findError = validate();

    if (Object.values(findError).some((m) => m !== "")) {
      setError(findError);
    } else {
      setIsSubmitting(true);
      try {
        const { partner, volume_target, ...rest } = form;

        const newData = {
          partner_id: partner?.id,
          volume_target: parseInt(volume_target),
          ...rest,
        };

        if (mode === "Edit") {
          // Api Update
          await mitra.update(data.id, newData);

          toast.success("Mitra Edited!");
        } else {
          // Api Register
          await mitra.create(newData);

          toast.success("Mitra Added!");
        }

        setForm({
          name: "",
          code: "",
          no_license: "",
          partner: null,
          area: "",
          volume_target: "",
          contract_end: "",
          address: "",
          contact: "",
          email: "",
          file: "",
          note: "",
        });

        setError({
          name: "",
          code: "",
          no_license: "",
          partner: "",
          area: "",
          volume_target: "",
          contract_end: "",
          email: "",
          file: "",
        });

        onClose();

        load();
      } catch (e) {
        console.log(e);
        setIsSubmitting(false);
      }
    }
  };

  useEffect(() => {
    if (mode === "Edit" && data) {
      setForm(data);
    }
  }, [mode, data]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          if (!isSubmitting) {
            onClose();
            setForm({
              name: "",
              code: "",
              no_license: "",
              partner: null,
              area: "",
              volume_target: "",
              contract_end: "",
              address: "",
              contact: "",
              email: "",
              file: "",
              note: "",
            });
            setError({
              name: "",
              code: "",
              no_license: "",
              partner: "",
              area: "",
              volume_target: "",
              contract_end: "",
              email: "",
              file: "",
            });
            setPreview("");
          }
        }}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{mode === "Edit" ? "Edit" : "Add New"} Mitra</DialogTitle>
        <DialogContent dividers>
          <TextField
            name="name"
            label="Name"
            autoComplete="off"
            fullWidth
            value={form.name}
            onChange={handleChange}
            error={error.name ? true : false}
            helperText={error.name}
            disabled={isSubmitting}
            sx={{ mb: 2 }}
          />
          <TextField
            name="code"
            label="Code"
            autoComplete="off"
            fullWidth
            value={form.code}
            onChange={handleChange}
            error={error.code ? true : false}
            helperText={error.code}
            disabled={isSubmitting}
            sx={{ mb: 2 }}
          />
          <TextField
            name="no_license"
            label="SKP/License Number"
            autoComplete="off"
            fullWidth
            value={form.no_license}
            onChange={handleChange}
            error={error.no_license ? true : false}
            helperText={error.no_license}
            disabled={isSubmitting}
            sx={{ mb: 2 }}
          />
          <Autocomplete
            fullWidth
            getOptionLabel={(option) => option.name}
            options={listType}
            loading={loading}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Partner Type"
                error={error.partner ? true : false}
                helperText={error.partner}
                InputProps={{
                  ...params.InputProps,
                }}
              />
            )}
            onChange={(e, v) => {
              setForm((f) => ({
                ...f,
                partner: v,
              }));

              setError((err) => ({
                ...err,
                partner: "",
              }));
            }}
            value={form.partner}
            disabled={isSubmitting}
            sx={{ mb: 2 }}
          />
          <TextField
            name="area"
            label="Area"
            autoComplete="off"
            fullWidth
            value={form.area}
            onChange={handleChange}
            error={error.area ? true : false}
            helperText={error.area}
            disabled={isSubmitting}
            sx={{ mb: 2 }}
          />
          <TextField
            name="volume_target"
            label="Target Vol (Month)"
            autoComplete="off"
            fullWidth
            value={form.volume_target}
            onChange={handleChange}
            error={error.volume_target ? true : false}
            helperText={error.volume_target}
            disabled={isSubmitting}
            InputProps={{
              inputComponent: NumberFormatThousand,
            }}
            sx={{ mb: 2 }}
          />
          <DatePicker
            renderInput={(params) => (
              <TextField
                {...params}
                label="Contract End"
                fullWidth
                error={error.contract_end ? true : false}
                helperText={error.contract_end}
                sx={{ mb: 2 }}
              />
            )}
            onChange={(v) => {
              setForm((f) => ({
                ...f,
                contract_end: v,
              }));
              setError((e) => ({
                ...e,
                contract_end: "",
              }));
            }}
            value={form.contract_end}
            disabled={isSubmitting}
          />
          <TextField
            name="address"
            label="Address"
            autoComplete="off"
            fullWidth
            value={form.address}
            onChange={handleChange}
            disabled={isSubmitting}
            sx={{ mb: 2 }}
          />
          <TextField
            name="contact"
            label="Contact"
            autoComplete="off"
            fullWidth
            value={form.contact}
            onChange={handleChange}
            disabled={isSubmitting}
            sx={{ mb: 2 }}
          />
          <TextField
            name="email"
            label="Email"
            autoComplete="off"
            fullWidth
            value={form.email}
            onChange={handleChange}
            error={error.email ? true : false}
            helperText={error.email}
            disabled={isSubmitting}
            sx={{ mb: 2 }}
          />
          <TextField
            name="note"
            label="Note"
            autoComplete="off"
            fullWidth
            value={form.note}
            onChange={handleChange}
            disabled={isSubmitting}
            multiline
            minRows={1}
            maxRows={4}
            sx={{ mb: 2 }}
          />
          <Label htmlFor="raised-button-file">
            <Button
              // fullWidth
              variant="contained"
              color="inherit"
              component="span"
              startIcon={
                <Icon icon="material-symbols:image" width={24} height={24} />
              }
              sx={{ mb: 2 }}
            >
              Choose Image
            </Button>
          </Label>
          {preview ? (
            <Box
              component="img"
              src={preview}
              alt={"Preview Image"}
              fullWidth
              sx={{ mb: 2 }}
            />
          ) : (
            ""
          )}
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <LoadingButton
            onClick={mode === "Edit" ? () => setOpenAlert(true) : handleSubmit}
            loading={isSubmitting}
            variant="contained"
          >
            {mode === "Edit" ? "Edit" : "Add"} Mitra
          </LoadingButton>
        </DialogActions>
        {/*Alert Dialog*/}
        <AlertDialog
          open={openAlert}
          title="Update Mitra"
          content="Are you sure want to update this Mitra?"
          handleOk={handleSubmit}
          handleClose={() => setOpenAlert(false)}
        />
      </Dialog>
      <input
        accept=".png, .jpg, .jpeg"
        style={{ display: "none" }}
        id="raised-button-file"
        type="file"
        onChange={async (e) => {
          let selectedFile = e.target.files;

          const data = await Promise.all(
            Object.values(selectedFile)?.map(async (item) => {
              if (item.size >= 2097152) {
                toast.error(`${item.name} size more than 2 mb!`);
              } else {
                const base64 = await getBase64(item);
                return {
                  filename: item.name,
                  url: base64,
                  isNew: true,
                  isRemove: false,
                };
              }
            })
          );

          const image = e.target.files[0];
          if (image) {
            setPreview(URL.createObjectURL(image));
          }
          setImage(data);

          setForm((f) => ({
            ...f,
            file: { list_image: data },
            // file: e.target.files[0],
          }));
        }}
      />
    </>
  );
}

export default AddMitra;
