//
import { useMemo, useState, useEffect } from "react";

//
import product from "../../../../constant/product";

function GetOnce(id) {
  const [data, setData] = useState({});

  const [loading, setLoading] = useState(false);

  const [load, setLoad] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    const getAll = async () => {
      setLoading(true);
      try {
        const res = await product.getOnce(id);

        setData(res.data);
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };

    if (id) {
      getAll();
    }
  }, [id]);

  useEffect(() => {
    const getAll = async () => {
      try {
        const res = await product.getOnce(id);

        setData(res.data);
      } catch (e) {
        setError(e);
      }
      setLoad(false);
    };

    if (load && id) {
      getAll();
    }
  }, [load, id]);

  return useMemo(
    () => [data, setData, loading, setLoad, error],
    [data, setData, loading, setLoad, error]
  );
}

export default GetOnce;
