// Material UI
import { Autocomplete, Container, TextField, Typography } from "@mui/material";

// Components
import { LoadingPage, Page } from "../../../components";

// API
import {
  GetVolumeAnnual,
  GetRevenueAnnual,
  GetProductAnnual,
  GetSKPAnnual,
  GetSupplyPointAnnual,
  GetTarget,
  GetRevenueAlir,
  GetListYearBOD,
} from "./API";

// Chart
import Chart from "react-apexcharts";
import GaugeChart from "react-gauge-chart";

// format rupiah
import { formatRupiah, formatNumber } from "../../../utils";
//
import React, { useEffect, useState } from "react";

// default value
const defaultYear = new Date().getFullYear().toString();
// Default
const defaultDate = `${(new Date().getMonth() + 1)
  .toString()
  .padStart(2, "0")}`;
const defaultType = "yearly";
const month = [
  { month: "Januari", value: "01" },
  { month: "Februari", value: "02" },
  { month: "Maret", value: "03" },
  { month: "April", value: "04" },
  { month: "Mei", value: "05" },
  { month: "Juni", value: "06" },
  { month: "Juli", value: "07" },
  { month: "Agustus", value: "08" },
  { month: "September", value: "09" },
  { month: "Oktober", value: "10" },
  { month: "November", value: "11" },
  { month: "Desember", value: "12" },
];

function ReportBod() {
  // set type, year, & month for parameter
  const [paramType, setParamType] = useState({
    type: defaultType,
  });
  const [paramYear, setParamYear] = useState({
    Yearly: defaultYear,
  });
  const [paramMonth, setParamMonth] = useState({
    Monthly: null,
  });

  /* Validasi jika type = 'montly' */
  useEffect(() => {
    if (paramType?.type === "monthly") {
      setParamMonth({ Monthly: defaultDate });
    }
  }, [paramType]);

  // get list year
  const [years, loadingYears] = GetListYearBOD();
  const listYear = years
    ?.map((item) => item?.year)
    ?.sort((a, b) => {
      const aA = parseInt(a?.year);

      const bB = parseInt(b?.year);

      return bB - aA;
    });

  // get list month
  const listMonth = month?.map((item) => item?.month);

  /* Filter Function */
  // list type filter
  const select = [
    { label: "Yearly", value: "yearly" },
    { label: "Monthly", value: "monthly" },
  ];
  const listSelect = select?.map((item) => item?.label);

  //list filter value dependant to type filter
  const listFilter = {
    Monthly: {
      filterYear: listYear,
      filterMonth: listMonth,
    },
    Yearly: listYear,
  };

  // set filter
  const [filter, setFilter] = useState(listSelect[0]);
  const [filterMonth, setFilterMonth] = useState(paramYear?.Yearly);
  const [valueMonth, setValueMonth] = useState(month[defaultDate - 1]);

  // Call data
  const [report, loading] = GetVolumeAnnual(
    paramType?.type,
    paramYear?.Yearly,
    paramMonth?.Monthly
  );
  const [revenue, loadingRevenue] = GetRevenueAnnual(
    paramType?.type,
    paramYear?.Yearly,
    paramMonth?.Monthly
  );
  const [product, loadingProduct] = GetProductAnnual(
    paramType?.type,
    paramYear?.Yearly,
    paramMonth?.Monthly
  );
  const [skp, loadingSKP] = GetSKPAnnual(
    paramType?.type,
    paramYear?.Yearly,
    paramMonth?.Monthly
  );
  const [supply, loadingSupply] = GetSupplyPointAnnual(
    paramType?.type,
    paramYear?.Yearly,
    paramMonth?.Monthly
  );
  const [revAlir, loadingRevAlir] = GetRevenueAlir(
    paramType?.type,
    paramYear?.Yearly,
    paramMonth?.Monthly
  );
  const [target, loadingTarget] = GetTarget(paramYear?.Yearly);

  const a = revenue?.map((item) => item?.week);
  console.log(a);

  /* Chart */
  const optionsVolume = {
    title: {
      text:
        paramType?.type === "yearly"
          ? "Analysis Volume Annual"
          : "Analysis Volume Monthly",
      align: "left",
    },
    subtitle: {
      text: "Volume Movements",
      align: "left",
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 4,
    },
    xaxis:
      paramType?.type === "yearly"
        ? {
            categories: revenue?.map((item) => item?.month),
          }
        : {
            categories: revenue?.map((item) => "Week " + item?.week),
          },
    yaxis: {
      labels: {
        formatter: function (value) {
          return `${formatNumber(value)}L`;
        },
      },
    },
    stroke: {
      width: 2,
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return `${value} L`;
        },
      },
    },
    chart: {
      zoom: {
        enabled: false,
      },
    },
  };

  const optionsRevenue = {
    title: {
      text:
        paramType?.type === "yearly"
          ? "Analysis Revenue Annual"
          : "Analysis Revenue Monthly",
      align: "left",
    },
    subtitle: {
      text: "Revenue Movements",
      align: "left",
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 4,
    },
    xaxis:
      paramType?.type === "yearly"
        ? {
            categories: revenue?.map((item) => item?.month),
          }
        : {
            categories: revenue?.map((item) => "Week " + item?.week),
          },
    yaxis: {
      labels: {
        formatter: function (value) {
          return `${formatNumber(value)} Rp`;
        },
      },
    },
    stroke: {
      width: 2,
      curve: "smooth",
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return formatRupiah(value);
        },
      },
    },
    chart: {
      zoom: {
        enabled: false,
      },
    },
  };

  const optionsPO = {
    title: {
      text: "Revenue",
      align: "left",
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (value) {
        return formatRupiah(value);
      },
    },
    xaxis: {
      categories: ["Revenue PO", "Revenue PO Alir"],
      labels: {
        formatter: function (value) {
          return formatNumber(value);
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (value) {
          return formatRupiah(value);
        },
      },
    },
  };

  const optionsProduct = {
    plotOptions: {
      pie: {
        offsetX: 0,
        offsetY: 0,
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 1,
        },
      },
    },
    chart: {
      type: "pie",
    },
    labels: product?.map((item) => item?.name),
    title: {
      text: "Analysis Product",
      align: "left",
    },
    // menampilkan data yang bukan persen
    /* dataLabels: {
      formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex]
      },
    }, */
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const optionsSKP = {
    plotOptions: {
      pie: {
        offsetX: 0,
        offsetY: 0,
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 1,
        },
      },
    },
    chart: {
      height: 0,
      type: "pie",
    },
    labels: skp?.map((item) => item?.skp_id),
    title: {
      text: "Analysis SKP",
      align: "left",
    },
    // menampilkan data yang bukan persen
    /* dataLabels: {
      formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex]
      },
    }, */
    colors: [
      "#F3B415",
      "#F27036",
      "#663F59",
      "#6A6E94",
      "#4E88B4",
      "#00A7C6",
      "#18D8D8",
      "#A9D794",
      "#46AF78",
      "#A93F55",
      "#8C5E58",
      "#2176FF",
      "#33A1FD",
      "#7A918D",
      "#BAFF29",
    ],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const optionsSupply = {
    plotOptions: {
      pie: {
        offsetX: 0,
        offsetY: 0,
        dataLabels: {
          offset: 0,
          minAngleToShowLabel: 1,
        },
      },
    },
    chart: {
      type: "pie",
      zoom: {
        enabled: true,
        type: "x",
        autoScaleYaxis: false,
        zoomedArea: {
          fill: {
            color: "#90CAF9",
            opacity: 0.4,
          },
          stroke: {
            color: "#0D47A1",
            opacity: 0.4,
            width: 1,
          },
        },
      },
    },
    labels: supply?.map((item) => item?.name),
    title: {
      text: "Analysis Supply Point",
      align: "left",
    },
    // menampilkan data yang bukan persen
    /* dataLabels: {
      formatter: function (val, opts) {
          return opts.w.config.series[opts.seriesIndex]
      },
    }, */
    colors: [
      "#F3B415",
      "#F27036",
      "#663F59",
      "#6A6E94",
      "#4E88B4",
      "#00A7C6",
      "#18D8D8",
      "#A9D794",
      "#46AF78",
      "#A93F55",
      "#8C5E58",
      "#2176FF",
      "#33A1FD",
      "#7A918D",
      "#BAFF29",
    ],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };

  const seriesVolume = [
    {
      name: paramType?.type === "yearly" ? "Volume Annual" : "Volume Monthly",
      data: report?.map((item) => item?.qty),
    },
  ];

  const seriesRevenue = [
    {
      name: paramType?.type === "yearly" ? "Revenue Annual" : "Revenue Monthly",
      data: revenue?.map((item) => item?.total),
      color: "#FF0000",
    },
  ];

  const seriesPO = [
    {
      data:
        paramType?.type === "yearly"
          ? [revAlir?.revPo, revAlir?.revAlir]
          : [revAlir?.revPo, revAlir?.revAlir],
      name: ["Revenue"],
    },
  ];

  const seriesProduct =
    paramType === "yearly"
      ? product?.map((item) => item?.total)
      : product?.map((item) => item?.total);

  const seriesSKP = skp?.map((item) => item?.total);

  const seriesSupply = supply?.map((item) => item?.total);

  const chartStyle = {
    height: 200,
    fontSize: 5,
  };

  return (
    <Page title="Report BOD | Bayu Sinergi">
      {/* Container */}
      <Container maxWidth="xl" sx={{ overflowY: "hidden" }}>
        {loading ||
        loadingRevenue ||
        loadingProduct ||
        loadingSKP ||
        loadingSupply ||
        loadingRevAlir ||
        loadingTarget ||
        loadingYears ? (
          <LoadingPage />
        ) : (
          <>
            {/* Gap */}
            <div style={{ height: 100 }} />
            {/* Chart */}
            <div style={{ display: "flex" }}>
              <div style={{ flex: 2 }}>
                {/* DEPENDANT DROPDOWN FILTER MONTHLY OR YEARLY */}
                <div style={{ display: "flex" }}>
                  <Autocomplete
                    options={listSelect}
                    sx={{ width: 150, marginBottom: 1, marginRight: 2 }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Filter" />
                    )}
                    onChange={(e, v) => {
                      setFilter(v);
                      setParamType(
                        v === "Yearly"
                          ? { type: "yearly" }
                          : v === "Monthly"
                          ? { type: "monthly" }
                          : { type: "error" }
                      );
                    }}
                    value={
                      filter === "Yearly"
                        ? { label: listSelect[0], value: "yearly" }
                        : filter === "Monthly"
                        ? { label: listSelect[1], value: "monthly" }
                        : { label: "" }
                    }
                    isOptionEqualToValue={(option, value) =>
                      option?.Yearly === value?.Yearly
                    }
                  />
                  {filter === "Yearly" ? (
                    <>
                      <Autocomplete
                        options={listFilter[filter]}
                        sx={{ width: 150, marginBottom: 1 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Filter Year" />
                        )}
                        onChange={(e, v) => {
                          setParamYear({ Yearly: v });
                        }}
                        value={paramYear?.Yearly || null}
                        isOptionEqualToValue={(option, value) =>
                          option?.Yearly === value?.Yearly
                        }
                      />
                    </>
                  ) : filter === "Monthly" ? (
                    <>
                      {/* Select Year */}
                      <Autocomplete
                        options={listFilter[filter]?.filterYear}
                        sx={{ width: 150, marginBottom: 1 }}
                        renderInput={(params) => (
                          <TextField {...params} label="Select Year" />
                        )}
                        onChange={(e, v) => {
                          setFilterMonth(v);
                          setParamYear({ Yearly: v });
                        }}
                        value={
                          filterMonth === "2022"
                            ? { label: listYear[0] }
                            : filterMonth === "2021"
                            ? { label: listYear[1] }
                            : { label: "" }
                        }
                        isOptionEqualToValue={(option, value) =>
                          option?.id === value?.id
                        }
                      />

                      {/* Select Month */}
                      {filterMonth && (
                        <>
                          <Autocomplete
                            options={month}
                            getOptionLabel={(option) => option.month}
                            getOptionValue={(option) => option.value}
                            value={valueMonth}
                            onChange={(e, v) => {
                              setValueMonth(v);
                              setParamMonth({ Monthly: v?.value });
                            }}
                            sx={{ width: 250, marginBottom: 1, marginLeft: 2 }}
                            isOptionEqualToValue={(option, value) =>
                              option?.month === value?.month
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="Filter Month" />
                            )}
                          />
                        </>
                      )}
                    </>
                  ) : (
                    /* jika user tidak memilih tipe filter, maka dropdown kedua dan ketiga dihilangkan */
                    <div></div>
                  )}
                </div>
                {/* Revenue Chart */}
                <Chart
                  options={optionsRevenue}
                  series={seriesRevenue}
                  type="bar"
                  height={280}
                />
                {/* End Revenue Chart */}

                {/* Volume Chart */}
                <Chart
                  options={optionsVolume}
                  series={seriesVolume}
                  type="line"
                  height={280}
                />
                {/* End Volume Chart */}
              </div>

              <div
                style={{
                  flex: 1,
                  marginLeft: 15,
                  backgroundColor: "ThreeDFace",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    height: 280,
                    backgroundColor: "",
                    marginBottom: 50,
                  }}
                >
                  {/* rev Po & rev rev PO alir chart */}
                  <Chart
                    options={optionsPO}
                    series={seriesPO}
                    type="bar"
                    height={280}
                  />
                </div>
                <div
                  style={{ width: "100%", height: 285, backgroundColor: "" }}
                >
                  {/* rev achievment target chart */}
                  <Typography variant="h6" style={{ marginLeft: 10 }}>
                    {
                      //Check if message failed
                      paramType?.type === "yearly" ? (
                        <div> Achievment (%) vs Target Annual </div>
                      ) : (
                        <div> Achievment (%) vs Target Monthly </div>
                      )
                    }
                  </Typography>
                  <GaugeChart
                    style={chartStyle}
                    id="gauge-chart"
                    textColor="#333"
                    nrOfLevels={3}
                    arcsLength={[0.33, 0.33, 0.33]}
                    colors={["#EA4228", "#ffff00", "#5BE12C"]}
                    percent={target.percent}
                    arcPadding={0.001}
                  />
                  <ol
                    style={{
                      listStyle: "none",
                      marginLeft: 20,
                      marginRight: 20,
                      marginBottom: 20,
                    }}
                  >
                    <li style={{ float: "left" }}>
                      <h6>🟥 Target belum tercapai</h6>
                    </li>
                    <li style={{ float: "right" }}>
                      <h6>🟩 Target telah tercapai</h6>
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div
              style={{
                marginTop: 24,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {/* pie Chart */}
              <Chart
                options={optionsProduct}
                series={seriesProduct}
                type="pie"
                width={430}
                height={300}
              />
              <Chart
                options={optionsSKP}
                series={seriesSKP}
                type="pie"
                width={410}
                height={300}
              />
              <Chart
                options={optionsSupply}
                series={seriesSupply}
                type="pie"
                width={605}
                height={300}
              />
              {/* End pie Chart */}
            </div>
          </>
        )}
      </Container>
    </Page>
  );
}

export default ReportBod;
