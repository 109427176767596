import axios from "../config";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  create: (data) => axios.post("/price-product/", data),
  getOnce: (id) => axios.get(`/price-product/${id}`),
  getPriceProduct: (region, type) =>
    axios.get(`/price-product/${region}/${type}`),
  update: (id, data) => axios.put(`/price-product/${id}`, data),
  delete: (id) => axios.delete(`/price-product/${id}`),
};
