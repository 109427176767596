//
import { useMemo, useState, useEffect } from "react";

//
import role from "../../../../constant/role";

function GetRoles() {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getRoles = async () => {
      setLoading(true);
      try {
        const res = await role.getAll();

        setData(
          res.data.map((item) => {
            return {
              id: item.id,
              name: item.role_desc,
            };
          })
        );
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    };

    getRoles();
  }, []);

  return useMemo(() => [data, loading], [data, loading]);
}

export default GetRoles;
