//
import { useEffect, useMemo, useState } from "react";
//
import report from "../../../../constant/report";

function GetTarget(year) {
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getAll = async () => {
      setLoading(true);
      try {
        const res = await report.getTarget(year);

        setData(res?.data);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    };

    getAll();
  }, [year]);

  return useMemo(() => [data, loading], [data, loading]);
}

export default GetTarget;