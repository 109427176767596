//
import { useEffect, useState } from "react";
//
import {
  Autocomplete,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
//
import salesCustomer from "../../../constant/salesCustomer";
//
import { useData } from "../../../context/DataProvider";
//
import { toast } from "react-toastify";

function PickCustomer({ onClose, load, dialog: { open } }) {
  const { user } = useData();

  const [pick, setPick] = useState(null);

  const [listPick, setListPick] = useState([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handlePick = async () => {
    setIsSubmitting(true);
    try {
      const { usc_id } = pick;

      const data = { sales_id: user.id };

      await salesCustomer.pickCustomer(usc_id, data);

      setPick(null);

      setListPick([]);

      load();

      onClose();

      toast.success("Customer picked");
    } catch (e) {
      console.log(e);
    }
    setIsSubmitting(false);
  };

  useEffect(() => {
    const getPick = async () => {
      try {
        const res = await salesCustomer.getPickCustomer();

        setListPick(res.data);
      } catch (e) {
        console.log(e);
      }
    };

    if (open) {
      getPick();
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Pick A Customer</DialogTitle>
      <DialogContent dividers>
        <Autocomplete
          fullWidth
          getOptionLabel={(option) => option.name}
          options={listPick}
          renderInput={(params) => <TextField {...params} label="Customer" />}
          onChange={(e, v) => {
            setPick(v);
          }}
          value={pick}
          disabled={isSubmitting}
          sx={{ mb: 2 }}
        />
        <Typography mb={0.5}>Address :</Typography>
        <Typography sx={{ color: "text.secondary" }}>
          {pick !== null
            ? listPick.filter((item) => item.id === pick?.id)[0]?.address
            : "-"}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ p: 2 }}>
        <LoadingButton
          onClick={handlePick}
          loading={isSubmitting}
          variant="contained"
        >
          Pick Customer
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}

export default PickCustomer;
