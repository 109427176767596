//
import { useEffect, useState, useMemo } from "react";
import socket from "../../../../constant/socket";

//
import loSummary from "../../../../constant/loSummary";

function GetLoSummary(id) {
  const [data, setData] = useState({});

  const [loading, setLoading] = useState(false);

  const [load, setLoad] = useState(false);

  const [error, setError] = useState("");

  useEffect(() => {
    const getAll = async () => {
      setLoading(true);
      try {
        const res = await loSummary.getOnce(id);

        setData(res.data);
      } catch (e) {
        setError(e);
      }
      setLoading(false);
    };

    if (id) {
      getAll();
    }
  }, [id]);

  useEffect(() => {
    const getAll = async () => {
      try {
        const res = await loSummary.getOnce(id);

        setData(res.data);
      } catch (e) {
        setError(e);
      }
      setLoad(false);
    };

    if (id && load) {
      getAll();
    }
  }, [id, load]);

  useEffect(() => {
    const getAll = async () => {
      try {
        const res = await loSummary.getOnce(id);

        setData(res.data);
      } catch (e) {
        setError(e);
      }
    };

    socket.on("load", () => {
      getAll();
    });
  }, [id]);

  return useMemo(
    () => [data, loading, setLoad, error],
    [data, loading, setLoad, error]
  );
}

export default GetLoSummary;
