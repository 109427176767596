//
import {
  Typography,
  styled,
  tableCellClasses,
  TableCell,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TextField,
} from "@mui/material";
import { NumberFormatQty, NumberFormatRp } from "../../../components";
//
import { formatThousand } from "../../../utils";

function OrderLine({
  pbbkb,
  oat,
  fee_agent,
  orderLine,
  hide,
  disabled,
  setOrderLine,
}) {
  const priceWithTax = Math.round(
    (orderLine?.total ?? 0) / (orderLine?.qty ?? 0)
  );

  const ppnVal = 0.11;

  const pbbkbVal = pbbkb ? pbbkb / 100 : 0;

  const basicPrice = Math.round(priceWithTax / (1 + ppnVal + pbbkbVal));

  const pbbkbPrice = basicPrice * pbbkbVal;

  const ppnPrice = basicPrice * ppnVal;

  const biaya = oat + fee_agent;

  const netPrice = basicPrice - biaya;

  return (
    <>
      <Typography mt={4} mb={3}>
        Order Lines
      </Typography>

      {/* Table */}
      <TableContainer>
        <Table>
          {/* Head */}
          <TableHead>
            <TableRow>
              <StyledTableCell style={{ width: !disabled && 200 }}>
                Product
              </StyledTableCell>
              <StyledTableCell style={{ width: !disabled && 200 }}>
                Quantity (L)
              </StyledTableCell>
              <StyledTableCell>Base Price</StyledTableCell>
              <StyledTableCell>PBBKB ({pbbkb}%)</StyledTableCell>
              <StyledTableCell>PPN (11%)</StyledTableCell>
              <StyledTableCell style={{ width: !disabled && 250 }}>
                Grand Total
              </StyledTableCell>
              {!hide && <StyledTableCell>Biaya</StyledTableCell>}
              {!hide && (
                <StyledTableCell>
                  Nett <br /> Product Price
                </StyledTableCell>
              )}
              {!hide && (
                <StyledTableCell>
                  Publish
                  <br />
                  Price
                </StyledTableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{orderLine?.products?.name ?? "-"}</TableCell>
              {!disabled ? (
                <TableCell>
                  <TextField
                    name="qty"
                    autoComplete="off"
                    placeholder="Quantity"
                    size="small"
                    fullWidth
                    value={orderLine.qty}
                    onChange={(e) => {
                      setOrderLine((o) => ({
                        ...o,
                        so_detail: {
                          ...orderLine,
                          qty: e.target.value,
                        },
                      }));
                    }}
                    InputProps={{
                      inputComponent: NumberFormatQty,
                    }}
                    disabled={orderLine?.product === null}
                  />
                </TableCell>
              ) : (
                <TableCell>{formatThousand(orderLine?.qty ?? 0)} L</TableCell>
              )}
              <TableCell>Rp. {formatThousand(basicPrice)} / L</TableCell>
              <TableCell>Rp. {formatThousand(pbbkbPrice)}</TableCell>
              <TableCell>Rp. {formatThousand(ppnPrice)}</TableCell>
              {!disabled ? (
                <TableCell>
                  <TextField
                    name="total"
                    autoComplete="off"
                    placeholder="Grand Total"
                    size="small"
                    onChange={(e) => {
                      setOrderLine((o) => ({
                        ...o,
                        so_detail: {
                          ...orderLine,
                          total: e.target.value,
                        },
                      }));
                    }}
                    value={orderLine?.total}
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatRp,
                    }}
                  />
                </TableCell>
              ) : (
                <TableCell>
                  Rp. {formatThousand(orderLine?.total ?? 0)}
                </TableCell>
              )}

              {!hide && <TableCell>Rp. {formatThousand(biaya)}</TableCell>}
              {!hide && (
                <TableCell>Rp. {formatThousand(netPrice)} / L</TableCell>
              )}
              {!hide && (
                <TableCell>
                  Rp. {formatThousand(orderLine?.publish_price?.price ?? 0)} / L
                </TableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

// Components
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.grey[200],
    color: "text.primary",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default OrderLine;
