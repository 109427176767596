import axios from "../config";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  create: (data) => axios.post("/province", data),
  getAll: () => axios.get("/province"),
  getPajak: () => axios.get("/province/pajak"),
  update: (id, data) => axios.put(`/province/${id}`, data),
  delete: (id) => axios.delete(`/province/${id}`),
};
