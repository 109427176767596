//
import { useState } from "react";
//
import {
  Container,
  Button,
  Typography,
  Stack,
  Card,
  TableCell,
  TableContainer,
  TableBody,
  Table,
  TableRow,
  TablePagination,
} from "@mui/material";
//
import {
  ActionTable,
  ListHead,
  LoadingPage,
  Page,
  SearchNotFound,
  TableToolBar,
} from "../../../components";
//
import { Icon } from "@iconify/react";
import plusFill from "@iconify/icons-eva/plus-fill";
//
import AddPaymentTerm from "./AddPaymentTerm";
//
import paymentTerm from "../../../constant/paymentTerm";
//
import { GetAll } from "./API";
//
import { toast } from "react-toastify";
import { applySortFilter, getComparator } from "../../../utils";
//
import { useData } from "../../../context/DataProvider";

// Table Head
const TABLE_HEAD = [
  { id: "no", label: "No", alignRight: false, isSort: false },
  { id: "name", label: "Name", alignRight: false, isSort: false },
  { id: "note", label: "Note", alignRight: false, isSort: false },
  { id: "value", label: "Value", alignRight: false, isSort: false },
  { id: "" },
];

const TABLE_HEAD_BOD = [
  { id: "no", label: "No", alignRight: false, isSort: false },
  { id: "name", label: "Name", alignRight: false, isSort: false },
  { id: "note", label: "Note", alignRight: false, isSort: false },
  { id: "value", label: "Value", alignRight: false, isSort: false },
];

function PaymentTerm() {
  const { user } = useData();

  const [dialog, setDialog] = useState({
    open: false,
    data: {},
    id: "",
    mode: "Add",
  });

  const [q, setQ] = useState("");

  const [listPayment, loading, refresh] = GetAll();

  const [no, setNo] = useState(0);

  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [order, setOrder] = useState("asc");

  const [orderBy, setOrderBy] = useState("name");

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    if (newPage > page) {
      setNo(no + 5);
    } else {
      setNo(no - 5);
    }
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  if (loading) {
    return <LoadingPage />;
  }

  const paymentList = applySortFilter(
    listPayment,
    getComparator(order, orderBy),
    q,
    "name"
  );

  const isNotFound = paymentList?.length === 0;

  return (
    <Page title="Payment Term | Bayu Sinergi">
      <Container maxWidth="xl">
        {/* Header */}
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            List Payment Term
          </Typography>
          {user?.role !== "bod" && (
            <Button
              onClick={(e) => {
                setDialog({ mode: "Add", open: true, data: {}, id: "" });
              }}
              variant="contained"
              startIcon={<Icon icon={plusFill} />}
            >
              New Payment Term
            </Button>
          )}
        </Stack>

        {/* Table */}
        <Card>
          {/* Search */}
          <TableToolBar
            value={q}
            onChange={(e) => {
              setQ(e.target.value);
              setPage(0);
            }}
            placeholder="Search Payment Term"
            onClear={() => setQ("")}
          />

          {/* Table */}
          <TableContainer sx={{ minWidth: 200 }}>
            <Table>
              <ListHead
                order={order}
                orderBy={orderBy}
                headLabel={user?.role === "bod" ? TABLE_HEAD_BOD : TABLE_HEAD}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {paymentList
                  ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{index + 1 + no}</TableCell>
                        <TableCell>{item?.name}</TableCell>
                        <TableCell>{item?.note}</TableCell>
                        <TableCell>{item?.value}</TableCell>
                        {user?.role !== "bod" && (
                          <ActionTable
                            onEdit={() => {
                              setDialog({
                                open: true,
                                data: item,
                                id: item.id,
                                mode: "Edit",
                              });
                            }}
                            onDelete={async () => {
                              try {
                                await paymentTerm.delete(item?.id);

                                refresh(true);

                                toast.success("Payment Term Deleted!");
                              } catch (e) {
                                console.log(e);
                              }
                            }}
                          />
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
              {isNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={4} sx={{ py: 3 }}>
                      <SearchNotFound searchQuery={q} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={paymentList?.length ?? 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>

      {/* Dialog */}
      <AddPaymentTerm
        dialog={dialog}
        load={() => {
          refresh(true);
        }}
        onClose={() => {
          setDialog({
            open: false,
            data: {},
            id: "",
            mode: "Add",
          });
        }}
      />
    </Page>
  );
}

export default PaymentTerm;
