import axios from "../config";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  create: (data) => axios.post("/sales-customer/", data),
  update: (data) => axios.put("/sales-customer/", data),
  getCustomer: () => axios.get(`/sales-customer/`),
  getPickCustomer: () => axios.get(`/sales-customer/pick`),
  pickCustomer: (id, data) => axios.put(`/sales-customer/${id}`, data),
  getSales: (id) => axios.get(`/sales-customer/sales/${id}`),
};
