import { useState } from "react";

// material
import { styled } from "@mui/material";

//
import { DashboardNavbar, DashboardSidebar } from "../../layouts";

//
import { Switch, Route, useLocation } from "react-router-dom";

//
import {
  Dashboard,
  User,
  Customer,
  Product,
  PurchaseOrder,
  ListOrder,
  PaymentTerm,
  Depo,
  Pajak,
  Province,
  Mitra,
  ListLoadingOrder,
  ListPage,
  AccessRole,
  DetailCustomer,
  DetailOrder,
  Region,
  ProductCategory,
  DetailAccessRole,
  DetailProduct,
  CustomerType,
  BusinessType,
  PartnerType,
  PaymentMethod,
  DetailMitra,
  ListDeliveryOrder,
  Settings,
  ReportBod,
  Report,
  ReportAdmin,
  ReportSalesRep,
  ReportOpeartion,
  Target,
} from "./";

function Navigator() {
  const [open, setOpen] = useState(false);

  const location = useLocation();

  const sidebarConfig = [
    {
      path: "/dashboard",
      component: Dashboard,
    },
    {
      path: "/user",
      component: User,
    },
    {
      path: "/customer",
      component: Customer,
    },
    {
      path: "/customer/:id",
      component: DetailCustomer,
    },
    {
      path: "/customer-type",
      component: CustomerType,
    },
    {
      path: "/product",
      component: Product,
    },
    {
      path: "/product/:id",
      component: DetailProduct,
    },
    {
      path: "/pajak",
      component: Pajak,
    },
    {
      path: "/new-order",
      component: PurchaseOrder,
    },
    {
      path: "/order",
      component: ListOrder,
    },
    {
      path: "/order/:id",
      component: DetailOrder,
    },
    {
      path: "/loading-order",
      component: ListLoadingOrder,
    },
    {
      path: "/payment-term",
      component: PaymentTerm,
    },
    {
      path: "/depo",
      component: Depo,
    },
    {
      path: "/province",
      component: Province,
    },
    {
      path: "/mitra",
      component: Mitra,
    },
    {
      path: "/mitra/:id",
      component: DetailMitra,
    },
    {
      path: "/page",
      component: ListPage,
    },
    {
      path: "/access-role",
      component: AccessRole,
    },
    {
      path: "/access-role/:id",
      component: DetailAccessRole,
    },
    {
      path: "/access-role",
      component: AccessRole,
    },
    {
      path: "/region",
      component: Region,
    },
    {
      path: "/product-category",
      component: ProductCategory,
    },
    {
      path: "/business-type",
      component: BusinessType,
    },
    {
      path: "/partner-type",
      component: PartnerType,
    },
    {
      path: "/payment-method",
      component: PaymentMethod,
    },
    {
      path: "/delivery-order",
      component: ListDeliveryOrder,
    },
    {
      path: "/settings",
      component: Settings,
    },
    {
      path: "/dashboard-report",
      component: ReportBod,
    },
    {
      path: "/report",
      component: Report,
    },
    {
      path: "/report-admin",
      component: ReportAdmin,
    },
    {
      path: "/report-salesrep",
      component: ReportSalesRep,
    },
    {
      path: "/report-operation",
      component: ReportOpeartion,
    },
    {
      path: "/target",
      component: Target,
    },
  ];

  return (
    <RootStyle>
      <DashboardNavbar
        onOpenSidebar={() => setOpen(!open)}
        isOpenSidebar={open}
      />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(!open)}
      />
      <MainStyle
        dashboard={
          location?.pathname === "/dashboard-report" ? "true" : "false"
        }
      >
        <Switch>
          {sidebarConfig.map((item, index) => {
            return (
              <Route
                exact
                component={item.component}
                path={item.path}
                key={index}
              />
            );
          })}
        </Switch>
      </MainStyle>
    </RootStyle>
  );
}

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme, dashboard }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: dashboard === "true" ? 0 : APP_BAR_MOBILE + 24,
  paddingBottom: dashboard === "true" ? 0 : theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: dashboard === "true" ? 0 : APP_BAR_DESKTOP + 24,
    paddingLeft: dashboard === "true" ? 0 : theme.spacing(2),
    paddingRight: dashboard === "true" ? 0 : theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default Navigator;
