import { CircularProgress, styled, Typography } from "@mui/material";

function LoadingOrder() {
  return (
    <RootStyle>
      <div>
        <CircularProgress size={60} />
        <Typography mt={2} style={{ color: "white", fontWeight: "bold" }}>
          Loading...
        </Typography>
      </div>
    </RootStyle>
  );
}

// Component

const RootStyle = styled("div")(() => ({
  display: "flex",
  position: "fixed",
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  zIndex: 9999,
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  height: "100vh",
  backgroundColor: "rgba(0,0,0,0.4)",
  textAlign: "center",
}));

export default LoadingOrder;
